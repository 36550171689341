import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  @Input() title: string = ''; // El título que puede contener la palabra a reemplazar
  @Input() items: any[] = [];
  @Input() active_item: string = '';

  constructor() {}

  ngOnInit() {
    // Reemplazar "#NOMBRE_PAGINA" por el valor de "title"
    if (this.title) {
      // Seleccionar el div por su id
      var titleDiv = document.getElementById("tittle_page");
      titleDiv.innerHTML = this.title; 

    }
  }


}
