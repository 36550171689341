import { Component, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs'
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";

import { environment } from '../../environments/environment';


@Component({
  selector: 'app-asesor-select-tienda',
  templateUrl: './asesor-select-tienda.component.html',
  styleUrls: ['./asesor-select-tienda.component.scss']
})
export class AsesorSelectTiendaComponent implements OnInit {

  tiendas: []
  tienda_selected

  constructor(private http: HttpClient, public router: Router) {
    this.tienda_selected = localStorage.getItem('tienda_selected')
  }

  pickTienda(){
    localStorage.setItem('tienda_selected', this.tienda_selected)
  }


  async ngOnInit() {
    const data = new FormData()
    data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}ClientAPI/get_stores_assigned_by_user_id`, data))
    if(response.success){
      this.tiendas = response.stores
  
      localStorage.setItem('tienda_selected', response.stores[0].id)
     
    }
  }

}
