import { Component, Input, Output, EventEmitter,  } from '@angular/core';

@Component({
  selector: 'app-product-quantity',
  templateUrl: './product-quantity.component.html',
  styleUrls: ['./product-quantity.component.scss']
})
export class ProductQuantityComponent {
  @Input() quantity: number = 1; // Cantidad inicial
  @Input() min: number = 1; // Cantidad mínima permitida
  @Input() max: number = 10; // Cantidad máxima permitida

  @Output() quantityChange: EventEmitter<number> = new EventEmitter<number>();

  increment() {
    if (this.quantity < this.max) {
      this.quantity++;
      this.emitQuantityChange();
    }
  }

  decrement() {
    if (this.quantity > this.min) {
      this.quantity--;
      this.emitQuantityChange();
    }
  }

  private emitQuantityChange() {
    this.quantityChange.emit(this.quantity);
  }


}
