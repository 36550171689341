<div class="detail-header decoration-header bg-primary text-white"></div>

<div class="container">

    <div class="row">
        <div class="col-4">
            <img src="../../assets/images/churromania/banner_1.png" alt="image-detail" class="img-fluid w-100">
        </div>
        <div class="col-8">
            <div *ngIf="product['product_category_id'] == 'combo'">
                <div class="form-group">
                    <h2>{{product['name']}}</h2>
                    <p>{{product['description']}}</p>
                </div>
        
                 <div *ngFor="let group of groupedProducts"> 
                    <hr>
                    <div class="grupo" *ngFor="let item of group.products">
                        <label>
                            <input [value]="item.variable.id" 
                                   type="checkbox" 
                                   [checked]="isChecked(item)"
                                   (change)="onCheckboxChangeCombo($event,item)">
                            {{ item.producto.name }} 
                            <span *ngIf="item.variable">- {{ item.variable.name }}</span>
                          </label>
                   
                    </div>
                </div> 
            </div>

            <div *ngIf="product['product_category_id'] != 'combo'">
                <div class="form-group">
                    <h3>{{products['name']}}</h3>
                </div>
            
                <div *ngIf="products['product_type'] == 2">
                    <div *ngIf="variables_product.length != 0" class="col-12 col-md-12 row">
                        <div class="col-12 col-md-8" *ngFor="let item of atributes_product | keyvalue">
                            <div class="row align-items-center">
                                <div class="col-2">
                                    <label>{{ item.key }}</label>
                                </div>
                                <div class="col-6">
                                    <ng-select 
                                            class="bg-white"
                                            [(ngModel)]="selectedAttributes[item.key]" 
                                            [items]="item.value" 
                                            bindLabel="item" 
                                            placeholder="Seleccionar {{item.key}}"
                                            (change)="onChangeVariable()">
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div *ngIf="products['extras_azucar'] || products['extras']   ">

        
                   
                    <div class="card mt-2 mb-3">
                        <div class="card-body p-3 scrollable-card">
                            
                            <ng-container *ngIf="products['extras_azucar'].length > 0" >
                                <p> 
                                    <b>Elige tu azúcar favorita</b> 
                                </p>
    
                                <div *ngIf="products['extras_azucar']">
                                    <div class="extras-container-azucar">
                                        <div *ngFor="let option of products['extras_azucar']; let i = index" class="extra-item">
                                            <div class="form-check">
                                                <input 
                                                    type="checkbox" 
                                                    class="form-check-input circle-checkbox"
                                                    [value]="option.id" 
                                                    [checked]="selectedExtrasAzucar.includes(option.id)" 
                                                    [disabled]="selectedExtrasAzucar.length > 0 && !selectedExtrasAzucar.includes(option.id)"
                                                    (change)="onCheckboxChange($event, option.id)"
                                                    id="extra-azucar-{{option.id}}">
                                                
                                                <label class="form-check-label m-1" for="extra-azucar-{{option.id}}">
                                                    {{ option.name }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr>

                            </ng-container>
                            
                            
                            

                            <ng-container *ngIf="products['qty_extras'].length > 0" >
                                <p> 
                                    <b>Salsas </b> 
                                    <label class="text-secondary mt-3">(Elegir máximo {{ products['qty_extras'] }} opciones)</label> 
                                </p>
                                
                                <div *ngIf="products['qty_extras'] != 0">
                                    <div *ngFor="let option of products['extras']; let i = index" class="mb-2">
                                        <!-- Cambia justify-content-between por justify-content-start -->
                                        <div class="d-flex align-items-center justify-content-start">
                                            <!-- Ajusta el espaciado entre los elementos -->
                                            <img width="20px" src="../../assets/images/churromania/salsa.png" alt="image-detail" class="img-fluid m-2">
                                            
                                            <span 
                                                [ngClass]="{'variable-selected': selectedExtras[i]?.count > 0, 'no-selected': selectedExtras[i]?.count <= 0}"
    
                                                class="flex-grow-1">
                                                {{ option.name }} - {{ currency_symbol_selected }} {{ option.price }}
                                            </span>
                        
                                            <div class="d-flex align-items-center">
                                                <button *ngIf="selectedExtras[i]?.count > 0"
                                                    [ngClass]="{'btn-qty': selectedExtras[i]?.count > 0, 'btn-qty-no-selected': selectedExtras[i]?.count <= 0}"
                                                    class="btn btn-secondary me-2"
                                                    [disabled]="selectedExtras[i]?.count <= 0"
                                                    (click)="decrementExtra(i)">
                                                    -
                                                </button>
                                                <span *ngIf="selectedExtras[i]?.count > 0" 
                                                    class="mx-2">{{ selectedExtras[i]?.count || 0 }}</span>
                                                <button 
                                                    [ngClass]="{'btn-qty': selectedExtras[i]?.count > 0, 'btn-qty-no-selected': selectedExtras[i]?.count <= 0}"
                                                    class="btn btn-secondary"
                                                    [disabled]="totalSelectedExtras >= products['qty_extras']"
                                                    (click)="incrementExtra(i)">
                                                    +
                                                </button>
                                            </div>
                                        </div>
                                        <hr class="m-1" *ngIf="products['extras'].length > 1 && option !== products['extras'][products['extras'].length - 1]">
                                    </div>
                                </div>
                            </ng-container>

                            
                        </div>
                    </div>
                    
                    
                    
                    
                </div>
              

                <div  class="col-12 col-md-12 row">
                    <div class="row align-items-center">

                            <div class="col-2">
                                <label  class="cantidad_label" for="cantidadProducto">Cantidad: </label>
                            </div>

                            <div class="col-6 form-group ">
                                    <div class="input-group">
                                        <!-- Botón izquierdo con redondeo solo a la izquierda -->
                                        <div class="input-group-prepend">
                                            <button
                                                class="btn-qty btn btn-secondary border-right-0 rounded-left"
                                                type="button"
                                                (click)="decrement()"
                                            >
                                                -
                                            </button>
                                        </div>
                                
                                        <!-- Input central sin bordes redondeados -->

                                        <p class="valor-qty">{{cantidadProducto}}</p>
                                     
                                
                                        <!-- Botón derecho con redondeo solo a la derecha -->
                                        <div class="input-group-append">
                                            <button
                                                class="btn-qty btn btn-secondary border-left-0 rounded-right"
                                                type="button"
                                                (click)="increment()"
                                            >
                                                +
                                            </button>
                                        </div>
                                    </div>
                            </div>
                        </div>
            
                </div>

                
               
                <div class="price" style="margin: 10px auto;">Subtotal:  {{currency_symbol_selected}} {{ price_variable | currency: currency_selected: ''}}</div>

                
            </div>

            <div class="form-group buttons" style="margin-top:10px;">
                <button [attr.disabled]="is_loading? true:null" class="btn btn-primary" (click)="onSubmit()">Agregar</button>
                <button class="btn btn-secondary" (click)="onCancel()">Cancelar</button>
            </div>
        </div>
    </div>



</div>