import { Component, OnInit } from '@angular/core';
import { BlackListService } from '../../../src/app/black-list.service';
import { ProductsService } from '../products.service';
import { OrdersService } from '../orders.service';

import { ActivatedRoute, Router} from '@angular/router';
import { lastValueFrom } from 'rxjs'
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { OrderDetailKitchenComponent } from '../order-detail-kitchen/order-detail-kitchen.component';


import Swal from 'sweetalert2';
import { co } from '@fullcalendar/core/internal-common';

@Component({
  selector: 'app-kitchen-display',
  templateUrl: './kitchen-display.component.html',
  styleUrls: ['./kitchen-display.component.scss']
})
export class KitchenDisplayComponent implements OnInit {


  protected orders = []

  // ARRAY CON FILTROS
  filtros
  buttonFiltrarTexto
  isCollapsed
  default_values
  estado
  cantidades
  constructor(
    private BlackListService: BlackListService,
    private ProductsService: ProductsService,
    private OrdersService: OrdersService,
    private router: Router,
    private http: HttpClient,
    private modalService: NgbModal,

  ) { 

    this.filtros = {}
    this.estado = "processing"

    if (localStorage.getItem("persons_filtros")) {
      // this.filtros = JSON.parse(localStorage.getItem("persons_filtros"))
    }
  
    this.default_values = {}


  }

  getTodayDate(): string {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = today.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  setTodayIfEmpty(): void {
    if (!this.filtros.dia) {
      this.filtros.dia = this.getTodayDate();
    }
  }

  mensajesEstado: { [key: string]: string } = {
    'processing': 'No hay pedidos pendientes en estos momentos. ¡Prepárate para la acción!',
    'completed': 'No hay pedidos completados recientemente.',
    'on-hold': 'No hay pedidos en Cocina.',
    'cancelled': '❌ No hay pedidos cancelados. ¡Todo está en orden! ✅',
  };
  
  async ngOnInit() {
    this.filtros.dia = this.getTodayDate();

    this.init_service()
    this.load_orders()
    this.load_values()
  }

  jwt: string;

  tienda_selected;

  async init_service() {
    this.jwt = JSON.parse(localStorage.user).jwt;

    this.tienda_selected = JSON.parse(localStorage.tienda_selected);

    if (this.tienda_selected) {
      this.filtros.tienda_id = this.tienda_selected;
    }

  }

  rerender(): void {


    // localStorage.setItem("persons_filtros", JSON.stringify(this.filtros))

    this.buttonFiltrarTexto = `Cargando...`
    this.load_orders();

    this.buttonFiltrarTexto = "Filtrar"

  }




  async load_values() {

    this.buttonFiltrarTexto = "Filtrar"


  }




  validarCorreo(correo: string): boolean {
    const cadena = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (correo) {
      return cadena.test(correo);
    } else {
      return false;
    }
  }

  validarCelular(celular: string): boolean {
    if (celular) {
      if (this.isNumber(celular)) {
        if (celular.length === 9) {
          return true;
        }
      }
    }
    return false;
  }

  private isNumber(n: any): boolean {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }



  
  async openImprimirModal(comprobante){
    const pdfWindow = window.open(comprobante, '_blank', 'width=800,height=600');
    pdfWindow?.print();
  }

  async openDetailsModal(id: string) {
    if (id) {

     

      const dialogRef = this.modalService.open(OrderDetailKitchenComponent, {
        size: 'xl'
      });
  
      dialogRef.componentInstance.id_pedido = id;
  
      dialogRef.result.then(
        (data) => {
          console.log(data)
        },
        (reason) => {
          console.log('Modal cerrado con motivo:', reason);
        }
      );


      
    }
  }


  onStatusChange(newStatus: string, orderId: number): void {
      const order = this.orders.find(o => o.id === orderId);

      if (order) {

          const order_id = order.id;
          const order_estado = newStatus;
          this.editOrder(order_id, order_estado);

      }
  }

  async openEditonfirmationModal(id: number) {
    const order = this.orders.find(o => o.id === id);
    if (order) {
        // Mostrar un cuadro de confirmación antes de proceder
        const result = await Swal.fire({
            title: '¿Estás seguro de actualizar este pedido?',
            text: `Pedido #${order.id} - Estado: ${this.getStatusText(order.status)}`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Sí, actualizar',
            cancelButtonText: 'Cancelar'
        });

        if (result.isConfirmed) {
            // Si el usuario confirma, proceder con la actualización
            const order_id = order.id;
            const order_estado = order.status;
            this.editOrder(order_id, order_estado);
        } else {
            console.log('Actualización cancelada.');
        }
    } else {
        Swal.fire({
            title: 'Error al actualizar Pedido',
            text: 'Hubo un problema al intentar encontrar la orden.',
            icon: 'error',
            confirmButtonText: 'Entendido',
            timer: 3000
        });
    }
}

async editOrder(order_id: number, estado: string) {
    const data = new FormData();
    data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
    data.append('order', order_id.toString());
    data.append('estado', estado);

    try {
        const res = <any>await this.OrdersService.editOrder(data);

        if (res.success) {
           
            this.load_orders(); 
        } else {
            Swal.fire({
                title: 'Error al Actualizar Pedido',
                text: 'Hubo un problema al intentar actualizar el producto.',
                icon: 'error',
                confirmButtonText: 'Entendido',
                timer: 3000
            });
        }
    } catch (error) {
        Swal.fire({
            title: 'Error de Conexión',
            text: 'Hubo un problema de red o servidor al intentar actualizar el pedido.',
            icon: 'error',
            confirmButtonText: 'Entendido',
            timer: 3000
        });
    }
}


  async load_orders() {

    Swal.showLoading();
    this.filtros.estado = this.estado;

    const res = <any>await this.OrdersService.get_orders__display_kitchen_filter(this.filtros);
    
    if (res.success) {
      
      this.orders = res.orders;
      this.cantidades = res.cantidades;

 
    }
    Swal.close()
  }


  getStatusClass(status: string): string {
    switch (status) {
        case 'pending':
            return 'status-pending';
        case 'processing':
            return 'status-processing';
        case 'on-hold':
            return 'status-on-hold';
        case 'completed':
            return 'status-completed';
        case 'cancelled':
            return 'status-cancelled';
        case 'refunded':
            return 'status-refunded';
        case 'failed':
            return 'status-failed';
        default:
            return '';
    }
}

elapsedTime(dateString: string): string {
  const created = new Date(dateString);
  const now = new Date();
  const diffMs = now.getTime() - created.getTime();
  
  // Convertir a minutos
  const minutes = Math.floor(diffMs / 60000);
  
  if (minutes < 1) {
    return 'Hace menos de un minuto';
  }
  if (minutes < 60) {
    return `Hace ${minutes} minuto${minutes === 1 ? '' : 's'}`;
  }
  
  // Convertir a horas
  const hours = Math.floor(minutes / 60);
  if (hours < 24) {
    return `Hace ${hours} hora${hours === 1 ? '' : 's'}`;
  }
  
  // Convertir a días
  const days = Math.floor(hours / 24);
  return `Hace ${days} día${days === 1 ? '' : 's'}`;
}

getStatusText(status: string): string {
    switch (status) {
        case 'pending':
            return 'Pendiente de Pago';
        case 'processing':
            return 'Procesando';
        case 'on-hold':
            return 'En Espera';
        case 'completed':
            return 'Completado';
        case 'cancelled':
            return 'Cancelado';
        case 'refunded':
            return 'Reembolsado';
        case 'failed':
            return 'Fallido';
        default:
            return 'Estado desconocido';
    }
}

onViewChange(view: string): void {
  this.estado = view;
  this.load_orders();

}





}


