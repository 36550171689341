<app-breadcrumb [title]="'Agregar venta'" [items]="['Ventas', 'Agregar']"></app-breadcrumb>

<div class="container-fluid">
    <div class="row">
        <div class="col-8">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                              <div class="search">
                                <form class="theme-form">
                                  <div class="form-group">
                                    <input class="form-control" [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}"
                                      (keyup)="searchTerm(searchText)" type="text" placeholder="Buscar productos"><i class="fa fa-search"></i>
                                  </div>
                                </form>
                            </div>
                            
                            
                        </div>
                        <div class="col-12">
                          <div class="categorias-container">
                            <button class="categoria-btn active"  [ngClass]="{'active': selectedButton === -1}" (click)="onCategoriaClick('',-1)">Todos</button>
                            <button 
                              *ngFor="let categoria of list_of_categories; let i = index" 
                              [ngClass]="{'active': selectedButton === i}"
                              (click)="onCategoriaClick(categoria,i)" 
                              class="categoria-btn">
                              {{ categoria.name }}
                            </button>
                            <button class="categoria-btn active"  [ngClass]="{'active': selectedButton === -2}" (click)="onCategoriaClick('combos',-2)">Combos</button>

                          </div>
                        </div>
                        <div class="col-12">
                          <div class="product-wrapper-grid" [class.list-view]="listView">
                            <div class="row gx-5"> <!-- Agregamos g-3 para el espacio -->
                              <div *ngFor="let product of searchProducts" 
                                   class="col-xl-4 col-md-6 col-sm-12 mb-5" 
                                   [class.col-xl-12]="listView">
                                <div class="product-box ml-2 mr-2">
                                  
                                  <div class="product-img">
                                    <img class="img-fluid img-product" src="{{ product.photo }}" alt="">
                                  </div>

                                  <div class="product-details">
                                    <h4>{{ product.name }}</h4>
                                    <div *ngIf="product.product_type == 2">
                                      <div *ngIf="product.price_range.min_price === product.price_range.max_price" 
                                           class="product-price">
                                        {{currency_symbol_selected}} {{ product.price_range.min_price | currency: currency_selected: ''}}
                                      </div>
                                      <div *ngIf="product.price_range.min_price !== product.price_range.max_price" 
                                           class="product-price">
                                        {{currency_symbol_selected}} {{ product.price_range.min_price | currency: currency_selected: ''}} - 
                                        {{currency_symbol_selected}} {{ product.price_range.max_price | currency: currency_selected: ''}}
                                      </div>
                                    </div>

                                    <div *ngIf="product.product_type != 2">
                                      <div class="product-price">
                                        {{currency_symbol_selected}} {{ product.price | currency: currency_selected: ''}}
                                      </div>
                                    </div>

                                    <button class="mt-2 btn-custom" (click)="addToCart(product)">Agregar</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        <div class="col-4">
          <div class="card">
            <div class="card-header decoration-header bg-primary text-white">
             
            </div>
            <div class="card-body p-4">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label for="">Seleccione un cliente</label>
                    <div class="box">
                        <ng-select bindValue="id" bindLabel="rut" class="bg-white text-dark"
                            [items]="list_of_customers" class="js-example-basic-multiple"
                            [(ngModel)]="sale.customer_id">
                        </ng-select>
                        <button (click)="open_modal_customer()" class="btn btn-primary">+</button>
                    </div>
                   
                </div>
                </div>
              </div>
              <div class="row">
                
                <div *ngFor="let item of cartItemsCustom">
                  <div class="title-cart-product">

                   
                 
                      <div class="name" *ngIf="item[0].name == null; else noItems">{{ item[0].name_father }}</div>
                      <ng-template #noItems>
                        <div class="name">{{ item[0].name_father }} - {{ item[0].name }} </div>
                      </ng-template>
                      
                      <div class="price"> {{currency_symbol_selected}} {{ item[0].precio | currency: currency_selected: ''}} </div>  
                   
                  
                    <app-product-quantity 
                      [quantity]="item[0].cantidad" 
                      [min]="1" 
                      [max]="item[0].stock" 
                      (quantityChange)="onQuantityChange($event, item[0])">
                    </app-product-quantity>

                    <button class="delete" (click)="removeProduct(item[0].product_id)">x</button>
                  </div>


                  <div class="col-6">
               

                    <div  *ngIf="item[0].qty_extras != 0">
                      <div *ngFor="let extra of item[0].extras_selected" style="display: flex;">
                              <div class="name">+ {{ extra.name }}</div>
                              <div class="price"  *ngIf="extra.price != 0">{{currency_symbol_selected}} {{ extra.price | currency: currency_selected: ''}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-8">
                    <div  *ngIf="item[0].selectedCategoriesProduct && item[0].selectedCategoriesProduct.length > 0">
                      
                      <div *ngFor="let cate of item[0].selectedCategoriesProduct" style="display: flex;">
                              <div class="name">+ {{ cate.producto.name }} - {{ cate.variable.name }}</div>
                              <!-- <div class="price"  *ngIf="extra.price != 0">{{currency_symbol_selected}} {{ extra.price | currency: currency_selected: ''}}</div> -->
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div *ngFor="let item of cartItems">
                  <div class="title-cart-product">
                    <div class="name">{{ item.product.name }}</div>
                  
                    <div class="price">{{currency_symbol_selected}} {{ item.product.price | currency: currency_selected: ''}}</div>
                    <button class="delete" (click)="removeProduct(item.product.product_id)">x</button>
                  </div>
                  <div class="col-6">
                      <app-product-quantity 
                      [quantity]="item.quantity" 
                      [min]="1" 
                      [max]="item.product.stock" 
                      (quantityChange)="onQuantityChange($event, item,false)">
                      </app-product-quantity>
                      <div *ngFor="let extra of item.product.extras">
                        <div *ngFor="let extra2 of item.extras">
                          <div class="extras" *ngIf="extra.id == extra2">
                              <div class="name">+ {{ extra.name }}</div>
                              <div class="price"  *ngIf="extra.price != 0">{{currency_symbol_selected}} {{ extra.price | currency: currency_selected: ''}}</div>
                            </div>
                          </div> 
                      </div>
                  </div>
                   
                   <hr>
                 
                </div>
               -->
                <div class="payment-info"><p>Subtotal</p><p>{{currency_symbol_selected}} {{ subtotal | currency: currency_selected: ''}}</p></div>
                <div class="payment-info"><p>IVA</p><p>{{currency_symbol_selected}} {{ tax | currency: currency_selected: ''}}</p></div>
                <hr class="total">
                <div class="payment-info total"><p>TOTAL</p><p>{{currency_symbol_selected}} {{ total | currency: currency_selected: ''}}</p></div>
                <button class="mt-2 btn-pay" (click)="continuar()" >Continuar con Pedido</button>

                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header decoration-header bg-gray text-white">
               
              </div>

              <div class="card-body">
                
                <div class="row">
                  <label class="subtittle">Forma de pago</label>

                  <div *ngIf="continuar_pedido" class="box-payment-method">
                    <ng-select bindValue="id" bindLabel="name" class="bg-white text-dark"
                        [items]="list_of_payment_method" class="js-example-basic-multiple"
                        [(ngModel)]="sale.payment_id">
                    </ng-select>
                </div>

                <ng-container *ngIf="sale.payment_id">
                  <div class="payment-method" *ngIf="sale.payment_id === 'efectivo'">
                    <input class="form-control" id="cantidadPago" type="number" [(ngModel)]="sale.amount_to_paid" (keyup)="calcularVuelto()" placeholder="Ingrese la cantidad">
                
                    <p *ngIf="sale.vuelto >= 0">Vuelto:{{currency_symbol_selected}} {{ sale.vuelto | currency: currency_selected: ''}}</p>
                    <!-- <p *ngIf="vuelto < 0">Falta: {{ Math.abs(vuelto) | currency }}</p> -->
                  </div>
                  <!-- Checkbox para solicitar factura -->
                  <label for="deseaFactura">
                    <input type="checkbox" id="deseaFactura" [(ngModel)]="sale.desea_factura" (change)="onCheckboxChange($event)">
                    ¿Desea factura?
                  </label>
                    <!-- Campos adicionales que se activan/desactivan según el estado del checkbox -->
                  <div class="factura" *ngIf="sale.desea_factura">
                   
                    <input class="form-control" id="rut" type="text" [(ngModel)]="sale.rut" placeholder="Ingrese su RUT">
                    <input class="form-control" id="razonSocial" type="text" [(ngModel)]="sale.razon_social" placeholder="Ingrese la Razón Social">
  
                  </div>
  
                  <button [disabled]="isButtonDisabled" class="btn-pay" (click)="pay()">Pagar</button>
                  <button class="btn-cancel">Cancelar</button>
                </ng-container>

              </div>
            </div>
          </div>
        </div>
    </div>
</div>