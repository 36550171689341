<app-breadcrumb [title]="''" [items]="['Turnos']" [active_item]="'Listado de turnos'">
</app-breadcrumb>
<div *ngIf="turno_id > 0; then edit_turno else pick_turno"></div>
<ng-template #pick_turno>
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <h5>Lista de turnos</h5>
                        <button (click)="agregar_turno_modal()" class="btn btn-primary m-t-10">Agregar +</button>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-concesionarios">
                            <thead>
                                <tr>
                                    <th scope="col">Nombre</th>
                                    <th scope="col">Fecha</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Editar</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let turno of turnos" class="tr-mobile">
                                    <td>
                                        <div class="td-mobile">Nombre</div><b>{{turno.name}}</b>
                                    </td>
                                    <td>
                                        <div class="td-mobile">Fecha</div>{{turno.created_at}}
                                    </td>
                                  

                                    <td>
                                        <div class="td-mobile">Estado</div>{{turno.status}}
                                    </td>
                                    <td class="text-center">
                                        <div class="td-mobile">Editar</div><a [routerLink]="[turno.id]"><i class="fa fa-edit"></i></a>
                                    </td>
                                
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #edit_turno>
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <h5>Editar turno</h5>
                        <span>{{turno_to_edit.name}}</span>
                    </div>
                    <div class="card-body content-to-edit">
                        <div class="usuario-campo">
                            <div class="row">
                                <div class="col-12 col-md-6 mb-3">
                                    <label class="form-label" for="name">Nombre</label>
                                    <input class="form-control" id="name" type="text" [(ngModel)]="turno_to_edit.name">
                                </div>
                                <div class="col-12 col-md-6 mb-3">
                                    <label class="form-label" for="comment">Comment</label>
                                    <input class="form-control" id="comment" type="text" [(ngModel)]="turno_to_edit.comment">
                                </div>

                            </div>
                        </div>
                        <div class="col-12 text-center m-b-10">
                            <button *ngIf="turno_to_edit.status == 'open'" (click)="asignar()" class="btn btn-primary">!Cerrar turno!</button>
                            <button *ngIf="turno_to_edit.status == 'closed'" (click)="asignar()" class="btn btn-primary">¡Abrir turno!</button>
                            <button  (click)="cancel()" class="btn btn-secondary">Cancelar</button>
                        </div>
                    </div>



                </div>
            </div>
        </div>
    </div>
</ng-template>
