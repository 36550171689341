<app-breadcrumb [title]="'Resumen Ventas'" [items]="['Resumen Ventas']"></app-breadcrumb>

<div class="container-fluid">
    <!-- Filtros -->
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body card-body-filtros">
                    <div id="collapseExample" [ngbCollapse]="isCollapsed">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <!-- Filtro: Día de Ventas -->
                                
                                    <div class="col-12 col-md-6">
                                        <div class="form-group row">
                                            <label class="col-sm-3 col-form-label">Fecha de inicio</label>
                                            <div class="col-sm-9">
                                                <input class="form-control digits"
                                                    [(ngModel)]="filtros.fecha_inicio"
                                                    id="example-datetime-local-input" type="date">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <div class="form-group row">
                                            <label class="col-sm-3 col-form-label">Fecha fin</label>
                                            <div class="col-sm-9">
                                                <input class="form-control digits"
                                                    [(ngModel)]="filtros.fecha_fin"
                                                    id="example-datetime-local-input" type="date">
                                            </div>
                                        </div>
                                    </div>
                                
                                    <!-- Botón Filtrar -->
                                    <div class="col-12 col-md-12">
                                        <button
                                            id="make_filter"
                                            type="button"
                                            class="btn btn-primary"
                                            (click)="rerender()"
                                            [attr.aria-expanded]="!isCollapsed"
                                            aria-controls="collapseExample"
                                            [disabled]="buttonFiltrarTexto !== 'Filtrar'">
                                            <span>{{ buttonFiltrarTexto }}</span>
                                        </button>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <!-- Resumen de Ventas por Día -->
    <div class="row">
        <!-- Ventas Totales -->
        <div class="col-md-6">
          <div class="card shadow-sm">
            <div class="card-header d-flex align-items-center justify-content-between">
              <h5 class="mb-0 text-primary"><i class="fas fa-dollar-sign me-2"></i> Ventas Totales</h5>
              <span class="badge bg-primary text-white">{{ cantidad_dias }} días</span>
            </div>
            <div class="card-body ">
              <table class="table table-striped table-hover">
                <thead>
                  <tr>
                    <th class="text-center">Total Ventas</th>
                    <th class="text-center">Total Impuestos</th>
                  </tr>
                </thead>
                <tbody>
                  <!-- Mostrar mensaje si no hay ventas -->
                  <ng-container *ngIf="sales_summary.length === 0; else salesData">
                    <tr>
                      <td class="text-center" colspan="2">Sin ventas</td>
                    </tr>
                  </ng-container>
      
                  <!-- Datos de ventas -->
                  <ng-template #salesData>
                    <tr *ngFor="let day of sales_summary">
                      <td class="text-center">{{ day.total_sales | currency }}</td>
                      <td class="text-center">{{ day.total_tax | currency }}</td>
                    </tr>
                  </ng-template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      
        <!-- Total de Transacciones -->
        <div class="col-md-6">
          <div class="card shadow-sm">
            <div class="card-header d-flex align-items-center justify-content-between">
              <h5 class="mb-0 text-success"><i class="fas fa-receipt me-2"></i> Total de Transacciones (TRX)</h5>
              <span class="badge bg-success text-white">{{ cantidad_dias }} días</span>
            </div>
            <div class="card-body ">
              <table class="table table-striped table-hover">
                <thead>
                  <tr>
                    <th class="text-center">Total Pedidos</th>
                  </tr>
                </thead>
                <tbody>
                  <!-- Mostrar mensaje si no hay transacciones -->
                  <ng-container *ngIf="sales_summary.length === 0; else trxData">
                    <tr>
                      <td class="text-center">Sin transacciones</td>
                    </tr>
                  </ng-container>
      
                  <!-- Datos de transacciones -->
                  <ng-template #trxData>
                    <tr *ngFor="let day of sales_summary">
                      <td class="text-center">{{ day.total_orders }}</td>
                    </tr>
                  </ng-template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      

    <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h5>Productos Más Vendidos</h5>
            </div>
            <div class="card-body">
              <ngx-charts-bar-vertical
                [view]="[1000, 400]"
                [scheme]="colorScheme"
                [results]="productosMasVendidos"
                [gradient]="gradient"
                [xAxis]="showXAxis"
                [yAxis]="showYAxis"
                [legend]="showLegend"
                [showXAxisLabel]="showXAxisLabel"
                [xAxisLabel]="xAxisLabel"
                [showYAxisLabel]="showYAxisLabel"
                [yAxisLabel]="yAxisLabel"
                [animations]="animations">
              </ngx-charts-bar-vertical>
            </div>
          </div>
        </div>
      </div>
      

    <div class="row">

        <div class="col-4">
             <div class="card">
                <div class="card-header">
                    <h5>Forma de Pago</h5>
                </div>
                <div class="card-body">
                  <ngx-charts-advanced-pie-chart
                    [view]="view"
                    [scheme]="colorScheme"
                    [results]="formaDePago"
                    [gradient]="gradient"
                    [animations]="animations">
                  </ngx-charts-advanced-pie-chart>
                </div>
              </div>
        </div>

        <div class="col-8">
            <div class="card">
                <div class="card-header">
                    <h5>Ventas por Forma de Pago</h5>
                </div>
                <div class="card-body">
                    <ngx-charts-bar-vertical
                    [view]="view"
                    [scheme]="colorScheme"
                    [results]="formaDePago"
                    [gradient]="gradient"
                    [xAxis]="showXAxis"
                    [yAxis]="showYAxis"
                    [legend]="showLegend"
                    [showXAxisLabel]="showXAxisLabel"
                    [xAxisLabel]="xAxisLabel"
                    [showYAxisLabel]="showYAxisLabel"
                    [yAxisLabel]="yAxisLabel"
                    [animations]="animations">
                  </ngx-charts-bar-vertical>
                
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-4">
            <div class="card">
                <div class="card-header">
                    <h5> Cantidad de Clientes

                    </h5>
                </div>
                <div class="card-body">
                    <ngx-charts-number-card
                    [view]="[300,300]"
                    [scheme]="colorScheme"
                    [results]="clientes"
                    [cardColor]="cardColor"
                    (select)="onSelect($event)">
                    </ngx-charts-number-card>
                
                </div>
            </div>
        </div>

        <div class="col-8">
            <div class="card">
                <div class="card-header">
                    <h5>Estados de Ventas</h5>
                </div>
                <div class="card-body">
                    <div class="flot-chart-container grid-pie-chart">
                        <div class="flot-chart-placeholder ngx-chart-direction" id="github-issues">
                            <ngx-charts-pie-grid [scheme]="pieChartColorScheme" [results]="pieChart"
                                (select)="onSelect($event)">
                            </ngx-charts-pie-grid>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

    
  

</div>
