<app-breadcrumb [title]="'Kitchen Display'" [items]="['Kitchen Display']"></app-breadcrumb>

<div class="col-12">
    <div class="card-body card-body-filtros">
 
        <div id="collapseExample" [ngbCollapse]="isCollapsed">
            <div class="card mb-0">
                <div class="card-body">
                    <div class="row">
                     

                        <div class="col-12 col-md-6">
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label">Día de Ventas</label>
                                <div class="col-sm-9">
                                    <input
                                        class="form-control digits"
                                        [(ngModel)]="filtros.dia"
                                        id="example-datetime-local-input"
                                        type="date"
                                        (blur)="setTodayIfEmpty()"
                                    >
                                </div>
                            </div>
                        </div>
                        
              

                
                        <div class="col-12 col-md-6 div-button_filter">
                            <button id="make_filter" type="button" class="btn btn-primary"
                                (click)="rerender()" [attr.aria-expanded]="!isCollapsed"
                                aria-controls="collapseExample"
                                [attr.disabled]="buttonFiltrarTexto != 'Filtrar' ? true:null">
                                <span>{{buttonFiltrarTexto}}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">

                    <!-- Encabezado con los botones de vista -->
                    <div class="d-flex justify-content-around mb-4 align-items-end">
                        <ul class="nav nav-tabs" id="orderTabs" role="tablist">
                            <li class="nav-item" role="presentation">
                                <p 
                                    class="nav-link estado processing" 
                                    [ngClass]="{'active-tab': estado === 'processing'}" 
                                    id="pending-tab" 
                                    (click)="onViewChange('processing')">
                                    🕒 {{cantidades.processing}} En Cola
                                </p>
                            </li>
                    
                            <li class="nav-item" role="presentation">
                                <p 
                                    class="nav-link estado on-hold" 
                                    [ngClass]="{'active-tab': estado === 'on-hold'}" 
                                    id="completed-tab" 
                                    (click)="onViewChange('on-hold')">
                                    🍳 {{cantidades['on-hold']}} En Cocina
                                </p>
                            </li>
                    
                            <li class="nav-item" role="presentation">
                                <p 
                                    class="nav-link estado completed" 
                                    [ngClass]="{'active-tab': estado === 'completed'}" 
                                    id="completed-tab" 
                                    (click)="onViewChange('completed')">
                                    ✅ {{cantidades.completed}} Completados
                                </p>
                            </li>
                    
                            <li class="nav-item" role="presentation">
                                <p 
                                    class="nav-link estado cancelled" 
                                    [ngClass]="{'active-tab': estado === 'cancelled'}" 
                                    id="cancelled-tab" 
                                    (click)="onViewChange('cancelled')">
                                    ❌ {{cantidades.cancelled}} Cancelados
                                </p>
                            </li>
                        </ul>
                    </div>
                    

                    <!-- Mensaje cuando no hay pedidos -->
                    <div *ngIf="orders.length === 0" class="text-center py-5">
                        <p class="h5">{{ mensajesEstado[estado] }}</p>
                    </div>

                    <!-- Lista de pedidos -->
                    <div *ngIf="orders.length > 0" class="container m-5">
                        <div class="row gy-4">
                            <div class="col-md-4" *ngFor="let o of orders">
                                <div class="card shadow-sm border-warning">
                                    <div class="card-header estado {{o.order.status}} border-bottom-0">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <!-- Información principal del pedido -->
                                            <div>
                                                <h6 class="fw-bold mb-0">Pedido #{{ o.order.order_code }}</h6>
                                            </div>
                                            <!-- Fecha del pedido -->
                                            <div>
                                                <span class="small">{{ o.order.created_time | date:'dd/MM/yyyy' }}</span>
                                            </div>
                                        </div>
                                        <!-- Segunda fila: Hora y tiempo transcurrido -->
                                        <div class="d-flex justify-content-between align-items-center mt-2">
                                            <div>
                                                <span class="badge bg-dark text-light small">{{ o.order.created_time | date:'shortTime' }}</span>
                                            </div>
                                            <div>
                                                <span class="small">{{ elapsedTime(o.order.created_time) }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="card-body card-kitchen">

                                        <!-- Cliente: Mostrar solo si hay datos del cliente -->
                                        <div *ngIf="o.cliente_datos?.name || o.cliente_datos?.last_name">
                                            <strong>Cliente:</strong>
                                            <li>{{ o.cliente_datos.name }} {{ o.cliente_datos.last_name }}</li>
                                        </div>
                                    
                                        <!-- Detalle del Pedido -->
                                        <strong>Detalle:</strong>
                                        <ul>
                                            <li *ngFor="let detail of o.order_details">
                                                <!-- Producto principal -->
                                                <div>
                                                    <strong>{{ detail.nombre }}</strong> x{{ detail.quantity }}
                                                    <div class="text-muted">Tipo: {{ detail.tipo }}</div>
                                                </div>
                                    
                                                <!-- Productos Combo (Solo mostrar si hay productos combo) -->
                                                <div *ngIf="detail.productos_combo.length > 0" class="ms-3">
                                                    <small><strong>Productos Combo:</strong></small>
                                                    <ul class="list-unstyled mb-1">
                                                        <li *ngFor="let item of detail.productos_combo" class="text-muted small">{{ item.name }}</li>
                                                    </ul>
                                                </div>
                                    
                                                <!-- Extras (Solo mostrar si hay extras) -->
                                                <div *ngIf="detail.extras.length > 0" class="ms-3">
                                                    <small><strong>Extras:</strong></small>
                                                    <ul class="list-unstyled mb-1">
                                                        <li *ngFor="let extra of detail.extras" class="text-muted small">{{ extra.name }}</li>
                                                    </ul>
                                                </div>
                                            </li>
                                        </ul>
                                    
                                        <!-- Cambiar Estado del Pedido -->
                                        <div class="d-flex gap-3 mt-3">
                                            <select (change)="onStatusChange($event.target.value, o.order.id)" 
                                                    class="form-select" [value]="o.order.status">
                                                <option value="processing">En Cola</option>
                                                <option value="on-hold">En Cocina</option>
                                                <option value="completed">Completado</option>
                                                <option value="refunded">Cancelar Pedido</option>
                                            </select>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


