import { Component, OnInit } from '@angular/core';
import { CampaignService } from '../shared/services/campaign.service';
import { lastValueFrom } from 'rxjs'
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';


import Swal from 'sweetalert2';
@Component({
  selector: 'app-loyalty-campaigns-sms',
  templateUrl: './loyalty-campaigns-sms.component.html',
  styleUrls: ['./loyalty-campaigns-sms.component.scss']
})
export class LoyaltyCampaignsSmsComponent implements OnInit {

  protected campaigns = [ ]

  // ARRAY CON FILTROS
  filtros

  saldo


  buttonFiltrarTexto
  isCollapsed
  default_values

  constructor(
    private campaignService: CampaignService,
    private http: HttpClient,
  ) { 

    this.filtros = {}

    if (localStorage.getItem("campaigns_filtros")) {
      this.filtros = JSON.parse(localStorage.getItem("campaigns_filtros"))
    }
  
    this.default_values = {}


  }

  


  detallado_campaign = {
    remarketing: 0,
    recompra: 0,
    referido: 0,
    encuesta: 0,
    bienvenida: 0,
    total: 0,
    otros: 0,
  }
  
  async ngOnInit() {
    this.load_campaigns()
    this.load_values()
  }

  rerender(): void {


    localStorage.setItem("campaigns_filtros", JSON.stringify(this.filtros))


    this.buttonFiltrarTexto = `Cargando...`
    this.load_campaigns();

    this.buttonFiltrarTexto = "Filtrar"

  }


  async load_values() {

    const res = <any>await this.campaignService.campaign_service_init_general();

    const response_saldo = <any>await this.campaignService.campaign_service_saldo_whatsapp();
    if (response_saldo.success) {
      this.saldo = response_saldo.saldo;
    }

    if (res.success) {

      this.default_values.type = res.payload.type
      this.default_values.tipo_filtro = res.payload.tipo_filtro
    }

    this.buttonFiltrarTexto = "Filtrar"


  }

  async load_campaigns() {
    Swal.showLoading()

    const res = <any>await this.campaignService.get_campaign_filter(this.filtros, "Whatsapp");

    if (res.success) {
      this.campaigns = res.campaigns;

      console.log(this.campaigns);


      this.detallado_campaign = {
        remarketing: res.detallado_leads.remarketing  ? res.detallado_leads.remarketing  : 0 ,
        recompra: res.detallado_leads.recompra ? res.detallado_leads.recompra  : 0 ,
        referido: res.detallado_leads.referidos ? res.detallado_leads.referidos  : 0 ,
        encuesta: res.detallado_leads.encuesta ? res.detallado_leads.encuesta  : 0 ,
        bienvenida: res.detallado_leads.bienvenida ? res.detallado_leads.bienvenida  : 0 ,
        total: res.detallado_leads.total ? res.detallado_leads.total  : 0 ,
        otros: res.detallado_leads.otro ? res.detallado_leads.otro  : 0 ,
      }

    }
    Swal.close()
  }

}



