import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { ConfiguracionesService } from '../configuraciones.service';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router} from '@angular/router';
import { Subscription, lastValueFrom } from 'rxjs';
import { HttpClient } from "@angular/common/http";


const Swal = require('sweetalert2')

@Component({
  selector: 'app-modal-create-country',
  templateUrl: './modal-create-country.component.html',
  styleUrls: ['./modal-create-country.component.scss']
})
export class ModalCreateCountryComponent implements OnInit {

  jwt
  is_loading = false
  imagePreview: string | ArrayBuffer | null = null;
  selectedFile: File | null = null; 
  currency: any;


  country_to_create = {
    name: '',
    status:'',
    currency:'',
    currencySymbol:'',
    imagen: null
  };

  currencies: any[] = [];
  selectedCurrency: any = null;
  selectedCurrencySymbol: any = null;

  constructor(
    private http: HttpClient,
    private activeModalService: NgbActiveModal,
    private configuracionService: ConfiguracionesService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.jwt = JSON.parse(localStorage.user).jwt
  }


  ngOnInit(): void {
    this.loadCurrency();
  }

  loadCurrency(): void {
    this.configuracionService.getCurrency().subscribe((data) => {
      this.currencies = Object.keys(data).map(key => {
        return {
          code: key,
          ...data[key],
          displayLabel: `${data[key].name} - (${data[key].code})`,
          displayLabelSymbol: `${data[key].name} - (${data[key].symbol_native})`
        };
      });
    });
  }
 
 

  async onSubmit() {
 
    if (this.validateFields()) {
      this.is_loading = true;
      // Implementar la lógica para agregar el producto
      console.log(this.country_to_create);
      this.generateCountry()
      this.is_loading = false;
    }
  }


  async generateCountry() {
    const data = new FormData();
    data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
    data.append('name', this.country_to_create.name);
    data.append('currency', this.selectedCurrency);
    data.append('currencySymbol', this.selectedCurrencySymbol);
    data.append('status', this.country_to_create.status);

    if (this.country_to_create.imagen) {
      data.append('imagen', this.country_to_create.imagen);
    }

    const res = <any>await this.configuracionService.generateCountry(data)

    if (res.success) {

      console.log(res)
      Swal.fire({
        title: '¡País Creado!',
        text: 'El país se ha creado exitosamente .',
        icon: 'success',
        confirmButtonText: 'Ok',
      });

      if (res.id_country) {
        this.activeModalService.close(res);
        // const route_link = '/details-product/'+ res.id_product;
        // this.router.navigate([route_link]); // Redirige a otra ruta
      }

    }else{
      Swal.fire({
          title: 'Error al crear país',
          text: 'Hubo un problema al intentar crear el país. Por favor, revisa los datos ingresados o intenta nuevamente más tarde.',
          icon: 'error',
          confirmButtonText: 'Entendido',
          timer: 3000
      });
    }

  }

  onCancel() {
    this.activeModalService.close({
      success: false
    });
  }

  validateFields() {
 
    if (!this.country_to_create.name) {
      Swal.fire('Ingrese nombre', '', 'error')
      return false
    }

    if (!this.selectedCurrency) {
      Swal.fire('Seleccione un tipo de moneda', '', 'error')
      return false
    }

    if (!this.selectedCurrencySymbol) {
      Swal.fire('Seleccione un símbolo', '', 'error')
      return false
    }
    return true;
  }

  clearImage(): void {
    this.selectedFile = null;
    this.imagePreview = null;

    // Limpiar el valor del input de archivo
    const fileInput = document.getElementById('imagenCountry') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
  }

  onFileSelected(event: any) {
    const file = event.target.files[0];

    if (!file) {
        return;
    }

    const maxSizeInMB = 2;
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

    if (file.size > maxSizeInBytes) {
        Swal.fire('Archivo muy grande', `El archivo debe ser menor a ${maxSizeInMB}MB`, 'error');
        return;
    }

    const validTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];
    if (!validTypes.includes(file.type)) {
        Swal.fire('Tipo de archivo no permitido', 'Solo se permiten imágenes (PNG, JPG, GIF)', 'error');
        return;
    }

    const reader = new FileReader();
    reader.onload = () => {
        this.imagePreview = reader.result;
    };
    reader.readAsDataURL(file);
    this.country_to_create.imagen = file;
}

}
