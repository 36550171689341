import { Component, Input, HostListener } from "@angular/core";

@Component({
  selector: "app-price",
  templateUrl: "./price.component.html",
  styleUrls: ["./price.component.scss"],
})
export class PriceComponent {
  @Input() placeholder: string = "0.00";
  @HostListener("keypress", ["$event"])
  onKeyPress(event: KeyboardEvent) {
    const pattern = /[0-9.,]/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
}
