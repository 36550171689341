import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from "@angular/common/http";
import { Subscription, lastValueFrom } from 'rxjs';
import { formatDate } from '@angular/common';
import { environment } from '../../environments/environment';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
const Swal = require('sweetalert2')

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class CustomerComponent implements OnInit {

  private jwt
  private routeSub: Subscription;
  customer_id
  customer_to_edit

  customers: any

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private modalService: NgbModal,
  ) {
    this.jwt = JSON.parse(localStorage.user).jwt
    this.customer_to_edit = {}
    this.routeSub = this.route.params.subscribe(params => {
      this.customer_id = params.customer_id
    })
   }

  ngOnInit(): void {
    if (this.customer_id >= 0) {
      this.get_data_from_customer()
    } else {
      this.refresh_data()
    }
  }

  async get_data_from_customer() {

  }

  async refresh_data() {
    const data = new FormData()
    data.append('jwt', this.jwt)
    const tienda_selected = JSON.parse(localStorage.tienda_selected);
    data.append('tienda_selected', tienda_selected)

    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}ClientAPI/get_list_of_customer`, data))

    if (response.success) {
      this.customers = response.payload.customers
    }
  }


  obtenerFecha(createdTime: string) {
    const fecha = new Date(createdTime);
    
    // Obtiene solo la fecha en formato 'dd/MM/yyyy'
    return formatDate(fecha, 'dd/MM/yyyy', 'en-US');

  }

  sendAlert(message, type) {
    Swal.fire({
      icon: type,
      title: message,
      text: ''
    });
  }

}
