<div class="container">
    <div class="form-group">
        <h2>Crear país</h2>
    </div>
    <div class="row">
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="nombre">Nombre</label>
                <input type="text" class="form-control" id="name" [(ngModel)]="country_to_create.name">
            </div>
        </div>
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="imagenProducto">Icono bandera</label>
                <!-- Input for selecting an image -->
                <input type="file" 
                        class="form-control" 
                        id="imagenCountry" 
                        (change)="onFileSelected($event)" 
                        accept="image/png, image/jpeg, image/jpg">

                <!-- Image preview or default image -->
                <img *ngIf="imagePreview" [src]="imagePreview" alt="Imagen del Bandera" 
                        class="img-thumbnail" style="max-width: 100px; margin: 10px;">

                        <br>
                <!-- Button to deselect the image -->
                <button *ngIf="imagePreview" (click)="clearImage()" class="btn btn-danger" 
                        style="margin-top: 10px;">Eliminar Imagen</button>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="moneda">Moneda</label>
                <ng-select
                    [items]="currencies"
                    bindLabel="displayLabel"    
                    bindValue="code"      
                    [(ngModel)]="selectedCurrency"
                    placeholder="Selecciona una moneda"
                    >
                </ng-select>
            </div>
        </div>
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="simbolo">Símbolo</label>
                <ng-select
                    [items]="currencies"
                    bindLabel="displayLabelSymbol"    
                    bindValue="symbol_native"      
                    [(ngModel)]="selectedCurrencySymbol"
                    placeholder="Selecciona una moneda"
                    >
                </ng-select>
            </div>
        </div>
    </div>

   
    <div class="row">
        <div class="col-12 col-md-6 mb-3">
            <label class="form-label" for="activo">¿Activo?</label>
            <i ngbTooltip="--"
            class="fa fa-fw fa-info-circle"></i>
        <br>
            <label class="switch">
                <input id="activo" [(ngModel)]="country_to_create.status" type="checkbox"><span
                    class="switch-state"></span>
            </label>
        </div>
    </div>
    <div class="form-group buttons">
        <button [attr.disabled]="is_loading? true:null" class="btn btn-primary" (click)="onSubmit()">Agregar</button>
        <button class="btn btn-secondary" (click)="onCancel()">Cancelar</button>
    </div>

</div>
