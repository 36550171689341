import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { Observable, lastValueFrom } from 'rxjs';
import { HttpClient } from "@angular/common/http";


@Injectable({
  providedIn: 'root'
})
export class ConfiguracionesService {

  configurations: any
  private apiUrl = 'assets/json/currency.json'; 

  constructor(
    private http: HttpClient
  ) {

  }



  async loadConfigurations() {
    const data = new FormData()
    data.append('jwt', JSON.parse(localStorage.user).jwt)
    const resultado = <any>await lastValueFrom(this.http.post(`${environment.url_api}Configuration/get_configurations`, data))
    if (resultado.success) {
      this.configurations = resultado.configuraciones
    }
  }

  getConfigurations() {
    return this.configurations
  }

  /**COUNTRY */

  getCurrency(): Observable<any> {
    return this.http.get<any>(this.apiUrl);
  }

  async generateCountry(data) {
    console.log('recibiendo data desde service')
    console.log(data)
  
    // const tienda_selected = JSON.parse(localStorage.tienda_selected);
    // data.append('tienda_selected', tienda_selected)

    const response = await lastValueFrom(this.http.post(`${environment.url_api}ClientAPI/generate_country`, data));

    return response
  }
}
