<app-breadcrumb [title]="'Combos'" [items]="['Combos']"></app-breadcrumb>


<div class="col-12">
    <div class="card-body card-body-filtros">
        <div class="buttons">
            <button type="button" class="btn btn-primary-honda button_filter__collapse"
                (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed"
                aria-controls="collapseExample">
                <span>Filtros</span>
                <app-feather-icons [icon]="'filter'"></app-feather-icons>

            </button>

            <button type="button" class="btn btn-primary-honda button_filter__collapse"
                (click)="CrearCombo()"
                aria-controls="collapseExample">
                <span>Agregar </span>
                <app-feather-icons [icon]="'plus'"></app-feather-icons>
            </button>


        
        </div>
        <div id="collapseExample" [ngbCollapse]="isCollapsed">
            <div class="card mb-0">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <h5><u>Filtros </u></h5>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label">Fecha de
                                    inicio</label>
                                <div class="col-sm-9">
                                    <input class="form-control digits"
                                        [(ngModel)]="filtros.fecha_inicio"
                                        id="example-datetime-local-input" type="date">
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label">Fecha de fin</label>
                                <div class="col-sm-9">
                                    <input class="form-control digits"
                                        [(ngModel)]="filtros.fecha_fin"
                                        id="example-datetime-local-input" type="date">
                                </div>
                            </div>
                        </div>



                        <div class="col-12">
                            <h5><u>Filtros adicionales</u></h5>
                   
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <div class="form-group">
                                        <div class="col-form-label">Por texto</div>
                                        <input class="form-control" type="text"
                                            [(ngModel)]="filtros.texto" placeholder="">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 div-button_filter">
                            <button id="make_filter" type="button" class="btn btn-primary"
                                (click)="rerender()" [attr.aria-expanded]="!isCollapsed"
                                aria-controls="collapseExample"
                                [attr.disabled]="buttonFiltrarTexto != 'Filtrar' ? true:null">
                                <span>{{buttonFiltrarTexto}}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <div class="text-first">
                        <b>  Total Productos : </b> {{ detallado_person.total }}
                    </div>
                  
                    
                    <div class="media-body text-end">
                        <a (click)="eliminar_seleccionados()" role="button" class="btn btn-secondary me-2">
                            Eliminar <i class="fa fa-trash-o"></i> 
                         </a>

                        <a (click)="load_combos()" role="button" class="btn btn-primary me-2">Actualizar</a>
                    </div>
                </div>
                <div class="card-body">

                    <div class="container m-5">

                
                    
                 
                    
                    <table class="table table-concesionarios">
                        <thead>
                            <tr>
                                <th class="text-center" scope="col">
                                    <input type="checkbox" (change)="selectAll($event)" />
                                </th>
                                <th class="text-center" scope="col">Imagen</th>
                                <th class="text-center" scope="col">Nombre de Combo </th>
                                <th class="text-center" scope="col">Status </th>

                                <th class="text-center" scope="col">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let c of products">

                                <td class="text-center">
                                    <input type="checkbox" [(ngModel)]="c.selected" (change)="updateSelection(c)" />
                                </td>

                                <td class="text-center" >
                                    <img *ngIf="c.photo; else defaultImage" [src]="c.photo" alt="Imagen existente" [style.height.px]="20">
                                    <ng-template #defaultImage>
                                        <img src="https://static.vecteezy.com/system/resources/thumbnails/004/776/092/small_2x/photo-icon-or-picture-icon-image-sign-and-symbol-vector.jpg" alt="Imagen por defecto" [style.height.px]="20">
                                    </ng-template>
                                </td>
                                  
                                  
                                <td class="text-center" >{{c.name}}</td>
                                <td class="text-center" >
                                    <p [ngClass]="c.status == '1' ? 'activo' : 'desactivado'" >{{c.status == '1' ? 'Activo' : 'Desactivado'}}</p>
                                </td>



                                <td class="text-center">
                                    <div class="d-flex justify-content-center">
                                      <button (click)="openDeleteConfirmationModal(c.id)" class="btn btn-danger me-2" aria-label="Eliminar">
                                        <i class="fa fa-trash-o"></i> Eliminar
                                      </button>
                                      <button (click)="openEditfirmationModal(c.id)" class="btn btn-primary me-2" aria-label="Editar">
                                        <i class="fa fa-pencil"></i> Editar
                                      </button>
                                      <button (click)="openDetailfirmationModal(c.id)" class="btn btn-success me-2" aria-label="Detlla">
                                        Ver Detalle
                                     </button>
                                    </div>
                                  </td>
                                  
                                
                                
                            </tr>
                        </tbody>
                    </table>


                </div>
            </div>
            
        </div>
    </div>
</div>