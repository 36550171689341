<app-breadcrumb [title]="''" [items]="['Cajas']" [active_item]="'Listado de cajas'">
</app-breadcrumb>
<div *ngIf="caja_id > 0; then edit_caja else pick_caja"></div>
<ng-template #pick_caja>
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <h5>Lista de cajas</h5>
                        <button (click)="agregar_caja_modal()" class="btn btn-primary m-t-10">Agregar +</button>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-concesionarios">
                            <thead>
                                <tr>
                                    <th scope="col">Nombre</th>
                                    <th scope="col">Usuario</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Editar</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let caja of cajas" class="tr-mobile">
                                    <td>
                                        <div class="td-mobile">Nombre</div><b>{{caja.name}}</b>
                                    </td>
                                    <td>
                                        <div class="td-mobile">Usuario</div>{{caja.username}}
                                    </td>
                                  

                                    <td>
                                        <div class="td-mobile">Estado</div>{{caja.status}}
                                    </td>
                                    <td class="text-center">
                                        <div class="td-mobile">Editar</div><a [routerLink]="[caja.id]"><i class="fa fa-edit"></i></a>
                                    </td>
                                
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #edit_caja>
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <h5 *ngIf="caja_to_edit.status == 'closed'">Abrir caja</h5>
                        <h5 *ngIf="caja_to_edit.status == 'open'">Cerrar caja</h5>
                        
                    </div>
                    <div class="card-body content-to-edit">
                        <p><label class="form-label">Nombre:</label> {{caja_to_edit.name}}</p>
                        <p><label class="form-label">Usuario:</label> {{caja_to_edit.username}}</p>
                        <div *ngIf="caja_to_edit.status == 'open'">
                            <p><label class="form-label">Hora apertura:</label> {{caja_to_edit.opened_at}}</p>
                            <p><label class="form-label">Caja inicial:</label></p>
                            <p><label class="form-label">Efectivo:</label> {{caja_to_edit.cash_in}}</p>
                            <p><label class="form-label">Cierre caja:</label></p>
                            <div class="usuario-campo">
                                <div class="row">
                                    <label class="form-label" for="cash_in">Efectivo final</label>
                                    <div class="col-6 col-md-3 mb-3">
                                        <input type="number"
                                        class="form-control"
                                        id="cash_out"
                                        [(ngModel)]="order_cash"
                                        >
                                    </div>
                                    <div class="col-6 col-md-3 mb-3">
                                        <label>{{order_cash}}</label>
                                    </div>
                                </div>
                                <div class="row">
                                    <label class="form-label" for="debitcard_out">Tarjeta débito</label>
                                    <div class="col-6 col-md-3 mb-3">                                      
                                        <input type="number"
                                        class="form-control"
                                        id="debitcard_out"
                                        [(ngModel)]="order_debitcard"
                                        >
                                    </div>
                                    <div class="col-6 col-md-3 mb-3"><label>{{order_debitcard}}</label></div>

                                </div>
                                <div class="row">
                                    <label class="form-label" for="creditcard_out">Tarjeta de crédito</label>
                                    <div class="col-6 col-md-3 mb-3">
                                        <input type="number"
                                        class="form-control"
                                        id="creditcard_out"
                                        [(ngModel)]="order_creditcard"
                                        >
                                    </div>
                                    <div class="col-6 col-md-3 mb-3">
                                        <label>{{order_creditcard}}</label>
                                    </div>
                                </div>
                                <div class="row">
                                    <label class="form-label" for="comment">Comentario</label>
                                    <div class="col-6 col-md-3 mb-3">
                                       
                                        <textarea
                                        class="form-control"
                                        id="caja_to_edit.comment"
                                        [(ngModel)]="order_creditcard"
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        

                        
                        <div class="usuario-campo" *ngIf="caja_to_edit.status == 'closed'">
                            <div class="row">
                                <div class="col-12 col-md-6 mb-3">
                                    <label class="form-label" for="cash_in">Efectivo inicial</label>
                                    <input type="number"
                                    class="form-control"
                                    id="cash_in"
                                    [(ngModel)]="caja_to_edit.cash_in"
                                    >
                                </div>
                              
                            </div>
                        </div>
                        <div class="col-12 text-center m-b-10">
                            <button *ngIf="caja_to_edit.status == 'open'" (click)="asignar()" class="btn btn-primary">!Cerrar caja!</button>
                            <button *ngIf="caja_to_edit.status == 'closed'" (click)="asignar()" class="btn btn-primary">¡Abrir caja!</button>
                            <button  (click)="cancel()" class="btn btn-secondary">Cancelar</button>
                        </div>
                    </div>



                </div>
            </div>
        </div>
    </div>
</ng-template>
