<div class="container">
    <div class="form-group">
        <h2>Crear campo adicional</h2>
    </div>
    <div class="row">
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="nombre">Nombre</label>
                <input type="text" class="form-control" id="nombre" [(ngModel)]="customer_field_to_create.name">
            </div>
        </div>
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="countries">Tipo de dato</label>
                <ng-select bindValue="id" bindLabel="name" [items]="type" class="js-example-basic-multiple"
                    [(ngModel)]="customer_field_to_create.type">
                </ng-select>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12 col-md-6 mb-3">
            <label class="form-label" for="activo">¿Activo?</label>
            <i ngbTooltip="--"
            class="fa fa-fw fa-info-circle"></i>
        <br>
            <label class="switch">
                <input id="activo" [(ngModel)]="customer_field_to_create.status" type="checkbox"><span
                    class="switch-state"></span>
            </label>
        </div>
    </div>
    <div class="form-group buttons">
        <button [attr.disabled]="is_loading? true:null" class="btn btn-primary" (click)="onSubmit()">Agregar</button>
        <button class="btn btn-secondary" (click)="onCancel()">Cancelar</button>
    </div>

</div>