import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from "@angular/common/http";
import { Subscription, lastValueFrom } from 'rxjs';
import { formatDate } from '@angular/common';
import { environment } from '../../environments/environment';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalCreateProductComponent } from 'src/app/modal-create-product/modal-create-product.component';

const Swal = require('sweetalert2')


@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {
  private jwt
  tienda_selected
  private routeSub: Subscription;

  product_id
  product_to_edit

  products: any

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private modalService: NgbModal,
  ) { 
    this.jwt = JSON.parse(localStorage.user).jwt
    this.tienda_selected = localStorage.getItem('tienda_selected')
    this.product_to_edit = {}
    this.routeSub = this.route.params.subscribe(params => {
      this.product_id = params.product_id
    })
  }

  ngOnInit(): void {
    if (this.product_id >= 0) {
      this.get_data_from_products()
    } else {
      this.refresh_data()
    }
  }

  async refresh_data() {
    const data = new FormData()
    data.append('jwt', this.jwt)
    data.append('tienda_selected', this.tienda_selected)
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}Product/get_list_of_products_full`, data))
    console.log(response)
    if (response.success) {
      this.products = response.products
    }
  }

  async get_data_from_products() {

  }

  agregar_producto_modal() {
    console.log('agregar_producto_modal')
    const dialogRef = this.modalService.open(ModalCreateProductComponent, {
      size: 'lg'
    })
    dialogRef.componentInstance.jwt = this.jwt

    dialogRef.result.then((data) => {

      if (data.success) {
        this.products = data.payload.products
        // this.usuarios.forEach((usu, index) => {
        //   this.usuarios[index].campus = this.usuarios[index].campus?.split(",")
        // })
      }
    })
  }

}
