import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, lastValueFrom } from 'rxjs';
import { HttpClient } from "@angular/common/http";

@Component({
  selector: 'app-modal-preview-customer',
  templateUrl: './modal-preview-customer.component.html',
  styleUrls: ['./modal-preview-customer.component.scss']
})
export class ModalPreviewCustomerComponent implements OnInit {

  jwt
  is_loading = false
  customer_id
  customer

  currency_selected
  currency_symbol_selected
  
  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private activeModalService: NgbActiveModal
  ) {
    this.jwt = JSON.parse(localStorage.user).jwt
    this.customer_id = JSON.parse(localStorage.user).customer_id
  }

  async ngOnInit(){
    this.currency_selected = localStorage.getItem('currency_selected')
    this.currency_symbol_selected = localStorage.getItem('currency_symbol_selected')

    const data = new FormData()
    data.append('jwt', this.jwt)
    data.append('customer_id',  JSON.stringify(this.customer_id))

    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}ClientAPI/get_customer_by_id`, data))
    if (response.success) {
      this.customer = response.customer
    }
  }

  editarDatos(){
    this.activeModalService.close();
    const route_link = '/edit-customer/'+ this.customer_id;
    this.router.navigate([route_link]);
  }
}
