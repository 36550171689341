import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { lastValueFrom } from 'rxjs'
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})

export class CampaignService {

    constructor(private http: HttpClient) { }

    async campaign_service_init_general() {

        const data = new FormData()
        data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
        const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}CampaignAPI/get_payload_init_general`, data))
        return response
      }

      async campaign_service_saldo_email() {

        const data = new FormData()
        data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
        const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}CampaignAPI/get_saldo_email`, data))
        return response
    }


    async campaign_service_saldo_whatsapp() {

        const data = new FormData()
        data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
        const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}CampaignAPI/get_saldo_whatsapp`, data))
        return response
    }

    async get_all_campaign() {
        const data = new FormData()
        data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
        const response = await lastValueFrom(this.http.post(`${environment.url_api}CampaignAPI/get_all_campaigns`, data));
        return response
    }

    async get_campaign_filter(filtros, type) {
        const data = new FormData()
        data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
        data.append('filtros', JSON.stringify(filtros));
        data.append('type', type);

        const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}CampaignAPI/get_campaigns_by_filter`, data));
        return response
    }

    async makeCampaign(campaign_data) {
        const data = new FormData()
        data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
        data.append('data', JSON.stringify(campaign_data))
        const response = await lastValueFrom(this.http.post(`${environment.url_api}CampaignAPI/makeCampaign`, data));
        return response
    }

    async makeCampaignWhatsapp(campaign_data) {
        const data = new FormData()
        data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
        data.append('data', JSON.stringify(campaign_data))
        const response = await lastValueFrom(this.http.post(`${environment.url_api}CampaignAPI/makeCampaignWhatsapp`, data));
        return response
    }

}
