import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, lastValueFrom } from 'rxjs';
import { HttpClient } from "@angular/common/http";

const Swal = require('sweetalert2')

@Component({
  selector: 'app-model-create-customer-fields',
  templateUrl: './model-create-customer-fields.component.html',
  styleUrls: ['./model-create-customer-fields.component.scss']
})
export class ModelCreateCustomerFieldsComponent implements OnInit {

  jwt
  is_loading = false
  
  customer_field_to_create = {
    'name': '',
    'status':'',
    'type': ''
  };

  type = [
    { id: 'email', name: 'email' },
    { id: 'number', name: 'number' },
    { id: 'datetime', name: 'datetime' },
    { id: 'decimal', name: 'decimal' },
    { id: 'text', name: 'text' }
  ]

  constructor(
    private http: HttpClient,
    private activeModalService: NgbActiveModal
  ) {
    this.jwt = JSON.parse(localStorage.user).jwt
  }

  async ngOnInit() {
    const data = new FormData()
    data.append('jwt', this.jwt)

    // const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}ClientAPI/add_store_init`, data))
    // if (response.success) {
    //   this.type = response.type
    // }
  }

  validate() {
    if (!this.customer_field_to_create.name) {
      Swal.fire('Ingrese nombre', '', 'error')
      return false
    }
 
    if (!this.customer_field_to_create.type) {
      Swal.fire('Selecciona un tipo', '', 'error')
      return false
    }

    return true
  }

  async onSubmit() {
    if (this.validate()) {
      
       const data = new FormData()
       data.append('jwt', this.jwt)
       data.append('customer_field', JSON.stringify(this.customer_field_to_create))
       //console.log(this.customer_field_to_create)
       this.is_loading = true
       const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}Customer/add_customer_fields`, data))
        
       this.is_loading = false
        if (response.success) {
          Swal.fire(response.message, '', 'success')
          this.activeModalService.close(response);
        } else {
        
          Swal.fire(response.message, '', 'error')
       }
    }
  }

  onCancel() {
    this.activeModalService.close({
      success: false
    });
  }

}
