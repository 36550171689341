import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, Router} from '@angular/router';
import { environment } from '../../environments/environment';
import { Subscription, lastValueFrom, timer } from 'rxjs';

import Swal from 'sweetalert2';  // Importa SweetAlert


@Component({
  selector: 'app-edit-customer',
  templateUrl: './edit-customer.component.html',
  styleUrls: ['./edit-customer.component.scss']
})
export class EditCustomerComponent implements OnInit {

  private routeSub: Subscription;
  jwt: string;
  is_loading = false;
  customer_edit
  customer_id
  merges = []

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private router: Router
  ) { 
    this.jwt = JSON.parse(localStorage.user).jwt
    this.customer_edit = {}
    this.routeSub = this.route.params.subscribe(params => {
      this.customer_id = params.id
    })
  }

  async ngOnInit(){

    const data = new FormData()
    data.append('jwt', this.jwt)
    data.append('customer_id',  JSON.stringify(this.customer_id))
    
    const res = <any>await lastValueFrom(this.http.post(`${environment.url_api}ClientAPI/get_edit_customer_by_id`, data))

    if(res.success){
      console.log(res)
      this.customer_edit = res.customer

      this.merges = []
      if (this.customer_edit) {
         for (let index = 0; index < this.customer_edit.length; index++) {
           const merge = this.customer_edit[index];
           
           this.merges.push({
             'id': merge['id'],
             'name': merge['name'],
             'type': merge['type'],
             'required': merge['required'],
             'value':''
           })
         }

      }
    }

  }

  async onSubmit() {
    console.log(this.customer_edit)
    if (this.validateFields()) {
      this.is_loading = true;
      console.log(this.customer_edit);
      const data = new FormData()
      data.append('jwt', this.jwt)
      data.append('customer_edit',  JSON.stringify(this.customer_edit))
      
      const res = <any>await lastValueFrom(this.http.post(`${environment.url_api}ClientAPI/update_customer`, data))
  
   
      if (res.success) {
       Swal.fire({
           title: 'Cliente actualizado',
           icon: 'success',
           confirmButtonText: 'Ok',
           timer: 3000
       });
       this.router.navigate(['/customer']); 
     } else {
       Swal.fire({
           title: 'Error al actualizar el cliente',
           icon: 'error',
           confirmButtonText: 'Entendido',
           timer: 3000
       });
     }

      this.is_loading = false;
    }
  }

  validateFields() {
    if (!this.customer_edit.name) {
      Swal.fire('Error', 'El nombre es obligatorio.', 'error');
      return false;
    }

    if (!this.customer_edit.last_name) {
      Swal.fire('Error', 'El apellido es obligatorio.', 'error');
      return false;
    }

    if (!this.customer_edit.rut) {
      Swal.fire('Error', 'El RUT es obligatorio.', 'error');
      return false;
    }


    return true;
  }

  onCancel() {
    Swal.fire('Cancelado', 'La edición del cliente ha sido cancelada.', 'info');
    this.router.navigate(['/customer'])

  }

}
