import { Component, Input, OnInit } from '@angular/core';
import { OrdersService } from '../orders.service';

@Component({
  selector: 'app-order-detail-kitchen',
  templateUrl: './order-detail-kitchen.component.html',
  styleUrls: ['./order-detail-kitchen.component.scss']
})
export class OrderDetailKitchenComponent implements OnInit {

  @Input() id_pedido: string;

  constructor(private OrdersService: OrdersService) { }

  ngOnInit(): void {
    // Llamar la función aquí si necesitas cargar los datos al iniciar
    this.traer_datos_pedido(this.id_pedido);
  }

  order_detail = {
    order_number: '',                // ID del pedido
    code: '',              // Código del pedido
    estado: '',            // Estado del pedido
    subtotal: '',          // Subtotal del pedido
    tax: '',               // Impuestos aplicados
    total: '',             // Total a pagar
    order_date: '',      // Fecha de creación del pedido
    metodo_pago: '',
    // Información del Cliente
    customer_name: '',     // Nombre del cliente
    customer_email: '',    // Correo electrónico del cliente
    customer_phone: '',    // Teléfono del cliente
    email_enviado: 'No',

    // Información de Facturación
    billing_address: '',   // Dirección de facturación
    billing_city: '',      // Ciudad de facturación
    billing_zip: '',       // Código postal de facturación
    billing_country: '',   // País de facturación

  
  };

  order_detail_product = [];


  // Método correcto para utilizar async/await
  async traer_datos_pedido(id: string): Promise<void> {
    try {
      // Llamada al servicio para obtener los datos del pedido
      const res = <any>await this.OrdersService.get_order_by_id(id);
      try {
        // Llamada al servicio para obtener los datos del pedido
        if (res.success) {


          const cliente_datos = res.cliente_datos;
          this.order_detail_product = res.order_details;

          this.order_detail = {
            order_number: res.order.id,
            code: res.order.order_code,
            estado: res.order.status,
            subtotal: res.order.subtotal,
            tax: res.order.tax,
            total: res.order.total,
            order_date: res.order.created_time,
            metodo_pago: res.order.metodo_pago,
             // Información del Cliente
             customer_name: `${cliente_datos?.name ?? ''} ${cliente_datos?.last_name ?? ''}`.trim() || '',
             customer_email: cliente_datos?.additional_fields?.['Correo electrónico'] ?? '',
             customer_phone: cliente_datos?.additional_fields?.['Teléfono'] ?? '',
             email_enviado: res.order.send_mail == 1 ? 'Si' : 'No',
    
            // Información de Facturación
            billing_address: '',   // Dirección de facturación
            billing_city: '',      // Ciudad de facturación
            billing_zip: '',       // Código postal de facturación
            billing_country: '',   // País de facturación
          };
    
        
    
        } 
      } catch (error) {
        console.error('Error al obtener los datos del pedido:', error);
      }
    } catch (error) {
      console.error('Error al obtener los datos del pedido:', error);
    }
  }

}
