import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { HttpClient } from "@angular/common/http";
import { Subscription, lastValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalCreateCajaComponent } from 'src/app/modal-create-caja/modal-create-caja.component';

const Swal = require('sweetalert2')

@Component({
  selector: 'app-caja',
  templateUrl: './caja.component.html',
  styleUrls: ['./caja.component.scss']
})
export class CajaComponent implements OnInit {
  private jwt
  private routeSub: Subscription;
  caja_id
  caja_to_edit

  cajas: any
  order_cash
  order_debitcard
  order_creditcard

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private router: Router,
  ) {
    this.jwt = JSON.parse(localStorage.user).jwt
    this.caja_to_edit = {}
    this.routeSub = this.route.params.subscribe(params => {
      this.caja_id = params.caja_id
    })
  }



  ngOnInit(): void {

    if (this.caja_id >= 0) {
      this.get_data_from_caja()
    } else {
      this.refresh_data()
    }
  }

  async get_data_from_caja() {
    const data = new FormData()
    data.append('jwt', this.jwt)
    data.append('caja_id', this.caja_id)


    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}ClientAPI/get_info_by_cajaID`, data))
    
    if (response.success) {
  
       this.caja_to_edit = response.caja
       this.order_cash = response.order_cash.efectivo == null ? 0 : response.order_cash.efectivo
       this.order_creditcard = response.order_creditcard.tarjeta_credito == null ? 0 : response.order_creditcard.tarjeta_credito
       this.order_debitcard = response.order_debitcard.tarjeta_debito == null ? 0 : response.order_debitcard.tarjeta_debito
      //  this.turno_to_edit.status = this.turno_to_edit.status == 1 ? true:false
     
    }
  }

  async cancel(){
 
    Swal.fire('Cancelado', 'La operació ha sido cancelada.', 'info');
    this.router.navigate(['/cajas'])
  }

  async asignar(){
    const data = new FormData()
    data.append('jwt', this.jwt)
    data.append('caja_id', this.caja_id)
    data.append('caja_to_edit', JSON.stringify(this.caja_to_edit))

    console.log(this.caja_to_edit);
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}ClientAPI/edit_caja`, data))
    this.sendAlert(response.message, response.success ? 'success' : 'error')
    this.router.navigate(['/cajas'])
  }

  async refresh_data() {
    const data = new FormData()
    data.append('jwt', this.jwt)
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}ClientAPI/get_list_of_cajas`, data))
    if (response.success) {
      this.cajas = response.payload.cajas
    }

  
  }

  sendAlert(message, type) {
    Swal.fire({
      icon: type,
      title: message,
      text: ''
    });
  }
  
  agregar_caja_modal() {
    const dialogRef = this.modalService.open(ModalCreateCajaComponent, {
      size: 'lg'
    })
    dialogRef.componentInstance.jwt = this.jwt

    dialogRef.result.then((data) => {
      console.log(data)
      if (data.success) {
        this.cajas = data.payload.cajas

      }
    })
  }

}
