<app-breadcrumb [title]="'Cerrar Caja / Turno'" [items]="['Turnos']"></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-12 col-md-6"></div>
        <div class="col-12 col-md-6">
            <app-breadcrumb-body  [items]="['Turno - Caja', 'Cerrar']"></app-breadcrumb-body>
        </div>
    </div>
</div>

<div class="container">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header card-caja">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label"><b>ID Caja:</b></label>
                                <div class="col-sm-8">
                                    <label class="col-form-label">{{this.caja.id_caja}}</label>
                                
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label  class="col-sm-4 col-form-label"><b>Nombre de caja:</b></label>
                                <div class="col-sm-8">
                                    <label  class="col-form-label">{{this.caja.nombre_caja}}</label>
                                
                                </div>
                            </div>
                        </div>
                       
                    </div>
                    <div class="row color-gray">
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label"><b>Usuario:</b></label>
                                <div class="col-sm-8">
                                    <label class="col-form-label">{{this.caja.nombre_usuario}}</label>
                                
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label"><b>Hora de Apertura:</b></label>
                                <div class="col-sm-8">
                                    <label class="col-form-label">{{this.caja.apertura}}</label>
                                
                                </div>
                            </div>
                        </div>
                       
                    </div>
                    <div class="row">
                        <div class="caja-apertura">
                            <h4>Caja Inicial:</h4>
                            <div class="row white">
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label class="col-sm-4 col-form-label"><b>Efectivo:</b></label>
                                        <div class="col-sm-8">
                                            <label class="col-form-label">{{currency_symbol_selected}} {{ this.caja.efectivo_inicial | currency: currency_selected: ''}}</label>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label  class="col-sm-4 col-form-label"><b>Cierre Caja:</b></label>
                                        <div class="col-sm-8">
                                            <label  class="col-form-label">{{currency_symbol_selected}} {{ this.order_total | currency: currency_selected: ''}}</label>
                                        
                                        </div>
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header card-caja custom">
                    <label>*Campo obligatorio</label>
                    <div class="row">
                        <div class="col-md-8">
                            <div class="form-group row">
                                <label  class="col-sm-3 col-form-label"><b>Efectivo*:</b></label>
                                <div class="col-sm-5">
                                    <div class="price-input-container">
                                        <div class="price-input-container__inner">
                                          <span class="currency-symbol">$</span>
                                          <input type="text" (keyup)="calcularDescuadre($event)" (keypress)="onlyNumbers($event)" [(ngModel)]="order_cash_custom"  placeholder ="0.00" class="price-input" value="{{ this.order_cash | currency: currency_selected: ''}}"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <label  class="col-form-label"><b>{{currency_symbol_selected}} {{ this.order_cash | currency: currency_selected: ''}}</b></label>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                    <div class="row">
                        <div class="col-md-8">
                            <div class="form-group row">
                                <label  class="col-sm-3 col-form-label"><b>Tarjeta débito*:</b></label>
                                <div class="col-sm-5">
                                    <div class="price-input-container">
                                        <div class="price-input-container__inner">
                                          <span class="currency-symbol">$</span>
                                          <input type="text" (keypress)="onlyNumbers($event)" (keyup)="calcularDescuadre($event)" [(ngModel)]="order_creditcard_custom" placeholder ="0.00" class="price-input" value="{{ this.order_creditcard | currency: currency_selected: ''}}"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <label  class="col-form-label"><b>{{currency_symbol_selected}} {{ this.order_creditcard | currency: currency_selected: ''}}</b></label>
                                
                                </div>
                            </div>
                        </div>
                       
                    </div>
                    <div class="row">
                        <div class="col-md-8">
                            <div class="form-group row">
                                <label  class="col-sm-3 col-form-label"><b>UberEats*:</b></label>
                                <div class="col-sm-5">
                                    <input type="text" class="form-control">
                                </div>
                                <div class="col-sm-4">
                                    <label  class="col-form-label"><b>$ 0</b></label>
                                
                                </div>
                            </div>
                        </div>
                       
                    </div>
                    <div class="row">
                        <div class="col-md-8">
                            <div class="form-group row">
                                <label  class="col-sm-3 col-form-label"><b>PedidosYa*:</b></label>
                                <div class="col-sm-5">
                                    <input type="text" class="form-control">
                                </div>
                                <div class="col-sm-4">
                                    <label  class="col-form-label"><b>$ 0</b></label>
                                
                                </div>
                            </div>
                        </div>
                       
                    </div>
                    <div class="row">
                        <div class="col-md-8">
                            <div class="form-group row">
                                <label  class="col-sm-3 col-form-label"><b>Rappi*:</b></label>
                                <div class="col-sm-5">
                                    <input type="text" class="form-control">
                                </div>
                                <div class="col-sm-4">
                                    <label  class="col-form-label"><b>$ 0</b></label>
                                
                                </div>
                            </div>
                        </div>
                       
                    </div>
                    <div class="row color-red">
                        <div class="col-md-8">
                            <div class="form-group row ">
                                <label  class="col-sm-3 col-form-label "><b>Descuadre:</b></label>
                                <div class="col-sm-5">
                                    <label  class="col-form-label"><b>{{currency_symbol_selected}} {{ this.descuadre | currency: currency_selected: ''}}</b></label>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                    <div class="row white">
                        <div class="col-md-8">
                            <div class="form-group">
                                <label  class="col-sm-3 col-form-label "><b>Comentarios:</b></label>
                                <div class="col-sm-9">
                                    <textarea  [(ngModel)]="caja_comment" 
                                    class="form-control"
                                    ></textarea>
                                    

                                </div>
                            </div>
                        </div>
                       
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-10">
                            <button class="btn btn-primary" (click)="cerrar_caja()">Cerrar caja</button>
                            <button class="btn btn-primary btn-cancelar">Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>