<app-breadcrumb [title]="''" [items]="['Tiendas']" [active_item]="'Listado de tiendas'">
</app-breadcrumb>
<div *ngIf="store_id > 0; then edit_store else pick_store"></div>
<ng-template #pick_store>
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <h5>Lista de tiendas</h5>
                        <button (click)="agregar_tienda_modal()" class="btn btn-primary m-t-10">Agregar +</button>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-concesionarios">
                            <thead>
                                <tr>
                                    <th scope="col">Nombre</th>
                                    <th scope="col">Dirección</th>
                                    <th scope="col">País</th>
                                    <th scope="col">Latitud</th>
                                    <th scope="col">Longitud</th>
                                    <th scope="col">Estado</th>
                                    <th scope="col">Editar</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let store of stores" class="tr-mobile">
                                    <td>
                                        <div class="td-mobile">Nombre</div><b>{{store.name}}</b>
                                    </td>
                                    <td>
                                        <div class="td-mobile">Dirección</div>{{store.adress}}
                                    </td>
                                    <td>
                                        <div class="td-mobile">País</div>{{store.country}}
                                    </td>
                                    <td>
                                        <div class="td-mobile">Latitud</div>{{store.latitud}}
                                    </td>
                                    <td>
                                        <div class="td-mobile">Longitud</div>{{store.longitud}}
                                    </td>

                                    <td>
                                        <div class="td-mobile">Estado</div>
                                        <span class="badge badge-success" *ngIf="store.status == 1">
                                           Activo
                                        </span>
                                        <span class="badge badge-primary" *ngIf="store.status == 0">
                                            Inactivo
                                         </span>
                                    </td>
                                    <td class="text-center">
                                        <div class="td-mobile">Editar</div><a [routerLink]="[store.id]"><i class="fa fa-edit"></i></a>
                                    </td>
                                
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #edit_store>
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <h5>Editar Tienda</h5>
                        <span>{{store_to_edit.name}}</span>
                    </div>
                    <div class="card-body content-to-edit">
                        <div class="usuario-campo">
                            <div class="row">
                                <div class="col-12 col-md-6 mb-3">
                                    <label class="form-label" for="name">Nombre</label>
                                    <input class="form-control" id="name" type="text" [(ngModel)]="store_to_edit.name">
                                </div>
                                <div class="col-12 col-md-6 mb-3">
                                    <label class="form-label" for="adress">Dirección</label>
                                    <input class="form-control" id="adress" type="text"
                                        [(ngModel)]="store_to_edit.adress">
                                </div>
                                <div class="col-12 col-md-6 mb-3">
                                    <label class="form-label" for="latitud">Latitud</label>
                                    <input class="form-control" id="latitud" type="text" [(ngModel)]="store_to_edit.latitud">
                                </div>
                                <div class="col-12 col-md-6 mb-3">
                                    <label class="form-label" for="longitud">Longitud</label>
                                    <input class="form-control" id="longitud" type="text" [(ngModel)]="store_to_edit.longitud">
                                </div>
                          
                                <div class="col-12 col-md-6 mb-3">
                                    <div class="form-group">
                                        <label for="country">Country</label>
                                        
                                        <ng-select bindValue="id" bindLabel="name" [items]="country" class="js-example-basic-multiple"
                                            [(ngModel)]="selectedCountry" [compareWith]="compareCountryObjects">
                                        </ng-select>

                                    </div>
                                </div>
                                <div class="col-12 col-md-6 mb-3">
                                    <label class="form-label" for="activo">¿Activo? </label>
                                    <i ngbTooltip="Si desactivas la tienda, no aparecerá en el listado de los demás usuarios."
                                        class="fa fa-fw fa-info-circle"></i>
                                    <br>

                                    <label class="switch">
                                        <input id="activo" [(ngModel)]="store_to_edit.status" type="checkbox"><span
                                            class="switch-state"></span>
                                    </label>
                                </div>

                            </div>
                        </div>
                        <div class="col-12 text-center m-b-10">
                            <button (click)="asignar()" class="btn btn-primary">¡Guardar!</button>
                        </div>
                    </div>



                </div>
            </div>
        </div>
    </div>
</ng-template>
