<app-breadcrumb
  [title]="'Reporte Clientes'"
  [items]="['Ventas']"></app-breadcrumb>
<div class="container-fluid">
  <div class="row">
    <div class="col-12 col-md-6"></div>
    <div class="col-12 col-md-6">
      <app-breadcrumb-body
        [items]="['Reporte', 'Reporte Clientes']"></app-breadcrumb-body>
    </div>
  </div>
</div>
<div class="container-fluid">
    <div class="card">
  
      <div class="row">
        <h2>Filtros de búsqueda:</h2>
        <div class="col-12 col-md-6">
          <div class="form-group row">
            <label class="col-sm-3 col-form-label"><b>Desde:</b></label>
            <div class="col-sm-9">
              <input
                  class="form-control digits"
                  [(ngModel)]="filtros.fecha_inicio"
                  id="example-datetime-local-input"
                  type="date" />
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group row">
            <label class="col-sm-3 col-form-label"><b>Hasta:</b></label>
            <div class="col-sm-9">
                <input
                      class="form-control digits"
                      [(ngModel)]="filtros.fecha_fin"
                      id="example-datetime-local-input"
                      type="date" />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group row">
            <label class="col-sm-3 col-form-label"><b>Tiendas:</b></label>
            <div class="col-sm-9">
              <ng-select
                bindValue="id"
                bindLabel="name"
                [items]="tiendas"
                class="col-sm-9 js-example-basic-multiple"
                placeholder="Seleccionar tiendas"
                [(ngModel)]="filtros.tiendas"
                [multiple]="true">
              </ng-select>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
            <div class="form-group row">
              <label class="col-sm-3 col-form-label"><b>Productos:</b></label>
              <div class="col-sm-9">
                <ng-select
                  bindValue="id"
                  bindLabel="name"
                  [items]="productos"
                  class="col-sm-9 js-example-basic-multiple"
                  placeholder="Seleccionar producto"
                  [(ngModel)]="filtros.productos"
                  [multiple]="true">
                </ng-select>
              </div>
            </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group row">
            <label class="col-sm-3 col-form-label"><b>Plataformas:</b></label>
            <div class="col-sm-9">
              <ng-select
                bindValue="id"
                bindLabel="nombre"
                [items]="plataformas"
                class="col-sm-9 js-example-basic-multiple"
                placeholder="Seleccionar plataforma"
                [(ngModel)]="filtros.plataformas"
                [multiple]="true">
              </ng-select>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6"></div>
      </div>
  
      <div class="row">
        <div class="col-12">
          <button
          id="make_filter"
          type="button"
          class="btn btn-primary btn-custom"
          (click)="rerender()"
          [attr.aria-expanded]="!isCollapsed"
          aria-controls="collapseExample"
          [attr.disabled]="buttonFiltrarTexto != 'Buscar' ? true : null">
          <span>{{ buttonFiltrarTexto }}</span>
        </button>
        </div>
      </div>

      
    </div>
</div> 

<div class="container-fluid">
    <div class="row gap-3">
        <div class="col-12">
            <div class="card p-3">
                <div class="row">
                    <div class="col-12 col-sm-6 col-xl-4 mb-3">
                        <div class="bg-primary b-r-4 card-body p-3">
                            <div class="media static-top-widget">
                                <div class="media-body">
                                    <span class="m-0">Total clientes registrados</span>
                                    <h4 class="mb-0 counter">{{ clientes_data.total_clientes ? clientes_data.total_clientes : 0 }}</h4>
                                    <i class="icon-bg" data-feather="edit-3"></i>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-sm-6 col-xl-4 mb-3">
                        <div class="bg-info b-r-4 card-body p-3">
                            <div class="media static-top-widget">
                                <div class="media-body">
                                    <span class="m-0">Clientes nuevos</span>
                                    <h4 class="mb-0 counter">{{ clientes_data.clientes_nuevos ? clientes_data.clientes_nuevos : 0 }}</h4>
                                    <i class="icon-bg" data-feather="user-plus"></i>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-sm-6 col-xl-4 mb-3">
                        <div class="bg-dark b-r-4 card-body p-3">
                            <div class="media static-top-widget">
                                <div class="media-body">
                                    <span class="m-0">Clientes recurrentes</span>
                                    <h4 class="mb-0 counter">{{ clientes_data.clientes_recurrentes ? clientes_data.clientes_recurrentes : 0 }}</h4>
                                    <i class="icon-bg" data-feather="refresh-cw"></i>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-sm-6 col-xl-4 mb-3">
                        <div class="bg-primary b-r-4 card-body p-3">
                            <div class="media static-top-widget">
                                <div class="media-body">
                                    <span class="m-0">Clientes activos</span>
                                    <h4 class="mb-0 counter">{{ clientes_data.clientes_activos ? clientes_data.clientes_activos : 0 }}</h4>
                                    <i class="icon-bg" data-feather="toggle-right"></i>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-sm-6 col-xl-4 mb-3">
                        <div class="bg-info b-r-4 card-body p-3">
                            <div class="media static-top-widget">
                                <div class="media-body">
                                    <span class="m-0">Promedio de visita</span>
                                    <h4 class="mb-0 counter">{{ clientes_data.promedio_visitas ? clientes_data.promedio_visitas : 0 }}</h4>
                                    <i class="icon-bg" data-feather="activity"></i>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-sm-6 col-xl-4 mb-3">
                        <div class="bg-dark b-r-4 card-body p-3">
                            <div class="media static-top-widget">
                                <div class="media-body">
                                    <span class="m-0">Ticket promedio</span>
                                    <h4 class="mb-0 counter">{{ clientes_data.ticket_promedio ? clientes_data.ticket_promedio : 0 }}</h4>
                                    <i class="icon-bg" data-feather="file-text"></i>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>


<div class="container-fluid">
    <div class="row gap-3">
        <div class="col-12">
          <div class="card p-3">
            <div class="row">
                <div class="col-12 col-sm-6 col-xl-6 mb-3">
                        <div class="bg-primary b-r-4 card-body p-3">
                            <div class="media static-top-widget">
                                <div class="media-body">
                                    <span class="m-0">CANTIDAD DE TRX</span>
                                    <h4 class="mb-0 counter">{{ clientes_data_ventas.ticket_promedio ? clientes_data_ventas.cantidad_trx : 0 }}</h4>
                                    <i class="icon-bg" data-feather="edit-3"></i>
                                </div>
                            </div>
                        </div>
                </div>
                
                <div class="col-12 col-sm-6 col-xl-6 mb-3">
                        <div class="bg-info b-r-4 card-body p-3">
                            <div class="media static-top-widget">
                                <div class="media-body">
                                    <span class="m-0">TOTAL DE VENTAS</span>
                                    <h4 class="mb-0 counter">{{ clientes_data_ventas.ticket_promedio ? clientes_data_ventas.total_ventas : 0 }}</h4>
                                    <i class="icon-bg" data-feather="user-plus"></i>
                                </div>
                            </div>
                        </div>
                </div>
                
             
              
            </div>
          </div>
        </div>
    </div>
</div>


<div class="container-fluid">
  <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-inner">
            <h2 class="card-title">Clientes vs. nuevos clientes Por mes </h2>
            <div class="row">
                <div class="col-md-12">
                  <div class="chart-container">
                    <apx-chart
                    [series]="chartOptions.series"
                    [chart]="chartOptions.chart"
                    [yaxis]="chartOptions.yaxis"
                    [xaxis]="chartOptions.xaxis"
                    [labels]="chartOptions.labels"
                    [stroke]="chartOptions.stroke"
                    [title]=""
                    [dataLabels]="chartOptions.dataLabels"
                   
                  ></apx-chart>
                    
                </div>
              </div>
              
            </div>
          
          </div>
        </div>
          
      </div>
    </div>
</div>

<div class="container-fluid">
    <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-inner">
              <h2 class="card-title"> Clientes vs. nuevos clientes Por AÑO </h2>
              <div class="row">
                  <div class="col-md-12">
                    <div class="chart-container">
                      <apx-chart
                      [series]="chartOptions.series"
                      [chart]="chartOptions.chart"
                      [yaxis]="chartOptions.yaxis"
                      [xaxis]="chartOptions.xaxis"
                      [labels]="chartOptions.labels"
                      [stroke]="chartOptions.stroke"
                      [title]=""
                      [dataLabels]="chartOptions.dataLabels"
                     
                    ></apx-chart>
                      
                  </div>
                </div>
                
              </div>
            
            </div>
          </div>
            
        </div>
      </div>
  </div>

  <div class="container-fluid">
    <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-inner">
              <h2 class="card-title"> Tasa de nuevos clientes & % nuevos ingresos </h2>
              <div class="row">
                  <div class="col-md-12">
                    <div class="chart-container">
                      <apx-chart
                      [series]="chartOptions.series"
                      [chart]="chartOptions.chart"
                      [yaxis]="chartOptions.yaxis"
                      [xaxis]="chartOptions.xaxis"
                      [labels]="chartOptions.labels"
                      [stroke]="chartOptions.stroke"
                      [title]=""
                      [dataLabels]="chartOptions.dataLabels"
                     
                    ></apx-chart>
                      
                  </div>
                </div>
                
              </div>
            
            </div>
          </div>
            
        </div>
      </div>
  </div>