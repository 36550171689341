import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { HttpClient } from "@angular/common/http";
import { Subscription, lastValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalCreateStoreComponent } from 'src/app/modal-create-store/modal-create-store.component';

const Swal = require('sweetalert2')

@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.scss']
})
export class StoreComponent implements OnInit {

  private jwt
  private routeSub: Subscription;
  store_id
  store_to_edit

  stores: any
  selectedCountry: any = null; 
  country = []


  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private router: Router,
  ) {
    this.jwt = JSON.parse(localStorage.user).jwt
    this.store_to_edit = {}
    this.routeSub = this.route.params.subscribe(params => {
      this.store_id = params.store_id
    })
  }



  ngOnInit(): void {
   
    if (this.store_id >= 0) {
      this.get_data_from_store()
    } else {
      this.refresh_data()
    }
  }

  async get_data_from_store() {
    const data = new FormData()
    data.append('jwt', this.jwt)
    data.append('store_id', this.store_id)


    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}ClientAPI/get_info_by_storeID`, data))
    
    if (response.success) {
  
       this.store_to_edit = response.store
       this.store_to_edit.status = this.store_to_edit.status == 1 ? true:false
       this.country = response.country
  
       this.selectedCountry = this.country.find(country => country.id === this.store_to_edit.country_id)
       
    }
  }


  async asignar() {

    const data = new FormData()
    data.append('jwt', this.jwt)
    data.append('store_id', this.store_id)
    data.append('country_id',   JSON.stringify(this.selectedCountry))
    data.append('store_to_edit', JSON.stringify(this.store_to_edit))

    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}ClientAPI/edit_store`, data))
    this.sendAlert(response.message, response.success ? 'success' : 'error')
    this.router.navigate(['/store'])
  }

  async refresh_data() {
    const data = new FormData()
    data.append('jwt', this.jwt)
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}ClientAPI/get_list_of_store`, data))
    if (response.success) {
      this.stores = response.payload.stores
    }

  
  }

  compareCountryObjects(item1: any, item2: any): boolean {
    return item1 && item2 ? item1 === item2 : item1 === item2;
  }

  sendAlert(message, type) {
    Swal.fire({
      icon: type,
      title: message,
      text: ''
    });
  }

  agregar_tienda_modal() {
    console.log('agregar_store_modal')
    const dialogRef = this.modalService.open(ModalCreateStoreComponent, {
      size: 'lg'
    })
    dialogRef.componentInstance.jwt = this.jwt

    dialogRef.result.then((data) => {
      console.log(data)
      if (data.success) {
        this.stores = data.payload.stores
        // this.usuarios.forEach((usu, index) => {
        //   this.usuarios[index].campus = this.usuarios[index].campus?.split(",")
        // })
      }
    })
  }

}
