<app-breadcrumb [title]="'Productos'" [items]="['Productos']"></app-breadcrumb>
<div class="container-fluid">
  <div class="row">
    <div class="col-12 col-md-6"></div>
    <div class="col-12 col-md-6">
      <app-breadcrumb-body
        [items]="['Productos', 'Listado']"></app-breadcrumb-body>
    </div>
  </div>
</div>
<div class="container-fluid mb-20">
  <div class="row">
    <div class="col-12">
      <div class="card-details">
        <app-card-status-sales
          [text]="'Total de productos'"
          [number]="total_productos"
          [icon]="'assets/images/check.svg'"
          [bgColor]="'#94BAFE'">
        </app-card-status-sales>
        <app-card-status-sales
          [text]="'Total de categorías'"
          [number]="total_productos_categorias_activos"
          [icon]="'assets/images/procesando.svg'"
          [bgColor]="'#007BFF'">
        </app-card-status-sales>
        <app-card-status-sales
          [text]="'Total de productos activos'"
          [number]="total_productos_activos"
          [icon]="'assets/images/pago.svg'"
          [bgColor]="'#404663'">
        </app-card-status-sales>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <button
        type="button"
        class="btn btn-primary mt-15"
        (click)="CrearProducto()"
        aria-controls="collapseExample">
        <span>Crear producto</span>
      </button>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div class="card">
    <div class="card-body p-4">
      <div class="row">
        <div class="col-12">
          <div class="search-container">
            <input
              type="text"
              class="search-input form-control"
              [(ngModel)]="filtros.texto"
              [ngModelOptions]="{ standalone: true }"
              type="text"
              placeholder="Buscar productos" />
            <div class="search-icon">
              <i class="fa fa-search"></i>
            </div>
          </div>

          <button
            type="button"
            class="btn btn-primary btn-custom"
            (click)="rerender()"
            [attr.aria-expanded]="!isCollapsed"
            aria-controls="collapseExample"
            [attr.disabled]="buttonFiltrarTexto != 'Buscar' ? true : null">
            <span>{{ buttonFiltrarTexto }}</span>
          </button>
        </div>
        <div class="col-12 col-md-6"></div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group row">
            <label class="col-sm-3 col-form-label"><b>Categorías:</b></label>
            <div class="col-sm-9">
              <ng-select
                bindValue="id"
                bindLabel="name"
                [items]="categorias"
                class="col-sm-9 js-example-basic-multiple"
                placeholder="Seleccionar categoría"
                [(ngModel)]="filtros.categorias"
                [multiple]="true">
              </ng-select>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group row">
            <label class="col-sm-3 col-form-label"><b>Tamaño:</b></label>
            <div class="col-sm-9">
              <ng-select
                bindValue="id"
                bindLabel="name"
                [items]="size"
                class="col-sm-9 js-example-basic-multiple"
                placeholder="Seleccionar tamaño"
                [(ngModel)]="filtros.size"
                [multiple]="true">
              </ng-select>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group row">
            <label class="col-sm-3 col-form-label"><b>Plataformas:</b></label>
            <div class="col-sm-9">
              <ng-select
                bindValue="id"
                bindLabel="nombre"
                [items]="plataformas"
                class="col-sm-9 js-example-basic-multiple"
                placeholder="seleccionar plataforma"
                [(ngModel)]="filtros.plataformas"
                [multiple]="true">
              </ng-select>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group row">
            <label class="col-sm-3 col-form-label"><b>Tiendas:</b></label>
            <div class="col-sm-9">
              <ng-select
                bindValue="id"
                bindLabel="name"
                [items]="stores"
                class="col-sm-9 js-example-basic-multiple"
                placeholder="Seleccionar tienda"
                [(ngModel)]="filtros.stores"
                [multiple]="true">
              </ng-select>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <button
            type="button"
            class="btn btn-primary btn-custom"
            (click)="rerender()"
            [attr.aria-expanded]="!isCollapsed"
            aria-controls="collapseExample"
            [attr.disabled]="buttonFiltrarTexto != 'Buscar' ? true : null">
            <span>{{ buttonFiltrarTexto }}</span>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <table class="table table-concesionarios">
            <thead>
              <tr>
                <th class="text-center" scope="col">
                  <input
                    type="checkbox"
                    class="table-input-check"
                    (change)="selectAll($event)" />
                </th>
                <th class="text-center" scope="col">Imagen</th>
                <th class="text-center" scope="col">Nombre</th>
                <th class="text-center" scope="col">Categoría</th>

                <th class="text-center" scope="col">Tipo</th>
                <th class="text-center" scope="col">Tamaño</th>

                <th class="text-center" scope="col">Status</th>
                <th class="text-center" scope="col">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let c of products">
                <td class="text-center">
                  <input
                    class="table-input-check"
                    type="checkbox"
                    [(ngModel)]="c.selected"
                    (change)="updateSelection(c)" />
                </td>

                <td class="text-center">
                  <img
                    *ngIf="c.photo; else defaultImage"
                    [src]="c.photo"
                    alt="Imagen existente"
                    [style.height.px]="20" />
                  <ng-template #defaultImage>
                    <img
                      src="https://static.vecteezy.com/system/resources/thumbnails/004/776/092/small_2x/photo-icon-or-picture-icon-image-sign-and-symbol-vector.jpg"
                      alt="Imagen por defecto"
                      [style.height.px]="20" />
                  </ng-template>
                </td>

                <td class="text-center">
                  <a
                    href="#"
                    (click)="openDetailfirmationModal($event, c.id)"
                    >{{ c.name }}</a
                  >
                </td>
                <td class="text-center">{{ c.category }}</td>
                <td class="text-center">{{ c.type }}</td>
                <td class="text-center">{{ c.size }}</td>

                <td class="text-center">
                  <!-- <p [ngClass]="c.available == '1' ? 'activo' : 'desactivado'">
                    {{ c.available == "1" ? "Activo" : "Desactivado" }}
                  </p> -->
                  <app-table-switch-status
                    [productId]="c.id"
                    [(available)]="c.available"></app-table-switch-status>
                </td>

                <td class="text-center">
                  <div class="d-flex justify-content-center">
                    <button
                      (click)="openEditonfirmationModal(c.id)"
                      class="btn btn-primary me-2 btn-acciones"
                      aria-label="Editar">
                      <i class="fa fa-pencil"></i> Editar
                    </button>
                    <button
                      (click)="openDeleteConfirmationModal(c.id)"
                      class="btn btn-danger me-2 btn-acciones"
                      aria-label="Eliminar">
                      <i class="fa fa-trash-o"></i> Eliminar
                    </button>

                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>


