import { Component, OnInit } from '@angular/core';
import { CampaignService } from '../shared/services/campaign.service';
import { lastValueFrom } from 'rxjs'
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DecimalPipe } from '@angular/common';


import Swal from 'sweetalert2';

@Component({
  selector: 'app-loyalty-campaigns-whatsapp',
  templateUrl: './loyalty-campaigns-whatsapp.component.html',
  styleUrls: ['./loyalty-campaigns-whatsapp.component.scss']
})
export class LoyaltyCampaignsWhatsappComponent implements OnInit {

  protected campaigns = [ ]

  // ARRAY CON FILTROS
  filtros

  saldo
  saldo_mes
  moneda

  buttonFiltrarTexto
  isCollapsed
  default_values

  constructor(
    private campaignService: CampaignService,
    private http: HttpClient,
    private decimalPipe: DecimalPipe

  ) { 

    this.filtros = {}

    if (localStorage.getItem("campaigns_filtros")) {
      this.filtros = JSON.parse(localStorage.getItem("campaigns_filtros"))
    }
  
    this.default_values = {}


  }

  
  formatDecimal(value: number): string {
    return this.decimalPipe.transform(value, '1.2-2')!;
  }

  formatNumber(value: number): string {
    return this.decimalPipe.transform(value, '1.0-0')!;
  }


  detallado_campaign = {
    remarketing: 0,
    recompra: 0,
    referido: 0,
    encuesta: 0,
    bienvenida: 0,
    total: 0,
    otros: 0,
  }

  detallado_envio = {
    remarketing: 0,
    recompra: 0,
    referido: 0,
    encuesta: 0,
    bienvenida: 0,
    total: 0,
    otros: 0,
  }
  
  
  async ngOnInit() {
    this.establecerFechas();
    this.load_campaigns()
    this.load_values()
  }

  establecerFechas() {
    const hoy = new Date();
    const primerDiaMes = new Date(hoy.getFullYear(), hoy.getMonth(), 1);
    const fechaFin = hoy;

    this.filtros.fecha_inicio = primerDiaMes.toISOString().split('T')[0];
    this.filtros.fecha_fin = fechaFin.toISOString().split('T')[0];
  }

  rerender(): void {


    localStorage.setItem("campaigns_filtros", JSON.stringify(this.filtros))


    this.buttonFiltrarTexto = `Cargando...`
    this.load_campaigns();

    this.buttonFiltrarTexto = "Filtrar"

  }


  async load_values() {

    const res = <any>await this.campaignService.campaign_service_init_general();

    const response_saldo = <any>await this.campaignService.campaign_service_saldo_whatsapp();
    if (response_saldo.success) {
      const [saldoString, currency] = response_saldo.saldo.split(' ');
      const saldoNumber = parseFloat(saldoString).toFixed(2);

      this.saldo = saldoNumber;
      this.saldo_mes = parseFloat(response_saldo.saldo_mes);
      this.moneda = response_saldo.moneda;

    }

    if (res.success) {

      this.default_values.type = res.payload.type
      this.default_values.tipo_filtro = res.payload.tipo_filtro
    }

    this.buttonFiltrarTexto = "Filtrar"


  }

  costo_total = 0;


  async load_campaigns() {
    Swal.showLoading()

    const res = <any>await this.campaignService.get_campaign_filter(this.filtros, "Whatsapp");

    if (res.success) {
      this.campaigns = res.campaigns;

      var costo = parseFloat(res.costo_total).toFixed(2);
      this.costo_total  = parseFloat(costo);
   

      this.detallado_campaign = {
        remarketing: res.detallado_leads.remarketing  ? res.detallado_leads.remarketing  : 0 ,
        recompra: res.detallado_leads.recompra ? res.detallado_leads.recompra  : 0 ,
        referido: res.detallado_leads.referidos ? res.detallado_leads.referidos  : 0 ,
        encuesta: res.detallado_leads.encuesta ? res.detallado_leads.encuesta  : 0 ,
        bienvenida: res.detallado_leads.bienvenida ? res.detallado_leads.bienvenida  : 0 ,
        total: res.detallado_leads.total ? res.detallado_leads.total  : 0 ,
        otros: res.detallado_leads.otro ? res.detallado_leads.otro  : 0 ,
      }

      this.detallado_envio = {
        remarketing: res.detallado_envios.remarketing  ? res.detallado_envios.remarketing  : 0 ,
        recompra: res.detallado_envios.recompra ? res.detallado_envios.recompra  : 0 ,
        referido: res.detallado_envios.referidos ? res.detallado_envios.referidos  : 0 ,
        encuesta: res.detallado_envios.encuesta ? res.detallado_envios.encuesta  : 0 ,
        bienvenida: res.detallado_envios.bienvenida ? res.detallado_envios.bienvenida  : 0 ,
        total: res.detallado_envios.total ? res.detallado_envios.total  : 0 ,
        otros: res.detallado_envios.otro ? res.detallado_envios.otro  : 0 ,
      }

    }
    Swal.close()
  }

}



