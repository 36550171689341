<!-- Page Header Start-->
<div class="page-header" [class.close_icon]="navServices.collapseSidebar">
  <div class="header-wrapper row m-0">
    <app-search></app-search>
    <div class="header-logo-wrapper col-2 p-0" >
      <div class="logo-wrapper">
        <a routerLink='/'>
          <img class="img-fluid for-light" src="assets/images/logo/logo-churomania.png" alt="">
          <img class="img-fluid for-dark" src="assets/images/logo/logo-churomania.png" alt="">
        </a>
      </div>
      <div class="toggle-sidebar" (click)="sidebarToggle()">
        <app-feather-icons [icon]="'align-center'" class="status_toggle middle"></app-feather-icons>
      </div>
    </div>



    <div class="nav-right col-10 pull-right right-header p-0">
      <ul class="nav-menus">
  
        <li>
          <div class="left-header col horizontal-wrapper ps-0">
            <div *ngIf="tiendas.length > 0" class="media-body">
              <span>{{tiendas[0].name}}</span>
            </div>
          </div>
        </li>
        

        <!-- <li>
          <div class="mode" (click)="layoutToggle()">
            <i class="fa fa-moon-o" *ngIf="!dark"></i>
            <i class="fa fa-lightbulb-o" *ngIf="dark"></i>
          </div>
        </li> -->
        
        <!-- <li class="cart-nav onhover-dropdown">
          <app-cart></app-cart>
        </li> -->
        <!-- <li class="onhover-dropdown">
          <app-message-box></app-message-box>
        </li> -->
        <!-- <li class="maximize">
          <a class="text-dark" href="javascript:void(0)" (click)="toggleFullScreen()">
            <app-feather-icons [icon]="'maximize'"></app-feather-icons>
          </a>
        </li> -->

        <!--
        
        <li class="profile-nav onhover-dropdown p-0">
          <app-my-account></app-my-account>
        </li>

         -->
   

        <li class="onhover-dropdown" routerLink='{{routerLink_generated}}' *ngIf="show_change_store">
          <app-bookmark [tiendas]="tiendas"></app-bookmark> 
          <app-change-store></app-change-store>
        </li>

        
   


          
      </ul>
    </div>
  </div>
</div>
<!-- Page Header Ends -->