<app-breadcrumb [title]="'Reporte Consumo'" [items]="['Campañas']"></app-breadcrumb>


<div class="col-12">
    <div class="card-body card-body-filtros">
        <div class="buttons">
            <button type="button" class="btn btn-primary-honda button_filter__collapse"
                (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed"
                aria-controls="collapseExample">
                <span>Filtros</span>
                <app-feather-icons [icon]="'filter'"></app-feather-icons>

            </button>
        </div>
        <div id="collapseExample" [ngbCollapse]="isCollapsed">
            <div class="card mb-0">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <h5><u>Filtros </u></h5>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label">Fecha de
                                    inicio</label>
                                <div class="col-sm-9">
                                    <input class="form-control digits"
                                        [(ngModel)]="filtros.fecha_inicio"
                                        id="example-datetime-local-input" type="date">
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label">Fecha fin</label>
                                <div class="col-sm-9">
                                    <input class="form-control digits"
                                        [(ngModel)]="filtros.fecha_fin"
                                        id="example-datetime-local-input" type="date">
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label">Fecha de
                                    inicio Lanzamiento</label>
                                <div class="col-sm-9">
                                    <input class="form-control digits"
                                        [(ngModel)]="filtros.fecha_lanzamiento_inicio"
                                        id="example-datetime-local-input" type="date">
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label">Fecha Fin Lanzamiento </label>
                                <div class="col-sm-9">
                                    <input class="form-control digits"
                                        [(ngModel)]="filtros.fecha_lanzamiento_fin"
                                        id="example-datetime-local-input" type="date">
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <div class="col-form-label">Tipo Campaña</div>

                              

                                <ng-select [items]="default_values.type"
                                    [(ngModel)]="filtros.type"
                                    class="js-example-basic-multiple" placeholder=""
                                    [multiple]="true" bindLabel="name" bindValue="id">
                                </ng-select>

                                
                            </div>
                        </div>
                        

                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <div class="col-form-label">Tipo de filtro</div>
                                <ng-select [items]="default_values.tipo_filtro"
                                    class="js-example-basic-multiple" placeholder=""
                                    [(ngModel)]="filtros.tipo_filtro" [multiple]="false">
                                </ng-select>
                            </div>
                        </div>
                   

                        <div class="col-12">
                            <h5><u>Filtros adicionales</u></h5>
                   
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <div class="form-group">
                                        <div class="col-form-label">Por texto</div>
                                        <input class="form-control" type="text"
                                            [(ngModel)]="filtros.texto" placeholder="">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 div-button_filter">
                            <button id="make_filter" type="button" class="btn btn-primary"
                                (click)="rerender()" [attr.aria-expanded]="!isCollapsed"
                                aria-controls="collapseExample"
                                [attr.disabled]="buttonFiltrarTexto != 'Filtrar' ? true:null">
                                <span>{{buttonFiltrarTexto}}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    
                    <div class="media-body text-end">
                        <a [routerLink]="'add'" class="btn btn-primary me-2">Crear campaña +</a>
                        <a (click)="load_campaigns()" role="button" class="btn btn-secondary">Actualizar</a>
                    </div>
                </div>
                <div class="card-body">
                    <table class="table table-concesionarios">
                        <thead>
                            <tr>
                                <th class="text-center" scope="col">Nombre de la campaña</th>
                                <th class="text-center" scope="col">Fecha</th>
                                <th class="text-center" scope="col">Tipo de campaña</th>
                                <th class="text-center" scope="col">Audiencia (Personas)</th>
                                <th class="text-center" scope="col">Estado</th>
                                <th class="text-center" scope="col">LanzamientDSdSo Programado</th>
                                <th class="text-center" scope="col">Reporte</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let c of campaigns">
                                <td>{{c.name}}</td>
                                <td class="text-center">{{c.created_time}}</td>
                                <td class="text-center">
                                    <span class="badge badge-{{c.type_color}}">{{c.type_name}}</span>
                                </td>
                                <td class="text-center">{{c.cantidadPersonas}}</td>
                                <td class="text-center">

                                    <span *ngIf="c.status != 'Cargando'">
                                        {{c.status}}
                                    </span>
                                    <div class="loader-box" *ngIf="c.status == 'Cargando'">
                                        <div class="loader-7"></div>
                                    </div>
                                </td>
                                <td class="text-center">{{c.fecha_lanzamiento}}</td>

                                <td class="text-center">
                                    <div *ngIf="c.status == 'Enviada'">
                                        <a *ngIf="c.type == 3" target="_blank"
                                            href="https://leads.pulpa.digital/Encuesta_Reporte/generar_reporte/{{c.id}}"><i
                                                class="fa fa-file-excel-o text-green"></i></a>
                                        <a *ngIf="c.type != 3" target="_blank"
                                            href="https://leads.pulpa.digital/Audiencia_Reporte/generar_reporte/{{c.audienceID}}/{{c.campaignID}}"><i
                                                class="fa fa-file-excel-o text-green"></i></a>
                                    </div>

                                    <div *ngIf="c.status == 'Programada' || c.status == 'Cargando' || c.status == 'Creada' ">
                            
        
                                        <div class="help" data-container="body" placement="left"
                                            ngbTooltip="Solo podrás el reporte de la campaña cuando esta sea enviada">
                                            <i class="fa fa-info-circle" aria-hidden="true"></i>

                                        </div>
                                 
                                    </div>

                                </td>
                            </tr>
                        </tbody>
                    </table>


                </div>
            </div>
        </div>
    </div>
</div>