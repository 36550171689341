import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { HttpClient } from "@angular/common/http";
import { Subscription, lastValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalCreateTurnoComponent } from 'src/app/modal-create-turno/modal-create-turno.component';

const Swal = require('sweetalert2')

@Component({
  selector: 'app-turno',
  templateUrl: './turno.component.html',
  styleUrls: ['./turno.component.scss']
})
export class TurnoComponent implements OnInit {

  private jwt
  private routeSub: Subscription;
  turno_id
  turno_to_edit

  turnos: any

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private router: Router,
  ) {
    this.jwt = JSON.parse(localStorage.user).jwt
    this.turno_to_edit = {}
    this.routeSub = this.route.params.subscribe(params => {
      this.turno_id = params.turno_id
    })
  }



  ngOnInit(): void {

    if (this.turno_id >= 0) {
      this.get_data_from_turno()
    } else {
      this.refresh_data()
    }
  }

  async get_data_from_turno() {
    const data = new FormData()
    data.append('jwt', this.jwt)
    data.append('turno_id', this.turno_id)


    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}ClientAPI/get_info_by_turnoID`, data))
    
    if (response.success) {
  
       this.turno_to_edit = response.turno
      //  this.turno_to_edit.status = this.turno_to_edit.status == 1 ? true:false
     
    }
  }

  

  async refresh_data() {
    const data = new FormData()
    data.append('jwt', this.jwt)
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}ClientAPI/get_list_of_turnos`, data))
    if (response.success) {
      this.turnos = response.payload.turnos
    }

  
  }


  async cancel(){
 
    Swal.fire('Cancelado', 'La operació ha sido cancelada.', 'info');
    this.router.navigate(['/turnos'])
  }

  async asignar(){
    const data = new FormData()
    data.append('jwt', this.jwt)
    data.append('turno_id', this.turno_id)
    data.append('turno_to_edit', JSON.stringify(this.turno_to_edit))

    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}ClientAPI/edit_turno`, data))
    this.sendAlert(response.message, response.success ? 'success' : 'error')
    this.router.navigate(['/turnos'])
  }

  sendAlert(message, type) {
    Swal.fire({
      icon: type,
      title: message,
      text: ''
    });
  }

  agregar_turno_modal() {
    console.log('agregar_turno_modal')
    const dialogRef = this.modalService.open(ModalCreateTurnoComponent, {
      size: 'lg'
    })
    dialogRef.componentInstance.jwt = this.jwt

    dialogRef.result.then((data) => {
      console.log(data)
      if (data.success) {
        this.turnos = data.payload.turnos
        // this.usuarios.forEach((usu, index) => {
        //   this.usuarios[index].campus = this.usuarios[index].campus?.split(",")
        // })
      }
    })
  }

}
