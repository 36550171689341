<app-breadcrumb [title]="''" [items]="['Paises']" [active_item]="'Listado de paises'">
</app-breadcrumb>
<div *ngIf="country_id > 0; then edit_country else pick_country"></div>
<ng-template #pick_country>
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <h5>Lista de países</h5>
                        <button (click)="agregar_country_modal()" class="btn btn-primary m-t-10">Agregar +</button>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-concesionarios">
                            <thead>
                                <tr>
                                    <th scope="col">Nombre</th>
                                    <!-- <th scope="col">Bandera</th> -->
                                    <th scope="col">Moneda</th>
                                    <th scope="col">Símbolo</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let country of countries" class="tr-mobile">
                                    <td>
                                        <div class="td-mobile">Nombre</div><b>{{country.name}}</b>
                                    </td>
                                    <!-- <td>
                                        <div class="td-mobile">Bandera</div>{{country.flag}}
                                    </td> -->
                                    <td>
                                        <div class="td-mobile">Moneda</div>{{country.currency}}
                                    </td>
                                    <td>
                                        <div class="td-mobile">Símbolo</div>{{country.currency_symbol}}
                                    </td>
                                
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #edit_country>

</ng-template>
