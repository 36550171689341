<app-breadcrumb
  [title]="'Tienda'"
  [items]="['Tienda', 'Agregar Tienda']"></app-breadcrumb>
  <div class="container-fluid">
    <div class="row">
        <div class="col-12 col-md-6"></div>
        <div class="col-12 col-md-6">
        <app-breadcrumb-body
            [items]="['Tiendas', 'Agregar']"></app-breadcrumb-body>
        </div>
    </div>
</div>
<div *ngIf="store_id > 0; then edit_store else pick_store"></div>

<ng-template #pick_store>
<div class="container-fluid">
    <div class="card">
        <div class="card-body p-4">
            <h2>Crear Nueva Tienda</h2>
            <nav class="breadcrumb-nav">
                <a routerLink="/customer"> ← Volver a tiendas</a>
            </nav>
            <div class="container-product">
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label for="nombre">Nombre del comercio</label>
                          <input type="text" placeholder="Ingresa el nombre de la tienda" class="form-control" id="nombre" [(ngModel)]="store_to_create.name">
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label for="rut">RUT</label>
                          <input type="text" placeholder="Ingresa el RUT" class="form-control" id="rut" [(ngModel)]="store_to_create.rut">
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label for="razon_social">Razón Social</label>
                          <input
                            type="text"
                            placeholder="Ingresa la razón social"
                            class="form-control"
                            id="razon_social" 
                            [(ngModel)]="store_to_create.razon_social"/>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label for="representante">Representante legal</label>
                          <input
                            type="text"
                            placeholder="Ingresa representante legal"
                            class="form-control"
                            id="representante"
                            [(ngModel)]="store_to_create.representante_legal" />
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label for="correo">Correo electrónico</label>
                          <input
                            type="email"
                            placeholder="Ingresa el correo electrónico"
                            class="form-control"
                            id="correo"
                            [(ngModel)]="store_to_create.correo" />
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label for="telefono">Teléfono</label>
                          <input
                           (keypress)="onlyNumbers($event)"
                            type="number"
                            placeholder="Ingresa el teléfono"
                            class="form-control"
                            id="telefono"
                            [(ngModel)]="store_to_create.telefono" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-product">
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label for="direccion">Dirección</label>
                          <input type="text" placeholder="Ingresa la dirección" class="form-control" id="direccion" [(ngModel)]="store_to_create.adress">
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <label for="countries">País</label>
                            <ng-select placeholder="Selecciona un país" bindValue="id" bindLabel="name" [items]="countries" class="js-example-basic-multiple"
                                [(ngModel)]="store_to_create.countries">
                            </ng-select>
                            
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label for="latitud">Latitud</label>
                          <input
                            type="text"
                            placeholder="Ingresa la latitud"
                            class="form-control"
                            id="latitud" 
                            [(ngModel)]="store_to_create.latitud"/>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label for="longitud">Longitud</label>
                          <input
                            type="text"
                            placeholder="Ingresa la longitud"
                            class="form-control"
                            id="longitud"
                            [(ngModel)]="store_to_create.longitud" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-product activated-apps">
              
                    <h3 class="mt-15">Habilitar tienda</h3>
                    <div class="container-apps">
                      <div class="content-switch">
                        <p class="content-switch-text">Deshabilitar / Habilitar:</p>
                        <label class="switch">
                          <input type="checkbox" [(ngModel)]="store_to_create.status" />
                          <span class="switch-state"></span>
                        </label>
                      </div>
                    </div>
            
            </div>
            <div class="container-product activated-apps">
              
                <h3 class="mt-15">Aplicaciones</h3>
                <div class="container-apps">
                    <div class="content-switch" *ngFor="let app of plataformas">
                      <p class="content-switch-text">{{ app.nombre }}:</p>
                      <label class="switch">
                        <input type="checkbox" [(ngModel)]="app.activo" />
                        <span class="switch-state"></span>
                      </label>
                    </div>
                  </div>
             </div>
             <div class="container-product">
              
                <h3 class="mt-15">Horarios de atención</h3>
                <input class="input-time" type="time" [(ngModel)]="store_to_create.horaInicioSeleccionada" />
                <input class="input-time" type="time" [(ngModel)]="store_to_create.horaFinSeleccionada" />
          
        
             </div>
             <div class="row">
                <div class="form-group buttons">
                  <button
                    [attr.disabled]="is_loading ? true : null"
                    class="btn btn-primary"
                    (click)="onSubmit()">
                    Crear tienda
                  </button>
                  <button class="btn btn-secondary" (click)="onCancel()">
                    Cancelar
                  </button>
                </div>
            </div>
        </div>
    </div>
</div>
</ng-template>
<ng-template #edit_store>
    <div class="container" style="display: none;">
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <h5>Editar Tienda</h5>
                        <span>{{store_to_edit.name}}</span>
                    </div>
                    <div class="card-body content-to-edit">
                        <div class="usuario-campo">
                            <div class="row">
                                <div class="col-12 col-md-6 mb-3">
                                    <label class="form-label" for="name">Nombre</label>
                                    <input class="form-control" id="name" type="text" [(ngModel)]="store_to_edit.name">
                                </div>
                                <div class="col-12 col-md-6 mb-3">
                                    <label class="form-label" for="adress">Dirección</label>
                                    <input class="form-control" id="adress" type="text"
                                        [(ngModel)]="store_to_edit.adress">
                                </div>
                                <div class="col-12 col-md-6 mb-3">
                                    <label class="form-label" for="latitud">Latitud</label>
                                    <input class="form-control" id="latitud" type="text" [(ngModel)]="store_to_edit.latitud">
                                </div>
                                <div class="col-12 col-md-6 mb-3">
                                    <label class="form-label" for="longitud">Longitud</label>
                                    <input class="form-control" id="longitud" type="text" [(ngModel)]="store_to_edit.longitud">
                                </div>
                          
                                <div class="col-12 col-md-6 mb-3">
                                    <div class="form-group">
                                        <label for="country">Country</label>
                                        
                                        <ng-select bindValue="id" bindLabel="name" [items]="country" class="js-example-basic-multiple"
                                            [(ngModel)]="selectedCountry" [compareWith]="compareCountryObjects">
                                        </ng-select>

                                    </div>
                                </div>
                                <div class="col-12 col-md-6 mb-3">
                                    <label class="form-label" for="activo">¿Activo? </label>
                                    <i ngbTooltip="Si desactivas la tienda, no aparecerá en el listado de los demás usuarios."
                                        class="fa fa-fw fa-info-circle"></i>
                                    <br>

                                    <label class="switch">
                                        <input id="activo" [(ngModel)]="store_to_edit.status" type="checkbox"><span
                                            class="switch-state"></span>
                                    </label>
                                </div>

                            </div>
                        </div>
                        <div class="col-12 text-center m-b-10">
                            <button (click)="asignar()" class="btn btn-primary">¡Guardar!</button>
                        </div>
                    </div>



                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="card">
            <div class="card-body p-4">
                <h2>Editar Datos de Tienda</h2>
                <nav class="breadcrumb-nav">
                    <a routerLink="/customer"> ← Volver a tiendas</a>
                </nav>
                <div class="container-product">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                              <label for="nombre">Nombre del comercio</label>
                              <input type="text" placeholder="Ingresa el nombre de la tienda" class="form-control" id="nombre" [(ngModel)]="store_to_edit.name">
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                              <label for="rut">RUT</label>
                              <input type="text" placeholder="Ingresa el RUT" class="form-control" id="rut" [(ngModel)]="store_to_edit.rut">
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                              <label for="razon_social">Razón Social</label>
                              <input
                                type="text"
                                placeholder="Ingresa la razón social"
                                class="form-control"
                                id="razon_social" 
                                [(ngModel)]="store_to_edit.razon_social"/>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                              <label for="representante">Representante legal</label>
                              <input
                                type="text"
                                placeholder="Ingresa representante legal"
                                class="form-control"
                                id="representante"
                                [(ngModel)]="store_to_edit.representante_legal" />
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                              <label for="correo">Correo electrónico</label>
                              <input
                                type="email"
                                placeholder="Ingresa el correo electrónico"
                                class="form-control"
                                id="correo"
                                [(ngModel)]="store_to_edit.correo" />
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                              <label for="telefono">Teléfono</label>
                              <input
                              (keypress)="onlyNumbers($event)"
                                type="number"
                                placeholder="Ingresa el teléfono"
                                class="form-control"
                                id="telefono"
                                [(ngModel)]="store_to_edit.telefono" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-product">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                              <label for="direccion">Dirección</label>
                              <input type="text" placeholder="Ingresa la dirección" class="form-control" id="direccion" [(ngModel)]="store_to_edit.adress">
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <label for="countries">País</label>
                                
                                <ng-select placeholder="Selecciona un país" bindValue="id" bindLabel="name" [items]="countries" class="js-example-basic-multiple"
                                    [(ngModel)]="store_to_edit.country_id">
                                </ng-select>
                                
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                              <label for="latitud">Latitud</label>
                              <input
                                type="text"
                                placeholder="Ingresa la latitud"
                                class="form-control"
                                id="latitud" 
                                [(ngModel)]="store_to_edit.latitud"/>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                              <label for="longitud">Longitud</label>
                              <input
                                type="text"
                                placeholder="Ingresa la longitud"
                                class="form-control"
                                id="longitud"
                                [(ngModel)]="store_to_edit.longitud" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-product activated-apps">
              
                    <h3 class="mt-15">Habilitar tienda</h3>
                    <div class="container-apps">
                      <div class="content-switch">
                        <p class="content-switch-text">Deshabilitar / Habilitar:</p>
                        <label class="switch">
                          <input type="checkbox" [(ngModel)]="store_to_edit.status" />
                          <span class="switch-state"></span>
                        </label>
                      </div>
                    </div>
            
            </div>
            <div class="container-product activated-apps">
              
                <h3 class="mt-15">Aplicaciones</h3>
                <div class="container-apps">
                    <div class="content-switch" *ngFor="let app of plataformas">
                      <p class="content-switch-text">{{ app.nombre }}:</p>
                      <label class="switch">
                        <input type="checkbox" 
                        [checked]="plataformaSeleccionada(app.id)"
                        (change)="togglePlataforma(app)" 
                        [(ngModel)]="app.activo" />
                        <span class="switch-state"></span>
                      </label>
                    </div>
                  </div>
             </div>
             <div class="container-product">
              
                <h3 class="mt-15">Horarios de atención</h3>
                <input class="input-time" type="time" [(ngModel)]="store_to_edit.hora_apertura" />
                <input class="input-time" type="time" [(ngModel)]="store_to_edit.hora_cierre" />
          
        
             </div>
             <div class="row">
                <div class="form-group buttons">
                  <button
                    [attr.disabled]="is_loading ? true : null"
                    class="btn btn-primary"
                    (click)="onSubmitEdit()">
                   Guardar
                  </button>
                  <button class="btn btn-secondary" (click)="onCancel()">
                    Cancelar
                  </button>
                </div>
            </div>
            </div>
        </div>
    </div>
</ng-template>