<app-breadcrumb [title]="'Asignar usuarios'" [items]="['Tienda', 'Agregar']"></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                 
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <label for="">Seleccione Tienda</label>
                                <ng-select (change)="get_store($event)" bindValue="id" bindLabel="name"
                                    [items]="list_of_stores" class="js-example-basic-multiple"
                                    [(ngModel)]="store.id">
                                </ng-select>
                            </div>
                        </div>
  

                        <div class="col-12 col-md-6" >
                            <div class="form-group">
                                <label for="">Seleccione los usuarios</label>
                                <ng-select [multiple]="true" bindValue="id" bindLabel="username" 
                                [items]="list_of_users"
                                    class="js-example-basic-multiple" [(ngModel)]="user.id">
                                </ng-select>
                            </div>
                        </div>


                        <div class="buttons">
                            <button (click)="addUsersToStore()" class="btn btn-secondary">Asignar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>