
<app-breadcrumb
  [title]="'Combos'"
  [items]="['Combos', 'Detalle Combo']"></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-12 col-md-6"></div>
        <div class="col-12 col-md-6">
        <app-breadcrumb-body
            [items]="['Combos', 'Detalle']"></app-breadcrumb-body>
        </div>
    </div>
</div>
<div class="container-fluid">
  <div class="card">
    <div class="card-body p-4">
        <h2>Detalle Combo</h2>
        <nav class="breadcrumb-nav">
          <a routerLink="/combos"> ← Volver a Combos</a>
        </nav>
        <div class="container-product">
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="nombreProducto">Nombre del Combo</label>
                <input disabled type="text" class="form-control" id="nombreProducto" [(ngModel)]="combo_detail.nombre">
  
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="estadoCombo">Estado</label>
                <select disabled class="form-control" id="estadoCombo" [(ngModel)]="combo_detail.estado">
                    <option [value]="'activo'">Activo</option>
                    <option [value]="'desactivado'">Desactivado</option>
                </select>
              </div>
             
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label for="descripcionProducto">Descripción</label>
                <textarea disabled class="form-control" id="descripcionProducto" rows="3" [(ngModel)]="combo_detail.descripcion"></textarea>  
  
              </div>
            </div>
          </div>
        </div>
        <div class="container-product">
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="stockProducto">Precio</label>
                <input disabled type="number"
                        class="form-control"
                        id="precio"
                        [(ngModel)]="combo_detail.precio"
                        >
              </div>
            </div>
          </div>
        </div>
        <div class="container-product activated-apps">
          <h3>Activar para tiendas y apps</h3>
          <p class="activated-apps-text">
            Selecciona las tiendas y las apps donde este producto estará activo
          </p>
          <div class="inputs-container">
            
            <div class="inputs-item" *ngFor="let store of stores">
              <input
                type="checkbox"
                [checked]="storeSeleccionada(store.id)"
                (change)="toggleStore(store)" 
                [id]="'store-' + store.id"
                [(ngModel)]="store.selected"
              />
              <label [for]="'store-' + store.id">{{ store.name }}</label>
            </div>
          </div>
          <h3 class="mt-15">Aplicaciones</h3>
          <div class="container-apps">
            <div class="content-switch" *ngFor="let app of plataformas">
              <p class="content-switch-text">{{ app.nombre }}:</p>
              <label class="switch">
                <input type="checkbox" 
                [checked]="plataformaSeleccionada(app.id)"
                (change)="togglePlataforma(app)" 
                [(ngModel)]="app.activo" />
                <span class="switch-state"></span>
              </label>
            </div>
          </div>
        </div>
        <div class="container-product">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label for="imagenProducto">Imagen del Combo</label>
                <!-- Image preview or default image -->
                <div class="upload-image">
                  <div class="upload-image-preview">
                    <img
                      *ngIf="imagePreview"
                      [src]="imagePreview"
                      alt="Imagen del Producto"
                      class="img-thumbnail" />
                    <div
                      *ngIf="!imagePreview"
                      style="
                        width: 140px;
                        height: 140px;
                        margin-bottom: 0;
                        background-color: #adb5bd;
                      "></div>
                    <ul>
                      <li>Formatos permitidos: JPG, JPEG, PNG.</li>
                      <li>Tamaño máximo: 5000 x 5000 píxeles.</li>
                      <li>Peso máximo: 16 MB</li>
                    </ul>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="container-product">
          <h3>Administrar Combos</h3>
          <ng-container *ngIf="categoriasSeleccionadas.length > 0">
            <div class="form-group mt-2 mb-2">
              <h3 >Categorías: </h3>
            </div>
            <div *ngFor="let categoria of categoriasSeleccionadas; let i = index" class="card mt-4 p-2">
              <div class="card-body">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <h4
                      class="category-title align-middle name-category-selected">
                      {{ categoria.name }}
                    </h4>
                  </div>
                </div>
                <div class="row">
               
                  <ng-container *ngIf="categoria.variables_product.length != 0">
                    <div class="col-12 col-md-12 row mt-2">
                      <div
                        class="col-12 col-md-4"
                        *ngFor="
                          let item of categoria.atributes_product | keyvalue
                        ">
                        <label>{{ item.key }}</label>
  
                        <ng-select [(ngModel)]="categoria.selectedAttributes[item.key]" 
                                [items]="item.value" 
                                bindLabel="item" 
                                placeholder="Select {{item.key}}"
                                (change)="onChangeVariable(categoria, i)">
                      </ng-select>
                      </div>
                    </div>
  
                    <div class="col-12 col-md-12 row mt-2">
                      <div class="col-12 col-md-4 form-group">
                        <label for="productosSelect"
                          >Seleccionar Producto Variable</label
                        >
                        <ng-select bindValue="id" bindLabel="name"
                        [items]="categoria.variables_product_select" class="js-example-basic-multiple"
                        [(ngModel)]="categoria.idVariable">
                         </ng-select>
                      </div>
                    </div>
                  </ng-container>
  
                </div>

                
                <!-- Lista de productos seleccionados para la categoría -->
                <div *ngIf="categoria.productos_seleccionados.length > 0" class="combo-selected-products mt-4">
                  <!-- <h4 class="align-middle name-category-selected">
                    Productos Seleccionados
                  </h4>
           -->
                  <ul>
                    <li *ngFor="let producto_seleccionado of categoria.productos_seleccionados" class="list-group-item">
                  
                      <p>
                        {{ producto_seleccionado.name }}
                      </p>
          
                    </li>
                  </ul>
          
                </div>
          
                
              </div>
            </div>
          </ng-container>
          <div class="row">
            <div class="form-group buttons">
              <button [attr.disabled]="is_loading ? true : null" class="btn btn-primary" (click)="Regresar()">Regresar a Combos</button>
            </div>
          </div>
        </div>
    </div>
  </div>
</div>



