<app-breadcrumb [title]="'Tiendas'" [items]="['Tiendas']"></app-breadcrumb>
<div class="container-fluid">
  <div class="row">
    <div class="col-12 col-md-6"></div>
    <div class="col-12 col-md-6">
      <app-breadcrumb-body
        [items]="['Tiendas', 'Listado']"></app-breadcrumb-body>
    </div>
  </div>
</div>
<div class="container-fluid">
    <div class="container-card">
      <h2>Lista de tiendas</h2>
      <div class="container-card-status-inner">
        <app-card-status-sales
          [text]="'Total de tiendas'"
          [number]="total_tiendas"
          [icon]="'assets/images/total-clientes.svg'"
          [bgColor]="'#94BAFE'">
        </app-card-status-sales>
        <app-card-status-sales
          [text]="'Tiendas nuevas'"
          [number]="000"
          [icon]="'assets/images/clientes-nuevos.svg'"
          [bgColor]="'#007BFF'">
        </app-card-status-sales>
        <app-card-status-sales
          [text]="'Tiendas inactivas'"
          [number]="total_tiendas_inactive"
          [icon]="'assets/images/clientes-recurrentes.svg'"
          [bgColor]="'#404663'">
        </app-card-status-sales>
      </div>
      <div class="container-card-status-inner">
        <app-card-status-sales
          [text]="'Tiendas activas'"
          [number]="total_tiendas_active"
          [icon]="'assets/images/clientes-activos.svg'"
          [bgColor]="'#94BAFE'">
        </app-card-status-sales>
        <app-card-status-sales
          [text]="'Promedio de Trx'"
          [number]="promedio_tiendas_trx"
          [icon]="'assets/images/promedio-visita.svg'"
          [bgColor]="'#007BFF'">
        </app-card-status-sales>
        <app-card-status-sales
          [text]="'Ticket promedio'"
          [number]="promedio_ticket_store | currency: currency_selected: ''"
          [icon]="'assets/images/ticket-promedio.svg'"
          [bgColor]="'#404663'">
        </app-card-status-sales>
      </div>
      <div class="row">
        <div class="col-12">
          <button
            type="button"
            class="btn btn-primary mt-15"
            (click)="CrearTienda()"
            aria-controls="collapseExample">
            <span>Crear nueva tienda</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="container-card">
      <div class="row">
        <h2>Buscar una tienda:</h2>
        <div class="col-12">
          <div class="search-container">
            <input
              type="text"
              class="search-input form-control"
              [(ngModel)]="filtros.texto"
              [ngModelOptions]="{ standalone: true }"
              type="text"
              placeholder="Ingresar tienda o RUT" />
            <div class="search-icon">
              <i class="fa fa-search"></i>
            </div>
          </div>
  
          <button
            id="make_filter"
            type="button"
            class="btn btn-primary btn-custom"
            (click)="rerender()"
            aria-controls="collapseExample"
            [attr.disabled]="buttonFiltrarTexto != 'Buscar' ? true : null">
            <span>{{ buttonFiltrarTexto }}</span>
          </button>
        </div>
        <div class="col-12 col-md-6"></div>
      </div>

      <div class="row">
        <h2>Filtros de búsqueda:</h2>
        
        <div class="col-12 col-md-6">
          <div class="form-group row">
            <label class="col-sm-3 col-form-label"><b>Plataformas:</b></label>
            <div class="col-sm-9">
              <ng-select
                bindValue="id"
                bindLabel="nombre"
                [items]="plataformas"
                class="col-sm-9 js-example-basic-multiple"
                placeholder="Seleccionar plataforma"
                [(ngModel)]="filtros.plataformas"
                [multiple]="true">
              </ng-select>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <button
          id="make_filter"
          type="button"
          class="btn btn-primary btn-custom"
          (click)="rerender()"
          aria-controls="collapseExample"
          [attr.disabled]="buttonFiltrarTexto != 'Buscar' ? true : null">
          <span>{{ buttonFiltrarTexto }}</span>
        </button>
        </div>
      </div>
    </div>
  </div> 
<div class="container-fluid">
    <div class="card">
        <div class="card-body p-4">
            <div class="row">

            </div>
            <div class="row">
                <div class="col-12">
                  <table class="table table-concesionarios">
                    <thead>
                      <tr>
                        <th class="text-center" scope="col">Nombre de la tienda</th>
                        <th class="text-center" scope="col">Teléfono</th>
                        <th class="text-center" scope="col">Correo</th>
                        <th class="text-center" scope="col">Status</th>
                        <!--<th scope="col">Longitud</th> -->
                        <th class="text-center" scope="col">Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let store of stores">
                            <td class="text-center">
                                <div class="td-mobile">Nombre</div>
                                <b><a href="#" (click)="openViewModal($event,store.id)">{{ store.name }}</a></b>
                            </td>
                            <td class="text-center">
                                <div class="td-mobile">Teléfono</div>{{store.mobile}}
                            </td>
                            <td class="text-center">
                                <div class="td-mobile">Correo</div>{{store.correo}}
                            </td>
    
                            <td class="text-center">
                    
                                <div class="table-swtch-status" [ngClass]="{'active': store.status == 1, 'inactivo': store.status != 1}" >
                                  <span class="status-text">{{ store.status == 1 ? "Habilitado" : "Deshabilitado" }}</span>
                                  <label class="switch" style="margin-bottom: 0 !important">
                                    <input
                                      type="checkbox"
                                      [checked]="store.status == 1"
                                      (change)="updateStatus($event.target.checked,store.id)"
                                      />
                                    <span class="switch-state"></span>
                                  </label>
                                </div>
                                
                              </td>
                            <td class="text-center">
                                
                                <div class="d-flex justify-content-center">
                                    
                                  <button
                                    (click)="openEditonfirmationModal(store.id)"
                                    class="btn btn-primary me-2 btn-acciones"
                                    aria-label="Editar">
                                    <i class="fa fa-pencil"></i> Editar
                                  </button>
              
                                </div>
                              </td>
                          
                        
                        </tr>
                    </tbody>
                   
                    
                  </table>
                </div>
              </div>
        </div>
    </div>
</div>
