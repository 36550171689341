<app-breadcrumb [title]="'Tienda'" [items]="['Asignar usuarios']"></app-breadcrumb>
<div class="container-fluid">
  <div class="row">
    <div class="col-12 col-md-6"></div>
    <div class="col-12 col-md-6">
      <app-breadcrumb-body
        [items]="['Tienda', 'Asignar usuarios']"></app-breadcrumb-body>
    </div>
  </div>
</div>
<div class="container-fluid">
    <div class="card">
        <div class="card-body p-4">
            <div class="row">
                <div class="col-12 col-md-6" style="margin-bottom:30px">
                    <button (click)="agregar_usuario()" class="btn btn-primary m-t-10">Agregar +</button>
                </div>
            
            </div>
            <div class="row">
                <h2>Asignar usuarios</h2>
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label for="">Seleccione Tienda</label>
                        <ng-select (change)="get_store($event)" bindValue="id" bindLabel="name"
                            [items]="list_of_stores" class="js-example-basic-multiple"
                            [(ngModel)]="store.id">
                        </ng-select>
                    </div>
                </div>


                <div class="col-12 col-md-6" >
                    <div class="form-group">
                        <label for="">Seleccione los usuarios</label>
                        <ng-select [multiple]="true" bindValue="id" bindLabel="username" 
                        [items]="list_of_users"
                            class="js-example-basic-multiple" [(ngModel)]="user.id">
                        </ng-select>
                    </div>
                </div>


                <div class="buttons">
                    <button (click)="addUsersToStore()" class="btn btn-primary">Guardar</button>
                </div>
            </div>
        </div>
    </div>
</div>
