import { Component,OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { ModalCreateCustomerComponent } from '../modal-create-customer/modal-create-customer.component';
import { ModalSelectProductComponent } from '../modal-select-product/modal-select-product.component';
import { CustomerService } from '../shared/services/customer.service';
import { ProductService } from '../shared/services/product.service';
import { Router } from '@angular/router';


const Swal = require('sweetalert2')

@Component({
  selector: 'app-create-sale',
  templateUrl: './create-sale.component.html',
  styleUrls: ['./create-sale.component.scss']
})
export class CreateSaleComponent implements OnInit {

  private jwt

  sale = {
    customer_id: null,
    payment_id: null,
    amount_to_paid: null,
    vuelto:null,
    desea_factura:null,
    rut:null,
    razon_social:null
  }


  product_id :any

  tienda_selected
  currency_selected
  currency_symbol_selected
  list_of_customers = []
  list_of_products = []
  searchProducts= []
  list_of_categories = []
  list_of_payment_method = [
    { id: 'efectivo', name: 'Efectivo' },
    { id: 'tarjeta_credito', name: 'Tarjeta de crédito' },
    { id: 'tarjeta_debito', name: 'Tarjeta de débito' }
  ]

  list_of_combos = []
  cartItemsCustom: any[] = [];

  cartItems: any[] = [];
  subtotal: number = 0;
  total: number = 0;
  tax: number = 0;

  itemsTabla = []
  public searchText : string;
  public listView: boolean = false;
  public col: string = '4';
  public col1: string = '4';
  public col2: string = '6';

  selectedButton: number | null = null;
  isButtonDisabled = false;

  constructor(
    private customerService: CustomerService,
    private productService: ProductService,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer,
    public router: Router
  ) {
    this.jwt = JSON.parse(localStorage.user).jwt
   }

  ngOnInit(): void {
    this.tienda_selected = localStorage.getItem('tienda_selected')
    this.currency_selected = localStorage.getItem('currency_selected')
    this.currency_symbol_selected = localStorage.getItem('currency_symbol_selected')
    this.init()
  }

  init() {
  
    this.loadCustomers()
    this.loadCategories(this.tienda_selected)
    this.loadProducts(this.tienda_selected)
    // this.loadCombos(this.tienda_selected)
    
  }



  async loadCustomers() {
    const res = <any>await this.customerService.get_all_customers__disponibles()
   
    console.log(res.customers)
    if (res.success) {
      this.list_of_customers = res.customers
    }
  }

  async loadProducts(tienda) {
    const res = <any>await this.productService.get_all_products__disponibles(tienda)
    const res_combos = <any>await this.productService.get_all_combos__disponibles(tienda)
    if (res.success) {
      
    const mixedArrayProducts = [];

    for (let i = 0; i < Math.max(res.products.length, res_combos.combos.length); i++) {
      if (i < res.products.length) {
        mixedArrayProducts.push(res.products[i]);
      }
      if (i < res_combos.combos.length) {
        mixedArrayProducts.push(res_combos.combos[i]);
      }
    }

    this.list_of_products = mixedArrayProducts
    this.searchProducts = mixedArrayProducts

    }
   
  }

  async loadCategories(tienda) {
    const res = <any>await this.productService.get_all_categories__disponibles(tienda)

    if (res.success) {
      this.list_of_categories = res.categories
    }

  }


  onQuantityChange(newQuantity: number, product: any) {
   

      product.cantidad = newQuantity;
      const precioTotalChange = this.cartItemsCustom.reduce((acumulador, producto) => {
  
      return acumulador + (producto[0].precio * newQuantity) + (producto[0].price_extras_total * newQuantity)
   
      }, 0);



      this.subtotal = precioTotalChange;
      const porcentajeImpuesto = 19;
      const impuesto = precioTotalChange * (porcentajeImpuesto / 100);
      this.tax = impuesto;
      this.total = precioTotalChange + impuesto;
    

  }


  async open_modal_customer(){

    const dialogRef = this.modalService.open(ModalCreateCustomerComponent, {
      size: 'lg'
    })
    dialogRef.componentInstance.jwt = this.jwt

    dialogRef.result.then((data) => {
      if (data.success) {
        Swal.fire(data.message, '', 'success')
        this.loadCustomers()
        // this.customers = data.customers
      }
    })
  }

  onCategoriaClick(categoria: any,index: number) {

    this.selectedButton = index;
    let user = []
    if(!categoria) return this.searchProducts = this.list_of_products

  
      this.list_of_products.filter(users => {
   
         if(categoria == 'combos'){
          if(users.product_category_id.includes('combo')) {
            user.push(users)
          } 
        }else{
          if(users.product_category_id.includes(categoria.id)) {
            user.push(users)
          } 
        }
       
      })
    
    
    this.searchProducts = user
   
  
    
  }

  continuar_pedido: boolean = false;

  continuar(){
    this.continuar_pedido = true;
  }

  async addToCart(product: any){

    const dialogRef = this.modalService.open(ModalSelectProductComponent, {
      size: 'xl', // Cambia a 'sm', 'lg', 'xl', o deja vacío según lo necesites.
      modalDialogClass: 'custom-modal-dialog' // Clase personalizada

    });

    dialogRef.componentInstance.jwt = this.jwt
    dialogRef.componentInstance.product = product
    dialogRef.componentInstance.tienda_id = this.tienda_selected


    dialogRef.result.then((data) => {

      if (data.success != false) {
        Swal.fire('Producto agregado', '', 'success')
        this.cartItemsCustom.push(data)

        console.log(this.cartItemsCustom);
   
        const precioTotalChange = this.cartItemsCustom.reduce((acumulador, producto) => {
         
          return acumulador + (producto[0].precio * producto[0].cantidad) + (producto[0].price_extras_total * producto[0].cantidad)
       
          }, 0);
          
          
          this.subtotal = precioTotalChange;
          const porcentajeImpuesto = 19;
          const impuesto = precioTotalChange * (porcentajeImpuesto / 100);
          this.tax = impuesto;
          this.total = precioTotalChange + impuesto;
      }
    })
  }

  removeProduct(productId: number) {
   
    this.cartItemsCustom = this.cartItemsCustom.filter(item => item[0].product_id !== productId);

    const precioTotal = this.cartItemsCustom.reduce((acumulador, producto) => {
      return acumulador + (producto[0].precio * producto[0].cantidad) + (producto[0].price_extras_total * producto[0].cantidad)
    }, 0);

    // this.subtotal = this.cartItems.reduce((acc, item) => acc + (item.product.price * item.quantity), 0)
    this.subtotal = precioTotal;
    const porcentajeImpuesto = 19;
    const impuesto = precioTotal * (porcentajeImpuesto / 100);
    this.tax = impuesto;
    this.total = precioTotal + impuesto;
  }

  searchTerm(term: any) {
  
    
     if(!term) return this.searchProducts = this.list_of_products
      term = term.toLowerCase();
      let user = []
      this.list_of_products.filter(users => {
        if(users.name.toLowerCase().includes(term)) {
          user.push(users)
        } 
      })
      this.searchProducts = user

  }

  async pay(){
   
    if(this.cartItemsCustom.length == 0 || this.cartItemsCustom == null){
      Swal.fire('Error', 'Seleccionar productos', 'error');
      return false;
    }
    if(this.sale.payment_id == null){
      Swal.fire('Error', 'La forma de pago es obligatorio', 'error');
      return false;
    }else{
      if(this.sale.payment_id == 'efectivo'){
        if(this.sale.amount_to_paid == null){
          Swal.fire('Error', 'Ingresar la cantidad', 'error');
          return false;
        }
      }

      if(this.sale.desea_factura){
        if(this.sale.rut == null){
          Swal.fire('Error', 'Ingresar RUT', 'error');
          return false;
        }
        if(this.sale.razon_social == null){
          Swal.fire('Error', 'Ingresar la Razón social', 'error');
          return false;
        }
        
      }
    }

    
    this.isButtonDisabled = true;
    const res = <any>await this.productService.makeAssignOrder(this.tienda_selected,this.sale,this.cartItemsCustom,this.subtotal,this.tax,this.total)
    // this.sendAlert(res.message, res.success ? 'success' : 'error')
    if (res.success) {

      Swal.fire({
        title: `Orden ${res.order_code} Creada!`,
        text: 'La oden se ha creado exitosamente.',
        icon: 'success',
        confirmButtonText: 'Perfecto',
        timer: 3000
      });

      this.sale.customer_id = null;
      this.sale.payment_id = null;
      this.cartItemsCustom = null;
      this.sale.amount_to_paid = null;
      this.sale.desea_factura = null;
      this.sale.rut = null;
      this.sale.vuelto = 0;
      this.sale.razon_social = null;
      this.subtotal = 0;
      this.tax = 0;
      this.total = 0;     
      
      this.isButtonDisabled = false;

    }else{
      Swal.fire({
        title: 'Error al crear la orden',
        text: 'Hubo un problema al intentar crear la orden. Por favor, revisa los datos ingresados o intenta nuevamente más tarde.',
        icon: 'error',
        confirmButtonText: 'Entendido',
        timer: 3000
    });
    }
   

  }

  calcularVuelto() {
    this.sale.vuelto = this.sale.amount_to_paid - this.total;
    console.log(this.sale.vuelto)
  }


  onCheckboxChange(event: any) {
  
    this.sale.desea_factura = event.target.checked;
    // const rutInput = document.getElementById('rut') as HTMLInputElement;
    // const razonSocialInput = document.getElementById('razonSocial') as HTMLInputElement;
    
    // if (this.sale.desea_factura) {
    //   rutInput.disabled = false;
    //   razonSocialInput.disabled = false;
    // }else{
    //   rutInput.disabled = true;
    //   razonSocialInput.disabled = true;
    // }
  }

}
