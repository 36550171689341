import { Component, OnInit } from '@angular/core';
import { CampaignService } from '../shared/services/campaign.service';
import { lastValueFrom } from 'rxjs'
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { DecimalPipe } from '@angular/common';

import Swal from 'sweetalert2';
@Component({
  selector: 'app-loyalty-campaigns',
  templateUrl: './loyalty-campaigns.component.html',
  styleUrls: ['./loyalty-campaigns.component.scss'],
  providers: [DecimalPipe]

})
export class LoyaltyCampaignsComponent implements OnInit {

  protected campaigns = [
    // {
    //   id: 1,
    //   name: 'HONDA 4W - HONDA REMARKETING BBDD 1 14.09.23',
    //   motivo: 'Motivo 1',
    //   fecha: '2023-01-01 12:15:00',
    //   created_by: 'Alessandra Lay',
    //   cantidad_audiencia: 10456,
    //   status: 'Enviado',
    //   status_class: 'badge-success'
    // },
    // {
    //   id: 2,
    //   name: 'HONDA 4W - HONDA RECOMPRA 14.09.23',
    //   motivo: 'Motivo 1',
    //   fecha: '2023-12-01 12:15:00',
    //   created_by: 'Fiorella Velita',
    //   cantidad_audiencia: 9458,
    //   status: 'Programado',
    //   status_class: 'badge-secondary'
    // },
    // {
    //   id: 3,
    //   name: 'HONDA 4W - HONDA REFERIDOS 14.09.23',
    //   motivo: 'Motivo 1',
    //   fecha: '2023-01-01 12:15:00',
    //   created_by: 'Alessandra Lay',
    //   cantidad_audiencia: 16159,
    //   status: 'Enviado',
    //   status_class: 'badge-success'
    // },
  ]

  // ARRAY CON FILTROS
  filtros

  saldo
  saldo_mes
  costo_total
  moneda

  buttonFiltrarTexto
  isCollapsed
  default_values

  constructor(
    private campaignService: CampaignService,
    private http: HttpClient,
    private decimalPipe: DecimalPipe
  ) { 

    this.filtros = {}

    if (localStorage.getItem("campaigns_filtros")) {
      this.filtros = JSON.parse(localStorage.getItem("campaigns_filtros"))
    }
  
    this.default_values = {}


  }

  
  formatDecimal(value: number): string {
    return this.decimalPipe.transform(value, '1.2-2')!;
  }

  formatNumber(value: number): string {
    return this.decimalPipe.transform(value, '1.0-0')!;
  }

  detallado_campaign = {
    remarketing: 0,
    recompra: 0,
    referido: 0,
    encuesta: 0,
    bienvenida: 0,
    total: 0,
    otros: 0
  }

  detallado_envio = {
    remarketing: 0,
    recompra: 0,
    referido: 0,
    encuesta: 0,
    bienvenida: 0,
    total: 0,
    otros: 0,
  }
  
  
  async ngOnInit() {
    this.establecerFechas();
    this.load_values();
    this.load_campaigns()
  }

  establecerFechas() {
    const hoy = new Date();
    const primerDiaMes = new Date(hoy.getFullYear(), hoy.getMonth(), 1);
    const fechaFin = hoy;

    this.filtros.fecha_inicio = primerDiaMes.toISOString().split('T')[0];
    this.filtros.fecha_fin = fechaFin.toISOString().split('T')[0];
  }

  
  rerender(): void {


    localStorage.setItem("campaigns_filtros", JSON.stringify(this.filtros))


    this.buttonFiltrarTexto = `Cargando...`
    this.load_campaigns();

    this.buttonFiltrarTexto = "Filtrar"

  }


  async load_values() {

    const res = <any>await this.campaignService.campaign_service_init_general();

    const response_saldo = <any>await this.campaignService.campaign_service_saldo_email();

    if (response_saldo.success) {
      this.saldo = 0;
      this.saldo_mes = response_saldo.saldo_mes;
      this.moneda = response_saldo.moneda;
      
    }

    if (res.success) {

      this.default_values.type = res.payload.type
      this.default_values.tipo_filtro = res.payload.tipo_filtro
    }

    this.buttonFiltrarTexto = "Filtrar"


  }

  async load_campaigns() {
    Swal.showLoading();

    const res = <any>await this.campaignService.get_campaign_filter(this.filtros, "Email");

    if (res.success) {
      this.campaigns = res.campaigns;

      this.costo_total = parseFloat(res.costo_total).toFixed(2) ;
      const response_saldo = <any>await this.campaignService.campaign_service_saldo_email();

      if (response_saldo.success) {
        this.saldo_mes = response_saldo.saldo_mes;
        var calculo_saldo = parseFloat(response_saldo.saldo);
        this.saldo = calculo_saldo.toFixed(2) 
      }
      
      


      console.log(this.saldo);

      this.detallado_campaign = {
        remarketing: res.detallado_leads.remarketing  ? res.detallado_leads.remarketing  : 0 ,
        recompra: res.detallado_leads.recompra ? res.detallado_leads.recompra  : 0 ,
        referido: res.detallado_leads.referidos ? res.detallado_leads.referidos  : 0 ,
        encuesta: res.detallado_leads.encuesta ? res.detallado_leads.encuesta  : 0 ,
        bienvenida: res.detallado_leads.bienvenida ? res.detallado_leads.bienvenida  : 0 ,
        total: res.detallado_leads.total ? res.detallado_leads.total  : 0 ,
        otros: res.detallado_leads.otro ? res.detallado_leads.otro  : 0 
      }

      this.detallado_envio = {
        remarketing: res.detallado_envios.remarketing  ? res.detallado_envios.remarketing  : 0 ,
        recompra: res.detallado_envios.recompra ? res.detallado_envios.recompra  : 0 ,
        referido: res.detallado_envios.referidos ? res.detallado_envios.referidos  : 0 ,
        encuesta: res.detallado_envios.encuesta ? res.detallado_envios.encuesta  : 0 ,
        bienvenida: res.detallado_envios.bienvenida ? res.detallado_envios.bienvenida  : 0 ,
        total: res.detallado_envios.total ? res.detallado_envios.total  : 0 ,
        otros: res.detallado_envios.otro ? res.detallado_envios.otro  : 0 ,
      }

    }
    Swal.close()
  }

}
