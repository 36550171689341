<div class="container">
    <div class="form-group">
        <h2>Crear turno</h2>
    </div>
    <div class="row">
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="nombre">Nombre</label>
                <input type="text" class="form-control" id="name" [(ngModel)]="turno_to_create.name">
            </div>
        </div>
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="direccion">Comment</label>
                <input type="text" class="form-control" id="comment" [(ngModel)]="turno_to_create.comment">
            </div>
        </div>
    </div>
   
    <div class="row">
        <div class="col-12 col-md-6 mb-3">
            <label class="form-label" for="activo">¿Activo?</label>
            <i ngbTooltip="--"
            class="fa fa-fw fa-info-circle"></i>
        <br>
            <label class="switch">
                <input id="activo" [(ngModel)]="turno_to_create.status" type="checkbox"><span
                    class="switch-state"></span>
            </label>
        </div>
    </div>
    <div class="form-group buttons">
        <button [attr.disabled]="is_loading? true:null" class="btn btn-primary" (click)="onSubmit()">Agregar</button>
        <button class="btn btn-secondary" (click)="onCancel()">Cancelar</button>
    </div>

</div>
