import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { UsersService } from '../../logueo/users.service';
import { Location } from "@angular/common"
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from "@angular/common/http";
import { Subscription, lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  public forgotPasswordForm: FormGroup;
  public successMessage: string = '';
  public errorMessage: string = '';

  private routeSub: Subscription;
  token;
  active_token: boolean = false;
  token_valid: boolean = false;

  constructor(
    private fb: FormBuilder, 
    public userService: UsersService,
    private route: ActivatedRoute, 
    private http: HttpClient,
    private location: Location) {
    this.forgotPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });

    this.routeSub = this.route.params.subscribe(parametros => {

      if (parametros.token_code) {
        this.token = parametros.token_code
        this.active_token = true
      }
      
    })

  }

  ngOnInit() {

  

  }

  resetPassword() {
    if (this.forgotPasswordForm.valid) {
      const email = this.forgotPasswordForm.value['email'];
      this.userService.showLoader = true;
      this.successMessage = '';
      this.errorMessage = '';
      
      this.userService.requestPasswordReset(email).then(
        (response) => {

          if (response.success) {
            this.successMessage = response.message;
          } else {
            this.errorMessage = response.message;
          }
        },
        (error) => {
          this.errorMessage = 'Ha ocurrido un error. Por favor, intenta de nuevo más tarde.';
        }
      ).finally(() => {
        this.userService.showLoader = false;
      });
    }
  }

}