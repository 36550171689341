<div style="display:none" class="col-12 pt-5">
  <div class="card-body card-body-filtros">
    <div class="buttons card-border-shadow">
      <div class="buttons-inner">
        <div class="buttons__content">
          <!-- Botón de Filtros -->
          <app-button-dashboard
            [text]="'Filtros'"
            [ariaExpanded]="!isCollapsed"
            ariaControls="collapseExample"
            (onClick)="isCollapsed = !isCollapsed">
          </app-button-dashboard>

          <!-- Botón de Crear Venta -->
          <app-button-dashboard
            [text]="'Agregar +'"
            ariaControls="collapseExample"
            (onClick)="CrearVenta()">
          </app-button-dashboard>
        </div>
        <app-breadcrumb
          [title]="'Listado Ventas'"
          [items]="['Ventas']"></app-breadcrumb>
      </div>
      <div id="collapseExample" [ngbCollapse]="isCollapsed" class="row">
        <div class="col-12">
          <h5>Filtros</h5>
        </div>

        <div class="col-12 col-md-4">
          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Fecha de inicio:</label>
            <div class="col-sm-8">
              <input
                class="form-control digits"
                [(ngModel)]="filtros.fecha_inicio"
                id="example-datetime-local-input"
                type="date" />
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Fecha de fin:</label>
            <div class="col-sm-8">
              <input
                class="form-control digits"
                [(ngModel)]="filtros.fecha_fin"
                id="example-datetime-local-input"
                type="date" />
            </div>
          </div>
        </div>

        <div class="col-12 col-md-4 div-button_filter">
          <button
            id="make_filter"
            type="button"
            (click)="rerender()"
            [attr.aria-expanded]="!isCollapsed"
            aria-controls="collapseExample"
            [attr.disabled]="buttonFiltrarTexto != 'Filtrar' ? true : null">
            <span>{{ buttonFiltrarTexto }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<app-breadcrumb [title]="'Pedido'" [items]="['Pedido']"></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
      <div class="col-12 col-md-6"></div>
      <div class="col-12 col-md-6">
        <app-breadcrumb-body
        [items]="['Pedido', 'Listado']"></app-breadcrumb-body>
     </div>
  </div>
</div>

<div class="container-fluid mb-20">
  <div class="row">
    <div class="col-12">
      <div class="card-details">
        <app-card-status-sales
          [text]="'Completado'"
          [number]="metricas.ordernes_completadas"
          [icon]="'assets/images/check.svg'"
          [bgColor]="'#94BAFE'">
        </app-card-status-sales>
        <app-card-status-sales
          [text]="'Procesadas'"
          [number]="metricas.ordernes_procesadas"
          [icon]="'assets/images/procesando.svg'"
          [bgColor]="'#007BFF'">
        </app-card-status-sales>
        <app-card-status-sales
          [text]="'Pendientes'"
          [number]="metricas.ordernes_pendientes"
          [icon]="'assets/images/pago.svg'"
          [bgColor]="'#404663'">
        </app-card-status-sales>
      </div>
    </div>
  </div>

</div>
<div class="container-fluid">
  <div class="card">
    <div class="card-body p-4">
      <div class="row">
 
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group row">
            <label class="col-sm-3 col-form-label"><b>Fecha inicio:</b></label>
            <div class="col-sm-9">
              <input
              class="form-control digits"
              [(ngModel)]="filtros.fecha_inicio"
              id="example-datetime-local-input"
              type="date" />
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group row">
            <label class="col-sm-3 col-form-label"><b>Fecha de fin:</b></label>
            <div class="col-sm-9">
              <input
                class="form-control digits"
                [(ngModel)]="filtros.fecha_fin"
                id="example-datetime-local-input"
                type="date" />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <button
            type="button"
            class="btn btn-primary btn-custom"
            (click)="rerender()"
            [attr.aria-expanded]="!isCollapsed"
            aria-controls="collapseExample"
            [attr.disabled]="buttonFiltrarTexto != 'Buscar' ? true : null">
            <span>{{ buttonFiltrarTexto }}</span>
          </button>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <table class="table table-concesionarios">
            <thead>
              <tr>
                <th class="text-center" scope="col">
                  <input type="checkbox" (change)="selectAll($event)" />
                </th>
                <th class="text-center" scope="col"># Pedido</th>
                <th class="text-center" scope="col">Fecha</th>
                <th class="text-center" scope="col">Plataforma</th>
                <th class="text-center" scope="col">Estado</th>

                <th class="text-center" scope="col">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let o of orders" class="tr-mobile">
                <td class="text-center align-middle">
                  <input
                    type="checkbox"
                    [(ngModel)]="o.selected"
                    (change)="updateSelection(o)" />
                </td>

                <td class="text-center align-middle">
                  <div class="td-mobile"># Pedido</div>
                  {{ o.order_code }}
                </td>
                <td class="text-center align-middle">
                  <div class="td-mobile">Fecha</div>
                  {{ o.created_time }}
                </td>
                <td class="text-center align-middle">
                  <div class="td-mobile">Plataforma</div>
                  <div class="label-{{o.plataforma}}">{{ o.plataforma }}</div>
                </td>
                <td class="text-center">
                  <div class="td-mobile">Estado</div>
                  <app-text-status-table
                    [text]="getStatusText(o.status)"
                    [statusClass]="getStatusClass(o.status)">
                  </app-text-status-table>
                </td>

                <td class="text-center">
                  <div class="d-flex justify-content-center">
                    <app-button-table-actions
                      [text]="'Imprimir'"
                      [ariaLabel]="'Imprimir'"
                      [iconClass]="'fa-print'"
                      additionalClasses="me-2"
                      [backgroundColor]="'#404663'"
                      (onClick)="openImprimirModal(o.comprobante,o.plataforma_id)">
                    </app-button-table-actions>
                    <app-button-table-actions *ngIf="o.status == 'completed' && user_group == 2"
                      [text]="'Anular'"
                      [ariaLabel]="'Anular'"
                      [iconClass]="'fa-trash-o'"
                      additionalClasses="me-2"
                      backgroundColor="#EB4446"
                      (onClick)="openDeleteConfirmationModal(o.id)">
                    </app-button-table-actions>
                    <app-button-table-actions *ngIf="user_group != 2"
                      [text]="'Anular'"
                      [ariaLabel]="'Anular'"
                      [iconClass]="'fa-trash-o'"
                      additionalClasses="me-2"
                      backgroundColor="#EB4446"
                      (onClick)="openDeleteConfirmationModal(o.id)">
                    </app-button-table-actions>
                    <app-button-table-actions
                      [text]="'Ver detalle'"
                      [ariaLabel]="'Ver detalle'"
                      [iconClass]="'fa-search'"
                      additionalClasses="me-2"
                      backgroundColor="#346ACA"
                      (onClick)="openEditonfirmationModal(o.id,o.plataforma_id)">
                    </app-button-table-actions>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
    </div>
  </div>
</div>




