import { Component, OnInit } from '@angular/core';
import { BlackListService } from '../../../src/app/black-list.service';
import { ProductsService } from '../products.service';
import { OrdersService } from '../orders.service';

import { ActivatedRoute, Router} from '@angular/router';
import { lastValueFrom } from 'rxjs'
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';



import Swal from 'sweetalert2';


@Component({
  selector: 'app-reporte-admin',
  templateUrl: './reporte-admin.component.html',
  styleUrls: ['./reporte-admin.component.scss']
})
export class ReporteAdminComponent implements OnInit {

  protected orders
  protected order_detail = []

  // ARRAY CON FILTROS
  filtros
  buttonFiltrarTexto
  isCollapsed
  default_values
  cantidad_dias = 0;
  jwt: string;
  clientes_nuevos;
  clientes_orders;
  tienda_selected
  plataformas : []
  productos :[]
  tiendas: []
  formaDePago = []; 
  transacciones
  cantidad_clientes = 0
  productos_order
  plataformas_order

  currency_selected
  currency_symbol_selected
  
  constructor(
    private BlackListService: BlackListService,
    private ProductsService: ProductsService,
    private OrdersService: OrdersService,
    private router: Router,
    private http: HttpClient,
  ) { 

    this.filtros = {}
    this.jwt = JSON.parse(localStorage.user).jwt
    // this.tienda_selected = JSON.parse(localStorage.tienda_selected)
    this.default_values = {}


  }

  async ngOnInit() {
    this.currency_selected = localStorage.getItem('currency_selected')
    this.currency_symbol_selected = localStorage.getItem('currency_symbol_selected')
    this.setFechas();
    this.init_service()
    this.load_resumen()
    this.load_values()
    this.updateChartSize();
    window.addEventListener('resize', () => this.updateChartSize());
  }

  updateChartSize() {
    const container = document.querySelector('.chart-container');
    const container6 = document.querySelector('.chart-container-6');
    if (container) {
      this.view = [container.clientWidth, 400]; // Ancho dinámico, altura fija
    }
    if (container6) {
      this.view6 = [container6.clientWidth, 400]; // Ancho dinámico, altura fija
    }
  }
  

  async init_service() {
    
    const data = new FormData()
    data.append('jwt', this.jwt)
 
    const res = <any>await lastValueFrom(this.http.post(`${environment.url_api}Ventas/load_ventas_init_general_admin`, data))

    if (res.success) {

      this.plataformas = res.payload.plataformas;
      this.productos = res.payload.productos;
      this.tiendas = res.payload.tiendas;

    }


  }

  rerender(): void {

    const fechaInicio = new Date(this.filtros.fecha_inicio);
    const fechaFin = new Date(this.filtros.fecha_fin);

    this.cantidad_dias = this.calcularDiasEntreFechas(fechaInicio, fechaFin);

    this.buttonFiltrarTexto = `Cargando...`
    this.load_resumen();

    this.buttonFiltrarTexto = "Buscar"

  }
  
  ticketPromedio = [];
  ticketPromedioCliente = [];
  cantidadOrdenes = [];
  cantidadClientes = [];

  cardColor: string = '#232837';

  ventasPorDia = [];
  ventasSemanales = [];
  ventasPorHoraTienda = [];
  ventasPorHora = [];
  ventasPorAnio = [];
  ventasPorMes= [];
  ventasPorMesArea= [];
  ventasPromedioPorMes= [];
  ventasCantidadPorMes= [];
  ventasPromedioNetoTiendaG = [];
  ventas_promedio_neto_tienda
  rotacion_promedio
  resultado_perido_ventas

  
  generateColors(count: number = 7): string[] {
    return Array.from({ length: count }, () =>
      '#' + Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0')
    );
  }
  
  


  async load_resumen() {

    Swal.showLoading()

    console.log(this.filtros)

    const res = <any>await this.OrdersService.get_reporte_ventas(this.filtros);
    console.log(res)
   
    if (res.success) {
      this.orders = res.orders;
      this.order_detail = res.order_detail;
      this.ticketPromedio = res.chartData_1;
      this.ticketPromedioCliente = res.chartData_1_2
      this.cantidadOrdenes = res.chartData_1_3
      this.cantidadClientes = res.chartData_1_4
      this.ventasPorDia = res.chartData_2
      this.ventasSemanales = res.chartData_3
      this.ventasPorHoraTienda = res.chartData_4
      this.ventasPorHora = res.chartData_5
      this.ventasPromedioNetoTiendaG = res.chartData_6
      this.ventasPorAnio = res.chartData_7
      this.ventasPorMes = res.chartData_8
      this.ventasPromedioPorMes= res.chartData_9
      this.ventasCantidadPorMes= res.chartData_10
      this.ventas_promedio_neto_tienda = res.ventas_promedio_neto_tienda
      this.ventasPorMesArea = res.chartData_11
      this.rotacion_promedio = res.rotacion_promedio
      this.resultado_perido_ventas = res.resultado_perido_ventas

      //  this.sales_summary = res.sales_summary;
      //  this.transacciones = res.transacciones;
      //  this.clientes_nuevos = res.clientes_nuevos;
      //  this.clientes_orders = res.clientes_orders;
      //  this.cantidad_clientes = res.cantidad_clientes;
  
    }

    Swal.close()
  }

  setFechas() {
    const fechaActual = new Date();
    const primerDiaDelMes = new Date(fechaActual.getFullYear(), fechaActual.getMonth(), 1);
    this.filtros.fecha_inicio = this.formatFecha(primerDiaDelMes);
    this.filtros.fecha_fin = this.formatFecha(fechaActual);

     this.cantidad_dias = this.calcularDiasEntreFechas(primerDiaDelMes, fechaActual);
  }

  calcularDiasEntreFechas(fechaInicio: Date, fechaFin: Date): number {
    const milisegundosPorDia = 1000 * 60 * 60 * 24;
    const diferenciaMilisegundos = fechaFin.getTime() - fechaInicio.getTime();
    return Math.ceil(diferenciaMilisegundos / milisegundosPorDia);
  }

  formatFecha(fecha: Date): string {
    const anio = fecha.getFullYear();
    const mes = ('0' + (fecha.getMonth() + 1)).slice(-2); // Se asegura que el mes sea de dos dígitos
    const dia = ('0' + fecha.getDate()).slice(-2); // Se asegura que el día sea de dos dígitos
    return `${anio}-${mes}-${dia}`;
  }

  async load_values() {
    this.buttonFiltrarTexto = "Buscar"
  }

  view: [number, number] = [0, 400]; // Tamaño del gráfico
  view6: [number, number] = [0, 400]; // Tamaño del gráfico

    showXAxis = true;
    showYAxis = true;
    gradient = false;
    showLegend = true;
    showXAxisLabel = false;
    xAxisLabel = '';
    showYAxisLabel = false;
    yAxisLabel = '';
    animations = true;

    colorScheme = {
      domain: this.generateColors()
    };

 
}
