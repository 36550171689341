import { Component, OnInit } from '@angular/core';
import { BlackListService } from '../../../src/app/black-list.service';
import { ProductsService } from '../products.service';
import { ActivatedRoute, Router} from '@angular/router';
import { lastValueFrom } from 'rxjs'
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-combos',
  templateUrl: './combos.component.html',
  styleUrls: ['./combos.component.scss']
})
export class CombosComponent implements OnInit {

  protected products = []

  // ARRAY CON FILTROS
  filtros
  buttonFiltrarTexto
  isCollapsed
  default_values

  constructor(
    private BlackListService: BlackListService,
    private ProductsService: ProductsService,
    private router: Router,
    private http: HttpClient,
  ) { 

    this.filtros = {}

    if (localStorage.getItem("persons_filtros")) {
      // this.filtros = JSON.parse(localStorage.getItem("persons_filtros"))
    }
  
    this.default_values = {}


  }

  detallado_person = {
    correo: 0,
    celular: 0,
    total: 0,
  }
  
  async ngOnInit() {
    this.init_service()
    this.load_combos()
    this.load_values()
  }

  jwt: string;
  categorias = [];
  size = [];
  tipo = [];
  tienda_selected;

  async init_service() {
    this.jwt = JSON.parse(localStorage.user).jwt;

    this.tienda_selected = JSON.parse(localStorage.tienda_selected);

    if (this.tienda_selected) {
      this.filtros.tienda_id = this.tienda_selected;
    }


    const res = <any>await this.ProductsService.lead_service_init_general(this.jwt);
    if (res.success) {

      
      this.categorias = res.payload.categorias;
      this.tipo = res.payload.tipos;
      this.size = res.payload.sizes;
    }
  }

  rerender(): void {


    // localStorage.setItem("persons_filtros", JSON.stringify(this.filtros))

    this.buttonFiltrarTexto = `Cargando...`
    this.load_combos();

    this.buttonFiltrarTexto = "Filtrar"

  }




  async load_values() {

    this.buttonFiltrarTexto = "Filtrar"


  }

  selectedCombos = [];

  eliminar_seleccionados(){
    if(this.selectedCombos.length != 0){

      var message = 'Se eliminarán las ' + this.selectedCombos.length + ' personas completamente';

      if(this.selectedCombos.length == 1){
         message = 'Se eliminará completamente';
      }  

      Swal.fire({
        title: '¿Estás seguro?',
        text: message,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, eliminar',
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        

        if (result.isConfirmed) {

          const response = await this.ProductsService.eliminar_combos_seleccionados(this.selectedCombos);
  
          if (response.success) {

            this.selectedCombos = [];
  
            Swal.fire(
              'Eliminados!',
              'Los Productos han sido eliminadas del Black List',
              'success'
            );
  
            this.load_combos();
  
          }

        }


      });

    } else {
      Swal.fire({
        title: "No hay elementos seleccionados",
        text: "Selecciona al menos un combo",
        icon: "warning"
      });
    }
  }

  updateSelection(person) {
    if (person.selected) {
      this.selectedCombos.push(person);
    } else {
      this.selectedCombos = this.selectedCombos.filter(p => p.id !== person.id);
    }
    console.log(this.selectedCombos)

  }

  selectAll(event) {
    const isChecked = event.target.checked;
    this.selectedCombos = isChecked ? [...this.products] : [];
    this.products.forEach(person => person.selected = isChecked);
    console.log(this.selectedCombos)

  }


  validarCorreo(correo: string): boolean {
    const cadena = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (correo) {
      return cadena.test(correo);
    } else {
      return false;
    }
  }

  validarCelular(celular: string): boolean {
    if (celular) {
      if (this.isNumber(celular)) {
        if (celular.length === 9) {
          return true;
        }
      }
    }
    return false;
  }

  private isNumber(n: any): boolean {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }



  CrearCombo() {
    this.router.navigate(['/create-combos']); 
  }
  
  async crear_black_person(newPerson) {
    const res = await this.BlackListService.new_persons_person_black_list(newPerson);
    return res;
  }
  
  async editar_black_person(newPerson) {
    // const res = await this.BlackListService.edit_person_black_list(newPerson);
    // return res;
  }
  

  async openDeleteConfirmationModal(id: string) {
    Swal.fire({
      title: '¿Estás seguro?',
      text: 'Se eliminará completamente',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {

        const response = await this.ProductsService.eliminar_combo(id);

        if (response.success) {

          Swal.fire(
            'Eliminado!',
            'El Combo ha sido eliminada.',
            'success'
          );

          this.load_combos();

        }



      }
    });
    
  }

  async openEditfirmationModal(id: string) {
    if (id) {
      const route_link = '/edit-combo/'+ id;
      console.log(route_link);
      this.router.navigate([route_link]); // Redirige a otra ruta
    }


    
  }
  

  async openDetailfirmationModal(id: string) {
    if (id) {
      const route_link = '/details-combo/'+ id;
      console.log(route_link);
      this.router.navigate([route_link]); // Redirige a otra ruta
    }


    
  }



  async load_combos() {

    Swal.showLoading()

    const res = <any>await this.ProductsService.get_combos_filter(this.filtros);

    if (res.success) {
      
      this.products = res.products;

      this.detallado_person = {
        correo: res.detallado_leads.correo  ? res.detallado_leads.correo  : 0 ,
        celular: res.detallado_leads.celular ? res.detallado_leads.celular  : 0 ,
        total: res.detallado_leads.total ? res.detallado_leads.total  : 0 ,
      }
    }
    Swal.close()
  }


}
