import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { lastValueFrom } from 'rxjs'
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private http: HttpClient) { }

  async get_all_products__disponibles(tienda) {
    const data = new FormData()
    data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
    data.append('tienda_selected', JSON.stringify(tienda))
    const response = await lastValueFrom(this.http.post(`${environment.url_api}Product/get_list_of_products`, data));

    return response
  }

  async get_all_products__disponibles_autoservicio(tienda) {
    const data = new FormData()
    // data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
    data.append('tienda_selected', JSON.stringify(tienda))
    const response = await lastValueFrom(this.http.post(`${environment.url_api}Product/get_list_of_products_autoservicio`, data));

    return response
  }

  async get_all_combos__disponibles(tienda) {
    const data = new FormData()
    data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
    data.append('tienda_selected', JSON.stringify(tienda))
    const response = await lastValueFrom(this.http.post(`${environment.url_api}Product/get_list_of_combos`, data));

    return response
  }

  async get_all_combos__disponibles_autoservicio(tienda) {
    const data = new FormData()
    data.append('tienda_selected', JSON.stringify(tienda))
    const response = await lastValueFrom(this.http.post(`${environment.url_api}Product/get_list_of_combos_autoservicio`, data));

    return response
  }


  async get_all_categories__disponibles(tienda) {
    const data = new FormData()
    data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
    data.append('tienda_selected', JSON.stringify(tienda))
    const response = await lastValueFrom(this.http.post(`${environment.url_api}Product/get_list_of_categories`, data));

    return response
  }

  async get_all_categories__disponibles_autoservicio(tienda) {
    const data = new FormData()
    data.append('tienda_selected', JSON.stringify(tienda))
    const response = await lastValueFrom(this.http.post(`${environment.url_api}Product/get_list_of_categories_autoservicio`, data));

    return response
  }
  
  async makeAssignOrder(tienda,customer,products,subtotal,tax,total){
    const data = new FormData()
    data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
    data.append('tienda_selected', JSON.stringify(tienda))
    data.append('customer', JSON.stringify(customer))
    data.append('products', JSON.stringify(products))
    data.append('subtotal', JSON.stringify(subtotal))
    data.append('tax', JSON.stringify(tax))
    data.append('total', JSON.stringify(total))
    const response = await lastValueFrom(this.http.post(`${environment.url_api}Order/makeAssignOrder`, data));
    return response


  }

  async validate_product(product_id, cantidad) {
    const data = new FormData()
    data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
    data.append('product_id', JSON.stringify(product_id))
    data.append('cantidad', JSON.stringify(cantidad))
    const response = await lastValueFrom(this.http.post(`${environment.url_api}Product/validate_product`, data));

    return response
  }



}
