<app-breadcrumb [title]="'Black List'" [items]="['Black List']"></app-breadcrumb>

<div class="row resumen-leads">
    



    <div class="col-12">
        <div class="card">

            <div class="card-body ">
                <div class="card o-hidden">
                    <div class="bg-reprogramado b-r-4 card-body text-white">
                        <div class="media static-top-widget">
                            <div class="media-body"><span
                                    class="nombre-estado-cliente m-0"> Total </span>
                                <h5 class="number-estado-cliente mb-0 counter"
                                    CountTo="" from="0"
                                    duration="1">{{detallado_person.total}}
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<div class="col-12">
    <div class="card-body card-body-filtros">
        <div class="buttons">
            <button type="button" class="btn btn-primary-honda button_filter__collapse"
                (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed"
                aria-controls="collapseExample">
                <span>Filtros</span>
                <app-feather-icons [icon]="'filter'"></app-feather-icons>

            </button>

            <button type="button" class="btn btn-primary-honda button_filter__collapse"
                (click)="openFormModal()"
                aria-controls="collapseExample">
                <span>Agregar </span>
                <app-feather-icons [icon]="'plus'"></app-feather-icons>
            </button>


            <button type="button" class="btn btn-success-honda button_filter__collapse"
                [routerLink]="'add-from-excel'"
                aria-controls="collapseExample">
                <span>Importar desde excel</span>
                <app-feather-icons [icon]="'file'"></app-feather-icons>
            </button>
            
        
        </div>
        <div id="collapseExample" [ngbCollapse]="isCollapsed">
            <div class="card mb-0">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <h5><u>Filtros </u></h5>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label">Fecha de
                                    inicio</label>
                                <div class="col-sm-9">
                                    <input class="form-control digits"
                                        [(ngModel)]="filtros.fecha_inicio"
                                        id="example-datetime-local-input" type="date">
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label">Fecha fin</label>
                                <div class="col-sm-9">
                                    <input class="form-control digits"
                                        [(ngModel)]="filtros.fecha_fin"
                                        id="example-datetime-local-input" type="date">
                                </div>
                            </div>
                        </div>

                   

                        <div class="col-12">
                            <h5><u>Filtros adicionales</u></h5>
                   
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <div class="form-group">
                                        <div class="col-form-label">Por texto</div>
                                        <input class="form-control" type="text"
                                            [(ngModel)]="filtros.texto" placeholder="">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 div-button_filter">
                            <button id="make_filter" type="button" class="btn btn-primary"
                                (click)="rerender()" [attr.aria-expanded]="!isCollapsed"
                                aria-controls="collapseExample"
                                [attr.disabled]="buttonFiltrarTexto != 'Filtrar' ? true:null">
                                <span>{{buttonFiltrarTexto}}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <div class="text-first">
                        <small>Aca se muestra todo el listado de las personas ingresadas en el black list.</small>
                    </div>
                    <div class="media-body text-end">
                        <a (click)="eliminar_seleccionados()" role="button" class="btn btn-secondary me-2">
                            Eliminar <i class="fa fa-trash-o"></i> 
                         </a>

                        <a (click)="load_persons()" role="button" class="btn btn-primary me-2">Actualizar</a>
                    </div>
                </div>
                <div class="card-body">
                    <table class="table table-concesionarios">
                        <thead>
                            <tr>
                                <th class="text-center" scope="col">
                                    <input type="checkbox" (change)="selectAll($event)" />
                                </th>
                                <th class="text-center" scope="col">Correo</th>
                                <th class="text-center" scope="col">Celular</th>
                                <th class="text-center" scope="col">Razón</th>
                                <th class="text-center" scope="col">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let c of persons">
                                <td class="text-center">
                                    <input type="checkbox" [(ngModel)]="c.selected" (change)="updateSelection(c)" />
                                </td>
                                <td>{{c.correo_electronico}}</td>
                                <td class="text-center">{{c.celular}}</td>
                                <td class="text-center">{{c.razon}}</td>

                                <td class="text-center">
                                    <div>
                                        <p (click)="openDeleteConfirmationModal(c.id)" style="color:red; font-weight: bold;">
                                            <i class="fa fa-trash-o"></i> 
                                        </p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            
        </div>
    </div>
</div>