import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { lastValueFrom } from 'rxjs'
import { environment } from '../environments/environment';


@Injectable({
  providedIn: 'root'
})

export class BlackListService {
  private blackList: string[] = [];

  constructor(public router: Router, private http: HttpClient){ }

  private registros


async get_person_black_list_filter(filtros) {
  const data = new FormData()
  data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
  data.append('filtros', JSON.stringify(filtros));

  const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}BlackList/get_persons_by_filter`, data));
  return response
}

async eliminar_person( encuesta_id: any) {
  const data = new FormData()
  data.append('id', encuesta_id)
  const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}BlackList/EliminarBlackList`, data))
  return response
}

async eliminar_persons_seleccionados( select: any) {
  const data = new FormData()
  data.append('seleccionados', JSON.stringify(select));

  const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}BlackList/EliminarBlackListSeleccionados`, data))
  return response
}

async new_persons_person_black_list( agregado: any) {
  const data = new FormData()
  data.append('data', JSON.stringify(agregado));

  const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}BlackList/new_persons_person_black_list`, data))
  return response
}


async generate_BlackList_FromExcel(audience) {
  const data = new FormData()
  data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
  data.append('audiencia', JSON.stringify(audience))
  const response = await lastValueFrom(this.http.post(`${environment.url_api}BlackList/generate_from_excel`, data));
  return response
}



}
