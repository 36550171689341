<app-breadcrumb
  [title]="'Resumen Ventas'"
  [items]="['Resumen Ventas']"></app-breadcrumb>

<!-- <div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body card-body-filtros">
          <div id="collapseExample" [ngbCollapse]="isCollapsed">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <div class="form-group row">
                      <label class="col-sm-3 col-form-label"
                        >Fecha de inicio</label
                      >
                      <div class="col-sm-9">
                        <input
                          class="form-control digits"
                          [(ngModel)]="filtros.fecha_inicio"
                          id="example-datetime-local-input"
                          type="date" />
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="form-group row">
                      <label class="col-sm-3 col-form-label">Fecha fin</label>
                      <div class="col-sm-9">
                        <input
                          class="form-control digits"
                          [(ngModel)]="filtros.fecha_fin"
                          id="example-datetime-local-input"
                          type="date" />
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-md-12">
                    <button
                      id="make_filter"
                      type="button"
                      class="btn btn-primary"
                      (click)="rerender()"
                      [attr.aria-expanded]="!isCollapsed"
                      aria-controls="collapseExample"
                      [disabled]="buttonFiltrarTexto !== 'Filtrar'">
                      <span>{{ buttonFiltrarTexto }}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6">
      <div class="card shadow-sm">
        <div
          class="card-header d-flex align-items-center justify-content-between">
          <h5 class="mb-0 text-primary">
            <i class="fas fa-dollar-sign me-2"></i> Ventas Totales
          </h5>
          <span class="badge bg-primary text-white"
            >{{ cantidad_dias }} días</span
          >
        </div>
        <div class="card-body">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th class="text-center">Total Ventas</th>
                <th class="text-center">Total Impuestos</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="sales_summary.length === 0; else salesData">
                <tr>
                  <td class="text-center" colspan="2">Sin ventas</td>
                </tr>
              </ng-container>

              <ng-template #salesData>
                <tr *ngFor="let day of sales_summary">
                  <td class="text-center">{{ day.total_sales | currency }}</td>
                  <td class="text-center">{{ day.total_tax | currency }}</td>
                </tr>
              </ng-template>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="col-md-6">
      <div class="card shadow-sm">
        <div
          class="card-header d-flex align-items-center justify-content-between">
          <h5 class="mb-0 text-success">
            <i class="fas fa-receipt me-2"></i> Total de Transacciones (TRX)
          </h5>
          <span class="badge bg-success text-white"
            >{{ cantidad_dias }} días</span
          >
        </div>
        <div class="card-body">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th class="text-center">Total Pedidos</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="sales_summary.length === 0; else trxData">
                <tr>
                  <td class="text-center">Sin transacciones</td>
                </tr>
              </ng-container>

              <ng-template #trxData>
                <tr *ngFor="let day of sales_summary">
                  <td class="text-center">{{ day.total_orders }}</td>
                </tr>
              </ng-template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h5>Productos Más Vendidos</h5>
        </div>
        <div class="card-body">
          <ngx-charts-bar-vertical
            [view]="[1000, 400]"
            [scheme]="colorScheme"
            [results]="productosMasVendidos"
            [gradient]="gradient"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="showLegend"
            [showXAxisLabel]="showXAxisLabel"
            [xAxisLabel]="xAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [yAxisLabel]="yAxisLabel"
            [animations]="animations">
          </ngx-charts-bar-vertical>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-4">
      <div class="card">
        <div class="card-header">
          <h5>Forma de Pago</h5>
        </div>
        <div class="card-body">
          <ngx-charts-advanced-pie-chart
            [view]="view"
            [scheme]="colorScheme"
            [results]="formaDePago"
            [gradient]="gradient"
            [animations]="animations">
          </ngx-charts-advanced-pie-chart>
        </div>
      </div>
    </div>

    <div class="col-8">
      <div class="card">
        <div class="card-header">
          <h5>Ventas por Forma de Pago</h5>
        </div>
        <div class="card-body">
          <ngx-charts-bar-vertical
            [view]="view"
            [scheme]="colorScheme"
            [results]="formaDePago"
            [gradient]="gradient"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="showLegend"
            [showXAxisLabel]="showXAxisLabel"
            [xAxisLabel]="xAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [yAxisLabel]="yAxisLabel"
            [animations]="animations">
          </ngx-charts-bar-vertical>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-4">
      <div class="card">
        <div class="card-header">
          <h5>Cantidad de Clientes</h5>
        </div>
        <div class="card-body">
          <ngx-charts-number-card
            [view]="[300, 300]"
            [scheme]="colorScheme"
            [results]="clientes"
            [cardColor]="cardColor"
            (select)="onSelect($event)">
          </ngx-charts-number-card>
        </div>
      </div>
    </div>

    <div class="col-8">
      <div class="card">
        <div class="card-header">
          <h5>Estados de Ventas</h5>
        </div>
        <div class="card-body">
          <div class="flot-chart-container grid-pie-chart">
            <div
              class="flot-chart-placeholder ngx-chart-direction"
              id="github-issues">
              <ngx-charts-pie-grid
                [scheme]="pieChartColorScheme"
                [results]="pieChart"
                (select)="onSelect($event)">
              </ngx-charts-pie-grid>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->


<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <h3 class="card-title">Resumen de ventas de turno</h3>
        <div class="card-body">
          <div class="row">
            <div class="col-md-4">
              <p><strong> Total venta bruta</strong></p>
            </div>
            <div class="col-md-8">
              <p>{{currency_symbol_selected}} {{ metricas.total_venta_bruta | currency: currency_selected: ''}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p><strong>Total descuentos</strong></p>
            </div>
            <div class="col-md-8">
              <p>$ 0</p>
            </div>
          </div>
          <div class="row row-big">
            <div class="col-md-4">
              <p><strong>Total Venta Bruta (c/IVA)</strong></p>
            </div>
            <div class="col-md-8">
              <p>{{currency_symbol_selected}} {{ metricas.total_venta_bruta | currency: currency_selected: ''}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p><strong>Impuestos (IVA)</strong></p>
            </div>
            <div class="col-md-8">
              <p>{{currency_symbol_selected}} {{ metricas.impuestos_iva | currency: currency_selected: ''}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p><strong>Venta neta (s/IVA)</strong></p>
            </div>
            <div class="col-md-8">
              <p>{{currency_symbol_selected}} {{ metricas.venta_neta_sin_iva | currency: currency_selected: ''}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p><strong>Costo neto productos (s/IVA)</strong></p>
            </div>
            <div class="col-md-8">
              <p>{{currency_symbol_selected}} {{ metricas.costo_neto_productos | currency: currency_selected: ''}}</p>
            </div>
          </div>
          <div class="row row-big">
            <div class="col-md-4">
              <p><strong>Margen de Contribución Neto</strong></p>
            </div>
            <div class="col-md-8">
              <p>$ 0</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p><strong>Número de personas</strong></p>
            </div>
            <div class="col-md-8">
              <p>{{metricas.numero_personas}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p><strong>Número de órdenes</strong></p>
            </div>
            <div class="col-md-8">
              <p>{{metricas.numero_ordenes}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p><strong>Total propinas</strong></p>
            </div>
            <div class="col-md-8">
              <p>$ 0</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p><strong>Total costo despacho</strong></p>
              <div class="cbx-resumen">
                <input
                  type="checkbox"
                  name="costo_despacho"
                  id="costo_despacho" />
                <label for="costo_despacho"
                  >Excluir costos de despacho de ventas</label
                >
              </div>
            </div>
            <div class="col-md-8">
              <p>$ 0</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p><strong>Total cambios</strong></p>
            </div>
            <div class="col-md-8">
              <p>$ 0</p>
            </div>
          </div>
          <app-button-dashboard
            [text]="'Descargar Ventas Totales'"
            [additionalClasses]="'px-4 mt-4'">
          </app-button-dashboard>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <h3 class="card-title">Forma de Pago</h3>
        <div class="card-body">
          <div class="row">
            <div class="col-md-4">
              <p><strong>Tarjeta débito</strong></p>
            </div>
            <div class="col-md-8">
              <p>$0</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p><strong>Tarjeta crédito</strong></p>
            </div>
            <div class="col-md-8">
              <p>{{currency_symbol_selected}} {{ order_creditcard | currency: currency_selected: ''}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p><strong>Efectivo</strong></p>
            </div>
            <div class="col-md-8">
              <p>{{currency_symbol_selected}} {{ order_cash | currency: currency_selected: ''}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p><strong>UberEats</strong></p>
            </div>
            <div class="col-md-8">
              <p>$ 0</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p><strong>Pedidos ya</strong></p>
            </div>
            <div class="col-md-8">
              <p>$ 0</p>
            </div>
          </div>
          <div class="row row-big">
            <div class="col-md-4">
              <p><strong>Total</strong></p>
            </div>
            <div class="col-md-8">
              <p>{{currency_symbol_selected}} {{ metricas.total_venta_bruta | currency: currency_selected: ''}}</p>
            </div>
          </div>
          <div class="row row-blue">
            <div class="col-md-4">
              <p><strong>Sub Total Ventas Débito + Crédito</strong></p>
            </div>
            <div class="col-md-8">
              <p>{{currency_symbol_selected}} {{ metricas.total_venta_bruta | currency: currency_selected: ''}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <h3 class="card-title card-title-small">
          Lista de productos vendidos en el día
        </h3>
        <div class="card-body-table">
          <div class="row row-table-header">
            <div class="col-md">
              <p class="text-center">ID</p>
            </div>
            <div class="col-md-4">
              <p class="text-center">Producto</p>
            </div>
            <div class="col-md-2">
              <p class="text-center">Cantidad</p>
            </div>
            <div class="col-md-2">
              <p class="text-center">Valor venta</p>
            </div>
            <div class="col-md">
              <p class="text-center">Descuentos</p>
            </div>
            <div class="col-md-2">
              <p class="text-center">Costos</p>
            </div>
          </div>
     
          <div class="row row-table" *ngFor="let p of productos;let i = index">
            <div class="col-md">
              <p class="text-center">{{ i + 1 }}</p>
            </div>
            <div class="col-md-4">
              <p class="text-center">{{p.producto}}</p>
            </div>
            <div class="col-md-2">
              <p class="text-center">{{p.cantidad_vendida}}</p>
            </div>
            <div class="col-md-2">
              <p class="text-center">{{currency_symbol_selected}} {{ p.total_venta | currency: currency_selected: ''}}</p>
            </div>
            <div class="col-md">
              <p class="text-center">0</p>
            </div>
            <div class="col-md-2">
              <p class="text-center">{{currency_symbol_selected}} {{ p.total_venta | currency: currency_selected: ''}}</p>
            </div>
          </div>
      
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <h3 class="card-title card-title-small">Ventas por usuario</h3>
        <div class="card-body-table">
          
          <div class="row row-table-header">
            <div class="col-md-2">
              <p class="text-center">Camarero</p>
            </div>
            <div class="col-md">
              <p class="text-center">Ventas</p>
            </div>
            <div class="col-md-2">
              <p class="text-center">Propinas</p>
            </div>
            <div class="col-md">
              <p class="text-center">%</p>
            </div>
            <div class="col-md-2">
              <p class="text-center">Ticket Prom / TRX</p>
            </div>
            <div class="col-md">
              <p class="text-center">TRX</p>
            </div>
            <div class="col-md-2">
              <p class="text-center">#ORD</p>
            </div>
          </div>
          <div class="row row-table" *ngFor="let vu of ventas_por_usuario;let i = index">
            <div class="col-md-2">
              <p class="text-center">{{vu.username}}</p>
            </div>
            <div class="col-md">
              <p class="text-center">{{vu.cantidad_ventas}}</p>
            </div>
            <div class="col-md-2">
              <p class="text-center">$ 0</p>
            </div>
            <div class="col-md">
              <p class="text-center">{{currency_symbol_selected}} {{ vu.venta_promedio | currency: currency_selected: ''}}</p>
            </div>
            <div class="col-md-2">
              <p class="text-center">{{vu.cantidad_ventas}}</p>
            </div>
            
            <div class="col-md-2">
              <p class="text-center">{{vu.cantidad_ordenes}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
