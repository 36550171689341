import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { HttpClient } from "@angular/common/http";
import { Subscription, lastValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalCreateUserComponent } from 'src/app/modal-create-user/modal-create-user.component';

const Swal = require('sweetalert2')


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  private jwt
  private routeSub: Subscription;
  user_id
  user_to_edit
  concesionarios = []
  grupos_activos = []
  new_concesionarios = []
  password_n
  usuarios: any
  selectedGroup: any = null; 


  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private router: Router,
  ) {
    this.jwt = JSON.parse(localStorage.user).jwt
    this.user_to_edit = {}
    this.routeSub = this.route.params.subscribe(params => {
      this.user_id = params.usuario_id
    })
  }

  async ngOnInit() {
    if (this.user_id >= 0) {
      this.get_data_from_user()
    } else {
      this.refresh_data()
    }
  }

  async get_data_from_user() {
    const data = new FormData()
    data.append('jwt', this.jwt)
    data.append('user_id', this.user_id)

    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}UserAPI/get_info_by_userID`, data))
   
    if (response.success) {
  
       this.user_to_edit = response.usuario
       this.user_to_edit.active = this.user_to_edit.active == 1 ? true:false
       this.concesionarios = response.concesionarios
       this.grupos_activos = response.grupos_activos

       this.selectedGroup = this.concesionarios.find(group => group.id === this.grupos_activos[0].id)
       console.log(this.selectedGroup)
    }
 

  }

  compareGroupObjects(item1: any, item2: any): boolean {
    return item1 && item2 ? item1 === item2 : item1 === item2;
  }

  async refresh_data() {
    const data = new FormData()
    data.append('jwt', this.jwt)
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}UserAPI/get_list_of_users`, data))
    if (response.success) {
      this.usuarios = response.payload.usuarios
      // this.usuarios.forEach((usu, index) => {
      //   this.usuarios[index].campus = this.usuarios[index].campus?.split(",")
      // })
    }
  }

  async asignar() {

    const data = new FormData()
    data.append('jwt', this.jwt)
    data.append('user_id', this.user_id)
    data.append('password_n', this.password_n)
    var concesionarios_to_update = []

    this.concesionarios.forEach((el, index) => {
      
      if (el.id == this.selectedGroup) {
        concesionarios_to_update.push(el.id)
      }
    })


    console.log(concesionarios_to_update)
    data.append('user_to_edit', JSON.stringify(this.user_to_edit))
    data.append('concesionarios', JSON.stringify(concesionarios_to_update))
   
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}UserAPI/edit_user`, data))
    this.sendAlert(response.message, response.success ? 'success' : 'error')
    this.router.navigate(['/users'])
  }




  sendAlert(message, type) {
    Swal.fire({
      icon: type,
      title: message,
      text: ''
    });
  }

  agregar_usuario_modal() {
 
    const dialogRef = this.modalService.open(ModalCreateUserComponent, {
      size: 'lg'
    })
    dialogRef.componentInstance.jwt = this.jwt

    dialogRef.result.then((data) => {
      if (data.success) {
        this.usuarios = data.payload.usuarios
        this.usuarios.forEach((usu, index) => {
          this.usuarios[index].campus = this.usuarios[index].campus?.split(",")
        })
      }
    })
  }

}
