import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { HttpClient } from "@angular/common/http";
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { UsersService } from '../../logueo/users.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public show: boolean = false;
  public loginForm: FormGroup;
  public errorMessage: any;
  isShowTiendas = false;
  isShowLogin = true;
  tiendas
  tiendaSeleccionadaId = 0
  tiendaSeleccionada: any

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    public userService: UsersService) {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }

  ngOnInit() {
  }

  showPassword() {
    this.show = !this.show;
  }

  login2(){
    
    if(this.tiendaSeleccionadaId != 0){
  
      localStorage.setItem('tienda_selected', this.tiendaSeleccionada.id)
      localStorage.setItem('currency_selected', this.tiendaSeleccionada.currency)
      localStorage.setItem('currency_symbol_selected', this.tiendaSeleccionada.currency_symbol)
      this.router.navigate(['/turnos'])
    }
  }
  
  // Simple Login
  login() {
    // this.userService.login(this.loginForm.value['email'], this.loginForm.value['password']);
    // this.authService.SignIn(this.loginForm.value['email'], this.loginForm.value['password']);
    this.userService.login(this.loginForm.value['email'], this.loginForm.value['password']).then(
      (response) => {

        console.log(response)
        if (response.tiendas.length == 1) {
          //solo una tienda - vendedor
          localStorage.setItem('tienda_selected', response.tiendas[0].id)
          localStorage.setItem('currency_selected', response.tiendas[0].currency)
          localStorage.setItem('currency_symbol_selected', response.tiendas[0].currency_symbol)
          this.router.navigate(['/turnos'])
        }else{
          //rol jefe
          this.isShowTiendas = true;
          this.isShowLogin = false;
          this.tiendas = response.tiendas;
          console.log(response.tiendas)
        }
      },
      (error) => {
        this.errorMessage = 'Ha ocurrido un error. Por favor, intenta de nuevo más tarde.';
      }
    ).finally(() => {
      this.userService.showLoader = false;
    });

  }

  cambiarOpcion(valor) {
    this.tiendaSeleccionadaId = valor['id'];
    this.tiendaSeleccionada = valor;
  }

}
