<!-- Tu breadcrumb existente -->
<app-breadcrumb [title]="'Productos'" [items]="['Productos','Editar Producto']"></app-breadcrumb>

<!-- Enlace de regreso justo después del breadcrumb -->



  <div class="container">
    <div class="form-group">
        <nav class="breadcrumb-nav">
            <a routerLink="/products-list" > ←  Volver a Productos</a>
        </nav>
    </div>

  </div>

<div class="container">
   
    

    <div class="form-group row">
        <div class="col-12 d-flex justify-content-between align-items-center">
          <h2>Editar Producto</h2>
          <button (click)="openDeleteConfirmationModal()" class="btn btn-danger" aria-label="Eliminar">
            <i class="fa fa-trash-o"></i> Eliminar
          </button>
        </div>
      </div>

      
    <div class="row">
        <!-- Nombre del producto -->
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="nombreProducto">Nombre del Producto</label>
                <input type="text" class="form-control" id="nombreProducto" placeholder="Ingrese el nombre del producto" [(ngModel)]="producto_detail.nombre">
            </div>
        </div>

        <!-- Estado del Combo -->
        <div class="col-12 col-md-6">
            <div class="form-group">
            <label for="estadoCombo">Estado</label>
            <select class="form-control" id="estadoCombo" [(ngModel)]="producto_detail.estado">
                <option [value]="'activo'">Activo</option>
                <option [value]="'desactivado'">Desactivado</option>
            </select>
            </div>
        </div>

        <!-- Tipo del producto -->
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="categoriaProducto">Tipo de Producto</label>
                <ng-select bindValue="id" bindLabel="name" [items]="tipo" class="form-control" [(ngModel)]="producto_detail.tipo">
                </ng-select>
            </div>
        </div>


       
    </div>
    <div class="row">
        <!-- Descripción del producto -->
        <div class="col-12">
            <div class="form-group">
                <label for="descripcionProducto">Descripción</label>
                <textarea class="form-control" id="descripcionProducto" rows="3" placeholder="Ingrese la descripción del producto" [(ngModel)]="producto_detail.descripcion"></textarea>
            </div>
        </div>
    </div>
    <div class="row">
        <!-- Categoría del producto -->
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="categoriaProducto">Categoría</label>
                <ng-select bindValue="id" bindLabel="name" [items]="categorias" class="form-control" [(ngModel)]="producto_detail.categoria">
                </ng-select>
            </div>
        </div>


        <!-- Tipo del producto -->
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="categoriaProducto">Tamaño Producto</label>
                <ng-select bindValue="id" bindLabel="name" [items]="size" class="form-control" [(ngModel)]="producto_detail.size">
                </ng-select>
            </div>
        </div>

 

            <!-- Precio del producto -->

            <div class="col-12 col-md-6">
                <div class="form-group">
                    <label for="stockProducto">Precio</label>
                    <input type="number"
                           class="form-control"
                           id="precio"
                           placeholder="Ingrese Precio"
                           [(ngModel)]="producto_detail.precio"
                           >
                </div>
            </div>
              
            <!-- Stock del producto -->
    
            <div class="col-12 col-md-6">
                <div class="form-group">
                    <label for="stockProducto">Stock</label>
                    <input type="number"
                            class="form-control"
                            id="precio"
                            placeholder="Ingrese stock"
                            [(ngModel)]="producto_detail.stock"
                            min="0"
                            max="2"
                            (input)="validarStock($event)"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                            >
                </div>
            </div>
        
    <!-- Imagen del producto -->
    <div class="row">
        <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <label for="imagenProducto">Imagen del Producto</label>
                    <!-- Image preview or default image -->
                    
                    <input type="file" 
                           class="form-control" 
                           id="imagenProducto" 
                           (change)="onFileSelected($event)" 
                           accept="image/png, image/jpeg, image/jpg, image/gif">

                
                           <img *ngIf="imagePreview" [src]="imagePreview" alt="Imagen del Producto" class="img-thumbnail" style="max-width: 100px; margin: 10px;">

                           <br>
                    <!-- Button to deselect the image -->
                    <button *ngIf="imagePreview" (click)="clearImage()" class="btn btn-danger" 
                            style="margin-top: 10px;">Eliminar Imagen</button>

                </div>
            </div>
        </div>
        

            <div class="card m-2">

                <div class="form-group m-2">
                    <h3>Azúcar </h3>
                </div> 
    
                <div class="card-body">
            
                    <div class="row">
                        <!-- Selección de productos -->
        
                        <div class="col-12 col-md-12 d-flex align-items-center">
                            <div class="form-check">

                                <input 
                                    class="form-check-input" 
                                    type="checkbox" 
                                    id="requeridoCheck" 
                                    [checked]="producto_detail.azucar_requerido == 1"
                                    (change)="actualizarAzucarRequerido()"
                                />
                        
        
                                <label class="form-check-label" for="requeridoCheck">
                                    Requerido
                                </label>
                            </div>
                        </div>
                    
                   
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <label for="productosSelect">Seleccionar Azúcar</label>
                                <ng-select bindValue="id" bindLabel="name"
                                           [items]="extras_azucar" class="js-example-basic-multiple"
                                           [(ngModel)]="selectExtraAzucarId" 
                                           [disabled]="producto_detail.extras_azucar.length >= 10">
                                </ng-select>
                            </div>
                        </div>
            
                        <!-- Selección de cantidad -->
                        <div class="col-12 col-md-3">
                            <div class="form-group">
                                <label for="precioExtra">Precio</label>
                                <div class="input-group">
                                    <!-- Input central sin bordes redondeados -->
                                    <input
                                        type="number"
                                        class="form-control text-center border-left-0 border-right-0"
                                        id="precioExtra"
                                        [(ngModel)]="precioAzucarExtra"
                                        [min]="1"
                                        placeholder="Cantidad" [attr.disabled]="producto_detail.extras_azucar.length >= 10 ? true : null"
                                    />
                                </div>
                            </div>
                        </div>
            
                        <!-- Checkbox para marcar como requerido -->
                 
            
                        <div class="col-12 col-md-3 d-flex align-items-center justify-content-center">
                            <button class="btn btn-success" (click)="agregarExtraAzucar()" [attr.disabled]="producto_detail.extras_azucar.length >= 10 ? true : null">Agregar + </button>
                        </div>
            
                        <!-- Productos seleccionados -->
                        <div *ngIf="producto_detail.extras_azucar.length != 0" class="row mb-3">
                            <div class="col-12">
                                <h4>Seleccionados</h4>
                                <ul class="list-group">
                                    <li *ngFor="let item of producto_detail.extras_azucar" class="list-group-item d-flex justify-content-between align-items-center">
                                        {{ item.extra.name }} - Precio: {{ item.precio }}
                                        <button class="btn btn-danger btn-sm" (click)="quitarProductoAzucar(item.extra.id)">Quitar</button>
                                    </li>
                                </ul>
                            </div>
                        </div>
            
                    </div>
                </div>
            </div>
            
        
            <div class="card m-2">
                <div class="form-group m-3">
                    <h3>Salsas </h3>
                </div>
    
                <div class="card-body">
    
    
                    <div class="col-12 col-md-12 d-flex align-items-center">
                        <div class="form-check">
                            <input 
                                class="form-check-input" 
                                type="checkbox" 
                                id="requeridoCheck" 
                                [checked]="producto_detail.extra_requerido == 1"
                                (change)="actualizarRequerido()"
                            />
                        

                            <label class="form-check-label" for="requeridoCheck">
                                Requerido
                            </label>
                        </div>
                    </div>
                    
    
                    <div class="form-group col-4">
                        <label for="stockProducto">Cantidades Salsas</label>
                        <input type="number"
                                class="form-control"
                                id="stockProducto"
                                placeholder="Ingrese Cantidades Extras"
                                [(ngModel)]="producto_detail.qty_extras"
                                min="0"
                                max="2"
                                (input)="validarExtras($event)"
                                onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                    </div>
    
                    <div class="row">
                        <!-- Selección de productos -->
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                              <label for="productosSelect">Seleccionar Salsa</label>
                              <ng-select bindValue="id" bindLabel="name"
                                          [items]="extras" class="js-example-basic-multiple"
                                          [(ngModel)]="selectExtraId" 
                                          [disabled]="producto_detail.extras.length >= 10">
                              </ng-select>
                            </div>
                          </div>
                          
                    
                        <!-- Selección de cantidad -->
                        <div class="col-12 col-md-3">
                            <div class="form-group">
                              <label for="precioExtra">Precio</label>
                              <div class="input-group">
                        
                                <!-- Input central sin bordes redondeados -->
                                <input
                                  type="number"
                                  class="form-control text-center border-left-0 border-right-0"
                                  id="precioExtra"
                                  [(ngModel)]="precioExtra"
                                  [min]="1"
                                  placeholder="Cantidad" [attr.disabled]="producto_detail.extras.length >= 10 ? true : null"
                                />
                          
                              
                              </div>
                            </div>
                          </div>
        
                          
                          
                          
                          
                          <div class="col-12 col-md-3 d-flex align-items-center justify-content-center">
                            <button class="btn btn-success"  (click)="agregarExtra()" [attr.disabled]="producto_detail.extras.length >= 10 ? true : null" >Agregar Salsas</button>
                          </div>
    
                        <!-- Productos seleccionados -->
                        <div  *ngIf="producto_detail.extras.length != 0" class="row mb-3">
                            <div class="col-12">
                            <h4>Seleccionados</h4>
                            <ul class="list-group">
                                <li *ngFor="let item of producto_detail.extras" class="list-group-item d-flex justify-content-between align-items-center">
                                {{ item.extra.name }} - Precio: {{ item.precio }}
                                <button class="btn btn-danger btn-sm" (click)="quitarProducto(item.extra.id)">Quitar</button>
                                </li>
                            </ul>
                            </div>
                        </div>
    
                          
    
                    </div>
                </div>
            </div>
            

        <ng-container  *ngIf="producto_detail.tipo == 2" >
           
            <div class="form-group mt-2 mb-2">
                <h3> Variaciones </h3>
            </div>
    
            <div class="card" >
                <div class="card-body">
                    <div class="row">

                        <div class="col-12 col-md-12 row"   *ngFor="let atributo of atributosExtra; let i = index" >

                            <!-- Selección Atributos -->
                            <div class="col-12 col-md-3" >

                              <div class="form-group">
                                <label for="productosSelect">Seleccionar Atributos {{ i + 1 }}</label>
                                <ng-select bindValue="id" bindLabel="name" [items]="atributo.opciones" class="js-example-basic-multiple"
                                [disabled]="atributo.selectedId && atributosExtra.length > 1"
                                [(ngModel)]="atributo.selectedId" (change)="seleccionarAtributo(atributo.selectedId)">
                                </ng-select>
                              </div>

                            </div>
                            <!-- Selección de cantidad -->

                            



                            
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                  <label for="precioExtra">Atributos</label>
                                  <div class="input-group">
                                    <ng-container *ngIf="attributesAll[atributo.selectedId] && attributesAll[atributo.selectedId]['opciones']?.length">
                                      <ng-select [items]="attributesAll[atributo.selectedId]['opciones']" 
                                                 [multiple]="true" 
                                                 bindLabel="name" 
                                                 [closeOnSelect]="false" 
                                                 bindValue="id" 
                                                 [(ngModel)]="selectAttributos[atributo.selectedId].seleccionados" 
                                                 class="w-100">
                                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                          <div (click)="$event.stopPropagation()">
                                            <input id="item-{{ index }}" 
                                                   type="checkbox" 
                                                   (change)="toggleSelect(item, $event.target.checked, atributo.selectedId)" 
                                                   [checked]="isItemSelected(item, atributo.selectedId)" />
                                            {{ item }}
                                          </div>
                                        </ng-template>
                                      </ng-select>
                                    </ng-container>
                                  </div>
                                </div>
                            </div>
  
                            <!-- Botón para agregar más selects -->
                            <div  class="col-12 col-md-3 d-flex align-items-center ">
                                <!-- Botón para agregar un nuevo atributo -->
                                <button  *ngIf="!atributo.agrego_extra" class="btn btn-primary col-12 col-md-6 m-1" 
                                        [disabled]="!atributo.selectedId || attributes.length == atributosExtra.length" 
                                        (click)="agregarAtributo(i)">
                                        <i class="fa fa-plus" aria-hidden="true"></i>

                                </button>
                                
                                <!-- Botón para quitar un atributo -->
                                <button *ngIf="i != 0" class="btn btn-danger col-12 col-md-6  m-1" 
                                        (click)="eliminarVariaciones(i)">
                                        <i class="fa fa-trash" aria-hidden="true"></i>
                                </button>
                            </div>
                            
                        </div>
                          
                          <div class="col-12 col-md-3 d-flex align-items-center justify-content-center">
                            <button class="btn btn-success"  (click)="agregarVariaciones()" >Generar Variaciones</button>
                          </div>
    
                        <!-- Productos seleccionados -->
                        <div *ngIf="producto_detail.variables.length != 0" class="row mb-3 mt-3">
                            <div class="col-12">
                                <h4>Variaciones Seleccionadas</h4>
                                <ul class="list-group">
                                    <li *ngFor="let item of producto_detail.variables; let i = index" class="list-group-item">
                                        <div class="d-flex justify-content-between align-items-center" (click)="toggleAcordeon(i)" >
                                            <!-- Título del disparador -->
                                            <div>
                                                <p>
                                                    <span *ngIf="!item.abierto"> ▼</span>  <!-- Ícono de desplegar -->
                                                    <span *ngIf="item.abierto"> ▲</span>   <!-- Ícono de colapsar --> 

                                                    <span *ngFor="let atributo of item.attributes;" class="badge badge-pill border border-primary text-primary px-3 py-2 mx-1">
                                                        <strong>{{ atributo.atributo_padre_name }}:</strong> {{ atributo.valor }}
                                                      </span>
                                                      
                                                 
                                                 </p>

                                               
                                            </div>
                        
                                            <!-- Botón para quitar variación -->
                                            <button class="btn btn-danger btn-sm" (click)="quitarVariation(item.id)">Quitar</button>
                                        </div>
                        
                                        <!-- Contenido del acordeón que se muestra/oculta -->
                                        <div *ngIf="item.abierto" class="mt-3">

                                            <div class="row">
                                                <!-- Campo de Nombre -->
                                                <div class="col-md-6 mt-2">
                                                    <label for="nombre{{ i }}">Nombre:</label>
                                                    <input 
                                                        type="text" 
                                                        class="form-control" 
                                                        id="nombre{{ i }}" 
                                                        [(ngModel)]="item.name" 
                                                        placeholder="Nombre de la variación">
                                                </div>
                                                                  
                        
                                                <!-- Campo de Precio -->
                                                <div class="col-md-6 mt-2">
                                                    <label for="stock{{ i }}">Stock:</label>
                                                    <input 
                                                        type="number" 
                                                        class="form-control" 
                                                        id="stock{{ i }}" 
                                                        [(ngModel)]="item.stock" 
                                                        placeholder="Stock">
                                                </div>

                                                <div class="col-md-6 mt-2">
                                                    <label for="precio{{ i }}">Precio:</label>
                                                    <input 
                                                        type="number" 
                                                        class="form-control" 
                                                        id="precio{{ i }}" 
                                                        [(ngModel)]="item.price" 
                                                        placeholder="Precio">
                                                </div>

                                                <div class="col-md-12 mt-2">
                                                    <label for="descripcion{{ i }}">Descripción:</label>
                                                    <textarea 
                                                    class="form-control" 
                                                    id="descripcion{{ i }}" 
                                                    [(ngModel)]="item.description" 
                                                    placeholder="Descripción" 
                                                    rows="3">
                                                </textarea>
                                                
                                                </div>

                                                <div class="col-md-12 mt-2">
                                                    <input type="file" 
                                                    class="form-control" 
                                                    id="imagenProducto" 
                                                    (change)="onFileSelectedVariation($event, item)" 
                                                    accept="image/png, image/jpeg, image/jpg, image/gif">

                            
                                                    <!-- Image preview or default image -->
                                                    <img *ngIf="item.imagePreview" [src]="item.imagePreview" alt="Imagen del Producto" 
                                                        class="img-thumbnail" style="max-width: 200px; margin: 10px;">

                                                </div>
                                                

                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        
                        
                          
    
                    </div>
                </div>
            </div>
        
        </ng-container>
        
    <!-- Botones -->
    <div class="form-group buttons">
        <button [attr.disabled]="is_loading ? true : null" class="btn btn-primary" (click)="onSubmit()">Guardar Producto</button>
        <button class="btn btn-secondary" (click)="onCancel()">Cancelar</button>
    </div>
</div>
