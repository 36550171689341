import { Component, OnInit, } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { NpsAddDatoPersonalModalComponent } from '../nps-add-dato-personal-modal/nps-add-dato-personal-modal.component';
import { NpsEditDatoPersonalModalComponent } from '../nps-edit-dato-personal-modal/nps-edit-dato-personal-modal.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EncuestaService } from '../shared/services/encuesta.service';
import { Router } from '@angular/router';
import uniqid from 'uniqid';
import { Encuesta } from '../interfaces/nps/encuesta.interface'
import { TipoEncuesta } from '../interfaces/nps/tipo_encuesta.interface'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';


const Swal = require('sweetalert2')

@Component({
  selector: 'app-nps-add',
  templateUrl: './nps-add.component.html',
  styleUrls: ['./nps-add.component.scss']
})
export class NpsAddComponent implements OnInit {



  encuesta: Encuesta
  tipo_encuesta: TipoEncuesta[]
  tiempo_encuesta: []
  preview_id
 
  iframeUrl: SafeResourceUrl;


  tool_tips_tipo_pregunta = [
    {
      id: 1,
      title: "Caritas",
      description: "Se mostrarán 5 caritas para que el usuario responda",
      imagen: 'assets/images/imagenes-ejemplo/caritas.png'
    },
    {
      id: 2,
      title: "Del 0 al 10",
      description: "Se mostrarán números del 0 al 10 para que el usuario responda",
      imagen: 'assets/images/imagenes-ejemplo/0_al_10.png'
    },
    {
      id: 3,
      title: "Respuesta libre",
      description: "Se dejará un campo de texto para que el usuario pueda responder",
      imagen: 'assets/images/imagenes-ejemplo/respuesta_libre.png'
    },
    {
      id: 4,
      title: "Seleccionable",
      description: "Se desplegará una lista para que el usuario pueda elegir",
      imagen: 'assets/images/imagenes-ejemplo/seleccionable.png'
    },
  ]


  tipo_de_opciones: any[]



  subtypesOptions: any[] = [];



  preguntas = []

  preguntas_genericas = []



  tipo_preguntas = [
    {
      id: 1,
      name: 'Caritas'
    },
    {
      id: 2,
      name: 'Del 0 al 10'
    },
    {
      id: 3,
      name: 'Respuesta libre'
    },
    {
      id: 4,
      name: 'Seleccionable'
    }
  ]



  constructor(
    private encuestaService: EncuestaService,
    private cdr: ChangeDetectorRef,
    private modalService: NgbModal,
    private router: Router,
    private sanitizer: DomSanitizer ) {
    this.tipo_de_opciones = [
      {
        id: 1,
        name: 'Singular'
      },
      {
        id: 2,
        name: 'Multiple'
      }
    ]

    // this.tiempo_encuesta = [
    //   {
    //     id: 1,
    //     name: '5 días',
    //   }
    // ]


    this.resetFields()
  }


  async resetFields() {
    this.encuesta = {
      id: null,
      name: null,
      name_server: null,
      type: null,
      sub_type: null,
      dias_disponible: 10,
      url: null,
      created_time: null,
      created_by: null,
    }
    this.preguntas = [];

    this.preguntas_genericas = [
      {
        name: 'Nombres',
        required: true,
        minlegth: 2,
        maxlength: false,
        type: 1,
        options: []
      },
      {
        name: 'DNI',
        required: true,
        minlegth: 8,
        maxlength: 8,
        type: 1,
        options: []
      },
      {
        name: 'Celular',
        required: true,
        minlegth: 9,
        maxlength: 9,
        type: 1,
        options: []
      },/*
      {
        name: 'Concesionario',
        required: true,
        minlegth: false,
        maxlength: false,
        type: 3,
        options: [
          {
            name: 'LIMA – LA MOLINA – MAQUINARIAS',
            id: '13'
          },
          {
            name: 'LIMA - SAN ISIDRO – PANA AUTOS',
            id: '14'
          },
          {
            name: 'LIMA - SAN MIGUEL – PANA AUTOS',
            id: '18'
          },
          {
            name: 'LIMA - INDEPENDENCIA – V MOTOR CENTER',
            id: '3'
          },
          {
            name: 'LIMA - EXPOMOTOR - PLAZA NORTE - V MOTOR CENTER',
            id: '15'
          },
          {
            name: 'AREQUIPA – MINAMI',
            id: '7'
          },
          {
            name: 'TRUJILLO – TSUTOMU',
            id: '10'
          },
          {
            name: 'PIURA – TSUBAKI',
            id: '9'
          },
          {
            name: 'HUANCAYO – NAGOMI',
            id: '8'
          },
        ]
      }, */
    ]


    this.tipo_encuesta = [
      {
        id: 1,
        name: 'Involuntaria',
        subtypes: []
      },
      {
        id: 2,
        name: 'Voluntaria',
        subtypes: [
          {
            id: 4,
            name: 'Voluntaria'
          }
        ]
      },
      {
        id: 3,
        name: 'Contact Center',
        subtypes: [
          {
            id: 5,
            name: 'Contact Center'
          }
        ]
      },
    ]

    const data = await this.encuestaService.init()
    this.tipo_encuesta[0].subtypes = data.data.encuesta_involuntarias_subtypes

    this.tipo_preguntas = [
      {
        id: 1,
        name: 'Caritas'
      },
      {
        id: 2,
        name: 'Del 0 al 10'
      },
      {
        id: 3,
        name: 'Respuesta libre'
      },
      {
        id: 4,
        name: 'Seleccionable'
      }
    ]

  }


  async insertar_encuesta_preview(){
    const res = await this.encuestaService.insertar_preview()
    if (res.success) {
      this.preview_id = res.preview_id
      this.iframeUrl = this.sanitizeUrl('https://autos.honda.com.pe/encuesta-prueba/'+this.preview_id+'/prueba/');

    }
  }

  logPreview() {
    this.update_encuesta_preview();

  }

  sanitizeUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  async update_encuesta_preview(){


    var encuesta_subtype = 0

    if (this.encuesta.type == 1) {
      encuesta_subtype = this.encuesta.sub_type
    } else if (this.encuesta.type == 2) {
      encuesta_subtype = 4
    } else if (this.encuesta.type == 3) {
      encuesta_subtype = 5
    }


    const encuesta = {
      id: this.preview_id,
      nombre: this.encuesta.name,
      name_server: this.encuesta.name_server,
      tipo_encuesta: this.encuesta.type,
      sub_type: encuesta_subtype,
      preguntas: this.preguntas,
      preguntas_genericas: this.preguntas_genericas
    }


    const res = await this.encuestaService.update_preview(encuesta)
    if (res.success) {
      
      this.iframeUrl = this.sanitizeUrl('https://autos.honda.com.pe/encuesta-prueba/'+this.preview_id+'/prueba/');

    }
  }

  async ngOnInit() {


    this.insertar_encuesta_preview();



    /** PUSHEANDO PREGUNTA */
    this.preguntas.push({
      texto: '', titulo: '', tipo: null, opciones: [], nuevaOpcion: '', subtipo: 1, uniqid: uniqid(),
      parent: {
        question_id: '',
        option_id: '',
        has_parent: false,
      },
      subPregunta: [{
        has_subpregunta: false,
        rangos: [
          {
            id: 1,
            name: '0-6',
            valorMax: 6,
            pregunta: ''
          },
          {
            id: 2,
            name: '7-8',
            valorMax: 8,
            pregunta: ''
          },
          {
            id: 3,
            name: '9-10',
            valorMax: 10,
            pregunta: ''
          }
        ]
      }]
    })
    this.updateSubtypesOptions()

  }
  eliminarDatoPersonal(index) {
    const datoPersonal = this.preguntas_genericas[index]

    Swal.fire({
      title: '¿Estás seguro?',
      text: `Eliminarás el dato personal: "${datoPersonal.name}"`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#000',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        this.preguntas_genericas.splice(index, 1);

      }
    })
  }
  editarDatoPersonal(index) {
    const datoPersonal = this.preguntas_genericas[index]
    const dialogRef = this.modalService.open(NpsEditDatoPersonalModalComponent, {
      size: 'xl'
    });
    dialogRef.componentInstance.data = {
      datoPersonal,
      index
    }

    dialogRef.result.then(
      (data) => {
        this.preguntas_genericas[index] = data
        Swal.fire('Se actualizó correctamente', `Se actualizó el dato personal "${data.name}"`, 'success')
   
      },
      (reason) => {
        console.log('Modal cerrado con motivo:', reason);
      }
    );
  }
  agregarDatoPersonal(): void {
    const dialogRef = this.modalService.open(NpsAddDatoPersonalModalComponent, {
      size: 'xl'
    });

    dialogRef.componentInstance.data = {
      nombres: 'ricardo',
      apellidos: 'garcia'
    };

    dialogRef.result.then(
      (data) => {
        console.log('LA DATA ES: ', data); // Aquí deberías obtener el dato personal o undefined
        if (data.name) {
          this.preguntas_genericas.push({
            name: data.name,
            required: data.required,
            minlength: data.minlength,
            maxlength: data.maxlength,
            type: data.type,
            options: data.options
          })
          Swal.fire('Se agregó correctamente', `Se agregó el dato personal "${data.name}"`, 'success')
        }
      },
      (reason) => {
        console.log('Modal cerrado con motivo:', reason);
      }
    );
  }

  agregarSubPregunta(questionID, opcion_value, optionID) {
    var value = ''
    var pregunta_padre_uniqid = ''
    for (let index = 0; index < this.preguntas.length; index++) {
      for (let index_j = 0; index_j < this.preguntas[index].opciones.length; index_j++) {
        if (this.preguntas[index].opciones[index_j].id == optionID) {
          this.preguntas[index].opciones[index_j].has_subpregunta = true
          value = this.preguntas[index].opciones[index_j].name
          pregunta_padre_uniqid = this.preguntas[index].uniqid
          break;
        }
      }
    }

    this.agregarPregunta(optionID, questionID, value, pregunta_padre_uniqid)

  }

  agregarPregunta(optionID = null, questionID = null, opcion_value = '', pregunta_padre_uniqid = ''): void {
    console.log(this.encuesta)
    /** PUSHEANDO PREGUNTA */
    if (optionID != null) {
      this.preguntas.push({
        texto: '', titulo: '', tipo: null, opciones: [], nuevaOpcion: '', subtipo: 1, uniqid: uniqid(),
        parent: {
          question_id: questionID,
          option_id: optionID,
          respuesta_gatillo: opcion_value,
          pregunta_padre_uniqid: pregunta_padre_uniqid,
          has_parent: true,
        },
        subPregunta: [{
          has_subpregunta: false,
          rangos: [
            {
              id: 1,
              name: '0-6',
              valorMax: 6,
              pregunta: ''
            },
            {
              id: 2,
              name: '7-8',
              valorMax: 8,
              pregunta: ''
            },
            {
              id: 3,
              name: '9-10',
              valorMax: 10,
              pregunta: ''
            }
          ]
        }]
      })
    } else {
      this.preguntas.push({
        texto: '', titulo: '', tipo: null, opciones: [], nuevaOpcion: '', subtipo: 1, uniqid: uniqid(),
        parent: {
          question_id: '',
          option_id: '',
          respuesta_gatillo: '',
          pregunta_padre_uniqid: '',
          has_parent: false,
        },
        subPregunta: [{
          has_subpregunta: false,
          rangos: [
            {
              id: 1,
              name: '0-6',
              valorMax: 6,
              pregunta: ''
            },
            {
              id: 2,
              name: '7-8',
              valorMax: 8,
              pregunta: ''
            },
            {
              id: 3,
              name: '9-10',
              valorMax: 10,
              pregunta: ''
            }
          ]
        }]
      })
    }
    // this.cdr.detectChanges()
  }

  eliminarPregunta(index: number, optionID): void {
    this.preguntas.splice(index, 1);
    if (optionID) {
      this.reactivarOpcion(optionID)
    }
  }


  reactivarOpcion(optionID) {
    for (let i = 0; i < this.preguntas.length; i++) {
      for (let j = 0; j < this.preguntas[i].opciones.length; j++) {
        if (this.preguntas[i].opciones[j].id == optionID) {
          this.preguntas[i].opciones[j].has_subpregunta = false
          break;
        }
      }
    }
  }

  agregarOpcion(index: number, pregunta_uniqid): void {
    const nuevaOpcion = this.preguntas[index].nuevaOpcion.trim();
    console.log(nuevaOpcion)
    if (nuevaOpcion !== '') {
      this.preguntas[index].opciones.push({
        name: nuevaOpcion,
        pregunta_index: index,
        pregunta_uniqid: pregunta_uniqid,
        id: uniqid(),
        has_subpregunta: false
      });
      console.log({
        name: nuevaOpcion,
        pregunta_index: index,
        pregunta_uniqid: pregunta_uniqid,
        id: uniqid(),
        has_subpregunta: false
      })
      this.preguntas[index].nuevaOpcion = ''; // Limpiar el campo después de agregar la opción
    }

  }

  eliminarOpcion(preguntaIndex: number, opcionIndex: number, optionID): void {
    this.preguntas[preguntaIndex].opciones.splice(opcionIndex, 1)
    this.eliminarSubPreguntaPorOpcion(optionID)

  }

  eliminarSubPreguntaPorOpcion(optionID) {
    for (let i = 0; i < this.preguntas.length; i++) {
      if (this.preguntas[i].parent.option_id == optionID) {
        this.eliminarPregunta(i, optionID)
        break;
      }
    }
  }

  preguntasGenericasParse() {
    return this.preguntas_genericas.slice(4)
  }

  async grabarEncuesta() {

    console.log('preguntas_genericas', this.preguntasGenericasParse())
    console.log('preguntas', this.preguntas)



    var encuesta_subtype = 0

    if (this.encuesta.type == 1) {
      encuesta_subtype = this.encuesta.sub_type
    } else if (this.encuesta.type == 2) {
      encuesta_subtype = 4
    } else if (this.encuesta.type == 3) {
      encuesta_subtype = 5
    }



    const encuesta = {
      nombre: this.encuesta.name,
      name_server: this.encuesta.name_server,
      tipo_encuesta: this.encuesta.type,
      sub_type: encuesta_subtype,
      preguntas: this.preguntas,
      preguntas_genericas: this.preguntas_genericas
    }


    console.log('==== GRABAR ESTO ====', encuesta)




    const res = await this.encuestaService.insertar(encuesta)

    if (res.success) {
      Swal.fire(res.message, '', 'success')
      console.log('se debe resetear')
      this.resetFields()
      this.router.navigateByUrl('/loyalty-comunication/nps')
    } else {
      Swal.fire(res.message, '', 'error')
    }



  }

  validarEncuesta() {
    console.warn('encuesta : ', this.encuesta)
    console.warn('preguntas : ', this.preguntas)
    console.warn('preguntas_genericas : ', this.preguntas_genericas)



    if (this.make_validation()) {

      Swal.fire({
        title: '¿Estás seguro?',
        text: `Grabarás la encuesta: ${this.encuesta.name}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#000',
        confirmButtonText: 'Si',
        cancelButtonText: 'No'
      }).then((result) => {
        if (result.isConfirmed) {
          this.grabarEncuesta()
        }
      })
    }
  }
  private make_validation() {
    if (!this.encuesta.name) {
      Swal.fire('Ingrese nombre de la encuesta', '', 'error');
      return false;
    }

    if (!this.encuesta.type) {
      Swal.fire('Seleccione el tipo de encuesta', '', 'error');
      return false;
    }

    if (this.encuesta.type == 1) {
      if (!this.encuesta.sub_type) {
        Swal.fire('Seleccione o agregue un subtipo de encuesta', '', 'error');
        return false;
      }
    }

    console.log(1);
    for (const pregunta of this.preguntas) {
      console.log('PREGUNTA', pregunta)
      if (!pregunta.texto) {
        Swal.fire('Ingrese nombre de la pregunta', '', 'error');
        return false
      }

      if (pregunta.tipo == 2) {
        console.log(508, pregunta.subPregunta[0].has_subpregunta)
        if (pregunta.subPregunta[0].has_subpregunta) {
          for (const rango of pregunta.subPregunta[0].rangos) {
            if (!rango.pregunta) {
              Swal.fire('Ingrese pregunta en el rango', '', 'error');
              return false
            }
          }
        }
      }

      if (pregunta.tipo == 4) {
        /** Es seleccionable */
        if (pregunta.opciones.length < 2) {
          Swal.fire('Debe elegir más de 2 opciones si elige una pregunta seleccionable', '', 'error');
          return false
        }

        if (!pregunta.subtipo) {
          Swal.fire('Debe seleccionar si será singular o múltiple', '', 'error');
          return false
        }

      }

    }
    console.log(3);

    return true;
  }



  updateSubtypesOptions() {

    if (this.encuesta.type > 0) {
      const selectedTipoEncuesta = this.tipo_encuesta.find(
        (tipo) => tipo.id === this.encuesta.type
      );

      if (selectedTipoEncuesta) {
        this.subtypesOptions = selectedTipoEncuesta.subtypes;
      }
    } else {
      this.subtypesOptions = [];
    }
  }

  onTipoEncuestaChange() {

    this.updateSubtypesOptions();


  }

  mostrarHelp(tipo_pregunta) {
    const description = this.tool_tips_tipo_pregunta[tipo_pregunta].description
    const title = this.tool_tips_tipo_pregunta[tipo_pregunta].title
    const imagen = this.tool_tips_tipo_pregunta[tipo_pregunta].imagen
    Swal.fire({
      title: `<strong>${title}</strong>`,
      icon: "info",
      html: `${description} <br>
      <br>
      <small style='text-align:left; float:left'>Ejemplo:</small>
        <img style="max-width:100% !important;margin-top:10px; width: 100% !important;" src='${imagen}'>
      `,
      showCloseButton: true,

      confirmButtonAriaLabel: "Thumbs up, great!",
      cancelButtonText: `
        <i class="fa fa-thumbs-down"></i>
      `,
      cancelButtonAriaLabel: "Thumbs down"
    });
  }

  agregarSubtipoEncuesta() {
    Swal.fire({
      title: "Ingrese subtipo de encuesta que desea agregar",
      input: "text",
      showCancelButton: true,
      confirmButtonText: "Agregar",
      showLoaderOnConfirm: true,
      preConfirm: async (name) => {
        const res = await this.encuestaService.insertar_subtipoencuesta(name)
        if (res.success) {
          this.tipo_encuesta[0].subtypes = res.encuesta_involuntarias_subtypes
          var last_id = res.encuesta_involuntarias_subtypes.pop().id
          console.log(last_id)
          this.updateSubtypesOptions();
          console.log('this.encuesta', this.encuesta)
          this.encuesta.sub_type = last_id
          Swal.fire('Se registró correctamente', `Se registró : "${name}"`, 'success')

        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      console.log(result)
      this.updateSubtypesOptions();

    });
  }


  moverPreguntaPersonalArriba(index: number) {
    if (index > 0) {
        const pregunta = this.preguntas_genericas[index];
        this.preguntas_genericas.splice(index, 1);
        this.preguntas_genericas.splice(index - 1, 0, pregunta);
    }
}

moverPreguntaPersonalAbajo(index: number) {
  if (index < this.preguntas_genericas.length - 1) {

        const pregunta = this.preguntas_genericas[index];
        this.preguntas_genericas.splice(index, 1);
        this.preguntas_genericas.splice(index + 1, 0, pregunta);
  }
}


  moverPreguntaArriba(index: number) {
    if (index > 0) {
      const pregunta = this.preguntas[index];
      this.preguntas.splice(index, 1);
      this.preguntas.splice(index - 1, 0, pregunta);

    }
  }

  moverPreguntaAbajo(index: number) {
    if (index < this.preguntas.length - 1) {
      const pregunta = this.preguntas[index];
      this.preguntas.splice(index, 1);
      this.preguntas.splice(index + 1, 0, pregunta);

    }
  }

  inputText: string = '';
  generatedArray: string[] = [];

  generateArray(currentIndex, currentUniqueId) {
    if (this.inputText) {
      this.generatedArray = this.inputText.split(/[\n,|]+/).map(item => item.trim());

      console.log(this.generatedArray);
        this.generatedArray.forEach(opcion => {
          this.agregarOpcion_array(currentIndex, currentUniqueId, opcion);
        });

        this.inputText = '';

      }
  }

  agregarOpcion_array(index: number, pregunta_uniqid, opcion): void {
    const nuevaOpcion = opcion;
    if (nuevaOpcion !== '') {
      this.preguntas[index].opciones.push({
        name: nuevaOpcion,
        pregunta_index: index,
        pregunta_uniqid: pregunta_uniqid,
        id: uniqid(),
        has_subpregunta: false
      });
    }

  }
  

}
