<app-breadcrumb [title]="'Campañas'" [items]="['Campañas', 'Configuración','Campañas']"></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div id="contenedor" class="card-body">
                    <h2>¿Qué tipo de envío deseas hacer?</h2>
                    <div class="row">
                        <div class="col-6 item" [routerLink]="'/loyalty-comunication/campaigns/from-email'">
                            <div class="card bg-primary">
                                <div class="card-body">
                                    <div class="media faq-widgets">
                                        <div class="media-body">
                                            <h5>EMAIL MARKETING</h5>
                                            <p>Aquí encontrarás todas las campañas para envios de Email Marketing</p>
                                        </div><i data-feather="mail"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 item" [routerLink]="'/loyalty-comunication/campaigns/from-whatsapp'">
                            <div class="card bg-success">
                                <div class="card-body">
                                    <div class="media faq-widgets">
                                        <div class="media-body">
                                            <h5>WHATSAPP</h5>
                                            <p>Aquí podrás visualizar las campañas de Whatsapp</p>
                                            <p></p>
                                        </div><i data-feather="message-square"></i>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- 
                        <div class="col-6 item" [routerLink]="['/loyalty-comunication/campaigns/from-sms']">
                            <div class="card bg-info">
                                <div class="card-body">
                                    <div class="media faq-widgets">
                                        <div class="media-body">
                                            <h5>SMS</h5>
                                            <p>Aquí podrás visualizar las audiencias para envios de SMS</p>
                                            <p></p>
                                        </div><i data-feather="message-square"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        -->


                    </div>
                </div>
            </div>
        </div>
     
        
    </div>
</div>