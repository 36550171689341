import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-radio-btn-tienda",
  templateUrl: "./radio-btn-tienda.component.html",
  styleUrls: ["./radio-btn-tienda.component.scss"],
})
export class RadioBtnTiendaComponent implements OnInit {
  @Input() identificador: string = "";
  @Input() name: string = "";
  @Input() value: string = "";

  constructor() {}

  ngOnInit(): void {}
}
