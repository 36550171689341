<app-breadcrumb [title]="''" [items]="['Clientes']" [active_item]="'Listado de clientes'">
</app-breadcrumb>
<div *ngIf="customer_id > 0; then edit_customer else pick_customer"></div>
<ng-template #pick_customer>
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <h5>Lista de clientes</h5>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-concesionarios">
                            <thead>
                                <tr>
                                    <th scope="col">Nombres</th>
                                    <th scope="col">Tienda</th>
                                    <th scope="col">Fecha de registro</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let customer of customers" class="tr-mobile">
                                    <td>
                                        <div class="td-mobile">Nombre</div><b>{{customer.name}} {{customer.last_name}}</b>
                                    </td>
                                    <td>
                                        <div class="td-mobile">Tienda</div>{{customer.tienda}}
                                    </td>
                                    <td>
                                        <div class="td-mobile">Fecha de registro</div>{{obtenerFecha(customer.created_time)}}
                                    </td>
                                
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #edit_customer>

</ng-template>
