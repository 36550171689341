import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, lastValueFrom } from 'rxjs';
import { HttpClient } from "@angular/common/http";

const Swal = require('sweetalert2')

@Component({
  selector: 'app-modal-create-store',
  templateUrl: './modal-create-store.component.html',
  styleUrls: ['./modal-create-store.component.scss']
})
export class ModalCreateStoreComponent implements OnInit {


  jwt
  is_loading = false
  
  store_to_create = {
    'name': '',
    'adress': '',
    'latitud': '',
    'longitud': '',
    'status':'',
    'countries': ''
  };

  countries = []

  
  constructor(
    private http: HttpClient,
    private activeModalService: NgbActiveModal
  ) {
    this.jwt = JSON.parse(localStorage.user).jwt
  }

  async ngOnInit() {
    const data = new FormData()
    data.append('jwt', this.jwt)

    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}ClientAPI/add_store_init`, data))
    if (response.success) {
      this.countries = response.countries
    }
  }


  validate() {
    if (!this.store_to_create.name) {
      Swal.fire('Ingrese nombre', '', 'error')
      return false
    }
    if (!this.store_to_create.adress) {
      Swal.fire('Ingrese dirección', '', 'error')
      return false
    }

    if (!this.store_to_create.latitud) {
      Swal.fire('Ingrese latitud', '', 'error')
      return false
    }

    if (!this.store_to_create.longitud) {
      Swal.fire('Ingrese longitud', '', 'error')
      return false
    }

    if (!this.store_to_create.countries) {
      Swal.fire('Selecciona un país', '', 'error')
      return false
    }

    return true
  }

  async onSubmit() {
    if (this.validate()) {
      
       const data = new FormData()
       data.append('jwt', this.jwt)
       data.append('store', JSON.stringify(this.store_to_create))
      
       this.is_loading = true
       const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}ClientAPI/add_store`, data))
        this.is_loading = false
        if (response.success) {
          Swal.fire(response.message, '', 'success')
          this.activeModalService.close(response);
        } else {
        
          Swal.fire(response.message, '', 'error')
        }
    }
  }
  onCancel() {
    this.activeModalService.close({
      success: false
    });
  }

}
