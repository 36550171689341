import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-breadcrumb-body',
  templateUrl: './breadcrumb-body.component.html',
  styleUrls: ['./breadcrumb-body.component.scss']
})
export class BreadcrumbBodyComponent implements OnInit {

  @Input() items: any[] = [];
  @Input() active_item: string = '';


  constructor() { }

  ngOnInit(): void {
  }

}
