import { Component, OnInit } from '@angular/core';
import { BlackListService } from '../../../src/app/black-list.service';
import { ProductsService } from '../products.service';
import { OrdersService } from '../orders.service';

import { ActivatedRoute, Router} from '@angular/router';
import { lastValueFrom } from 'rxjs'
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-listado-ventas',
  templateUrl: './listado-ventas.component.html',
  styleUrls: ['./listado-ventas.component.scss']
})
export class ListadoVentasComponent implements OnInit {

  protected orders = []

  // ARRAY CON FILTROS
  filtros
  buttonFiltrarTexto
  isCollapsed
  default_values

  constructor(
    private BlackListService: BlackListService,
    private ProductsService: ProductsService,
    private OrdersService: OrdersService,
    private router: Router,
    private http: HttpClient,
  ) { 

    this.filtros = {}

    if (localStorage.getItem("persons_filtros")) {
      // this.filtros = JSON.parse(localStorage.getItem("persons_filtros"))
    }
  
    this.default_values = {}


  }

  
  async ngOnInit() {
    this.init_service()
    this.load_orders()
    this.load_values()
  }

  jwt: string;

  tienda_selected;

  async init_service() {
    this.jwt = JSON.parse(localStorage.user).jwt;

    this.tienda_selected = JSON.parse(localStorage.tienda_selected);

    if (this.tienda_selected) {
      this.filtros.tienda_id = this.tienda_selected;
    }

  }

  rerender(): void {


    // localStorage.setItem("persons_filtros", JSON.stringify(this.filtros))

    this.buttonFiltrarTexto = `Cargando...`
    this.load_orders();

    this.buttonFiltrarTexto = "Filtrar"

  }




  async load_values() {

    this.buttonFiltrarTexto = "Filtrar"


  }

  selectedOrders = [];

  eliminar_seleccionados(){
    if(this.selectedOrders.length != 0){

      var message = 'Se eliminarán los ' + this.selectedOrders.length + ' pedidos completamente';

      if(this.selectedOrders.length == 1){
         message = 'Se eliminará completamente';
      }  

      Swal.fire({
        title: '¿Estás seguro?',
        text: message,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, eliminar',
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        

        if (result.isConfirmed) {

          const response = await this.OrdersService.eliminar_orders_seleccionados(this.selectedOrders);
  
          if (response.success) {

            this.selectedOrders = [];
  
            Swal.fire(
              'Eliminados!',
              'Los Pedidos han sido eliminados',
              'success'
            );
  
            this.load_orders();
  
          }

        }


      });

    } else {
      Swal.fire({
        title: "No hay elementos seleccionados",
        text: "Selecciona al menos un combo",
        icon: "warning"
      });
    }
  }

  updateSelection(order) {
    console.log(order);
    if (order.selected) {
      this.selectedOrders.push(order);
    } else {
      this.selectedOrders = this.selectedOrders.filter(p => p.id !== order.id);
    }
    console.log(this.selectedOrders)

  }

  selectAll(event) {
    const isChecked = event.target.checked;
    this.selectedOrders = isChecked ? [...this.orders] : [];
    this.orders.forEach(person => person.selected = isChecked);
    console.log(this.selectedOrders)

  }


  validarCorreo(correo: string): boolean {
    const cadena = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (correo) {
      return cadena.test(correo);
    } else {
      return false;
    }
  }

  validarCelular(celular: string): boolean {
    if (celular) {
      if (this.isNumber(celular)) {
        if (celular.length === 9) {
          return true;
        }
      }
    }
    return false;
  }

  private isNumber(n: any): boolean {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }



  CrearVenta() {
    this.router.navigate(['/create-sale']); 
  }
  
  async crear_black_person(newPerson) {
    const res = await this.BlackListService.new_persons_person_black_list(newPerson);
    return res;
  }
  
  async editar_black_person(newPerson) {
    // const res = await this.BlackListService.edit_person_black_list(newPerson);
    // return res;
  }
  
  async openImprimirModal(comprobante){
    const pdfWindow = window.open(comprobante, '_blank', 'width=800,height=600');
    pdfWindow?.print();
  }

  async openDeleteConfirmationModal(id: string) {
    Swal.fire({
      title: '¿Estás seguro?',
      text: 'Se eliminará completamente',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {

        const response = await this.OrdersService.eliminar_Order(id);

        if (response.success) {

          Swal.fire(
            'Eliminado!',
            'El Pedido ha sido eliminado',
            'success'
          );

          this.load_orders();

        }



      }
    });
    
  }

  async openEditonfirmationModal(id: string) {
    if (id) {
      const route_link = 'ventas/detail/'+ id;
      console.log(route_link);
      this.router.navigate([route_link]); // Redirige a otra ruta
    }


    
  }
  



  async load_orders() {

    Swal.showLoading()

    const res = <any>await this.OrdersService.get_orders_filter(this.filtros);

    if (res.success) {
      
      this.orders = res.orders;

 
    }
    Swal.close()
  }


  getStatusClass(status: string): string {
    switch (status) {
        case 'pending':
            return 'status-pending';
        case 'processing':
            return 'status-processing';
        case 'on-hold':
            return 'status-on-hold';
        case 'completed':
            return 'status-completed';
        case 'cancelled':
            return 'status-cancelled';
        case 'refunded':
            return 'status-refunded';
        case 'failed':
            return 'status-failed';
        default:
            return '';
    }
}

getStatusText(status: string): string {
    switch (status) {
        case 'pending':
            return 'Pendiente de Pago';
        case 'processing':
            return 'Procesando';
        case 'on-hold':
            return 'En Espera';
        case 'completed':
            return 'Completado';
        case 'cancelled':
            return 'Cancelado';
        case 'refunded':
            return 'Reembolsado';
        case 'failed':
            return 'Fallido';
        default:
            return 'Estado desconocido';
    }
}


}
