

<div class="row media profile-media">   

  <div class="icon-circle">
    <app-feather-icons class="user-icon" [icon]="'user'"></app-feather-icons>
  </div>

  <div class="media-body" *ngIf="isVisible" >
    <span class="text-menu">{{userName}}</span>
    <p class="mb-0 font-roboto">{{userType}} <i class="middle fa fa-angle-down"></i></p>
  </div>

</div>


<ul class="profile-dropdown onhover-show-div"  *ngIf="isVisible" >
  <li><a href="/user/profile">
      <app-feather-icons style="margin-left: 10px;" [icon]="'user'"></app-feather-icons><span>Mi cuenta </span>
    </a></li>
  <li (click)="logoutFunc()">
    <app-feather-icons style="margin-left: 10px;" [icon]="'log-in'"></app-feather-icons><span>Cerrar sesión</span>
  </li>
</ul>