import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
@Component({
  selector: 'app-mail-order',
  templateUrl: './mail-order.component.html',
  styleUrls: ['./mail-order.component.scss']
})
export class MailOrderComponent implements OnInit {
  

  @Input() boleta: string;
  safeBoleta: SafeHtml;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    console.log('Boleta recibida:', this.boleta);

    // Sanitiza el HTML (si es necesario)
    this.safeBoleta = this.sanitizer.bypassSecurityTrustHtml(this.boleta);
  }

}
