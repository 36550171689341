import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, lastValueFrom } from 'rxjs';
import { HttpClient } from "@angular/common/http";

const Swal = require('sweetalert2')

@Component({
  selector: 'app-modal-create-caja',
  templateUrl: './modal-create-caja.component.html',
  styleUrls: ['./modal-create-caja.component.scss']
})
export class ModalCreateCajaComponent implements OnInit {

  jwt
  is_loading = false
  
  caja_to_create = {
    'name': '',
    'status_system':'',
    'turnos': '',
  };

  turnos = []
  selectedOption: { id: number; text: string } | null = null;
  constructor(
    private http: HttpClient,
    private activeModalService: NgbActiveModal
  ) {
    this.jwt = JSON.parse(localStorage.user).jwt
  }

  async ngOnInit() {
    const data = new FormData()
    data.append('jwt', this.jwt)

    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}ClientAPI/add_caja_init`, data))
    if (response.success) {
      this.turnos = response.turnos
    }

  }

  validate() {
    if (!this.caja_to_create.name) {
      Swal.fire('Ingrese nombre', '', 'error')
      return false
    }
    if (!this.caja_to_create.turnos) {
      Swal.fire('Selecciona un turno', '', 'error')
      return false
    }
    return true
  }

  async onSubmit() {
    if (this.validate()) {
      
       const data = new FormData()
       data.append('jwt', this.jwt)
       data.append('caja', JSON.stringify(this.caja_to_create))
       data.append('turno_selected', JSON.stringify(this.selectedOption))
      
       this.is_loading = true
       console.log(this.caja_to_create)
       const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}ClientAPI/add_caja`, data))
        this.is_loading = false
        if (response.success) {
          Swal.fire(response.message, '', 'success')
          this.activeModalService.close(response);
        } else {
        
          Swal.fire(response.message, '', 'error')
        }
    }
  }

  onCancel() {
    this.activeModalService.close({
      success: false
    });
  }

  onSelectionChange(event: any) {
    this.selectedOption = event;
  }

}
