<app-breadcrumb [title]="'Listado Ventas'" [items]="['Ventas']"></app-breadcrumb>


<div class="col-12">
    <div class="card-body card-body-filtros">
        <div class="buttons">
            <button type="button" class="btn btn-primary-honda button_filter__collapse"
                (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed"
                aria-controls="collapseExample">
                <span>Filtros</span>
                <app-feather-icons [icon]="'filter'"></app-feather-icons>

            </button>

            <button type="button" class="btn btn-primary-honda button_filter__collapse"
                (click)="CrearVenta()"
                aria-controls="collapseExample">
                <span>Agregar </span>
                <app-feather-icons [icon]="'plus'"></app-feather-icons>
            </button>


        
        </div>
        <div id="collapseExample" [ngbCollapse]="isCollapsed">
            <div class="card mb-0">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <h5><u>Filtros </u></h5>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label">Fecha de
                                    inicio</label>
                                <div class="col-sm-9">
                                    <input class="form-control digits"
                                        [(ngModel)]="filtros.fecha_inicio"
                                        id="example-datetime-local-input" type="date">
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label">Fecha de fin</label>
                                <div class="col-sm-9">
                                    <input class="form-control digits"
                                        [(ngModel)]="filtros.fecha_fin"
                                        id="example-datetime-local-input" type="date">
                                </div>
                            </div>
                        </div>



                
                        <div class="col-12 col-md-6 div-button_filter">
                            <button id="make_filter" type="button" class="btn btn-primary"
                                (click)="rerender()" [attr.aria-expanded]="!isCollapsed"
                                aria-controls="collapseExample"
                                [attr.disabled]="buttonFiltrarTexto != 'Filtrar' ? true:null">
                                <span>{{buttonFiltrarTexto}}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                  
                    <div class="media-body text-end">
                        <a (click)="eliminar_seleccionados()" role="button" class="btn btn-secondary me-2">
                            Eliminar <i class="fa fa-trash-o"></i> 
                         </a>

                        <a (click)="load_orders()" role="button" class="btn btn-primary me-2">Actualizar</a>
                    </div>
                </div>

                <div class="card-body">
                    <div class="table-responsive container m-5">

                            <table class="table table-concesionarios">
                        <thead>
                            <tr>
                                <th class="text-center" scope="col">
                                    <input type="checkbox" (change)="selectAll($event)" />
                                </th>
                                <th class="text-center" scope="col"># Pedido </th>
                                <th class="text-center" scope="col">Fecha </th>

                                <th class="text-center" scope="col">Estado </th>

                                <th class="text-center" scope="col">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let o of orders" class="tr-mobile" >

                                <td class="text-center">
                                    <input type="checkbox" [(ngModel)]="o.selected" (change)="updateSelection(o)" />
                                </td>

                                <td class="text-center" ><div class="td-mobile"># Pedido</div> {{o.order_code}}</td>
                                <td class="text-center" ><div class="td-mobile">Fecha</div> {{o.created_time}}</td>

                                <td class="text-center">
                                    <div class="td-mobile">Estado</div> 
                                    <p [ngClass]="getStatusClass(o.status)" class="status_order">
                                        {{ getStatusText(o.status) }}
                                    </p>
                                </td>
                                

                                <td class="text-center">
                                    <div class="d-flex justify-content-center">
                                        
                                        <button (click)="openImprimirModal(o.comprobante)" class="btn btn-secondary me-2" aria-label="Imprimir">
                                            <i class="fa fa-print"></i> Imprimir
                                        </button>
                                      <button (click)="openDeleteConfirmationModal(o.id)" class="btn btn-danger me-2" aria-label="Eliminar">
                                        <i class="fa fa-trash-o"></i> Eliminar
                                      </button>
                                      <button (click)="openEditonfirmationModal(o.id)" class="btn btn-primary" aria-label="Editar">
                                       Ver detalle
                                      </button>
                                    </div>
                                  </td>
                                  
                                
                                
                            </tr>
                        </tbody>
                    </table>


                </div>
            </div>
            
        </div>
    </div>
</div>