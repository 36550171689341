import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, lastValueFrom } from 'rxjs';
import { HttpClient } from "@angular/common/http";

const Swal = require('sweetalert2')


@Component({
  selector: 'app-modal-create-product',
  templateUrl: './modal-create-product.component.html',
  styleUrls: ['./modal-create-product.component.scss']
})
export class ModalCreateProductComponent implements OnInit {

  
  jwt
  is_loading = false
  
  product_to_create = {
    'name': '',
    'description': '',
    'is_combo': '',
    'product_type': ''
  };

  product_type = []

  
  constructor(
    private http: HttpClient,
    private activeModalService: NgbActiveModal
  ) {
    this.jwt = JSON.parse(localStorage.user).jwt
  }


  async ngOnInit() {
    const data = new FormData()
    data.append('jwt', this.jwt)

    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}Product/add_product_init`, data))
    if (response.success) {
      this.product_type = response.product_type
    }
  }

  async onSubmit() {
    
  }

  onCancel() {
    this.activeModalService.close({
      success: false
    });
  }
}
