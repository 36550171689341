import { Component, Input, OnInit } from '@angular/core';
import { UsersService } from 'src/app/logueo/users.service'
import { Router } from '@angular/router';
@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {

  @Input() isVisible: boolean = true;

  public userName: string
  public userType: string
  public profileImg: 'assets/images/dashboard/profile_honda.png';

  constructor(public usersService: UsersService, public router: Router) {
    const userName = JSON.parse(localStorage.getItem("user")).nombres
    const userType = JSON.parse(localStorage.user).user_type
    if (userName) {
      this.userName = userName
      this.userType = userType
    }
    // if (JSON.parse(localStorage.getItem('user'))) {
    //   console.log("true");
    // } else {
    //   console.log("NO ");
    // }

  }


  logoutFunc(event: Event) {
    event.preventDefault();
    // this.usersService.SignOut();
    console.log('✅ Logout')
    localStorage.removeItem('user');
    localStorage.removeItem('lead_comercial__filtros')
    localStorage.removeItem('tienda_selected')
    this.userName = null
    this.router.navigate(['auth/login']);
  }

  ngOnInit() {
  }

}
