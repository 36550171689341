<div class="container">
    <div class="container-card">
        <div class="row">
            <h2>{{customer.name}} {{customer.last_name}}</h2>
            <div class="col-12">
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label"><b>RUT:</b></label>
                    <label class="col-sm-9 col-form-label">{{customer.rut}}</label>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label"><b>Correo electrónico:</b></label>
                    <label class="col-sm-9 col-form-label">{{customer.additional_fields['Correo electrónico']}}</label>
                </div>
            </div>
         
        </div>
    </div>
    <div class="container-card">
        <div class="row">
            <div class="col-12">
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label"><b>Fecha de registro:</b></label>
                    <label class="col-sm-9 col-form-label">{{customer.created_time}}</label>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label"><b>Tienda de registro:</b></label>
                    <label class="col-sm-9 col-form-label">{{customer.tienda}}</label>
                </div>
            </div>
         
        </div>
    </div>
    <div class="container-card">
        <div class="row">
            <div class="col-12">
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label"><b>Última compra:</b></label>
                    <label class="col-sm-9 col-form-label">{{customer.ultimo_trx}}</label>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label"><b>TRX último mes:</b></label>
                    <label class="col-sm-9 col-form-label">{{currency_symbol_selected}} {{ customer.ultimo_total_trx | currency: currency_selected: ''}}</label>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label"><b>Ticket Promedio:</b></label>
                    <label class="col-sm-9 col-form-label">{{currency_symbol_selected}} {{ customer.total_ticket | currency: currency_selected: ''}}</label>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label"><b>TRX promedio x mes:</b></label>
                    <label class="col-sm-9 col-form-label">{{currency_symbol_selected}} {{ customer.total_ticket_by_month | currency: currency_selected: ''}}</label>
                </div>
                <!-- <div class="form-group row">
                    <label class="col-sm-3 col-form-label"><b>Tienda más visitada:</b></label>
                    <label class="col-sm-9 col-form-label">1213</label>
                </div> -->
            </div>
         
        </div>
    </div>
 
    <div class="form-group buttons">
        <button class="btn btn-primary" (click)="editarDatos()">Editar datos</button>
       <!--  <button class="btn btn-secondary" (click)="onCancel()">Cancelar</button> -->
    </div>

</div>