<app-breadcrumb
  [title]="'Reporte Producto'"
  [items]="['Ventas']"></app-breadcrumb>
<div class="container-fluid">
  <div class="row">
    <div class="col-12 col-md-6"></div>
    <div class="col-12 col-md-6">
      <app-breadcrumb-body
        [items]="['Reporte', 'Reporte Producto']"></app-breadcrumb-body>
    </div>
  </div>
</div>
<div class="container-fluid">
    <div class="card">
  
      <div class="row">
        <h2>Filtros de búsqueda:</h2>
        <div class="col-12 col-md-6">
          <div class="form-group row">
            <label class="col-sm-3 col-form-label"><b>Desde:</b></label>
            <div class="col-sm-9">
              <input
                  class="form-control digits"
                  [(ngModel)]="filtros.fecha_inicio"
                  id="example-datetime-local-input"
                  type="date" />
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group row">
            <label class="col-sm-3 col-form-label"><b>Hasta:</b></label>
            <div class="col-sm-9">
                <input
                      class="form-control digits"
                      [(ngModel)]="filtros.fecha_fin"
                      id="example-datetime-local-input"
                      type="date" />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group row">
            <label class="col-sm-3 col-form-label"><b>Tiendas:</b></label>
            <div class="col-sm-9">
              <ng-select
                bindValue="id"
                bindLabel="name"
                [items]="tiendas"
                class="col-sm-9 js-example-basic-multiple"
                placeholder="Seleccionar tiendas"
                [(ngModel)]="filtros.tiendas"
                [multiple]="true">
              </ng-select>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
            <div class="form-group row">
              <label class="col-sm-3 col-form-label"><b>Productos:</b></label>
              <div class="col-sm-9">
                <ng-select
                  bindValue="id"
                  bindLabel="name"
                  [items]="productos"
                  class="col-sm-9 js-example-basic-multiple"
                  placeholder="Seleccionar producto"
                  [(ngModel)]="filtros.productos"
                  [multiple]="true">
                </ng-select>
              </div>
            </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group row">
            <label class="col-sm-3 col-form-label"><b>Plataformas:</b></label>
            <div class="col-sm-9">
              <ng-select
                bindValue="id"
                bindLabel="nombre"
                [items]="plataformas"
                class="col-sm-9 js-example-basic-multiple"
                placeholder="Seleccionar plataforma"
                [(ngModel)]="filtros.plataformas"
                [multiple]="true">
              </ng-select>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6"></div>
      </div>
  
      <div class="row">
        <div class="col-12">
          <button
          id="make_filter"
          type="button"
          class="btn btn-primary btn-custom"
          (click)="rerender()"
          [attr.aria-expanded]="!isCollapsed"
          aria-controls="collapseExample"
          [attr.disabled]="buttonFiltrarTexto != 'Buscar' ? true : null">
          <span>{{ buttonFiltrarTexto }}</span>
        </button>
        </div>
      </div>

      
    </div>
</div> 
<div class="container-fluid">
    <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-inner">
              <h2 class="card-title">Ranking de productos más vendidos</h2>
              <div class="row">
                  <div class="col-md-12">
                    <div class="chart-container">
                        <ngx-charts-bar-horizontal
                        [view]="view"
                        [scheme]="colorScheme"
                        [results]="rankingProductos"
                        [gradient]="gradient"
                        [xAxis]="showXAxis"
                        [yAxis]="showYAxis"
                        [legend]="showLegend"
                        [showXAxisLabel]="showXAxisLabel"
                        [showYAxisLabel]="showYAxisLabel"
                        [xAxisLabel]="xAxisLabel"
                        [yAxisLabel]="yAxisLabel">
                      </ngx-charts-bar-horizontal>
                      
                    </div>
                </div>
                
              </div>
            
            </div>
          </div>
            
        </div>
      </div>
</div>

<div class="container-fluid">
  <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-inner">
            <h2 class="card-title">VENTA POR PRODUCTO
            </h2>
            <div class="row">
                <div class="col-md-12">
                  <div class="chart-container">
                    <apx-chart
                    [series]="chartOptions.series"
                    [chart]="chartOptions.chart"
                    [yaxis]="chartOptions.yaxis"
                    [xaxis]="chartOptions.xaxis"
                    [labels]="chartOptions.labels"
                    [stroke]="chartOptions.stroke"
                    [title]=""
                    [dataLabels]="chartOptions.dataLabels"
                   
                  ></apx-chart>
                    
                </div>
              </div>
              
            </div>
          
          </div>
        </div>
          
      </div>
    </div>
</div>

<div class="container-fluid">
    <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-inner">
              <h2 class="card-title">Participación de los productos</h2>
              <div class="row">
                  <div class="col-md-6">
                    <table class="table table-striped table-custom-border">
                        <thead>
                            <tr>
                              <th class="text-center">Jerarquía</th>
                              <th class="text-center">Venta</th>
                              <th class="text-center">Participación venta</th>
                              <th class="text-center">Cantidad productos vendidos</th>
                              <th class="text-center">Participación cantidad</th>
                            </tr>
                          </thead>
                          <tbody>
                         
                              <tr *ngFor="let ppr of participacionProductos">
                                <td class="text-center">{{ppr.categoria }}</td>
                                <td class="text-center">{{currency_symbol_selected}} {{ ppr.venta | currency: currency_selected: ''}}</td>
                                <td class="text-center">{{ ppr.participacion_venta }}</td>
                                <td class="text-center">{{ ppr.cantidad_vendida }}</td>
                                <td class="text-center">{{ ppr.participacion_cantidad }}</td>
                              </tr>
                          </tbody>
                    </table>
                 
                </div>

                <div class="col-md-6">
                  <div id="chart">
                    <apx-chart
                      [series]="participacionProductosPie.series"
                      [chart]="participacionProductosPie.chart"
                      [labels]="participacionProductosPie.labels"
                      [responsive]="participacionProductosPie.responsive"
                    ></apx-chart>
                  </div>
                  
                </div>


                
              </div>





            <div class="row" style="margin-top:30px">
                <div class="col-md-12">
                    <p>Detalle de Productos Por Local</p>
                    <table class="table table-striped table-custom-border_two ">
                        <thead>
                            <tr class="table-thead">
                                <th class="text-start text-white" [attr.colspan]="(detalle_products_tienda.data | keyvalue)?.length * 2 + 2 +1">
                                  Producto / Cantidad / Venta
                                </th>
                            </tr>

                          
                            <tr>
                                <th class="text-center"></th>
                                <th *ngFor="let producto of detalle_products_tienda.data | keyvalue" class="text-center" colspan="2">
                                    {{ producto.value.producto }}
                                </th>
                                <th class="text-center" colspan="2">Total</th>
                            </tr>
                            <tr>
                              <th class="text-center">Restaurante</th>
                              <ng-container *ngFor="let producto of detalle_products_tienda.data | keyvalue">
                                    <th class="text-center">Cantidad</th>
                                    <th class="text-center">Venta</th>
                                </ng-container>
                                <th class="text-center">Cantidad</th>
                                <th class="text-center">Venta</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let tienda of detalle_products_tienda.tiendas">
                                <td class="text-center">{{ tienda }}</td>
                                <ng-container *ngFor="let producto of detalle_products_tienda.data | keyvalue">
                                    <td class="text-center">{{ producto.value[tienda]?.cantidad || 0 }}</td>
                                    <td class="text-center">{{ producto.value[tienda]?.venta || 0 | currency }}</td>
                                </ng-container>
                                <td class="text-center">{{ getTotalByTienda(detalle_products_tienda.data, tienda, 'cantidad') }}</td>
                                <td class="text-center">{{ getTotalByTienda(detalle_products_tienda.data, tienda, 'venta') | currency }}</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th class="text-center">Total</th>
                                <ng-container *ngFor="let producto of detalle_products_tienda.data | keyvalue">
                                    <th class="text-center">{{ getTotalByProduct(detalle_products_tienda.data, producto.value.producto, 'cantidad') }}</th>
                                    <th class="text-center">{{ getTotalByProduct(detalle_products_tienda.data, producto.value.producto, 'venta') | currency }}</th>
                                </ng-container>
                                <th class="text-center">{{ getGrandTotal(detalle_products_tienda.data, 'cantidad') }}</th>
                                <th class="text-center">{{ getGrandTotal(detalle_products_tienda.data, 'venta') | currency }}</th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>

            
              
        
            
            </div>
          </div>
            
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-inner">
                    <p>Venta de extras por día de la semana</p>
                    <div class="chart-container">
                      <ngx-charts-bar-vertical-2d
                      [view]="view"
                      [scheme]="colorScheme"
                      [results]="ventasExtrasPorSemana"
                      [gradient]="gradient"
                      [xAxis]="showXAxis"
                      [yAxis]="showYAxis"
                      [legend]="showLegend"
                      [showXAxisLabel]="showXAxisLabel"
                      [xAxisLabel]="xAxisLabel"
                      [showYAxisLabel]="showYAxisLabel"
                      [yAxisLabel]="yAxisLabel"
                      [animations]="animations">
                    </ngx-charts-bar-vertical-2d>
          
                
                    </div>
                </div>
            </div>
        
        </div>
        <div class="col-md-12">
            <div class="card">
                <div class="card-inner">
                    <p>Venta de extras por día</p>
                    <div class="chart-container"><ngx-charts-line-chart
                        [view]="view"
                        [scheme]="colorScheme"
                        [results]="ventasExtrasPorDia"
                        [xAxis]="true"
                        [yAxis]="true"
                        [legend]="true"
                        [showXAxisLabel]="true"
                        [xAxisLabel]="'Día'"
                        [showYAxisLabel]="true"
                        [yAxisLabel]="'Ventas (M)'"
                        [autoScale]="true"
                        [timeline]="true">
                      </ngx-charts-line-chart>
                
                    </div>
                </div>
            </div>
        
        </div>

        <div class="col-md-12">
            <div class="card">
                <div class="card-inner">
                    <p>Venta de extras por hora</p>
                    <div class="chart-container"><ngx-charts-line-chart
                        [view]="view"
                        [scheme]="colorScheme"
                        [results]="ventasExtrasPorHora"
                        [xAxis]="true"
                        [yAxis]="true"
                        [legend]="true"
                        [showXAxisLabel]="true"
                        [xAxisLabel]="''"
                        [showYAxisLabel]="true"
                        [yAxisLabel]="''"
                        [autoScale]="true"
                        [timeline]="true">
                      </ngx-charts-line-chart>
                
                    </div>
                </div>
            </div>
        
        </div>
    </div>
    <div class="row" >
      <div class="col-md-12">
        <div class="card">
            <div class="card-inner">
                  <div class="col-md-12">
                      <p>Detalle de Productos Extra Por Local</p>
                      <table class="table table-striped table-custom-border_two">
                          <thead>
                              <tr class="table-thead">
                                  <th class="text-start text-white" [attr.colspan]="(detalle_products_extra_tienda.data | keyvalue)?.length * 2 + 2 +1">
                                      Extra / Cantidad / Venta
                                  </th>
                              </tr>
                              <tr>
                                  <th class="text-center"></th>
                                  <th *ngFor="let producto of detalle_products_extra_tienda.data | keyvalue" class="text-center" colspan="2">
                                      {{ producto.value.producto }}
                                  </th>
                                  <th class="text-center" colspan="2">Total</th>
                              </tr>
                              <tr>
                                <th class="text-center">Restaurante</th>
                                  <ng-container *ngFor="let producto of detalle_products_extra_tienda.data | keyvalue">
                                      <th class="text-center">Cantidad</th>
                                      <th class="text-center">Venta</th>
                                  </ng-container>
                                  <th class="text-center">Cantidad</th>
                                  <th class="text-center">Venta</th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr *ngFor="let tienda of detalle_products_extra_tienda.tiendas">
                                  <td class="text-center">{{ tienda }}</td>
                                  <ng-container *ngFor="let producto of detalle_products_extra_tienda.data | keyvalue">
                                      <td class="text-center">{{ producto.value[tienda]?.cantidad || 0 }}</td>
                                      <td class="text-center">{{ producto.value[tienda]?.venta || 0 | currency }}</td>
                                  </ng-container>
                                  <td class="text-center">{{ getTotalByTienda(detalle_products_extra_tienda.data, tienda, 'cantidad') }}</td>
                                  <td class="text-center">{{ getTotalByTienda(detalle_products_extra_tienda.data, tienda, 'venta') | currency }}</td>
                              </tr>
                          </tbody>
                          <tfoot>
                              <tr>
                                  <th class="text-center">Total</th>
                                  <ng-container *ngFor="let producto of detalle_products_extra_tienda.data | keyvalue">
                                      <th class="text-center">{{ getTotalByProduct(detalle_products_extra_tienda.data, producto.value.producto, 'cantidad') }}</th>
                                      <th class="text-center">{{ getTotalByProduct(detalle_products_extra_tienda.data, producto.value.producto, 'venta') | currency }}</th>
                                  </ng-container>
                                  <th class="text-center">{{ getGrandTotal(detalle_products_extra_tienda.data, 'cantidad') }}</th>
                                  <th class="text-center">{{ getGrandTotal(detalle_products_extra_tienda.data, 'venta') | currency }}</th>
                              </tr>
                          </tfoot>
                      </table>
                  </div>
        
  
            </div>
        </div>
    
    </div>
    

    </div>
</div>