<!-- <div class="container-fluid p-0">
    <div class="row">
      <div class="col-12">
        <div class="login-card">
          <div class="login-main">
            <div>
              <a class="logo" routerLink='/'>
                <img class="img-fluid for-light" src="assets/images/logo/logo.svg" alt="logopage">
                <img class="img-fluid for-dark" src="assets/images/logo/logo.svg" alt="logopage">
              </a>
              <form class="theme-form" [formGroup]="forgotPasswordForm">
                <h4>Restablecer contraseña</h4>
                <p>Ingrese su correo electrónico para recibir instrucciones</p>
                <div class="form-group">
                  <label class="col-form-label">Correo electrónico</label>
                  <input class="form-control" type="email" required="" placeholder="app@yourmail.com"
                    formControlName="email">
                  <div *ngIf="forgotPasswordForm.controls.email.touched && forgotPasswordForm.controls.email.errors?.required"
                    class="text text-danger mt-1">
                    Correo electrónico es requerido
                  </div>
                  <div *ngIf="forgotPasswordForm.controls.email.touched && forgotPasswordForm.controls.email.errors?.email"
                    class="text text-danger mt-1">
                    Correo electrónico inválido
                  </div>
                </div>
                <div class="form-group mb-0">
                  <button class="btn btn-primary d-block w-100" [class.loader--text]="userService.showLoader"
                    [disabled]="!forgotPasswordForm.valid || userService.showLoader" (click)="resetPassword()"
                    type="button"><span>{{ userService.showLoader ? 'Enviando...' : 'Enviar instrucciones' }}</span>
                  </button>
                </div>
                <p class="mt-4 mb-0">¿Recordaste tu contraseña? <a [routerLink]="'/login'" class="ms-2">Iniciar sesión</a></p>
              </form>

              <div *ngIf="successMessage" class="alert alert-success mt-3">
                {{ successMessage }}
              </div>
              <div *ngIf="errorMessage" class="alert alert-danger mt-3">
                {{ errorMessage }}
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
</div> -->

<div class="container-fluid p-0 bg-image">
  <div class="row">
    <div class="col-12">
      <div class="login-card">
        <div></div>
        <div class="login-main">
          <div>
            <a class="logo" routerLink="/">
              <img
                class="img-fluid for-light"
                src="assets/images/logo/logo-login-mayu.svg"
                alt="looginpage" />
              <img
                class="img-fluid for-dark"
                src="assets/images/logo/logo-login-mayu.svg"
                alt="looginpage" />
            </a>
            <form class="theme-form">
              <!-- <h4>Login</h4> -->
              <h3>Restablecer contraseña</h3>
              <p>Ingrese su correo electrónico para recibir instrucciones</p>
              <div class="form-group">
                <label class="col-form-label">Correo electrónico</label>
                <input
                  class="form-control"
                  type="email"
                  required=""
                  placeholder="app@yourmail.com"
                  formControlName="email" />
                <!-- <div class="text text-danger mt-1">
                  Correo electrónico es requerido
                </div>
                <div class="text text-danger mt-1">
                  Correo electrónico inválido
                </div> -->
              </div>
              <div class="form-group mb-0">
                <button
                  class="btn btn-primary d-block btnEnviar"
                  [class.loader--text]="userService.showLoader"
                  type="button">
                  <span>{{ userService.showLoader ? "" : "Enviar" }}</span>
                </button>
              </div>
              <div class="row">
                <div class="col-12">
                  <p class="text-center forgot-text">
                    Recordaste tu contraseña
                    <a [routerLink]="'/auth/login'" class="forgot-pass"
                      >Iniciar sesión</a
                    >
                  </p>
                </div>
              </div>
            </form>
          </div>
          <div class="login-footer">
            por <img src="../../../assets/images/logo/logo-footer-mayu.svg" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
