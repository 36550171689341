import { Component, OnInit } from '@angular/core';
import { BlackListService } from '../../../src/app/black-list.service';
import { ProductsService } from '../products.service';
import { OrdersService } from '../orders.service';

import { ActivatedRoute, Router} from '@angular/router';
import { lastValueFrom } from 'rxjs'
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';



import Swal from 'sweetalert2';
@Component({
  selector: 'app-reporte-producto',
  templateUrl: './reporte-producto.component.html',
  styleUrls: ['./reporte-producto.component.scss']
})


export class ReporteProductoComponent implements OnInit {

  protected orders
  protected order_detail = []

  // ARRAY CON FILTROS
  filtros
  buttonFiltrarTexto
  isCollapsed
  default_values
  cantidad_dias = 0;
  jwt: string;
  clientes_nuevos;
  clientes_orders;
  tienda_selected
  plataformas : []
  productos :[]
  tiendas: []
  formaDePago = []; 
  transacciones
  cantidad_clientes = 0
  productos_order
  plataformas_order

  currency_selected
  currency_symbol_selected



  chartOptions = {
    series: [
      {
        name: "Cantidad Vendida",
        type: "column",
        data: []
      },
      {
        name: "Venta",
        type: "line",
        data: []
      }
    ],
    chart: {
      height: 350,
      type: "line"
    },
    stroke: {
      width: [0, 4]
    },
    title: {
      text: ""
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [1]
    },
    labels: [],
    xaxis: {
      type: "category"
    },
    yaxis: [
      {
        title: {
          text: "Venta"
        }
      },
      {
        opposite: true,
        title: {
          text: "Cantidad "
        }
      }
    ],
    colors: [ "#346aca", "#4AB6FFFF"] 

  };
  
  constructor(
    private BlackListService: BlackListService,
    private ProductsService: ProductsService,
    private OrdersService: OrdersService,
    private router: Router,
    private http: HttpClient,
  ) { 

    this.filtros = {}
    this.jwt = JSON.parse(localStorage.user).jwt
    // this.tienda_selected = JSON.parse(localStorage.tienda_selected)
    this.default_values = {}


  }

  async ngOnInit() {
    this.currency_selected = localStorage.getItem('currency_selected')
    this.currency_symbol_selected = localStorage.getItem('currency_symbol_selected')
    this.setFechas()
    this.init_service()
    this.load_resumen()
     this.load_values()
    this.updateChartSize();
    window.addEventListener('resize', () => this.updateChartSize());
  }


  async init_service() {
    
    const data = new FormData()
    data.append('jwt', this.jwt)
 
    const res = <any>await lastValueFrom(this.http.post(`${environment.url_api}Ventas/load_ventas_init_general_admin`, data))

    if (res.success) {

      this.plataformas = res.payload.plataformas;
      this.productos = res.payload.productos;
      this.tiendas = res.payload.tiendas;

    }


  }

  rankingProductos = [];
  participacionProductos
  detalleExtraProductos
  detalleParticipacionExtraProductos
  ventasExtrasPorHora = []
  ventasExtrasPorDia = []
  ventasExtrasPorSemana = []
  ventas_data = [];
  detalle_products_tienda: any ;
  detalle_products_extra_tienda: any ;

  
  participacionProductosPie = {
    series: [],
    chart: {
      type: "donut",
      width: 500, // Ajusta el ancho del gráfico
      height: 500 // Ajusta la altura del gráfico
    },
    labels: [],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: "bottom"
          }
        }
      }
    ]
  };
  
  async load_resumen() {

    Swal.showLoading()


    const res = <any>await this.OrdersService.get_reporte_ventas_productos(this.filtros);
    console.log(res)
   
    if (res.success) {

      this.rankingProductos = res.ranking_productos;
      this.participacionProductos = res.participacion_producto;

      this.detalleExtraProductos = res.detalle_extra_producto;
      this.detalleParticipacionExtraProductos = res.detalle_participacion_extra_producto;
      this.ventasExtrasPorSemana = res.extras_por_semana;
      this.ventasExtrasPorDia = res.extras_por_dia;
      this.ventasExtrasPorHora = res.extras_por_hora;
      this.ventas_data = res.ventas_data; // Datos obtenidos de la API


      this.participacionProductosPie.series  =  res.participacion_producto_pie.series;
      this.participacionProductosPie.labels  =  res.participacion_producto_pie.labels;

      this.detalle_products_tienda = res.detalle_products_tienda;
      this.detalle_products_extra_tienda = res.detalle_products_extra_tienda;

      res.participacion_producto_pie;

      // Extraer datos para ApexCharts
      const labels =  res.ventas_data.labels;
      const cantidades =  res.ventas_data.cantidades;
      const ventas =  res.ventas_data.ventas;
      
      // Asignar los valores al chartOptions
      this.chartOptions.series = [
        
        {
          name: "Venta",
          type: "column",
          data: ventas
        },
        {
          name: "Cantidad Vendida",
          type: "line",
          data: cantidades
        }
      ];
      
      this.chartOptions.labels = labels;

      
    }

    Swal.close()
  }

  rerender(): void {

    const fechaInicio = new Date(this.filtros.fecha_inicio);
    const fechaFin = new Date(this.filtros.fecha_fin);

    this.cantidad_dias = this.calcularDiasEntreFechas(fechaInicio, fechaFin);

    this.buttonFiltrarTexto = `Cargando...`
    this.load_resumen();

    this.buttonFiltrarTexto = "Buscar"

  }
  

  setFechas() {
    const fechaActual = new Date();
    const primerDiaDelMes = new Date(fechaActual.getFullYear(), fechaActual.getMonth(), 1);
    this.filtros.fecha_inicio = this.formatFecha(primerDiaDelMes);
    this.filtros.fecha_fin = this.formatFecha(fechaActual);

     this.cantidad_dias = this.calcularDiasEntreFechas(primerDiaDelMes, fechaActual);
  }

  calcularDiasEntreFechas(fechaInicio: Date, fechaFin: Date): number {
    const milisegundosPorDia = 1000 * 60 * 60 * 24;
    const diferenciaMilisegundos = fechaFin.getTime() - fechaInicio.getTime();
    return Math.ceil(diferenciaMilisegundos / milisegundosPorDia);
  }

  formatFecha(fecha: Date): string {
    const anio = fecha.getFullYear();
    const mes = ('0' + (fecha.getMonth() + 1)).slice(-2); // Se asegura que el mes sea de dos dígitos
    const dia = ('0' + fecha.getDate()).slice(-2); // Se asegura que el día sea de dos dígitos
    return `${anio}-${mes}-${dia}`;
  }

  async load_values() {
    this.buttonFiltrarTexto = "Buscar"
  }

  updateChartSize() {
    const container = document.querySelector('.chart-container');
    const container6 = document.querySelector('.chart-container-6');
    if (container) {
      this.view = [container.clientWidth, 400]; // Ancho dinámico, altura fija
    }
    if (container6) {
      this.view6 = [container6.clientWidth, 400]; // Ancho dinámico, altura fija
    }
  }

  view: [number, number] = [0, 400]; // Tamaño del gráfico
  view6: [number, number] = [0, 400]; // Tamaño del gráfico

    showXAxis = true;
    showYAxis = true;
    gradient = false;
    showLegend = true;
    showXAxisLabel = false;
    xAxisLabel = 'Ventas Promedio';
    showYAxisLabel = false;
    yAxisLabel = 'Tiendas';
    animations = true;

    colorScheme = {
      domain: this.generateColors()
    };

    generateColors(count: number = 7): string[] {
      return Array.from({ length: count }, () =>
        '#' + Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0')
      );
    }

    salesData = [
      {
        name: 'Producto A',
        series: [
          { name: '01 Mar', value: 1500000 },
          { name: '02 Mar', value: 1700000 },
          { name: '03 Mar', value: 1400000 },
          { name: '04 Mar', value: 2000000 }
        ]
      },
      {
        name: 'Producto B',
        series: [
          { name: '01 Mar', value: 1200000 },
          { name: '02 Mar', value: 1350000 },
          { name: '03 Mar', value: 1100000 },
          { name: '04 Mar', value: 1800000 }
        ]
      },
      {
        name: 'Producto C',
        series: [
          { name: '01 Mar', value: 900000 },
          { name: '02 Mar', value: 950000 },
          { name: '03 Mar', value: 1300000 },
          { name: '04 Mar', value: 1600000 }
        ]
      }
    ];
  


  getTotalByTienda(data, tienda: string, tipo: string) {
      let total = 0;
      for (let producto of Object.values(data)) {
          total += producto[tienda]?.[tipo] || 0;
      }
      return total;
  }
    
  getTotalByProduct(data, producto: string, tipo: string) {
      let total = 0;
      for (let tienda of this.detalle_products_tienda.tiendas) {
          total += data[producto]?.[tienda]?.[tipo] || 0;
      }
      return total;
  }
  
  getGrandTotal(data, tipo: string) {
      let total = 0;
      for (let tienda of this.detalle_products_tienda.tiendas) {
          total += this.getTotalByTienda(data, tienda, tipo);
      }
      return total;
  }
  
    
}
