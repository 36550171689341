<div class="container">
    <div class="form-group">
        <h2>Crear cliente</h2>
    </div>
    <div class="row">
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="nombres">Nombres</label>
                <input type="text" class="form-control" id="nombres" [(ngModel)]="customer_to_create.nombres" required>
            </div>
        </div>
        <div class="col-12 col-md-6">
                <div class="form-group">
                    <label for="apellidos">Apellidos</label>
                    <input type="text" class="form-control" id="apellidos" [(ngModel)]="customer_to_create.apellidos">
                </div>
        </div>
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="rut">RUT</label>
                <input type="text" class="form-control" id="rut" [(ngModel)]="customer_to_create.rut">
            </div>
    </div>
        <div class="col-sm-12 col-md-6" *ngFor="let cus of merges">
            <div class="form-group">
                <label for="{{cus.name}}">{{cus.name}}</label>
                <input type="{{cus.type}}" class="form-control" id="{{cus.id}}"  [(ngModel)]="cus.value" autocomplete="off" >

            </div>
        </div>
    </div>
 
    <div class="form-group buttons">
        <button [attr.disabled]="is_loading? true:null" class="btn btn-primary" (click)="onSubmit()">Agregar</button>
        <button class="btn btn-secondary" (click)="onCancel()">Cancelar</button>
    </div>

</div>