
<div class="logo-wrapper" style="display: none;">

  <a routerLink='/'>
    <img class="img-fluid for-light centered" src="assets/images/logo/logo-churomania.png" alt="">
    <img class="img-fluid for-dark centered" src="assets/images/logo/logo-churomania.png" alt="">
  </a>

</div>


<div class="logo-icon-wrapper" style="display: none;">
  <a href="javascript:void(0)">
    <img class="img-fluid" width="50px" src="assets/images/logo/logo-churomania.png" alt="">
  </a>
</div>

<nav class="sidebar-main">


  <div class="left-arrow" id="left-arrow" [class.d-none]="leftArrowNone" (click)="scrollToLeft()">
    <app-feather-icons [icon]="'arrow-left'"></app-feather-icons>
  </div>



  <div id="sidebar-menu" [ngStyle]="{ marginLeft : this.layout.config.settings.layout == 'Rome' || 'Singapore' || 'Barcelona' ? margin + 'px' : '0px'}">
    <ul class="sidebar-links custom-scrollbar">



      <div class="icon-churro"  *ngIf="navServices.collapseSidebar" (click)="sidebarToggle()" >
        <svg width="43" height="32" viewBox="0 0 43 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_134_5444)">
          <path d="M42.8518 21.4032C42.8518 21.4032 42.8518 21.4032 42.871 21.3651C42.9283 21.2508 42.9474 21.1173 42.9474 20.9839C42.9474 20.8505 42.9283 20.7171 42.871 20.6028C42.871 20.6028 42.8518 20.5837 42.8518 20.5646C42.7945 20.4693 42.7372 20.374 42.6608 20.2978L35.2482 13.2841C34.8661 12.922 34.2547 12.941 33.8917 13.3222C33.5288 13.7034 33.5479 14.3133 33.93 14.6754L39.604 20.031H28.9436C28.4087 20.031 27.9884 20.4503 27.9884 20.9839C27.9884 21.5176 28.4087 21.9369 28.9436 21.9369H39.604L33.93 27.2924C33.5479 27.6546 33.5288 28.2645 33.8917 28.6456C34.0828 28.8362 34.3312 28.9506 34.5795 28.9506C34.8088 28.9506 35.0571 28.8553 35.2291 28.6838L42.6417 21.6701C42.7181 21.5938 42.7945 21.4985 42.8327 21.3842L42.8518 21.4032Z" fill="#346ACA"/>
          <path d="M31.5801 25.9202C31.0451 25.9202 30.6248 26.3395 30.6248 26.8731V28.9696C30.6248 29.5986 30.109 30.1132 29.4786 30.1132H3.03765C2.40719 30.1132 1.91047 29.5986 1.91047 28.9696V12.0071H32.5353V3.04943C32.5353 1.37225 31.1598 0 29.4786 0H3.03765C1.37554 0 0 1.37225 0 3.04943V28.9696C0 30.6468 1.37554 32.0191 3.03765 32.0191H29.4786C31.1598 32.0191 32.5353 30.6468 32.5353 28.9696V26.8731C32.5353 26.3395 32.115 25.9202 31.5801 25.9202ZM1.91047 3.04943C1.91047 2.42049 2.4263 1.9059 3.03765 1.9059H29.4786C30.109 1.9059 30.6248 2.42049 30.6248 3.04943V10.1203H1.91047V3.04943Z" fill="#346ACA"/>
          <path d="M24.3585 14.3323H9.07473C8.5398 14.3323 8.1195 14.7516 8.1195 15.2853C8.1195 15.8189 8.5398 16.2382 9.07473 16.2382H24.3585C24.8934 16.2382 25.3137 15.8189 25.3137 15.2853C25.3137 14.7516 24.8934 14.3323 24.3585 14.3323Z" fill="#346ACA"/>
          <path d="M5.48305 13.9702C4.75707 13.9702 4.16483 14.561 4.16483 15.2853C4.16483 16.0095 4.75707 16.6004 5.48305 16.6004C6.20903 16.6004 6.80127 16.0095 6.80127 15.2853C6.80127 14.561 6.20903 13.9702 5.48305 13.9702Z" fill="#346ACA"/>
          <path d="M24.3585 20.0881H9.07473C8.5398 20.0881 8.1195 20.5074 8.1195 21.0411C8.1195 21.5747 8.5398 21.994 9.07473 21.994H24.3585C24.8934 21.994 25.3137 21.5747 25.3137 21.0411C25.3137 20.5074 24.8934 20.0881 24.3585 20.0881Z" fill="#346ACA"/>
          <path d="M5.48305 22.3561C6.21108 22.3561 6.80127 21.7674 6.80127 21.0411C6.80127 20.3148 6.21108 19.726 5.48305 19.726C4.75501 19.726 4.16483 20.3148 4.16483 21.0411C4.16483 21.7674 4.75501 22.3561 5.48305 22.3561Z" fill="#346ACA"/>
          <path d="M24.3585 25.863H9.07473C8.5398 25.863 8.1195 26.2823 8.1195 26.816C8.1195 27.3496 8.5398 27.7689 9.07473 27.7689H24.3585C24.8934 27.7689 25.3137 27.3496 25.3137 26.816C25.3137 26.2823 24.8934 25.863 24.3585 25.863Z" fill="#346ACA"/>
          <path d="M5.48305 28.131C6.21108 28.131 6.80127 27.5422 6.80127 26.816C6.80127 26.0897 6.21108 25.5009 5.48305 25.5009C4.75501 25.5009 4.16483 26.0897 4.16483 26.816C4.16483 27.5422 4.75501 28.131 5.48305 28.131Z" fill="#346ACA"/>
          </g>
          <defs>
          <clipPath id="clip0_134_5444">
          <rect width="42.9474" height="32" fill="white"/>
          </clipPath>
          </defs>
        </svg>
      </div>

      <div class="icon-churro"   *ngIf="!navServices.collapseSidebar" (click)="sidebarToggle()" >
        <svg width="43" height="32" viewBox="0 0 43 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_134_5455)">
          <path d="M0.0952948 21.4032C0.0952948 21.4032 0.0952948 21.4032 0.0762358 21.3651C0.019059 21.2698 0 21.1364 0 21.003C0 20.8696 0.019059 20.7361 0.0762358 20.6218C0.0762358 20.6218 0.0952948 20.6027 0.0952948 20.5837C0.152472 20.4884 0.209649 20.3931 0.285884 20.3168L7.68076 13.3032C8.06194 12.941 8.67183 12.9601 9.03395 13.3413C9.39607 13.7224 9.37701 14.3323 8.99583 14.6945L3.33532 20.05H13.9702C14.5039 20.05 14.9232 20.4693 14.9232 21.003C14.9232 21.5366 14.5039 21.9559 13.9702 21.9559H3.33532L8.99583 27.3115C9.37701 27.6736 9.39607 28.2835 9.03395 28.6647C8.84336 28.8553 8.59559 28.9696 8.34783 28.9696C8.11912 28.9696 7.87135 28.8743 7.69982 28.7028L0.304943 21.6891C0.228708 21.6129 0.152472 21.5176 0.114354 21.4032H0.0952948Z" fill="#346ACA"/>
          <path d="M10.3871 26.8731V28.9696C10.3871 30.6468 11.7594 32.0191 13.4366 32.0191H39.8142C41.4914 32.0191 42.8445 30.6468 42.8445 28.9696V12.0071V10.1012V3.04943C42.8445 1.37225 41.4914 0 39.8142 0H13.4366C11.7594 0 10.3871 1.37225 10.3871 3.04943V12.0262H11.3401H40.9387V28.9696C40.9387 29.5986 40.4241 30.1132 39.8142 30.1132H13.4366C12.8076 30.1132 12.293 29.5986 12.293 28.9696V26.8731C12.293 26.3395 11.8737 25.9202 11.3401 25.9202C10.8064 25.9202 10.3871 26.3395 10.3871 26.8731ZM40.9387 10.1012H12.293V3.04943C12.293 2.42049 12.8076 1.9059 13.4366 1.9059H39.8142C40.4431 1.9059 40.9387 2.42049 40.9387 3.04943V10.1203V10.1012Z" fill="#346ACA"/>
          <path d="M37.2031 14.3323H21.9559C21.4223 14.3323 21.003 14.7516 21.003 15.2853C21.003 15.8189 21.4223 16.2382 21.9559 16.2382H37.2031C37.7367 16.2382 38.156 15.8189 38.156 15.2853C38.156 14.7516 37.7367 14.3323 37.2031 14.3323Z" fill="#346ACA"/>
          <path d="M18.3728 13.9702C17.6486 13.9702 17.0578 14.561 17.0578 15.2853C17.0578 16.0095 17.6486 16.6004 18.3728 16.6004C19.0971 16.6004 19.6879 16.0095 19.6879 15.2853C19.6879 14.561 19.0971 13.9702 18.3728 13.9702Z" fill="#346ACA"/>
          <path d="M37.2031 20.0881H21.9559C21.4223 20.0881 21.003 20.5074 21.003 21.0411C21.003 21.5747 21.4223 21.994 21.9559 21.994H37.2031C37.7367 21.994 38.156 21.5747 38.156 21.0411C38.156 20.5074 37.7367 20.0881 37.2031 20.0881Z" fill="#346ACA"/>
          <path d="M18.3728 22.3561C19.0991 22.3561 19.6879 21.7674 19.6879 21.0411C19.6879 20.3148 19.0991 19.726 18.3728 19.726C17.6465 19.726 17.0578 20.3148 17.0578 21.0411C17.0578 21.7674 17.6465 22.3561 18.3728 22.3561Z" fill="#346ACA"/>
          <path d="M37.2031 25.863H21.9559C21.4223 25.863 21.003 26.2823 21.003 26.816C21.003 27.3496 21.4223 27.7689 21.9559 27.7689H37.2031C37.7367 27.7689 38.156 27.3496 38.156 26.816C38.156 26.2823 37.7367 25.863 37.2031 25.863Z" fill="#346ACA"/>
          <path d="M18.3728 28.131C19.0991 28.131 19.6879 27.5422 19.6879 26.816C19.6879 26.0897 19.0991 25.5009 18.3728 25.5009C17.6465 25.5009 17.0578 26.0897 17.0578 26.816C17.0578 27.5422 17.6465 28.131 18.3728 28.131Z" fill="#346ACA"/>
          </g>
          <defs>
          <clipPath id="clip0_134_5455">
          <rect width="42.8445" height="32" fill="white"/>
          </clipPath>
          </defs>
        </svg>
      </div>

      <hr>


      <div class="media-body profile" (click)="sidebarToggle()">
        <li class="profile-nav onhover-dropdown p-0"  [class]="!navServices.collapseSidebar ? 'user_active' :  'user_active'" >
          <app-my-account [isVisible]="!navServices.collapseSidebar ? true : false"></app-my-account>
        </li>
      </div>

      <li [class]="menuItem.headTitle1 ? 'sidebar-main-title' : 'sidebar-list'" 
      *ngFor="let menuItem of menuItems" [ngClass]="{active: menuItem.active}">
        
        <div *ngIf="menuItem.headTitle1">
          <!-- {{menuItem.headTitle1}} -->
          <h6 class="lan-1">{{menuItem.headTitle1 | translate}}</h6>
          <p class="lan-2">{{menuItem.headTitle2 | translate}}</p>
        </div>

        <label class="badge badge-{{menuItem.badgeType}}" *ngIf="menuItem.badgeType && menuItem.badgeValue">{{menuItem.badgeValue}}</label>
        <!-- Sub -->
        <a href="javascript:void(0)"  class="sidebar-link sidebar-title" 
          [class.link-nav]="!menuItem.children"
          [ngClass]="{active: menuItem.active}"
          *ngIf="menuItem.type === 'sub'" (click)="toggletNavActive(menuItem)">
          <i   data-feather={{menuItem.icon}} ></i>

          <app-feather-icons 
            [ngClass]="{'active-icon': menuItem.active}" 
            class="icon-churro" 
            [icon]="menuItem.icon">
          </app-feather-icons>
        
          <span class="text-menu" >{{menuItem.title | translate}}</span>
      
        </a>

   



        <!-- Link -->
        <a [routerLink]="!menuItem.type ? null : [menuItem.path]" routerLinkActive="active" class="sidebar-link sidebar-title"
          [class.link-nav]="!menuItem.children"
          [ngClass]="{active: menuItem.active}"
          *ngIf="menuItem.type === 'link'">
          <app-feather-icons [icon]="menuItem.icon" class="icon-churro" ></app-feather-icons>
          <span class="text-menu">{{menuItem.title | translate}}</span>
          <div class="according-menu">
            <i class="fa fa-angle-{{menuItem.active ? 'down' : 'right'}} pull-right" *ngIf="menuItem.children"></i>
          </div>
        </a>
        <!-- External Link -->
        <a href="{{ !menuItem.type ? null : menuItem.path }}" class="sidebar-link sidebar-title"
          [class.link-nav]="!menuItem.children" 
          [ngClass]="{active: menuItem.active}"
          *ngIf="menuItem.type === 'extLink'">
          <app-feather-icons [icon]="menuItem.icon" class="icon-churro" ></app-feather-icons><span>{{menuItem.title | translate}}</span>
          <div class="according-menu">
            <i class="fa fa-angle-{{menuItem.active ? 'down' : 'right'}} pull-right" *ngIf="menuItem.children"></i>
          </div>
        </a>
        <!-- External Tab Link -->
        <a href="{{ !menuItem.type ? null : menuItem.path }}" target="_blank" class="sidebar-link sidebar-title"
            [class.link-nav]="!menuItem.children"  
            [ngClass]="{active: menuItem.active}" 
            *ngIf="menuItem.type === 'extTabLink'">
          <app-feather-icons [icon]="menuItem.icon" class="icon-churro" ></app-feather-icons><span>{{menuItem.title }}</span>
          <div class="according-menu">
            <i class="fa fa-angle-{{menuItem.active ? 'down' : 'right'}} pull-right" *ngIf="menuItem.children"></i>
          </div>
        </a>
        <!-- 2nd Level Menu -->
        <ul class="sidebar-submenu" [ngClass]="{active: menuItem.active}"
          [ngClass]="{'menu-open': menuItem.active, 'menu-close': !menuItem.active }" *ngIf="menuItem.children"
          [style.display]="menuItem.active ? 'block' : 'none'">
          <li *ngFor="let childrenItem of menuItem.children" [ngClass]="{active: childrenItem.active}">
            <!-- Sub -->
          
            <a class="submenu-title" href="javascript:void(0)" *ngIf="childrenItem.type === 'sub'" (click)="toggletNavActive(childrenItem)">
              <span class="text-menu" > {{childrenItem.title | translate}}</span>
              <div class="according-menu">
                <i class="fa fa-angle-{{childrenItem.active ? 'down' : 'right'}} pull-right"
                  *ngIf="childrenItem.children"></i>
              </div>
            </a>
            <!-- Link -->
            <a class="submenu-title" [routerLink]="!childrenItem.type ? null : [childrenItem.path]" *ngIf="childrenItem.type === 'link'"
              routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
             <span class="text-menu">{{childrenItem.title | translate}}</span>
              <i class="fa fa-angle-down pull-right" *ngIf="childrenItem.children"></i>
            </a>
            <!-- External Link -->
            <a class="submenu-title" href="{{ !childrenItem.type ? null : childrenItem.path }}" *ngIf="childrenItem.type === 'extLink'"
              routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
             <span class="text-menu">{{childrenItem.title | translate}}</span>
              <i class="fa fa-angle-down pull-right" *ngIf="childrenItem.children"></i>
            </a>
            <!-- External Tab Link -->
            <a class="submenu-title" href="{{ !childrenItem.type ? null : childrenItem.path }}" target="_blank"
              *ngIf="childrenItem.type === 'extTabLink'">
             <span class="text-menu">{{childrenItem.title | translate}}</span>
              <i class="fa fa-angle-down pull-right" *ngIf="childrenItem.children"></i>
            </a>
            <!-- 3rd Level Menu -->
            <ul class="nav-sub-childmenu submenu-content" *ngIf="childrenItem.children"
              [ngClass]="{active: childrenItem.active}"
              [ngClass]="{'menu-open': childrenItem.active, 'menu-close': !childrenItem.active }"
              [style.display]="childrenItem.active ? 'block' : 'none'">
              <li *ngFor="let childrenSubItem of childrenItem.children" [ngClass]="{active: childrenSubItem.active}">
                <!-- Link -->
                <a [routerLink]="!childrenSubItem.type ? null : [childrenSubItem.path]"
                  *ngIf="childrenSubItem.type === 'link'" routerLinkActive="active"
                  [routerLinkActiveOptions]="{exact: true}">
                  <span class="text-menu" > {{childrenSubItem.title | translate}}</span>
                </a>
                <!-- External Link -->
                <a href="{{ !childrenSubItem.type ? null : childrenSubItem.path }}"
                  *ngIf="childrenSubItem.type === 'extLink'" routerLinkActive="active"
                  [routerLinkActiveOptions]="{exact: true}">
                  <span>{{childrenSubItem.title | translate}}</span>
                </a>
                <!-- External Tab Link -->
                <a href="{{ !childrenSubItem.type ? null : childrenSubItem.path }}" target="_blank"
                  *ngIf="childrenSubItem.type === 'extTabLink'">
                  <span>{{childrenSubItem.title | translate}}</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
  <div class="right-arrow" id="right-arrow" [class.d-none]="rightArrowNone" (click)="scrollToRight()">
    <app-feather-icons [icon]="'arrow-right'"></app-feather-icons>
  </div>
</nav>
