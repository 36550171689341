<div class="container-fluid p-0">
    <div class="row">
      <div class="col-12">
        <div class="login-card">
          <div class="login-main">
            <div>
              <a class="logo" routerLink='/'>
                <img class="img-fluid for-light" src="assets/images/logo/logo.svg" alt="logopage">
                <img class="img-fluid for-dark" src="assets/images/logo/logo.svg" alt="logopage">
              </a>
  
              <div *ngIf="token_valid"> 
                <form class="theme-form" [formGroup]="forgotPasswordForm">
                  <h4>Restablecer contraseña</h4>
                  <div class="form-group">
                    <label class="col-form-label">Ingrese Nueva Contraseña</label>
                    <input class="form-control" [type]="show ? 'text' : 'password'" formControlName="password" placeholder="*********">
                    <div class="show-hide" (click)="showPassword()">

                      
                      <span *ngIf="!show" class="show">  </span>
                      <span *ngIf="show"  class="Hide">  </span>

                    </div>
                    <div *ngIf="forgotPasswordForm.controls.password.touched && forgotPasswordForm.controls.password.errors?.required" class="text text-danger mt-1">
                      Campo requerido
                    </div>
                  </div>
  
                  <div class="form-group mb-0">
                    <button class="btn btn-primary d-block w-100" [class.loader--text]="userService.showLoader"
                      [disabled]="!forgotPasswordForm.valid || userService.showLoader" (click)="resetPassword()" type="button">
                      <span>{{ userService.showLoader ? '' : 'Restablecer Contraseña' }}</span>
                    </button>
                  </div>
                </form>
  
                <div *ngIf="successMessage" >

                  <div class="alert alert-success mt-3" >  {{ successMessage }} </div>
                  <p class="mt-4 mb-0">Regresar a <a [routerLink]="'/login'" class="ms-2">Iniciar sesión</a></p>

                </div>
                <div *ngIf="errorMessage" class="alert alert-danger mt-3">
                  {{ errorMessage }}

                  
                </div>
              </div>
  
              <div *ngIf="!token_valid"> 
                <h5>El enlace de recuperación no es válido</h5>
                <p class="mt-4 mb-0">El enlace que has utilizado ha expirado o es incorrecto. 
                   Por favor, solicita un nuevo enlace de recuperación.</p>
                <p class="mt-4 mb-0">Regresar a <a [routerLink]="'/login'" class="ms-2">Iniciar sesión</a></p>
              </div>
              

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  