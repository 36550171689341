import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from "@angular/common/http";
import { Subscription, lastValueFrom } from 'rxjs';
import { formatDate } from '@angular/common';
import { environment } from '../../environments/environment';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalPreviewCustomerComponent } from 'src/app/modal-preview-customer/modal-preview-customer.component';

const Swal = require('sweetalert2')

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class CustomerComponent implements OnInit {

  private jwt
  private routeSub: Subscription;
  customer_id

  customers: any
  total_customer = 0;
  promedio_customer_trx = 0;
  currency_selected
  currency_symbol_selected

  filtros
  buttonFiltrarTexto
  isCollapsed
  default_values

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private modalService: NgbModal,
  ) {
    this.filtros = {}
    this.default_values = {}
    this.jwt = JSON.parse(localStorage.user).jwt
    this.routeSub = this.route.params.subscribe(params => {
      this.customer_id = params.customer_id
    })
   }

  ngOnInit(): void {
    this.currency_selected = localStorage.getItem('currency_selected')
    this.currency_symbol_selected = localStorage.getItem('currency_symbol_selected')
    this.init_service()
    this.load_customers()
    this.load_values()
  }

  plataformas = [];
  stores = [];

  async init_service() {

    const data = new FormData()
    data.append('jwt', this.jwt)
    //get_list_of_customer
    const res = <any>await lastValueFrom(this.http.post(`${environment.url_api}ClientAPI/load_customer_init_general`, data))

    // const res = <any>await this.ProductsService.lead_service_init_general(this.jwt);
    if (res.success) {

      this.plataformas = res.payload.plataformas;
      this.stores = res.payload.stores;
      this.total_customer = res.payload.total_customer
      this.promedio_customer_trx = res.payload.promedio_trx_customer.total

    }
  }


  rerender(): void {

     this.buttonFiltrarTexto = `Cargando...`
     this.load_customers();
 
     this.buttonFiltrarTexto = "Buscar"
 
  }

  async load_values() {
    this.buttonFiltrarTexto = "Buscar"
  }

  async load_customers() {
    Swal.showLoading()
    console.log(this.filtros)

    const data = new FormData()
    data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
    data.append('filtros', JSON.stringify(this.filtros));
  
    const res = <any>await lastValueFrom(this.http.post(`${environment.url_api}ClientAPI/get_customers_by_filter`, data));
    if (res.success) {
      
      this.customers = res.customers;
    }

    Swal.close()

  }

  async get_data_from_customer() {
    const data = new FormData()
    data.append('jwt', this.jwt)
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}ClientAPI/get_list_of_customer`, data))

    if (response.success) {
      this.customers = response.payload.customers
      this.total_customer = response.payload.total_customer
      this.promedio_customer_trx = response.payload.promedio_trx_customer.total

    }
  }

  async refresh_data() {
    const data = new FormData()
    data.append('jwt', this.jwt)
    const tienda_selected = JSON.parse(localStorage.tienda_selected);
    data.append('tienda_selected', tienda_selected)

    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}ClientAPI/get_list_of_customer`, data))
 
    if (response.success) {
      this.customers = response.payload.customers
    }
  }

  async openViewModal(event: Event, customer_id){
    event.preventDefault();
 
    const dialogRef = this.modalService.open(ModalPreviewCustomerComponent, {
      size: 'lg'
    })
    dialogRef.componentInstance.jwt = this.jwt
    dialogRef.componentInstance.customer_id = customer_id
    dialogRef.result.then((data) => {
      console.log(data)
    })
  }

  obtenerFecha(createdTime: string) {
    const fecha = new Date(createdTime);
    
    // Obtiene solo la fecha en formato 'dd/MM/yyyy'
    return formatDate(fecha, 'dd/MM/yyyy', 'en-US');

  }

}
