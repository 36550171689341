import { Component, OnInit, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-popup-product-success",
  templateUrl: "./popup-product-success.component.html",
  styleUrls: ["./popup-product-success.component.scss"],
})
export class PopupProductSuccessComponent implements OnInit {
  isModalOpen: boolean = true;
  @Output() onClose = new EventEmitter<void>();
  constructor() {}

  ngOnInit(): void {}
  close(): void {
    this.isModalOpen = false;
    this.onClose.emit();
  }
}
