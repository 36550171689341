<app-breadcrumb [title]="''" [items]="['Customer']" [active_item]="'Listado de campos adicionales'">
</app-breadcrumb>
<div *ngIf="customer_field_id > 0; then edit_customer_fields else pick_customer_fields"></div>
<ng-template #pick_customer_fields>
    <div class="pick_customer_fields">
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <h5>Lista de campos adicionales</h5>
                        <button (click)="agregar_customer_fields()" class="btn btn-primary m-t-10">Agregar +</button>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-concesionarios">
                            <thead>
                                <tr>
                                    <th scope="col">Nombre</th>
                                    <th scope="col">Tipo</th>
                                    <th scope="col">Estado</th>
                                    <th scope="col">Editar</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let cusfi of customer_fields" class="tr-mobile">
                                    <td>
                                        <div class="td-mobile">Nombre</div><b>{{cusfi.name}}</b>
                                    </td>
                                    <td>
                                        <div class="td-mobile">Tipo</div>{{cusfi.type}}
                                    </td>

                                    <td>
                                        <div class="td-mobile">Estado</div>
                                        <span class="badge badge-success" *ngIf="cusfi.status == 1">
                                           Activo
                                        </span>
                                        <span class="badge badge-primary" *ngIf="cusfi.status == 0">
                                            Inactivo
                                         </span>
                                    </td>
                                    <td class="text-center">
                                        <div class="td-mobile">Editar</div><a [routerLink]="[cusfi.id]"><i class="fa fa-edit"></i></a>
                                    </td>
                                
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #edit_customer_fields>

</ng-template>
