import { Component, OnInit } from '@angular/core';
import { ProductsService } from '../products.service';
import Swal from 'sweetalert2';  // Importa SweetAlert
import { ActivatedRoute, Router} from '@angular/router';
import { elementAt } from 'rxjs';

@Component({
  selector: 'app-create-single-product',
  templateUrl: './create-single-product.component.html',
  styleUrls: ['./create-single-product.component.scss']
})
export class CreateSingleProductComponent implements OnInit {

  constructor(private ProductsService: ProductsService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  jwt: string;
  categorias = [];
  size = [];
  tipo = [];
  is_loading = false;
  attributes = [];

  producto_a_crear = {
    nombre: '',
    precio: 0,
    descripcion: '',
    categoria: null,
    tipo: null,
    size: null,
    stock: 0,
    imagen: null,
    estado: 'activo',
    extras_azucar: [],
    extras: [],
    variables: [],
    qty_extras: 0,
    extra_requerido: 0,
    azucar_requerido: 0

  };

  actualizarAzucarRequerido() {
    this.producto_a_crear.azucar_requerido = this.producto_a_crear.azucar_requerido == 1 ? 0 : 1;
    console.log(this.producto_a_crear.azucar_requerido);
  }


  actualizarRequerido() {
      this.producto_a_crear.extra_requerido = this.producto_a_crear.extra_requerido == 1 ? 0 : 1;
      console.log(this.producto_a_crear.extra_requerido);
  }

  tienda_selected

  ngOnInit(): void {

    this.tienda_selected = JSON.parse(localStorage.tienda_selected);

    if (!this.tienda_selected) {
      this.router.navigate(['/']);
    }

    this.init_service();
  }

  atributosExtra: any[]; // Lista para almacenar selects adicionales

  async init_service() {
    this.jwt = JSON.parse(localStorage.user).jwt;
    const res = <any>await this.ProductsService.lead_service_init_general(this.jwt);
    if (res.success) {
      this.categorias = res.payload.categorias;
      this.tipo = res.payload.tipos;
      this.size = res.payload.sizes;

      // EXTRAS 

      this.extras = res.payload.extras;
      this.extras_azucar = res.payload.extras_azucar;

      this.attributes = res.payload.attributes;

      this.atributosExtra =  [{ selectedId: null, opciones: this.attributes, agrego_extra: false  }];
    }
  }



  imagePreview: string | ArrayBuffer | null = null;  // Default preview variable



  selectedFile: File | null = null;  
  clearImage(): void {
    this.selectedFile = null;
    this.imagePreview = null;

    // Limpiar el valor del input de archivo
    const fileInput = document.getElementById('imagenProducto') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
  }



  onFileSelected(event: any) {
      const file = event.target.files[0];

      if (!file) {
          return;
      }

      const maxSizeInMB = 2;
      const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

      if (file.size > maxSizeInBytes) {
          Swal.fire('Archivo muy grande', `El archivo debe ser menor a ${maxSizeInMB}MB`, 'error');
          return;
      }

      const validTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];
      if (!validTypes.includes(file.type)) {
          Swal.fire('Tipo de archivo no permitido', 'Solo se permiten imágenes (PNG, JPG, GIF)', 'error');
          return;
      }

      const reader = new FileReader();
      reader.onload = () => {
          this.imagePreview = reader.result;
      };
      reader.readAsDataURL(file);

      this.producto_a_crear.imagen = file;
  }


  onFileSelectedVariation(event: any, item: any) {
    const file = event.target.files[0];

    if (!file) {
        return;
    }

    const maxSizeInMB = 2;
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

    if (file.size > maxSizeInBytes) {
        Swal.fire('Archivo muy grande', `El archivo debe ser menor a ${maxSizeInMB}MB`, 'error');
        return;
    }

    const validTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];
    if (!validTypes.includes(file.type)) {
        Swal.fire('Tipo de archivo no permitido', 'Solo se permiten imágenes (PNG, JPG, GIF)', 'error');
        return;
    }

    const reader = new FileReader();
    reader.onload = () => {
        item.imagePreview = reader.result as string; // Asigna la imagen al preview de la variación
    };
    reader.readAsDataURL(file);

    console.log(file);

    item.imagen = file; // Asigna la imagen al campo `imagen` de la variación
}


  validateFields() {
    // Validación del nombre
    if (!this.producto_a_crear.nombre) {
      Swal.fire('Error', 'El nombre es obligatorio.', 'error');
      return false;
    }

    // Validación de la categoría
    if (!this.producto_a_crear.categoria) {
      Swal.fire('Error', 'Debe seleccionar una categoría.', 'error');
      return false;
    }

    // Validación del tipo
    if (!this.producto_a_crear.tipo) {
      Swal.fire('Error', 'Debe seleccionar un tipo.', 'error');
      return false;
    }

    // Validación del tamaño
    if (this.producto_a_crear.tipo == 1) {
      if (!this.producto_a_crear.size) {
        Swal.fire('Error', 'Debe seleccionar un tamaño.', 'error');
        return false;
      }
    }





    return true;
  }

  onSubmit() {
    if (this.validateFields()) {
      this.is_loading = true;
      // Implementar la lógica para agregar el producto
      console.log(this.producto_a_crear);

      this.generateProducts()

      this.is_loading = false;
    }
  }
  


  validarStock(event: any) {
    let value = parseInt(event.target.value, 10);
    
    if (isNaN(value)) {
        this.producto_a_crear.stock = 0;
    } else if (value < 0) {
        this.producto_a_crear.stock = 0;
    } else {
        this.producto_a_crear.stock = value;
    }
  
    event.target.value = this.producto_a_crear.stock;
  }



  validarExtras(event: any) {
    let value = parseInt(event.target.value, 10);
    
    if (isNaN(value) || value < 0) {
        this.producto_a_crear.qty_extras = 0;
    } else if (value > 2) {
        this.producto_a_crear.qty_extras = 2;
    } else {
        this.producto_a_crear.qty_extras = value;
    }
  
    event.target.value = this.producto_a_crear.qty_extras;
}

  
  

  async generateProducts() {

    const data = new FormData();
    data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
    data.append('nombre', this.producto_a_crear.nombre);
    data.append('descripcion', this.producto_a_crear.descripcion);
    data.append('categoria', this.producto_a_crear.categoria);
    data.append('tipo', this.producto_a_crear.tipo);
    data.append('size', this.producto_a_crear.size);
    data.append('precio', this.producto_a_crear.precio.toString());
    data.append('stock', this.producto_a_crear.stock.toString());
    data.append('tienda_id',this.tienda_selected);
    data.append('estado', this.producto_a_crear.estado);


    
    data.append('extras',  JSON.stringify(this.producto_a_crear.extras));
    data.append('extras_azucar',  JSON.stringify(this.producto_a_crear.extras_azucar));
    data.append('extra_requerido',this.producto_a_crear.extra_requerido.toString());
    data.append('azucar_requerido',this.producto_a_crear.azucar_requerido.toLocaleString());

    // VARIABLES


    const variablesWithoutFiles = this.producto_a_crear.variables.map(item => {
      const { imagen, imagePreview, ...rest } = item; // Excluye ambas propiedades
      return rest;
    });
    
    // Ahora puedes usar variablesWithoutFiles
    data.append('variables', JSON.stringify(variablesWithoutFiles));

    this.producto_a_crear.variables.forEach((item, index) => {
        if (item.imagen) {
            data.append(`variable_image_${index}`, item.imagen);
        }
    });


    data.append('qty_extras', this.producto_a_crear.qty_extras.toString());

    if (this.producto_a_crear.imagen) {
        data.append('imagen', this.producto_a_crear.imagen);
    }
    
   const res = <any>await this.ProductsService.generateProduct(data)

   if (res.success) {

      Swal.fire({
          title: '¡Producto Creado!',
          text: 'El producto se ha creado exitosamente y ahora está disponible en tu inventario.',
          icon: 'success',
          confirmButtonText: 'Perfecto',
          timer: 3000
      });

      if (res.id_product) {
        const route_link = '/details-product/'+ res.id_product;
        this.router.navigate([route_link]); // Redirige a otra ruta
      }

      
  } else {
      Swal.fire({
          title: 'Error al Crear Producto',
          text: 'Hubo un problema al intentar crear el producto. Por favor, revisa los datos ingresados o intenta nuevamente más tarde.',
          icon: 'error',
          confirmButtonText: 'Entendido',
          timer: 3000
      });
  }



  }

  



  onCancel() {
    Swal.fire('Cancelado', 'La creación del producto ha sido cancelada.', 'info');
    this.router.navigate(['/products-list'])

  }


 extras = [];
 extras_azucar = [];

 selectExtraId: number | null = null; // Producto seleccionado
 precioExtra: number = 0; // Inicialización


 selectExtraAzucarId: number | null = null; // Producto seleccionado
 precioAzucarExtra: number = 0; // Inicialización


 agregarExtraAzucar() {
  console.log(this.selectExtraAzucarId);
  console.log(this.precioAzucarExtra);

  if (this.producto_a_crear.extras_azucar.length < 10) {
    if (this.selectExtraAzucarId) {

      const extra = this.extras_azucar.find(p => p.id == this.selectExtraAzucarId);
      console.log(extra);

      if (extra) {

        const productoExistente = this.producto_a_crear.extras_azucar.find(p => p.extra.id == extra.id);

        if (productoExistente) {
          productoExistente.precio = this.precioAzucarExtra; // Si ya existe, aumentamos la cantidad
        } else {
          this.producto_a_crear.extras_azucar.push({ extra, precio: this.precioAzucarExtra });
        }

        this.selectExtraAzucarId = null;
        this.precioAzucarExtra = 0;
      }
    }

    console.log(this.producto_a_crear.extras_azucar);

  } 
}

quitarProductoAzucar(extraID: number) {
  this.producto_a_crear.extras_azucar = this.producto_a_crear.extras_azucar.filter(p => p.extra.id !== extraID);
}


 agregarExtra() {
  if (this.producto_a_crear.extras.length < 10) {
    if (this.selectExtraId) {
      const extra = this.extras.find(p => p.id == this.selectExtraId);

      if (extra) {

        const productoExistente = this.producto_a_crear.extras.find(p => p.extra.id == extra.id);

        if (productoExistente) {
          productoExistente.precio = this.precioExtra; // Si ya existe, aumentamos la cantidad
        } else {
          this.producto_a_crear.extras.push({ extra, precio: this.precioExtra });
        }

        this.selectExtraId = null;
        this.precioExtra = 0;
      }
    }
  } 
}

quitarProducto(extraID: number) {
  this.producto_a_crear.extras = this.producto_a_crear.extras.filter(p => p.extra.id !== extraID);
}

/* VARIACIONES */

selectAttributos = [];
atributo_no_tomar = [];

eliminarVariaciones(i) {
  var dato = this.atributosExtra[i];

  this.atributo_no_tomar.splice(dato.selectedId, 1);
  
  this.atributosExtra.splice(i, 1);
  this.atributosExtra[i-1].agrego_extra = false;

}


agregarAtributo(i) {


  this.atributosExtra[i].agrego_extra = true;

  this.atributosExtra.forEach(element => {
    if (!this.atributo_no_tomar.includes(element.selectedId)) {
      this.atributo_no_tomar.push(element.selectedId); 
    }
  });
  
  
  const atributosFiltrados = this.attributes.filter(atributo => 
    !this.atributo_no_tomar.includes(atributo.id)
  ); 

  this.atributosExtra.push({ selectedId: null, opciones: atributosFiltrados, agrego_extra: false});


}

attributesAll = []; // Cambiado a un arreglo

seleccionarAtributo(id, index) {
  // Limpiar la selección previa
  this.selectAttributos[id] = []; 

  const selectedAttribute = this.attributes.find(attr => attr.id === id);
  const opciones = selectedAttribute ? selectedAttribute.opciones : [];

  if (opciones.length) {
      // Verificar si ya existe un índice con este id
      const existingIndex = Object.values(this.selectAttributos).find(attr => attr.index === index && attr.id !== id);

      // Si existe, reemplazarlo
      if (existingIndex) {
          const existingId = existingIndex.id;
          delete this.selectAttributos[existingId];  // Eliminar el atributo anterior con el mismo índice
      }

      // Agregar el nuevo atributo
      this.selectAttributos[id] = {
          index: index,
          id: id,
          seleccionados: [],
      }; 

      // Preparar las opciones para mostrar
      if (!this.attributesAll[id]) {
          this.attributesAll[id] = { opciones: [] }; 
      }
      this.attributesAll[id].opciones = ['Seleccionar Todo', ...opciones];
  }

  console.log(this.attributesAll);
  console.log(this.selectAttributos);
}





isItemSelected(item: any, selectedId: any): boolean {


  if (item !== 'Seleccionar Todo') {
    if (this.selectAttributos[selectedId].seleccionados) {
      return this.selectAttributos[selectedId].seleccionados.includes(item);

    } else {
      return false;
    }
  }
}

toggleSelect(item: any, isSelected: boolean, selectedId: any): void {

  
  if (item === 'Seleccionar Todo') {
    if (isSelected) {
      this.selectAttributos[selectedId].seleccionados = this.attributesAll[selectedId].opciones.filter(option => option !== 'Seleccionar Todo');
    } else {
      this.selectAttributos[selectedId].seleccionados = [];
    }
  } else {
    if (isSelected) {
      this.selectAttributos[selectedId].seleccionados = [...new Set([...this.selectAttributos[selectedId].seleccionados, item])];
    } else {
      this.selectAttributos[selectedId].seleccionados = this.selectAttributos[selectedId].seleccionados.filter(selectedItem => selectedItem !== item);
    }
  }

  const allSelected = this.attributesAll[selectedId].opciones
    .filter(option => option !== 'Seleccionar Todo')
    .every(option => this.selectAttributos[selectedId].seleccionados.includes(option));

  if (!allSelected) {
    // Si no todas las opciones están seleccionadas, asegurarse de que "Seleccionar Todo" no esté marcado
    const selectAllCheckbox = document.getElementById(`item-${this.attributesAll[selectedId].opciones.indexOf('Seleccionar Todo')}`) as HTMLInputElement;
    if (selectAllCheckbox) {
      selectAllCheckbox.checked = false;
    }
  }

}




// Función para abrir/cerrar acordeón
toggleAcordeon(index: number) {
  this.producto_a_crear.variables[index].abierto = !this.producto_a_crear.variables[index].abierto;
}

 
generateUniqueCode(): string {
  return crypto.randomUUID();
}


productos_variables = [];
attributoID: number | null = null; // Producto seleccionado

quitarVariation(id: number) {
  this.producto_a_crear.variables = this.producto_a_crear.variables.filter(p => p.id !== id);
}



agregarVariaciones() {

  if (this.selectAttributos.length > 0) {

    var atributos_object = this.selectAttributos;
    const atributos = atributos_object.map(arr => {
        if (arr.seleccionados.length > 0) {
            return arr; 
        } else {
            return []; 
        }
    }).filter(arr => arr.seleccionados.length > 0);


    const combinaciones = this.generarCombinacionesIds(atributos);
  
    combinaciones.forEach(combinacion => {
  
      var names = [];
      combinacion.forEach(valor => {
        names.push(valor.valor);       
      });
  
      this.producto_a_crear.variables.push({
        id: this.generateUniqueCode(),
        attributes: combinacion,
        name: names.join(' - '),
        price: 0,
        stock: 0,
        description: "",
        product_category_id: null,
        product_type: 3,
        photo: null
      });
    });
  
    this.selectAttributos = [];
    this.attributoID = null;
    this.attributesAll = [];
    this.atributosExtra = [{ selectedId: null, opciones: this.attributes, agrego_extra: false}];
    this.atributo_no_tomar = [];
  }



}

generarCombinacionesIds(atributos) {
  console.log(atributos);
  const resultados = [];


  const backtrack = (combinacion, inicio) => {
    if (combinacion.length === atributos.length) {
      resultados.push([...combinacion]);
      return;
    }

    for (let i = inicio; i < atributos.length; i++) {
      
      var atributo_actual = atributos[i].seleccionados;
      var atributo_id = atributos[i].id;

      for (const atributo of atributo_actual) {

        const key = this.selectAttributos[atributo_id].id;

        var atributosFiltrados = this.attributes.filter(valor => valor.id == key);
        console.log(atributosFiltrados);
        var atributo_padre_name = atributosFiltrados[0].name;

        combinacion.push({ valor: atributo, atributo_padre_name: atributo_padre_name, atributo_padre_key: key });

        backtrack(combinacion, i + 1);
        combinacion.pop();
      }
    }
  };

  backtrack([], 0);

  return resultados;
}




}
