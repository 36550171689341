import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, lastValueFrom } from 'rxjs';
import { HttpClient } from "@angular/common/http";
@Component({
  selector: 'app-modal-preview-store',
  templateUrl: './modal-preview-store.component.html',
  styleUrls: ['./modal-preview-store.component.scss']
})
export class ModalPreviewStoreComponent implements OnInit {

  jwt
  is_loading = false
  store_id
  store = {
    'name': '',
    'RUT': '',
    'correo': '',
    'adress': '',
    'representante_legal':'',
    'created_time': '',
    'cantidad_clientes': '',
    'ultima_compra': '',
    'trx_por_mes':'',
    'ticket_promedio':''
  };

  currency_selected
  currency_symbol_selected
  
  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private activeModalService: NgbActiveModal
  ) {
    this.jwt = JSON.parse(localStorage.user).jwt
    this.store_id = JSON.parse(localStorage.user).store_id
  }

  async ngOnInit(){
    this.currency_selected = localStorage.getItem('currency_selected')
    this.currency_symbol_selected = localStorage.getItem('currency_symbol_selected')

    const data = new FormData()
    data.append('jwt', this.jwt)
    data.append('store_id',  JSON.stringify(this.store_id))

    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}ClientAPI/get_detail_by_store_id`, data))
   console.log(response)
    if (response.success) {
      this.store = response.store
      this.store.cantidad_clientes = response.cantidad_clientes.clientes
      this.store.ultima_compra = response.ultima_compra == null ? '-' :response.ultima_compra['created_time']
      this.store.trx_por_mes = response.trx_por_mes.total
      this.store.ticket_promedio = response.ticket_promedio.total == null ? 0 :  response.ticket_promedio.total
      console.log(this.store)

    }
  }

  editarDatos(){
    this.activeModalService.close();
    const route_link = '/create-store/'+ this.store_id;
    this.router.navigate([route_link]);
  }
}
