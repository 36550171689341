import { Component, OnInit} from '@angular/core';
import { ProductsService } from '../products.service';
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, Router} from '@angular/router';
import { Subscription, lastValueFrom, timer } from 'rxjs';

import Swal from 'sweetalert2';  // Importa SweetAlert

@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.scss']
})

export class EditProductComponent implements OnInit {

  private routeSub: Subscription;
  jwt: string;
  categorias = [];
  size = [];
  tipo = [];
  stores = [];
  plataformas = [];
  is_loading = false;
  attributes = [];

  constructor(
    private ProductsService: ProductsService,
    private route: ActivatedRoute,
    private http: HttpClient,
    private router: Router
  ) { }



  producto_detail = {
    nombre: '',
    precio: 0,
    descripcion: '',
    categoria: null,
    tipo: null,
    size: null,
    stock: 0,
    imagen: null,
    estado: 'activo',
    qty_extras: 0,
    variables: [],
    extras: [],
    extras_azucar: [],
    stores_selected: [],
    plataformas_selected: [],

    extra_requerido: 0,
    azucar_requerido: 0

  };


  producto_id;
  tienda_selected;

  ngOnInit(): void {

    // this.tienda_selected = JSON.parse(localStorage.tienda_selected);

    // if (!this.tienda_selected) {
    //   this.router.navigate(['/']);
    // }


    this.routeSub = this.route.params.subscribe(parametros => {

      if (isNaN(parametros.id) || parametros.id <= 0) { // Verifica si no es un número válido
        this.router.navigate(['/products-list']); // Redirige a otra ruta
      }

      this.producto_id = parametros.id

      this.init_producto_by_id(this.producto_id);

    })


    this.init_service();
    this.load_product_extras();

  }

  async openDeleteConfirmationModal() {

    if (this.producto_id) {
      Swal.fire({
        title: '¿Estás seguro?',
        text: 'Se eliminará completamente',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, eliminar',
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        if (result.isConfirmed) {
  
          const response = await this.ProductsService.eliminar_product(this.producto_id);
  
          if (response.success) {
  
            Swal.fire(
              'Eliminado!',
              'El producto ha sido eliminada.',
              'success'
            );
  
            this.router.navigate(['/products-list']); 
  
          }
  
        }
      });
    }
 
    
  }


  actualizarAzucarRequerido() {
      this.producto_detail.azucar_requerido = this.producto_detail.azucar_requerido == 1 ? 0 : 1;
      console.log(this.producto_detail.azucar_requerido);
  }


  actualizarRequerido() {
      this.producto_detail.extra_requerido = this.producto_detail.extra_requerido == 1 ? 0 : 1;
      console.log(this.producto_detail.extra_requerido);
  }


  async init_producto_by_id(id) {

    const res = <any>await this.ProductsService.get_product_by_id(id);
    console.log(res);
    console.log('asdasasd')
    if (res.success) {

      this.producto_detail = {
        nombre: res.product.name,
        precio: res.product.price,
        descripcion: res.product.description,
        categoria: res.product.product_category_id,
        tipo: res.product.product_type,
        size: res.product.product_size_id,
        stock: res.product.stock,
        imagen: null,
        qty_extras: res.product.qty_extras,
        estado: res.product.available == 1 ? 'activo' : 'desactivado',
        variables: res.product.variables,

        extras: res.product.extras ? res.product.extras : [] ,
        extras_azucar: res.product.extras_azucar ?res.product.extras_azucar  : [] ,
        extra_requerido: res.product.extras_required,
        azucar_requerido: res.product.azucar_required,
        stores_selected:res.product.stores_selected ? res.product.stores_selected : [] ,
        plataformas_selected:res.product.plataformas_selected ? res.product.plataformas_selected : [] ,

      };


      
      console.log(this.producto_detail);

      this.imagePreview = res.product.photo;

    } else {
      this.router.navigate(['/products-list']); 
    }
  }

  atributosExtra: any[]; 


  async init_service() {
    this.jwt = JSON.parse(localStorage.user).jwt;
    const res = <any>await this.ProductsService.lead_service_init_general(this.jwt);

    if (res.success) {
      this.categorias = res.payload.categorias;
      this.tipo = res.payload.tipos;
      this.size = res.payload.sizes;
      this.stores = res.payload.stores;
      this.plataformas = res.payload.plataformas;

      this.extras = res.payload.extras;
      this.extras_azucar = res.payload.extras_azucar;
      
      this.attributes = res.payload.attributes;
      this.atributosExtra =  [{ selectedId: null, opciones: this.attributes, agrego_extra: false  }];

    }
  }

  imagePreview: string | ArrayBuffer | null = null;  // Default preview variable
  elimino_imagen: boolean = false;
  selectedFile: File | null = null;  


  clearImage(): void {
    this.elimino_imagen = true;

    this.selectedFile = null;
    this.imagePreview = null;

    // Limpiar el valor del input de archivo
    const fileInput = document.getElementById('imagenProducto') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
  }

  onFileSelected(event: any) {
      const file = event.target.files[0];

      if (!file) {
          return;
      }

      const maxSizeInMB = 2;
      const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

      if (file.size > maxSizeInBytes) {
          Swal.fire('Archivo muy grande', `El archivo debe ser menor a ${maxSizeInMB}MB`, 'error');
          return;
      }

      const validTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];
      if (!validTypes.includes(file.type)) {
          Swal.fire('Tipo de archivo no permitido', 'Solo se permiten imágenes (PNG, JPG, GIF)', 'error');
          return;
      }

      const reader = new FileReader();
      reader.onload = () => {
          this.imagePreview = reader.result;
      };
      reader.readAsDataURL(file);

      this.producto_detail.imagen = file;

  }


  validateFields() {
    // Validación del nombre
    if (!this.producto_detail.nombre) {
      Swal.fire('Error', 'El nombre es obligatorio.', 'error');
      return false;
    }

    // Validación de la categoría
    if (!this.producto_detail.categoria) {
      Swal.fire('Error', 'Debe seleccionar una categoría.', 'error');
      return false;
    }

    // Validación del tipo
    if (!this.producto_detail.tipo) {
      Swal.fire('Error', 'Debe seleccionar un tipo.', 'error');
      return false;
    }

    // Validación del tamaño
    if (this.producto_detail.tipo == 1) {
      if (!this.producto_detail.size) {
        Swal.fire('Error', 'Debe seleccionar un tamaño.', 'error');
        return false;
      }
    }
    


    return true;
  }

  validarStock(event: any) {
    let value = parseInt(event.target.value, 10);
    
    if (isNaN(value)) {
        this.producto_detail.stock = 0;
    } else if (value < 0) {
        this.producto_detail.stock = 0;
    } else {
        this.producto_detail.stock = value;
    }
  
    event.target.value = this.producto_detail.stock;
  }

  validarExtras(event: any) {
    let value = parseInt(event.target.value, 10);
    
    if (isNaN(value) || value < 0) {
        this.producto_detail.qty_extras = 0;
    } else if (value > 2) {
        this.producto_detail.qty_extras = 2;
    } else {
        this.producto_detail.qty_extras = value;
    }
  
    event.target.value = this.producto_detail.qty_extras;
}

  

  onSubmit() {
    if (this.validateFields()) {
      this.is_loading = true;
      // Implementar la lógica para agregar el producto

      this.editProduct()

      this.is_loading = false;
    }
  }

  async editProduct() {

    const data = new FormData();
    data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
    data.append('producto_id', this.producto_id);
    data.append('nombre', this.producto_detail.nombre);
    data.append('descripcion', this.producto_detail.descripcion);
    data.append('categoria', this.producto_detail.categoria);
    data.append('tipo', this.producto_detail.tipo);
    data.append('size', this.producto_detail.size);
    data.append('precio', this.producto_detail.precio.toString());
    data.append('stock', this.producto_detail.stock.toString());
    data.append('estado', this.producto_detail.estado);
   
    data.append('elimino_imagen', this.elimino_imagen ? 'si' : 'no');

     //TIENDAS
     data.append('tiendas',  JSON.stringify(this.producto_detail.stores_selected));

     //PLATAFORMAS
     data.append('plataformas',  JSON.stringify(this.producto_detail.plataformas_selected));

     
    data.append('extras',  JSON.stringify(this.producto_detail.extras));
    data.append('extras_azucar',  JSON.stringify(this.producto_detail.extras_azucar));

    data.append('extra_requerido',this.producto_detail.extra_requerido.toString());
    data.append('azucar_requerido',this.producto_detail.azucar_requerido.toString());

    data.append('qty_extras', this.producto_detail.qty_extras.toString());

     // VARIABLES
     const variablesWithoutFiles = this.producto_detail.variables.map(item => {
      const { imagen, imagePreview, ...rest } = item; // Excluye ambas propiedades
      return rest;
    });
    
    // Ahora puedes usar variablesWithoutFiles
    data.append('variables', JSON.stringify(variablesWithoutFiles));
    
    this.producto_detail.variables.forEach((item, index) => {

      if (item.cambio_imagen) {
        if (item.imagen) {
            data.append(`variable_image_${index}`, item.imagen);
        }
      }
        
    });

    if (this.producto_detail.imagen) {
        data.append('imagen', this.producto_detail.imagen);
    }
    
   const res = <any>await this.ProductsService.editProduct(data)

   if (res.success) {
    Swal.fire({
        title: '¡Producto Actualizado!',
        text: 'El producto se ha actualizado exitosamente.',
        icon: 'success',
        confirmButtonText: 'Perfecto',
        timer: 3000
    });
  } else {
    Swal.fire({
        title: 'Error al Actualizar Producto',
        text: 'Hubo un problema al intentar actualizar el producto',
        icon: 'error',
        confirmButtonText: 'Entendido',
        timer: 3000
    });
  }
  



  }



  onCancel() {
    // Lógica para cancelar la operación
    console.log('Operación cancelada');
    Swal.fire('Cancelado', 'La Edición del producto ha sido cancelada.', 'info');
    this.router.navigate(['/products-list'])

  }


 extras = [];
 extras_azucar = [];
 selectExtraId: number | null = null; // Producto seleccionado
 precioExtra: number = 0; // Inicialización


 async load_product_extras() {
   const res = <any>await this.ProductsService.get_products_extras();

   if(res.success){
     this.extras = res.extras;
   }

 }


 selectExtraAzucarId: number | null = null; // Producto seleccionado
 precioAzucarExtra: number = 0; // Inicialización

 agregarExtraAzucar() {
 
  if (this.producto_detail.extras_azucar.length < 10) {
    if (this.selectExtraAzucarId) {

      const extra = this.extras_azucar.find(p => p.id == this.selectExtraAzucarId);
      console.log(extra);

      if (extra) {

        const productoExistente = this.producto_detail.extras_azucar.find(p => p.extra.id == extra.id);

        if (productoExistente) {
          productoExistente.precio = this.precioAzucarExtra; // Si ya existe, aumentamos la cantidad
        } else {
          this.producto_detail.extras_azucar.push({ extra, precio: this.precioAzucarExtra });
        }

        this.selectExtraAzucarId = null;
        this.precioAzucarExtra = 0;
      }
    }

    console.log(this.producto_detail.extras_azucar);

  } 
}

quitarProductoAzucar(extraID: number) {
  this.producto_detail.extras_azucar = this.producto_detail.extras_azucar.filter(p => p.extra.id !== extraID);
}

 agregarExtra() {
  // Verificamos si ya tenemos menos de 2 elementos en `extras`
  if (this.producto_detail.extras.length < 10) {

    if (this.selectExtraId ) {

      const extra = this.extras.find(p => p.id == this.selectExtraId);


      if (extra) {
        const productoExistente = this.producto_detail.extras.find(p => p.extra.id == extra.id);

        console.log(productoExistente);


        if (productoExistente) {
          productoExistente.precio = this.precioExtra; // Si ya existe, aumentamos la cantidad
        } else {
          this.producto_detail.extras.push({ extra, precio: this.precioExtra });
        }

        this.selectExtraId = null;
        this.precioExtra = 0;
      }
    }
  } else {
    console.log('No se pueden agregar más de 2 elementos en extras');
  }
}

quitarProducto(extraID: number) {
  this.producto_detail.extras = this.producto_detail.extras.filter(p => p.extra.id !== extraID);
}


attributesAll = [];
selectAttributos = [];
attributoID: number | null = null; // Producto seleccionado
productos_variables = [];

isItemSelected(item: any, selectedId: any): boolean {


  if (item !== 'Seleccionar Todo') {
    if (this.selectAttributos[selectedId].seleccionados) {
      return this.selectAttributos[selectedId].seleccionados.includes(item);

    } else {
      return false;
    }
  }
}


seleccionarAtributo(id, index) {
  // Limpiar la selección previa
  this.selectAttributos[id] = []; 

  const selectedAttribute = this.attributes.find(attr => attr.id === id);
  const opciones = selectedAttribute ? selectedAttribute.opciones : [];

  if (opciones.length) {
      // Verificar si ya existe un índice con este id
      const existingIndex = Object.values(this.selectAttributos).find(attr => attr.index === index && attr.id !== id);

      // Si existe, reemplazarlo
      if (existingIndex) {
          const existingId = existingIndex.id;
          delete this.selectAttributos[existingId];  // Eliminar el atributo anterior con el mismo índice
      }

      // Agregar el nuevo atributo
      this.selectAttributos[id] = {
          index: index,
          id: id,
          seleccionados: [],
      }; 

      // Preparar las opciones para mostrar
      if (!this.attributesAll[id]) {
          this.attributesAll[id] = { opciones: [] }; 
      }
      this.attributesAll[id].opciones = ['Seleccionar Todo', ...opciones];
  }

  console.log(this.attributesAll);
  console.log(this.selectAttributos);
}


toggleSelect(item: any, isSelected: boolean, selectedId: any): void {

  
  if (item === 'Seleccionar Todo') {
    if (isSelected) {
      this.selectAttributos[selectedId].seleccionados = this.attributesAll[selectedId].opciones.filter(option => option !== 'Seleccionar Todo');
    } else {
      this.selectAttributos[selectedId].seleccionados = [];
    }
  } else {
    if (isSelected) {
      this.selectAttributos[selectedId].seleccionados = [...new Set([...this.selectAttributos[selectedId].seleccionados, item])];
    } else {
      this.selectAttributos[selectedId].seleccionados = this.selectAttributos[selectedId].seleccionados.filter(selectedItem => selectedItem !== item);
    }
  }

  const allSelected = this.attributesAll[selectedId].opciones
    .filter(option => option !== 'Seleccionar Todo')
    .every(option => this.selectAttributos[selectedId].seleccionados.includes(option));

  if (!allSelected) {
    // Si no todas las opciones están seleccionadas, asegurarse de que "Seleccionar Todo" no esté marcado
    const selectAllCheckbox = document.getElementById(`item-${this.attributesAll[selectedId].opciones.indexOf('Seleccionar Todo')}`) as HTMLInputElement;
    if (selectAllCheckbox) {
      selectAllCheckbox.checked = false;
    }
  }

}



// Función para abrir/cerrar acordeón
toggleAcordeon(index: number) {
  this.producto_detail.variables[index].abierto = !this.producto_detail.variables[index].abierto;
}

   
generateUniqueCode(): string {
  return crypto.randomUUID();
}

agregarVariaciones() {

  if (this.selectAttributos.length > 0) {

    var atributos_object = this.selectAttributos;
    const atributos = atributos_object.map(arr => {
        if (arr.seleccionados.length > 0) {
            return arr; 
        } else {
            return []; 
        }
    }).filter(arr => arr.seleccionados.length > 0);


    const combinaciones = this.generarCombinacionesIds(atributos);
  
    combinaciones.forEach(combinacion => {
  
      var names = [];
      combinacion.forEach(valor => {
        names.push(valor.valor);       
      });
  
      this.producto_detail.variables.push({
        id: this.generateUniqueCode(),
        attributes: combinacion,
        name: names.join(' - '),
        price: 0,
        stock: 0,
        description: "",
        product_category_id: null,
        product_type: 3,
        photo: null
      });
    });
  
    this.selectAttributos = [];
    this.attributoID = null;
    this.attributesAll = [];
    this.atributosExtra = [{ selectedId: null, opciones: this.attributes, agrego_extra: false}];
    this.atributo_no_tomar = [];
  }



}

generarCombinacionesIds(atributos) {
  console.log(atributos);
  const resultados = [];


  const backtrack = (combinacion, inicio) => {
    if (combinacion.length === atributos.length) {
      resultados.push([...combinacion]);
      return;
    }

    for (let i = inicio; i < atributos.length; i++) {
      
      var atributo_actual = atributos[i].seleccionados;
      var atributo_id = atributos[i].id;

      for (const atributo of atributo_actual) {

        const key = this.selectAttributos[atributo_id].id;

        var atributosFiltrados = this.attributes.filter(valor => valor.id == key);
        console.log(atributosFiltrados);
        var atributo_padre_name = atributosFiltrados[0].name;

        combinacion.push({ valor: atributo, atributo_padre_name: atributo_padre_name, atributo_padre_key: key });

        backtrack(combinacion, i + 1);
        combinacion.pop();
      }
    }
  };

  backtrack([], 0);

  return resultados;
}






 quitarVariation(id: number) {
  this.producto_detail.variables = this.producto_detail.variables.filter(p => p.id !== id);
}


atributo_no_tomar = [];

eliminarVariaciones(i) {
  var dato = this.atributosExtra[i];

  this.atributo_no_tomar.splice(dato.selectedId, 1);
  
  this.atributosExtra.splice(i, 1);
  this.atributosExtra[i-1].agrego_extra = false;

}


agregarAtributo(i) {


  this.atributosExtra[i].agrego_extra = true;

  this.atributosExtra.forEach(element => {
    if (!this.atributo_no_tomar.includes(element.selectedId)) {
      this.atributo_no_tomar.push(element.selectedId); 
    }
  });
  
  
  const atributosFiltrados = this.attributes.filter(atributo => 
    !this.atributo_no_tomar.includes(atributo.id)
  ); 

  this.atributosExtra.push({ selectedId: null, opciones: atributosFiltrados, agrego_extra: false});


}


onFileSelectedVariation(event: any, item: any) {
  const file = event.target.files[0];

  if (!file) {
      return;
  }

  const maxSizeInMB = 2;
  const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

  if (file.size > maxSizeInBytes) {
      Swal.fire('Archivo muy grande', `El archivo debe ser menor a ${maxSizeInMB}MB`, 'error');
      return;
  }

  const validTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];
  if (!validTypes.includes(file.type)) {
      Swal.fire('Tipo de archivo no permitido', 'Solo se permiten imágenes (PNG, JPG, GIF)', 'error');
      return;
  }

  const reader = new FileReader();
  reader.onload = () => {
      item.imagePreview = reader.result as string; // Asigna la imagen al preview de la variación
  };
  reader.readAsDataURL(file);
  item.cambio_imagen = true; // Asigna la imagen al campo `imagen` de la variación
  item.imagen = file; // Asigna la imagen al campo `imagen` de la variación
}


plataformaSeleccionada(id: number): boolean {
  this.plataformas.forEach(app => {
    app.activo = this.producto_detail.plataformas_selected.some(p => p.plataforma_id === app.id);
  
  });
   return true;
}

storeSeleccionada(id: number): boolean {
  this.stores.forEach(store => {
    store.selected = this.producto_detail.stores_selected.some(p => p.store_id === store.id);
  
  });
   return true;
}


togglePlataforma(app: any) {
  if (app.activo) {
    this.producto_detail.plataformas_selected.push({ plataforma_id: app.id.toString() });
  } else {
    this.producto_detail.plataformas_selected = this.producto_detail.plataformas_selected.filter(p => p.plataforma_id !== app.id.toString());
  }
}

toggleStore(store: any) {
  if (store.selected) {
    this.producto_detail.stores_selected.push({ store_id: store.id.toString() });
  } else {
    this.producto_detail.stores_selected = this.producto_detail.stores_selected.filter(p => p.store_id !== store.id.toString());
  }
}


}
