import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { lastValueFrom } from 'rxjs'
import { environment } from '../environments/environment';
const Swal = require('sweetalert2')

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(private http: HttpClient, public router: Router) { 

    
  }

  async get_product_by_id( id) {

    const jwt = JSON.parse(localStorage.user).jwt;
    const tienda_selected = JSON.parse(localStorage.tienda_selected);

    const data = new FormData()
    data.append('jwt', jwt)
    data.append('product_id', id)
    data.append('tienda_selected', tienda_selected)

    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}Product/get_product_id_detail`, data))
    return response
  }

  async get_combo_by_id( id) {

    const jwt = JSON.parse(localStorage.user).jwt;
    const tienda_selected = JSON.parse(localStorage.tienda_selected);

    const data = new FormData()
    data.append('jwt', jwt)
    data.append('product_id', id)
    data.append('tienda_selected', tienda_selected)

    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}Product/get_combo_id_detail`, data))
    return response
  }

  async get_valores_atributos_by_id(id) {

    const jwt = JSON.parse(localStorage.user).jwt;

    const data = new FormData()
    data.append('jwt', jwt)
    data.append('atributo_id', id)

    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}Product/get_opciones_by_atributo_id`, data))
    return response;
  }


  async get_products_extras() {
    const data = new FormData()
    data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)

  
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}Product/get_product_extras`, data));
    return response
  }

  async get_products_filter(filtros) {
    const data = new FormData()
    data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
    data.append('filtros', JSON.stringify(filtros));
  
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}Product/get_products_by_filter`, data));
    return response
  }

  async get_products_filter_by_category(categoryID, filtros) {
    const data = new FormData()
    data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
    data.append('filtros', JSON.stringify(filtros));
    data.append('categoryID', categoryID);

    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}Product/get_products_category_by_filter`, data));
    return response
  }


  async get_combos_filter(filtros) {
    const data = new FormData()
    data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
    data.append('filtros', JSON.stringify(filtros));
  
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}Product/get_combos_by_filter`, data));
    return response
  }

  async lead_service_init_general(jwt) {

    console.log(jwt);
    const data = new FormData()
    data.append('jwt', jwt)
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}Product/get_payload_init_general`, data))
    return response
  }

  async generateProduct(data) {
  
    const tienda_selected = JSON.parse(localStorage.tienda_selected);
    data.append('tienda_selected', tienda_selected)

    const response = await lastValueFrom(this.http.post(`${environment.url_api}Product/generate_product`, data));

    return response
  }

  async generateCombo(data) {
    const tienda_selected = JSON.parse(localStorage.tienda_selected);
    data.append('tienda_selected', tienda_selected)
    const response = await lastValueFrom(this.http.post(`${environment.url_api}Product/generate_combo`, data));

    return response
  }

  async editProduct(data) {
    
    const response = await lastValueFrom(this.http.post(`${environment.url_api}Product/edit_product`, data));

    return response
  }

  async editCombo(data) {
    
    const response = await lastValueFrom(this.http.post(`${environment.url_api}Product/edit_combo`, data));

    return response
  }

  async eliminar_product( encuesta_id: any) {
    const data = new FormData()
    data.append('id', encuesta_id)
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}Product/EliminarProduct`, data))
    return response
  }
  
  async eliminar_products_seleccionados( select: any) {
    const data = new FormData()
    data.append('seleccionados', JSON.stringify(select));
  
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}Product/EliminarProductstSeleccionados`, data))
    return response
  }
  

  async eliminar_combo( encuesta_id: any) {
    const data = new FormData()
    data.append('id', encuesta_id)
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}Product/EliminarCombo`, data))
    return response
  }
  
  async eliminar_combos_seleccionados( select: any) {
    const data = new FormData()
    data.append('seleccionados', JSON.stringify(select));
  
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}Product/EliminarCombosSeleccionados`, data))
    return response
  }

}
