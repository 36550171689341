import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { DragulaModule } from "ng2-dragula";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SharedModule } from "./shared/shared.module";
import { AppRoutingModule } from "./app-routing.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ToastrModule } from "ngx-toastr";
import { NgSelectModule } from "@ng-select/ng-select";
// // for HttpClient import:
import { LoadingBarHttpClientModule } from "@ngx-loading-bar/http-client";
// // for Router import:
import { LoadingBarRouterModule } from "@ngx-loading-bar/router";
// // for Core import:
import { LoadingBarModule } from "@ngx-loading-bar/core";

// import { AuthService } from './shared/services/firebase/auth.service';
import { CampusService } from "./shared/services/campus.service";
import { LeadsService } from "./shared/services/leads.service";
import { AudienceService } from "./shared/services/audience.service";
import { EncuestaService } from "./shared/services/encuesta.service";
import { TemplateService } from "./shared/services/template.service";
import { CampaignService } from "./shared/services/campaign.service";

import { UsersService } from "./logueo/users.service";
import { AdminGuard } from "./shared/guard/admin.guard";
import { SecureInnerPagesGuard } from "./shared/guard/SecureInnerPagesGuard.guard";
import { CookieService } from "ngx-cookie-service";
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";

import { environment } from "../environments/environment";
import { AngularFirestoreModule } from "@angular/fire/compat/firestore";

/** FULL CALENDAR */
import { FullCalendarModule } from "@fullcalendar/angular";

import { AppComponent } from "./app.component";
import { LoginComponent } from "./auth/login/login.component";

import { OverlayModule } from "@angular/cdk/overlay";
import { LeadsComponent } from "./components/leads/leads.component";
import { SelectTiendaComponent } from "./components/select-tienda/select-tienda.component";

import { DataTablesModule } from "angular-datatables";
import { LeadDetalleComponent } from "./components/lead-detalle/lead-detalle.component";
import { ReportesComponent } from "./reportes/reportes.component";
import { AsesorSelectTiendaComponent } from "./asesor-select-tienda/asesor-select-tienda.component";
import { ChartModule } from "angular-highcharts";

import { NgxChartsModule } from "@swimlane/ngx-charts";
import { RegistrarPedidoComponent } from "./components/registrar-pedido/registrar-pedido.component";
import { ColaDeRequerimientoComponent } from "./components/cola-de-requerimiento/cola-de-requerimiento.component";
import { RegistrosComponent } from "./components/registros/registros.component";
import { LeadWhatsappComponent } from "./components/lead-whatsapp/lead-whatsapp.component";
import { LeadMailComponent } from "./components/lead-mail/lead-mail.component";
import { UsersComponent } from "./components/users/users.component";
import { UsersDescansoComponent } from "./components/users-descanso/users-descanso.component";
import { UsersActividadComponent } from "./components/users-actividad/users-actividad.component";
import { ConcesionariosComponent } from "./components/concesionarios/concesionarios.component";
import { DashboardSesionComponent } from "./components/dashboard-sesion/dashboard-sesion.component";
import { ModalAddConcesionarioComponent } from "./components/modales-generados/modal-add-concesionario/modal-add-concesionario.component";
import { VincularUsuarioConcesionarioComponent } from "./components/modales-generados/vincular-usuario-concesionario/vincular-usuario-concesionario.component";
import { SendCotizacionComponent } from "./components/modales-generados/send-cotizacion/send-cotizacion.component";

import { NgxDropzoneModule } from "ngx-dropzone";
import { LeadHistoryInfoComponent } from "./components/modales-generados/lead-history-info/lead-history-info.component";
import { CreateLeadComponent } from "./create-lead/create-lead.component";
import { ModalCreateLeadComponent } from "./modal-create-lead/modal-create-lead.component";
import { ModalCreateUserComponent } from "./modal-create-user/modal-create-user.component";
import { JefeSelectTiendaComponent } from "./jefe-select-tienda/jefe-select-tienda.component";
import { ChangeStoreComponent } from "./change-store/change-store.component";
import { ModalReasignarLeadComponent } from "./modal-reasignar-lead/modal-reasignar-lead.component";
import { ModalReasignarmasivoLeadComponent } from "./modal-reasignarmasivo-lead/modal-reasignarmasivo-lead.component";
import { DashboardUsuariosHoraActividadComponent } from "./dashboard-usuarios-hora-actividad/dashboard-usuarios-hora-actividad.component";
import { DashboardUsuariosDiasConexionComponent } from "./dashboard-usuarios-dias-conexion/dashboard-usuarios-dias-conexion.component";
import { DashboardHistorialUsuarioActividadesComponent } from "./dashboard-historial-usuario-actividades/dashboard-historial-usuario-actividades.component";
import { DashboardUsuarioTiempoPromedioComponent } from "./dashboard-usuario-tiempo-promedio/dashboard-usuario-tiempo-promedio.component";
import { DashboardUsuarioPorcentajesComponent } from "./dashboard-usuario-porcentajes/dashboard-usuario-porcentajes.component";
import { DashboardUsuarioCantidadesLeadComponent } from "./dashboard-usuario-cantidades-lead/dashboard-usuario-cantidades-lead.component";
import { DashboardCantidadLeadPorStatusComponent } from "./dashboard-cantidad-lead-por-status/dashboard-cantidad-lead-por-status.component";
import { DashboardCantidadLeadPorFuenteComponent } from "./dashboard-cantidad-lead-por-fuente/dashboard-cantidad-lead-por-fuente.component";
import { DashboardLeadsPorConcesionarioComponent } from "./dashboard-leads-por-concesionario/dashboard-leads-por-concesionario.component";
import { DashboardUserComparativaComponent } from "./dashboard-user-comparativa/dashboard-user-comparativa.component";
import { HistoryComunicationComponent } from "./history-comunication/history-comunication.component";
import { ModalLlamadasTelefonicasDetailComponent } from "./modal-llamadas-telefonicas-detail/modal-llamadas-telefonicas-detail.component";
import { ModalCorreoDetailComponent } from "./modal-correo-detail/modal-correo-detail.component";
import { ModalSeeUtmsHistoryComponent } from "./modal-see-utms-history/modal-see-utms-history.component";
import { DashboardModelosCotizadosComponent } from "./dashboard-modelos-cotizados/dashboard-modelos-cotizados.component";
import { DashboardModelosCotizadosDetalleModeloComponent } from "./dashboard-modelos-cotizados-detalle-modelo/dashboard-modelos-cotizados-detalle-modelo.component";
import { ModalProgramarLlamadaComponent } from "./modal-programar-llamada/modal-programar-llamada.component";
import { ModalProgramarLlamadaHourpickerComponent } from "./modal-programar-llamada-hourpicker/modal-programar-llamada-hourpicker.component";
import { NotificacionesComponent } from "./notificaciones/notificaciones.component";
import { NpsComponent } from "./nps/nps.component";
import { NpsAddComponent } from "./nps-add/nps-add.component";
import { NpsAddDatoPersonalModalComponent } from "./nps-add-dato-personal-modal/nps-add-dato-personal-modal.component";
import { NpsEditDatoPersonalModalComponent } from "./nps-edit-dato-personal-modal/nps-edit-dato-personal-modal.component";
import { TemplateAddModalComponent } from "./template-add-modal/template-add-modal.component";
import { TemplateEditModalComponent } from "./template-edit-modal/template-edit-modal.component";
import { ModalSelectTemplateComponent } from "./modal-select-template/modal-select-template.component";
import { ModalPreviewTemplateLoyaltyComponent } from "./modal-preview-template-loyalty/modal-preview-template-loyalty.component";
import { ReportesAntiguosComponent } from "./reportes-antiguos/reportes-antiguos.component";
import { NotFoundComponent } from "./not-found/not-found.component";
import { TemplatesPickerComponent } from "./templates-picker/templates-picker.component";
import { TemplatesMetaComponent } from "./templates-meta/templates-meta.component";
import { CampaignTypePickerComponent } from "./campaign-type-picker/campaign-type-picker.component";

import { AudienceTypePickerComponent } from "./audience-type-picker/audience-type-picker.component";
import { TemplateEditModalWhatsappComponent } from "./template-edit-modal-whatsapp/template-edit-modal-whatsapp.component";
import { TemplateAddModalWhatsappComponent } from "./template-add-modal-whatsapp/template-add-modal-whatsapp.component";
import { ModalSelectWhatsappTemplateComponent } from "./modal-select-whatsapp-template/modal-select-whatsapp-template.component";
import { ModalPreviewTemplateLoyaltyWhatsappComponent } from "./modal-preview-template-loyalty-whatsapp/modal-preview-template-loyalty-whatsapp.component";

import { CampaignWhatsappComponent } from "./campaign-whatsapp/campaign-whatsapp.component";
import { CampaignWhatsappAddComponent } from "./campaign-whatsapp-add/campaign-whatsapp-add.component";
import { ImportBlackListComponent } from "./import-black-list/import-black-list.component";
import { ReporteConsumoComponent } from "./reporte-consumo/reporte-consumo.component";
import { StoreComponent } from "./store/store.component";
import { ModalCreateStoreComponent } from "./modal-create-store/modal-create-store.component";
import { CustomerComponent } from "./customer/customer.component";
import { AssignUserStoreComponent } from "./assign-user-store/assign-user-store.component";
import { CustomerFieldsComponent } from "./customer-fields/customer-fields.component";
import { ModelCreateCustomerFieldsComponent } from "./model-create-customer-fields/model-create-customer-fields.component";
import { CreateSaleComponent } from "./create-sale/create-sale.component";
import { ModalCreateCustomerComponent } from "./modal-create-customer/modal-create-customer.component";
import { ModalCreateProductComponent } from "./modal-create-product/modal-create-product.component";
import { ProductComponent } from "./product/product.component";
import { ModalSelectProductComponent } from "./modal-select-product/modal-select-product.component";
import { ProductQuantityComponent } from "./product-quantity/product-quantity.component";
import { ForgotPasswordComponent } from "./auth/forgot-password/forgot-password.component";
import { ForgotPasswordTokenComponent } from "./auth/forgot-password-token/forgot-password-token.component";
import { CreateSingleProductComponent } from "./create-single-product/create-single-product.component";
import { DetalleProductComponent } from "./detalle-product/detalle-product.component";
import { ProductsListComponent } from "./products-list/products-list.component";
import { CombosComponent } from "./combos/combos.component";
import { CreateComboComponent } from "./create-combo/create-combo.component";
import { ComboDetailsComponent } from "./combo-details/combo-details.component";
import { ListadoVentasComponent } from "./listado-ventas/listado-ventas.component";
import { VentaDetailsComponent } from "./venta-details/venta-details.component";
import { ResumenVentasComponent } from "./resumen-ventas/resumen-ventas.component";
import { HeroDetailComponent } from "./hero-detail/hero-detail.component";
import { MailOrderComponent } from "./mail-order/mail-order.component";
import { KitchenDisplayComponent } from "./kitchen-display/kitchen-display.component";
import { OrderDetailKitchenComponent } from "./order-detail-kitchen/order-detail-kitchen.component";
import { AutoservicioComponent } from "./autoservicio/autoservicio.component";
import { EditProductComponent } from "./edit-product/edit-product.component";
import { EditComboComponent } from "./edit-combo/edit-combo.component";
import { ModalCreateTurnoComponent } from "./modal-create-turno/modal-create-turno.component";
import { ModalCreateCajaComponent } from "./modal-create-caja/modal-create-caja.component";
import { TurnoComponent } from "./turno/turno.component";
import { CajaComponent } from "./caja/caja.component";
import { ButtonDashboardComponent } from "./button-dashboard/button-dashboard.component";
import { CardStatusSalesComponent } from "./card-status-sales/card-status-sales.component";
import { ButtonTableActionsComponent } from "./button-table-actions/button-table-actions.component";
import { TextStatusTableComponent } from "./text-status-table/text-status-table.component";
import { CountryComponent } from "./country/country.component";
import { ModalCreateCountryComponent } from "./modal-create-country/modal-create-country.component";
import { BreadcrumbBodyComponent } from "./breadcrumb-body/breadcrumb-body.component";
import { TableSwitchStatusComponent } from "./table-switch-status/table-switch-status.component";
import { CerrarTurnoComponent } from "./cerrar-turno/cerrar-turno.component";
import { PriceComponent } from "./price/price.component";
import { PopupProductSuccessComponent } from './popup-product-success/popup-product-success.component';
import { RadioBtnTiendaComponent } from './radio-btn-tienda/radio-btn-tienda.component';
import { ModalPreviewCustomerComponent } from './modal-preview-customer/modal-preview-customer.component';
import { EditCustomerComponent } from './edit-customer/edit-customer.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LeadsComponent,
    SelectTiendaComponent,
    LeadDetalleComponent,
    ReportesComponent,
    AsesorSelectTiendaComponent,
    RegistrarPedidoComponent,
    ColaDeRequerimientoComponent,
    RegistrosComponent,
    LeadWhatsappComponent,
    LeadMailComponent,
    UsersComponent,
    UsersDescansoComponent,
    UsersActividadComponent,
    ConcesionariosComponent,
    DashboardSesionComponent,
    ModalAddConcesionarioComponent,
    VincularUsuarioConcesionarioComponent,
    SendCotizacionComponent,
    LeadHistoryInfoComponent,
    CreateLeadComponent,
    ModalCreateLeadComponent,
    ModalCreateUserComponent,
    JefeSelectTiendaComponent,
    ChangeStoreComponent,
    ModalReasignarLeadComponent,
    ModalReasignarmasivoLeadComponent,
    DashboardUsuariosHoraActividadComponent,
    DashboardUsuariosDiasConexionComponent,
    DashboardHistorialUsuarioActividadesComponent,
    DashboardUsuarioTiempoPromedioComponent,
    DashboardUsuarioPorcentajesComponent,
    DashboardUsuarioCantidadesLeadComponent,
    DashboardCantidadLeadPorStatusComponent,
    DashboardCantidadLeadPorFuenteComponent,
    DashboardLeadsPorConcesionarioComponent,
    DashboardUserComparativaComponent,
    HistoryComunicationComponent,
    ModalLlamadasTelefonicasDetailComponent,
    ModalCorreoDetailComponent,
    ModalSeeUtmsHistoryComponent,
    DashboardModelosCotizadosComponent,
    DashboardModelosCotizadosDetalleModeloComponent,
    ModalProgramarLlamadaComponent,
    ModalProgramarLlamadaHourpickerComponent,
    NotificacionesComponent,
    NpsComponent,
    NpsAddComponent,
    NpsAddDatoPersonalModalComponent,
    NpsEditDatoPersonalModalComponent,
    TemplateAddModalComponent,
    TemplateEditModalComponent,
    ModalSelectTemplateComponent,
    ModalPreviewTemplateLoyaltyComponent,
    ReportesAntiguosComponent,
    NotFoundComponent,
    TemplatesPickerComponent,
    TemplatesMetaComponent,

    AudienceTypePickerComponent,
    TemplateEditModalWhatsappComponent,
    TemplateAddModalWhatsappComponent,
    ModalSelectWhatsappTemplateComponent,
    ModalPreviewTemplateLoyaltyWhatsappComponent,

    CampaignTypePickerComponent,
    CampaignWhatsappComponent,
    CampaignWhatsappAddComponent,
    ImportBlackListComponent,
    ReporteConsumoComponent,
    StoreComponent,
    ModalCreateStoreComponent,
    CustomerComponent,
    AssignUserStoreComponent,
    CustomerFieldsComponent,
    ModelCreateCustomerFieldsComponent,
    CreateSaleComponent,
    ModalCreateCustomerComponent,
    ModalCreateProductComponent,
    ProductComponent,
    ModalSelectProductComponent,
    ProductQuantityComponent,
    ForgotPasswordComponent,
    ForgotPasswordTokenComponent,
    CreateSingleProductComponent,
    DetalleProductComponent,
    ProductsListComponent,
    CombosComponent,
    CreateComboComponent,
    ComboDetailsComponent,
    ListadoVentasComponent,
    VentaDetailsComponent,
    ResumenVentasComponent,
    HeroDetailComponent,
    MailOrderComponent,
    KitchenDisplayComponent,
    OrderDetailKitchenComponent,
    AutoservicioComponent,
    EditProductComponent,
    EditComboComponent,
    ModalCreateTurnoComponent,
    ModalCreateCajaComponent,
    TurnoComponent,
    CerrarTurnoComponent,
    CajaComponent,
    ButtonDashboardComponent,
    CardStatusSalesComponent,
    ButtonTableActionsComponent,
    TextStatusTableComponent,
    CountryComponent,
    ModalCreateCountryComponent,
    BreadcrumbBodyComponent,
    TableSwitchStatusComponent,
    PriceComponent,
    PopupProductSuccessComponent,
    RadioBtnTiendaComponent,
    ModalPreviewCustomerComponent,
    EditCustomerComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    OverlayModule,
    DataTablesModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    NgSelectModule,
    NgxChartsModule,
    NgbModule,
    ChartModule,
    NgxDropzoneModule,
    ToastrModule.forRoot(),
    DragulaModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    FullCalendarModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    //     // for HttpClient use:
    LoadingBarHttpClientModule,
    //     // for Router use:
    LoadingBarRouterModule,
    //     // for Core use:
    LoadingBarModule,
  ],
  providers: [
    UsersService,
    AdminGuard,
    SecureInnerPagesGuard,
    CookieService,
    CampusService,
    LeadsService,
    AudienceService,
    EncuestaService,
    TemplateService,
    CampaignService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
