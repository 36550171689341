<app-breadcrumb [title]="'Combos'" [items]="['Combos','Editar Combo']"></app-breadcrumb>

<div class="container">
  <div class="form-group">
    <nav class="breadcrumb-nav">
      <a routerLink="/combos"> ← Volver a Combos</a>
    </nav>
  </div>
</div>

<div class="container">


  <div class="form-group row">
    <div class="col-12 d-flex justify-content-between align-items-center">
      <h2>Editar Combo</h2>
      <button (click)="openDeleteConfirmationModal()" class="btn btn-danger" aria-label="Eliminar">
        <i class="fa fa-trash-o"></i> Eliminar
      </button>
    </div>
  </div>


    <div class="row">
        <!-- Nombre del Combo -->
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="nombreProducto">Nombre del Combo</label>
                <input type="text" class="form-control" id="nombreProducto" placeholder="Ingrese el nombre del combo" [(ngModel)]="combo_detail.nombre">
            </div>
        </div>


      <!-- Estado del Combo -->
        <div class="col-12 col-md-6">
            <div class="form-group">
            <label for="estadoCombo">Estado</label>
            <select class="form-control" id="estadoCombo" [(ngModel)]="combo_detail.estado">
                <option [value]="'activo'">Activo</option>
                <option [value]="'desactivado'">Desactivado</option>
            </select>
            </div>
        </div>

    </div>
  
    <div class="row">
        <!-- Descripción del Combo -->
        <div class="col-12">
        <div class="form-group">
            <label for="descripcionProducto">Descripción</label>
            <textarea class="form-control" id="descripcionProducto" rows="3" placeholder="Ingrese la descripción del combo" [(ngModel)]="combo_detail.descripcion"></textarea>
        </div>
        </div>
    </div>

    <!-- Precio del producto -->

    <div class="col-12 col-md-6">
        <div class="form-group">
            <label for="stockProducto">Precio</label>
            <input type="number"
                    class="form-control"
                    id="precio"
                    placeholder="Ingrese Precio"
                    [(ngModel)]="combo_detail.precio"
                    >
        </div>
    </div>
  
      <!-- Imagen del producto -->
      <div class="row">
        <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <label for="imagenProducto">Imagen del Combo</label>
                    <!-- Image preview or default image -->
                    
                    <input type="file" 
                           class="form-control" 
                           id="imagenProducto" 
                           (change)="onFileSelected($event)" 
                           accept="image/png, image/jpeg, image/jpg, image/gif">

                
                           <img *ngIf="imagePreview" [src]="imagePreview" alt="Imagen del Producto" class="img-thumbnail" style="max-width: 100px; margin: 10px;">

                           <br>
                           <!-- Button to deselect the image -->
                           <button *ngIf="imagePreview" (click)="clearImage()" class="btn btn-danger" style="margin-top: 10px;">Eliminar Imagen</button>


                </div>
            </div>
        </div>

    <div class="form-group mt-2 mb-2">
        <h3>Administrar de Combo</h3>
    </div>

      <!-- Selección de Categoría -->
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12 col-md-4">
              <div class="form-group">
                <label for="productosSelect">Seleccionar Categorias</label>
                <ng-select
                  bindValue="id"
                  bindLabel="name"
                  [items]="categorias"
                  class="js-example-basic-multiple"
                  [(ngModel)]="selecteCategoriaID">
                </ng-select>
              </div>
            </div>

            <div class="col-12 col-md-3">
              <div class="form-group">
                <label for="cantidadProducto">Cantidad</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <button class="btn btn-secondary border-right-0 rounded-left"
                            type="button" (click)="decrement()"> - </button>
                  </div>

                  <input type="number"
                        class="form-control text-center border-left-0 border-right-0"
                        id="cantidadProducto"
                        [(ngModel)]="cantidadProducto"
                        [min]="1"
                        placeholder="Cantidad" />

                  <div class="input-group-append">
                    <button class="btn btn-secondary border-left-0 rounded-right"
                            type="button" (click)="increment()"> + </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-2 d-flex align-items-center justify-content-center">
              <button [disabled]="!selecteCategoriaID"
                      class="btn btn-success"
                      (click)="agregarCategoria()">Agregar Categoria</button>


            </div>
          </div>
        </div>
      </div>  

      <ng-container *ngIf="categoriasSeleccionadas.length > 0">
        <div class="form-group mt-2 mb-2">
          <h3 >Categorías: </h3>
        </div>
        <div *ngFor="let categoria of categoriasSeleccionadas; let i = index" class="card mt-4">
          <div class="card-body">
            <div class="row">
      
      
              <div class="col-12 col-md-12 row">
                <div class="col-12 col-md-6">
                  <h4 class="align-middle"> {{ categoria.name }} </h4>
                </div>
      
                <div class="col-12 col-md-3">
                  <label> Cantidad </label>
                  <input type="number" class="form-control text-center border-left-0 border-right-0" [(ngModel)]="categoria.cantidad" />  
                </div>

                <div class="col-12 col-md-3 text-end">
                  <button class="btn btn-danger btn-sm" (click)="quitarCategoria(i)"> X </button>
                </div>

              </div>
      
              <div  class="col-12 col-md-12 mt-2"> 
                <div class="col-12 col-md-4">
                  <label>Seleccionar Productos </label>
                  <div class="form-group">
                    <ng-select bindValue="id"
                              bindLabel="name"
                              [items]="categoria.productos"
                              [(ngModel)]="categoria.selectedProductId"
                              class="js-example-basic-multiple"
                              (change)="validar_producto_variable(i, categoria)">
                    </ng-select>
                  </div>
                </div>
              </div>
              
              <ng-container *ngIf="categoria.variables_product.length != 0" >
                <div  class="col-12 col-md-12 row mt-2">
                  <div class="col-12 col-md-4" *ngFor="let item of categoria.atributes_product | keyvalue">
                    <label>{{ item.key }}</label>
                    
                    <ng-select [(ngModel)]="categoria.selectedAttributes[item.key]" 
                              [items]="item.value" 
                              bindLabel="item" 
                              placeholder="Select {{item.key}}"
                              (change)="onChangeVariable(categoria, i)">
                    </ng-select>
                  </div>
                </div>
        
                <div class="col-12 col-md-12 mt-2">
                  <div class="col-12 col-md-4 form-group">
                    <label for="productosSelect">Seleccionar Producto Variable</label>
                    <ng-select bindValue="id" bindLabel="name"
                        [items]="categoria.variables_product_select" class="js-example-basic-multiple"
                        [(ngModel)]="categoria.idVariable">
                    </ng-select>
                  </div>
                </div>
              </ng-container>
              
      
      
              <div class="col-12 col-md-12 mt-3">
                <button [disabled]="categoria.disabled_buton" class="btn btn-success" (click)="agregarProducto(categoria, i)">Agregar Producto</button>

              </div>
      
            </div>
        
            <!-- Lista de productos seleccionados para la categoría -->
            <div *ngIf="categoria.productos_seleccionados.length > 0" class="mt-4">
              <h5>Productos Seleccionados:</h5>
      
              <ul class="list-group">
                <li *ngFor="let producto_seleccionado of categoria.productos_seleccionados" class="list-group-item d-flex justify-content-between align-items-center">
              
                  <p>
                    {{ producto_seleccionado.name }}
                  </p>
      
                  <button class="btn btn-danger btn-sm" (click)="quitarProducto(i, categoria, producto_seleccionado)">Quitar</button>
                </li>
              </ul>
      
            </div>
      
            
          </div>
        </div>
      </ng-container>


  <div class="row">
    <!-- Botones -->
    <div class="form-group buttons">
      <button [attr.disabled]="is_loading ? true : null" class="btn btn-primary" (click)="onSubmit()">Editar Combo</button>
      <button class="btn btn-secondary" (click)="onCancel()">Cancelar</button>
    </div>
  </div>
</div>
