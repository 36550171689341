import { Component, OnInit, Input, Output, EventEmitter  } from '@angular/core';
import { environment } from '../../environments/environment';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, lastValueFrom } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { Product } from '../shared/data/tables/product-list';

const Swal = require('sweetalert2')

@Component({
  selector: 'app-modal-select-product',
  templateUrl: './modal-select-product.component.html',
  styleUrls: ['./modal-select-product.component.scss']
})
export class ModalSelectProductComponent implements OnInit {

  jwt
  currency_symbol_selected = "$";
  tienda_id
  @Input() type: any;
  is_loading = false
  @Input() product: any;
  product_categories = []
  
  products = [];
  selectedOptions: string[] = [];
  selectedExtras = [];
  selectedCategories = [];
  //selectedCategories: { [key: string]: number | null } = {};
  seleccionadosCat: { [categoria: string]: number } = {};
  variables_product = [];
  variables_product_select = [];
  atributes_product = [];
  selectedAttributes: { [key: string]: string } = {};

  idVariable;

  disabled_buton = false;

  selectedProductId: number | null = null; // Producto seleccionado
  cantidadProducto: number = 1; // Inicialización
  price_variable = 0;

  producto_crear = [];
  groupedProducts = [];
  
  price_extras_total_custom = 0;

  increment() {
    this.cantidadProducto++;
  }
  
  decrement() {
    if (this.cantidadProducto > 1) {
      this.cantidadProducto--;
    }
  }


  constructor(
    private http: HttpClient,
    private activeModalService: NgbActiveModal
  ) {
    
     if(localStorage.user != undefined){
        this.jwt = JSON.parse(localStorage.user).jwt
     }
 
  }

  async ngOnInit() {
  
    const data = new FormData()
    if(this.type != 'autoservicio' && this.type != undefined){
      data.append('jwt', this.jwt)
    }
   
    data.append('tienda_id', this.tienda_id)
    data.append('product', JSON.stringify(this.product))
    
//combo_detail_category


    const url = this.type !== 'autoservicio' && this.type !== undefined
  ? `${environment.url_api}Product/select_product_init`
  : `${environment.url_api}Product/select_product_init_autoservicio`;

    const response = <any>await lastValueFrom(this.http.post(url, data));

    if (response.success) {
      if(this.product['product_category_id'] == 'combo'){
         var grouped = response.products.reduce((acc, product) => {
      
          // Si la categoría aún no existe en el acumulador, inicialízala como un array vacío
          if (!acc[product.producto.product_category_id]) {
            acc[product.producto.product_category_id] = [];
          }
          
          // Agrega el producto al array correspondiente a su categoría
          acc[product.producto.product_category_id].push(product);
        
          return acc;
        }, {});  // Iniciamos con un objeto vacío
       

        const groupedArray = Object.keys(grouped).map(category => {
          return { category, products: grouped[category] };
        });

       
        this.groupedProducts = groupedArray

        this.products = response.products

      }else{
       this.products = response.products
       this.selectedProductId = this.products['id']
       this.variables_product = response.products.variables
       this.atributes_product = response.products.atributos
      }
    

    }
  }

  // Función que se ejecuta cuando se selecciona o deselecciona una opción
  totalSelectedExtras: number = 0;
  
  incrementExtra(index: number) {
      const extra = this.products['extras'][index];
      console.log(extra);
  
      // Buscar si el extra ya está en la lista
      let selectedExtra = this.selectedExtras.find(e => e.id === extra.id);
  
      // Si no existe, agregarlo con count inicial 1
      if (!selectedExtra) {
          if (this.totalSelectedExtras < this.products['qty_extras']) {
              this.selectedExtras.push({ id: extra.id, count: 1, price: Number(extra.price), name: extra.name });
              this.totalSelectedExtras++;
          }
      } else {
          // Incrementar si el total no excede el límite
          if (this.totalSelectedExtras < this.products['qty_extras']) {
              selectedExtra.count++;
              this.totalSelectedExtras++;
          }
      }
  
      this.updatePrice();
  }
  
  decrementExtra(index: number) {
      const extra = this.products['extras'][index];
  
      // Buscar el extra en la lista
      let selectedExtra = this.selectedExtras.find(e => e.id === extra.id);
  
      if (selectedExtra && selectedExtra.count > 0) {
          selectedExtra.count--;
          this.totalSelectedExtras--;
  
          // Si el count llega a 0, eliminarlo de la lista
          if (selectedExtra.count === 0) {
              this.selectedExtras = this.selectedExtras.filter(e => e.id !== extra.id);
          }
      }
  
      this.updatePrice();
  }
  
  updatePrice() {
      if (this.product['product_type'] == 2 && this.product['qty_extras'] != 0) {
          this.price_extras_total_custom = this.selectedExtras.reduce(
              (acc, extra) => acc + extra.count * extra.price, 0
          );
      }

      if(this.variables_product_select.length > 0){
        this.price_variable = this.price_extras_total_custom + Number(this.variables_product_select[0].price);
      }
  
  }
  
  // Variable para almacenar las selecciones
selectedExtrasAzucar: number[] = [];

// Método para manejar cambios
onCheckboxChange(event: Event, id: number): void {
    const checkbox = event.target as HTMLInputElement;

    if (checkbox.checked) {
        // Agregar el ID al array si está seleccionado
        this.selectedExtrasAzucar = [id]; // Permitir solo un seleccionado
    } else {
        // Vaciar el array si se deselecciona
        this.selectedExtrasAzucar = [];
    }
}


  onChangeVariable(attributeKey: string): void {
   
    const selectedAttributes = this.selectedAttributes;
    const variables_product = this.variables_product; 

    const filteredProducts = variables_product.filter(product => {
        return Object.keys(selectedAttributes).every(key => {
            return product.atributos[key] && product.atributos[key].includes(selectedAttributes[key]);
        });
    });
    this.idVariable = '';
    this.variables_product_select = filteredProducts;

    
    
    if (this.product['product_type'] == 2 && this.product['qty_extras'] != 0) {
      this.price_extras_total_custom = this.selectedExtras.reduce((acc, extra) => acc + Number(extra.price), 0)
    
    }

    this.price_variable = this.price_extras_total_custom + Number(this.variables_product_select[0].price)

    
}




 onCancel() {
  
    this.activeModalService.close({
      success: false
    });
  }

  async onSubmit() {
    let insertar_product = false;
   
    
    if (this.product['product_category_id'] === 'combo') {
      
      if(Object.keys(this.seleccionadosCat).length !== 0){
        insertar_product = true;
        
      }
     }else{
      if (this.selectedProductId  && this.cantidadProducto >= 1){
        insertar_product = true;
        // if(this.variables_product_select.length > 0){
        //   insertar_product = true;
        // }
        // if (this.selectedExtras && this.selectedExtras.length !== 0) {
        //   insertar_product = true;
        // }
      }
     }


    if(insertar_product){
        if (this.product['product_category_id'] == 'combo') {
          const prArray = [];
      
          for (const categoria in this.seleccionadosCat) {
            if (this.seleccionadosCat.hasOwnProperty(categoria)) {
              const id = this.seleccionadosCat[categoria];
              const pr = this.products.find(p => p.variable.id === id);
      
              if (pr) {
                prArray.push(pr);
              } else {
                console.log(`No se encontró producto en la categoría: ${categoria}`);
              }
            } else {
              console.log('No existe id');
            }
          }
          
          console.log(this.product);
          
          this.producto_crear.push({
            product_id: this.product['id'],
            product_type: this.product['product_category_id'],
            cantidad: 1,
            name_father: this.product['name'],
            qty_extras: 0,
            precio: this.product['price'],
            price_extras_total: 0,
            stock: 100,
            selectedCategories: this.seleccionadosCat,
            selectedCategoriesProduct: prArray
          });
      
          this.activeModalService.close(this.producto_crear);
        } else {
        
          if (this.selectedProductId && this.cantidadProducto >= 1) {
    
            let price_extras_total = 0;
    
            if (this.product['product_type'] == 2 && this.product['qty_extras'] != 0) {
              price_extras_total = this.selectedExtras.reduce((acc, extra) => acc + Number(extra.price), 0)
            
            }

            if (this.product['product_type'] == 2 && this.variables_product.length > 0) {
              this.producto_crear.push({
                product_id: this.variables_product_select[0].id,
                product_type: this.product['product_category_id'],
                cantidad: this.cantidadProducto,
                name_father: this.product['name'],
                name: this.variables_product_select[0].name,
                qty_extras: this.product['qty_extras'],
                extras_selected: this.selectedExtras,
                precio: this.variables_product_select[0].price,
                price_extras_total,
                stock: this.variables_product_select[0].stock
              });
            } else {
              this.producto_crear.push({
                product_id: this.product['product_id'],
                product_type: this.product['product_category_id'],
                cantidad: this.cantidadProducto,
                name_father: this.product['name'],
                qty_extras: this.product['qty_extras'],
                precio: this.product['price'],
                price_extras_total: 0,
                stock: this.product['stock']
              });
            }
            
            console.log(this.producto_crear)
            this.activeModalService.close(this.producto_crear);
          }
        }
    }
  }

  isChecked(item): boolean {
    
    return this.seleccionadosCat[item.producto.product_category_id] === item.variable.id;
  }

  onCheckboxChangeCombo(event: any,item) {
    
    const isChecked = (event.target as HTMLInputElement).checked;
    
    if (isChecked) {
      
      this.seleccionadosCat[item.producto.product_category_id] = item.variable.id;
      
    } else {
      delete this.seleccionadosCat[item.producto.product_category_id];
  
    }
    

  }


}
