import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { ProductsService } from '../products.service';

@Component({
  selector: "app-table-switch-status",
  templateUrl: "./table-switch-status.component.html",
  styleUrls: ["./table-switch-status.component.scss"],
})
export class TableSwitchStatusComponent implements OnInit {
  @Input() available: string = "0";
  @Input() productId: number;
  @Output() availableChange = new EventEmitter<string>();
  // @Output() availableChange = new EventEmitter<string>();

  get status(): boolean {
    return this.available === "1";
  }

  //  set status(value: boolean) {
  //   if(this.productId!=0){
  //     console.log(this.available)
  //     console.log(this.productId)

  //     const response = await this.ProductsService.update_estado_product(this.productId,this.available);
  //   }

  //   this.available = value ? "1" : "0";
  //   this.availableChange.emit(this.available);
  // }

  async updateStatus(value: boolean) {
    if (this.productId !== 0) {
      this.available = value ? "1" : "0";
      try {
        const response = await this.ProductsService.update_estado_product(this.productId, this.available);
        console.log(response);
      } catch (error) {
        console.error('Error al actualizar estado:', error);
      }
      this.availableChange.emit(this.available);
    }
  }

  constructor(
    private ProductsService: ProductsService
  ) {}

  ngOnInit(): void {}
}
