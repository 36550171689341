import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { lastValueFrom } from 'rxjs'
import { environment } from '../../../environments/environment';
@Injectable({
    providedIn: 'root'
})

export class AudienceService {


    constructor(private http: HttpClient) { }

    async get_opciones_por_audiencia(audience_id) {
        const data = new FormData()
        data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
        data.append('audience_id', audience_id)
        const response = await lastValueFrom(this.http.post(`${environment.url_api}Audience/get_opciones_por_audiencia`, data));
        return response
    }

    async get_opciones_por_audiencia_whatsapp(audience_id) {
        const data = new FormData()
        data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
        data.append('audience_id', audience_id)
        const response = await lastValueFrom(this.http.post(`${environment.url_api}Audience/get_opciones_por_audiencia_whatsapp`, data));
        return response
    }

    async get_all_audiences() {
        const data = new FormData()
        data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
        const response = await lastValueFrom(this.http.post(`${environment.url_api}Audience/get_all_audiences`, data));
        return response
    }

    async get_all_audiences__disponibles() {
        const data = new FormData()
        data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
        const response = await lastValueFrom(this.http.post(`${environment.url_api}Audience/get_all_audiences__disponibles`, data));
        return response
    }

    async get_all_audiences_type() {
        const data = new FormData()
        data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
        const response = await lastValueFrom(this.http.post(`${environment.url_api}Audience/get_all_audiences_type`, data));
        return response
    }


    async get_all_audiences_whatsapp_disponibles() {
        const data = new FormData()
        data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
        const response = await lastValueFrom(this.http.post(`${environment.url_api}Audience/get_all_audiences_whatsapp_disponibles`, data));
        return response
    }

    async get_all_audiences_by_type_disponibles(type) {
        const data = new FormData()
        data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
        data.append('type', type)

        const response = await lastValueFrom(this.http.post(`${environment.url_api}Audience/get_audiences_disponibles_by_type`, data));
        return response
    }

    async get_tipos_de_discriminacion_merge(campaign) {
        const data = new FormData()
        data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
        data.append('campaign', JSON.stringify(campaign))
        const response = await lastValueFrom(this.http.post(`${environment.url_api}Audience/get_tipos_de_discriminacion_merge`, data));
        return response
    }




    async get_audience_by_name(name) {

        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });

        const data = {
            'jwt': JSON.parse(localStorage.getItem("user")).jwt,
            'name': name
        }
        const response = await lastValueFrom(this.http.post(`${environment.node_api}api/audience/get_by_name`, data, { headers }));

        return response
    }

    async generateAudience(audience) {
        const data = new FormData()
        data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
        data.append('audience', JSON.stringify(audience))

        console.log('audience', audience)


        const response = await lastValueFrom(this.http.post(`${environment.url_api}Audience/generate_audience`, data));

        return response
    }

    async generateAudienceWhatsapp(audience) {
        const data = new FormData()
        data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
        data.append('audience', JSON.stringify(audience))

        console.log('audience', audience)


        const response = await lastValueFrom(this.http.post(`${environment.url_api}Audience/generate_audience_whatsapp`, data));

        return response
    }

    async generateAudienceSMS(audience) {
        const data = new FormData()
        data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
        data.append('audience', JSON.stringify(audience))

        console.log('audience', audience)


        const response = await lastValueFrom(this.http.post(`${environment.url_api}Audience/generate_audience_SMS`, data));

        return response
    }


    async generate_and_uploadAudienceFromExcel(audience) {
        const data = new FormData()
        data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
        data.append('audiencia', JSON.stringify(audience))
        const response = await lastValueFrom(this.http.post(`${environment.url_api}Audience/generate_and_uploadAudienceFromExcel`, data));
        return response
    }

    async generate_and_uploadAudienceFromExcelWhatsapp(audience) {
        const data = new FormData()
        data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
        data.append('audiencia', JSON.stringify(audience))
        const response = await lastValueFrom(this.http.post(`${environment.url_api}Audience/generate_and_uploadAudienceWhatsappFromExcel`, data));
        return response
    }

    async generate_and_uploadAudienceFromExcelSMS(audience) {
        const data = new FormData()
        data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
        data.append('audiencia', JSON.stringify(audience))
        const response = await lastValueFrom(this.http.post(`${environment.url_api}Audience/generate_and_uploadAudienceSMSFromExcel`, data));
        return response
    }

}
