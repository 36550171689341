<app-breadcrumb [title]="''" [items]="['Productos']" [active_item]="'Listado de productos'">
</app-breadcrumb>
<div *ngIf="product_id > 0; then edit_product else pick_product"></div>
<ng-template #pick_product>
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <h5>Lista de productos</h5>
                        <button (click)="agregar_producto_modal()" class="btn btn-primary m-t-10">Agregar +</button>

                    </div>
                    <div class="table-responsive">
                        <table class="table table-concesionarios">
                            <thead>
                                <tr>
                                    <th scope="col">Nombre</th>
                                    <th scope="col">Descripción</th>
                                    <th scope="col">Combo</th>
                                    <th scope="col">Tipo de producto</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let pro of products" class="tr-mobile">
                                    <td>
                                        <div class="td-mobile">Nombre</div><b>{{pro.name}}</b>
                                    </td>
                                    <td>
                                        <div class="td-mobile">Descripción</div>{{pro.description}}
                                    </td>
                                 
                                    <td>
                                        <div class="td-mobile">Combo</div>
                                        <span class="badge badge-success" *ngIf="pro.is_combo == 1">
                                           Si
                                        </span>
                                        <span class="badge badge-primary" *ngIf="pro.is_combo == 0">
                                            No
                                         </span>
                                    </td>
                                    <td>
                                        <div class="td-mobile">Tipo de producto</div>{{pro.product_type}}
                                    </td>
                                
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #edit_product>

</ng-template>
