<app-breadcrumb [title]="''" [items]="['Usuarios']" [active_item]="'Listado de usuarios'">
</app-breadcrumb>
<div *ngIf="user_id > 0; then edit_user else pick_user"></div>
<ng-template #pick_user>
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <h5>Lista de usuarios</h5>
                        <button (click)="agregar_usuario_modal()" class="btn btn-primary m-t-10">Agregar +</button>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-concesionarios">
                            <thead>
                                <tr>
                                    <th scope="col">Nombres</th>
                                    <th scope="col">Usuario</th>
                                    <th scope="col">Rol</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Editar</th>
                                    <!-- <th scope="col">Actividad</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let usuario of usuarios" class="tr-mobile">
                                    <td>
                                        <div class="td-mobile">Nombres</div><b>{{usuario.first_name}} {{usuario.last_name}}</b>
                                    </td>
                                    <td>
                                        <div class="td-mobile">Usuario</div>{{usuario.email}}
                                    </td>
                                
                                    <td>
                                        <div class="td-mobile">Rol</div>
                                        <span class="badge badge-primary" *ngFor="let group of usuario.groups">
                                            {{group.name}}
                                        </span>
                                    </td>
                                    <td>
                                        <div class="td-mobile">Email</div>{{usuario.email}}
                                    </td>
                                    <td class="text-center">
                                        <div class="td-mobile">Editar</div><a [routerLink]="[usuario.id]"><i class="fa fa-edit"></i></a>
                                    </td>
                                    <!-- <td class="text-center">
                                        <div class="td-mobile">Historial</div>
                                        <a [routerLink]="[usuario.id]+'/actividad'"><i class="fa fa-history"></i></a>
                                    </td> -->
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #edit_user>
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <h5>Editar Usuario</h5>
                        <span>{{user_to_edit.first_name}} {{user_to_edit.last_name}}</span>
                    </div>
                    <div class="card-body content-to-edit">
                        <div class="usuario-campo">
                            <div class="row">
                                <div class="col-12 col-md-6 mb-3">
                                    <label class="form-label" for="name">Nombre</label>
                                    <input class="form-control" id="name" type="text" [(ngModel)]="user_to_edit.first_name">
                                </div>
                                <div class="col-12 col-md-6 mb-3">
                                    <label class="form-label" for="last_name">Apellido</label>
                                    <input class="form-control" id="last_name" type="text"
                                        [(ngModel)]="user_to_edit.last_name">
                                </div>
                                <div class="col-12 col-md-6 mb-3">
                                    <label class="form-label" for="password_n">Contraseña (si quieres cambiarla)</label>
                                    <input class="form-control" id="password_n" type="password" [(ngModel)]="password_n">
                                </div>
                          
                                <div class="col-12 col-md-6 mb-3">
                                    <div class="form-group">
                                        <label for="tipo_de_usuario">Tipo de usuario</label>
                                        
                                        <ng-select bindValue="id" bindLabel="name" [items]="concesionarios" class="js-example-basic-multiple"
                                            [(ngModel)]="selectedGroup" [compareWith]="compareGroupObjects">
                                        </ng-select>

                                    </div>
                                </div>
                                <div class="col-12 col-md-6 mb-3">
                                    <label class="form-label" for="activo">¿Activo? </label>
                                    <i ngbTooltip="Si desactivas el usuario, no podrá ingresar al CRM ni recibir leads en caso sea vendedor."
                                        class="fa fa-fw fa-info-circle"></i>
                                    <br>

                                    <label class="switch">
                                        <input id="activo" [(ngModel)]="user_to_edit.active" type="checkbox"><span
                                            class="switch-state"></span>
                                    </label>
                                </div>
                                <!-- <div class="col-12 col-md-6 mb-3">
                                    <label class="form-label">Descanso médico / Vacaciones</label><br>
                                    <a class="btn btn-primary" [routerLink]="['descanso']">Asignar</a>
                                </div> -->

                            </div>
                        </div>
                        <div class="col-12 text-center m-b-10">
                            <button (click)="asignar()" class="btn btn-primary">¡Guardar!</button>
                        </div>
                    </div>



                </div>
            </div>
        </div>
    </div>
</ng-template>