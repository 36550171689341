import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AudienceService } from '../shared/services/audience.service';
import { Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-loyalty-campaigns-audience-add-from-excel',
  templateUrl: './loyalty-campaigns-audience-add-from-excel.component.html',
  styleUrls: ['./loyalty-campaigns-audience-add-from-excel.component.scss']
})
export class LoyaltyCampaignsAudienceAddFromExcelComponent implements OnInit {

  files: File[] = [];
  audiencia
  audienciaType


  title = 'crm';
  file?: File;
  timeout: any;
  uploadProgress: number = 0;
  uploadSub?: Subscription;
  rows = [];
  rows_to_show = []
  cabeceras = []

  audiencia_type



  columns = [{ prop: 'nombres', name: 'nombres' },
  { prop: 'celular', name: 'celular' },
  { prop: 'dni', name: 'dni' },
  { prop: 'correo_electronico', name: 'correo_electronico' },
  { prop: 'fecha', name: 'fecha' },
  { prop: 'modelo', name: 'modelo' },
  { prop: 'version', name: 'version' },
  { prop: 'modelo_comunicacion', name: 'modelo_comunicacion' },
  { prop: 'fuente', name: 'fuente' }];


  constructor(
    private audienceService: AudienceService,
    private http: HttpClient,
    public router: Router,
    private route: ActivatedRoute

  ) {

    this.audiencia_type = ""

    this.audiencia = {
      name: '',
      type: ''
    }

    this.audienciaType = [];
    
    /* this.audienciaType = [
      'Remarketing',
      'Recompra',
      'Encuesta - Cotizaciones',
      'Encuesta - Entrega',
      'Referidos',
      'Bienvenida',
      'Encuesta - Planning',
      'Invitaciones',
      'Encuesta - Regalo',
    ] */
  }

  ngOnInit(): void {

    this.route.url.subscribe(segments => {

      const currentPath = segments.join('/');


      if (currentPath.includes('audiencias-whatsapp')) {

        this.audiencia_type = 'Whatsapp'
  
      } else if (currentPath.includes('sms')) {

        this.audiencia_type = 'SMS';

      } else if (currentPath.includes('audiencias')) {
  
        this.audiencia_type = 'Email'
      }

    })

    this.traer_tipos_audiencia();

  }

  async traer_tipos_audiencia(){
    const audiencia_res = <any>await this.audienceService.get_all_audiences_type();
    this.audienciaType = audiencia_res.audiences;
  }

  replaceFile() {
    this.files.splice(0, 1);
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  generate_preview() {

    for (let indice in this.rows[0]) {
      this.cabeceras.push(indice)
    }

    var maxValue = 10
    for (let index = 0; index < this.rows.length; index++) {
      if (this.rows_to_show.length < maxValue) {
        this.rows_to_show.push(this.rows[index])
      } else {
        break
      }
    }

    console.log(this.rows_to_show)

  }

  async generate_audience() {


    if (!this.audiencia.name) {
      Swal.fire('Ingrese nombre de la audiencia', '', 'error')
      return false
    }

    if (!this.audiencia.type) {
      Swal.fire('Seleccione el tipo de la audiencia', '', 'error')
      return false
    }

    if (this.rows.length < 1) {
      Swal.fire('Debe ingresar un excel con contenido', '', 'error')
      return false
    }


    Swal.fire({
      title: '¿Estás seguro?',
      text: `Crearás una audiencia con ${this.rows.length} personas.`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#000',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        this.generarAudiencia();
      }
    })


  }



  async generarAudiencia() {



    const to_add = {
      'data': this.rows,
      'audiencia': this.audiencia
    }

    let res: any; // Declarar res fuera de los bloques if

    if (this.audiencia_type == 'Email') {
       res = <any>await this.audienceService.generate_and_uploadAudienceFromExcel(to_add)
    }

    if (this.audiencia_type == 'Whatsapp') {
       res = <any>await this.audienceService.generate_and_uploadAudienceFromExcelWhatsapp(to_add)
    }

    if (this.audiencia_type == 'SMS') {
       res = <any>await this.audienceService.generate_and_uploadAudienceFromExcelSMS(to_add)
    }

    if (res.success) {
      console.log(res)
      this.audiencia = {
        name: '',
        type: ''
      }

      Swal.fire(res.message, '', 'success')
      this.reset()

      if(this.audiencia_type == 'Email'){

        this.router.navigate(['loyalty-comunication/audiencias']);

      } 
      
      if(this.audiencia_type == 'Whatsapp'){
        this.router.navigate(['loyalty-comunication/audiencias-whatsapp']);
      }

      if(this.audiencia_type == 'SMS'){
        this.router.navigate(['loyalty-comunication/audiencias-sms']);
      }

    } else {
      Swal.fire(res.message, '', 'error')
    }
  }



  reset() {
    this.file = undefined;
    this.uploadProgress = 0;
    this.uploadSub = undefined;
    this.rows = [];
    this.rows_to_show = []
    this.cabeceras = []
  }

  onPage(event: any) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      console.log('paged!', event);
    }, 100);
  }

  onSelect(event: any) {
    if (!this.audiencia.type) {
      Swal.fire('Debes elegir el tipo de audiencia primero', '', 'error')
    } else {
      this.file = event.addedFiles[0];

      if (this.file) {
        const formData = new FormData();

        formData.append("fileToUpload", this.file)
        formData.append("tipo_audiencia", this.audiencia.type)


        Swal.showLoading()
        const upload$ = this.http.post(`${environment.url_api}Audience/process_excel`, formData, {
          reportProgress: true,
          observe: 'events'
        })

        this.uploadSub = upload$.subscribe((event: any) => {
          switch (event.type) {
            case HttpEventType.Sent:
              this.uploadProgress = 0;
              break;

            case HttpEventType.UploadProgress:
              this.uploadProgress = Math.round(100 * (event.loaded / event.total));
              break;

            case HttpEventType.Response:
              const body: any = event.body

              if (!body.success) {
                Swal.fire('Error', body.message, 'error')
                this.reset();
              } else {
                Swal.fire('¡Listo!', `Se encontraron: ${body.data.length} registros`, 'success')
                this.rows = body.data
                console.log('ROWS : ', this.rows)
                this.generate_preview()
              }
              break;

            default:
          }
        })
      }
    }
  }


}