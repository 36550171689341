import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { HttpClient } from "@angular/common/http";
import { Subscription, lastValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';

const Swal = require('sweetalert2')


@Component({
  selector: 'app-create-store',
  templateUrl: './create-store.component.html',
  styleUrls: ['./create-store.component.scss']
})
export class CreateStoreComponent implements OnInit {

  private jwt
  private routeSub: Subscription;
  store_id
  store_to_edit = {
    name: '',
    rut: '',
    razon_social: '',
    representante_legal: '',
    correo: '',
    telefono: '',
    adress: '',
    country_id: '',
    latitud: '',
    longitud: '',
    status: '',
    hora_apertura:'',
    hora_cierre:'',
    plataformas_selected: [],
  }
  is_loading = false
  
  store_to_create = {
    'name': '',
    'rut':'',
    'razon_social':'',
    'representante_legal':'',
    'correo':'',
    'telefono':'',
    'adress': '',
    'latitud': '',
    'longitud': '',
    'status':'',
    'countries': '',
    'horaInicioSeleccionada':'',
    'horaFinSeleccionada':''
  };

  countries = []
  plataformas=[]

   constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.jwt = JSON.parse(localStorage.user).jwt
    this.routeSub = this.route.params.subscribe(params => {
      this.store_id = params.store_id
    })
  }

  async ngOnInit() {

    if (this.store_id >= 0) {
      this.get_data_from_store()
    } else {
      this.get_data_new_store()
    }


  }


async get_data_from_store(){
  const data = new FormData()
  data.append('jwt', this.jwt)
  data.append('store_id', this.store_id)


  const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}ClientAPI/get_info_by_storeID`, data))

  if (response.success) {

     this.countries = response.country
     this.plataformas = response.plataformas
  
     this.store_to_edit = {
      name: response.store.name,
      rut: response.store.RUT,
      razon_social: response.store.razon_social,
      representante_legal: response.store.representante_legal,
      correo: response.store.correo,
      telefono: response.store.mobile,
      adress: response.store.adress,
      country_id: response.store.country_id,
      latitud: response.store.latitud,
      longitud: response.store.longitud,
      status: response.store.status,
      hora_apertura:response.store.hora_apertura,
      hora_cierre:response.store.hora_cierre,
      plataformas_selected:response.store_plataforma ? response.store_plataforma : [] ,
     }
   
     
  }
}

async get_data_new_store(){
  const data = new FormData()
  data.append('jwt', this.jwt)

  const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}ClientAPI/add_store_init`, data))
  if (response.success) {
    this.countries = response.countries
    this.plataformas = response.plataformas
  }
}

  onCancel() {
    this.router.navigate(['/store']); 
  }

  validate() {
    if (!this.store_to_create.name) {
      Swal.fire('Ingrese nombre de comercio', '', 'error')
      return false
    }
    if (!this.store_to_create.rut) {
      Swal.fire('Ingrese un rut', '', 'error')
      return false
    }
    if (!this.store_to_create.razon_social) {
      Swal.fire('Ingrese razón social', '', 'error')
      return false
    }
    if (!this.store_to_create.representante_legal) {
      Swal.fire('Ingrese representante legal', '', 'error')
      return false
    }

    if (!this.store_to_create.correo) {
      Swal.fire('Ingrese correo electrónico', '', 'error')
      return false
    } else if (!this.validar_correo(this.store_to_create.correo)) {
      Swal.fire('Ingrese correo electrónico válido', '', 'error')
      return false
    }

    if (!this.store_to_create.telefono) {
      Swal.fire('Ingrese teléfono', '', 'error')
      return false
    }

    if (!this.store_to_create.adress) {
      Swal.fire('Ingrese dirección', '', 'error')
      return false
    }

    if (!this.store_to_create.latitud) {
      Swal.fire('Ingrese latitud', '', 'error')
      return false
    }

    if (!this.store_to_create.longitud) {
      Swal.fire('Ingrese longitud', '', 'error')
      return false
    }

    if (!this.store_to_create.countries) {
      Swal.fire('Selecciona un país', '', 'error')
      return false
    }

    if (!this.store_to_create.horaInicioSeleccionada) {
      Swal.fire('Ingresa hora de inicio', '', 'error')
      return false
    }

    if (!this.store_to_create.horaFinSeleccionada) {
      Swal.fire('Ingresa hora de fin', '', 'error')
      return false
    }

    return true
  }

  validate_edit() {
    if (!this.store_to_edit.name) {
      Swal.fire('Ingrese nombre de comercio', '', 'error')
      return false
    }
    if (!this.store_to_edit.rut) {
      Swal.fire('Ingrese un rut', '', 'error')
      return false
    }
    if (!this.store_to_edit.razon_social) {
      Swal.fire('Ingrese razón social', '', 'error')
      return false
    }
    if (!this.store_to_edit.representante_legal) {
      Swal.fire('Ingrese representante legal', '', 'error')
      return false
    }

    if (!this.store_to_edit.correo) {
      Swal.fire('Ingrese correo electrónico', '', 'error')
      return false
    } else if (!this.validar_correo(this.store_to_edit.correo)) {
      Swal.fire('Ingrese correo electrónico válido', '', 'error')
      return false
    }


    if (!this.store_to_edit.telefono) {
      Swal.fire('Ingrese teléfono', '', 'error')
      return false
    }

    if (!this.store_to_edit.adress) {
      Swal.fire('Ingrese dirección', '', 'error')
      return false
    }

    if (!this.store_to_edit.latitud) {
      Swal.fire('Ingrese latitud', '', 'error')
      return false
    }

    if (!this.store_to_edit.longitud) {
      Swal.fire('Ingrese longitud', '', 'error')
      return false
    }

    if (!this.store_to_edit.country_id) {
      Swal.fire('Selecciona un país', '', 'error')
      return false
    }

    if (!this.store_to_edit.hora_apertura) {
      Swal.fire('Ingresa hora de inicio', '', 'error')
      return false
    }

    if (!this.store_to_edit.hora_cierre) {
      Swal.fire('Ingresa hora de fin', '', 'error')
      return false
    }

    return true
  }

  async onSubmit() {
    if (this.validate()) {
      
       const data = new FormData()
       data.append('jwt', this.jwt)
       data.append('plataformas',  JSON.stringify(this.plataformas.filter(app => app.activo)));
       data.append('store', JSON.stringify(this.store_to_create))

       this.is_loading = true
       const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}ClientAPI/add_store`, data))
        this.is_loading = false
        if (response.success) {
          Swal.fire(response.message, '', 'success')
          this.router.navigate(['/store']); 
        } else {
          Swal.fire(response.message, '', 'error')
        }
    }
  }

  async onSubmitEdit() {
     if (this.validate_edit()) {
      
       const data = new FormData()
       data.append('jwt', this.jwt)
       data.append('store_id', this.store_id)
       data.append('store_to_edit', JSON.stringify(this.store_to_edit))

       this.is_loading = true
       const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}ClientAPI/edit_store`, data))
        this.is_loading = false
        if (response.success) {
          Swal.fire(response.message, '', 'success')
          this.router.navigate(['/store']); 
        } else {
          Swal.fire(response.message, '', 'error')
        }
     }
  }

  plataformaSeleccionada(id: number): boolean {
    
    this.plataformas.forEach(app => {
      app.activo = this.store_to_edit.plataformas_selected.some(p => p.plataforma_id === app.id);
    
    });
     return true;
  }
  
  
  togglePlataforma(app: any) {
    if (app.activo) {
      this.store_to_edit.plataformas_selected.push({ plataforma_id: app.id.toString() });
    } else {
      this.store_to_edit.plataformas_selected = this.store_to_edit.plataformas_selected.filter(p => p.plataforma_id !== app.id.toString());
    }
  }

  onlyNumbers(event: KeyboardEvent) {
    const pattern = /[0-9.,]/;
    const inputChar = String.fromCharCode(event.charCode);
    const charCode = event.which ? event.which : event.keyCode;
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  validar_correo = (correo) => {
    const cadena = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    if (correo) {
      if (cadena.test(correo)) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

}
