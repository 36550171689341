import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { UsersService } from '../../logueo/users.service';
import { Location } from "@angular/common"
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from "@angular/common/http";
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-forgot-password-token',
  templateUrl: './forgot-password-token.component.html',
  styleUrls: ['./forgot-password-token.component.scss']
})
export class ForgotPasswordTokenComponent implements OnInit {

  private routeSub: Subscription;
  public forgotPasswordForm: FormGroup;
  public successMessage: string = '';
  public errorMessage: string = '';
  token: string;
  active_token: boolean = false;
  token_valid: boolean = false;
  public show: boolean = false;
  usuario_id;

  constructor(
    private fb: FormBuilder,
    public userService: UsersService,
    private route: ActivatedRoute,
    private http: HttpClient,
    private location: Location) {

    // Inicialización del formulario para el password
    this.forgotPasswordForm = this.fb.group({
      password: ['', Validators.required]
    });

    // Suscripción para obtener el token de los parámetros
    this.routeSub = this.route.params.subscribe(parametros => {
      if (parametros.token_code) {
        this.token = parametros.token_code;
        this.active_token = true;
      }
    });
  }

  showPassword() {
    this.show = !this.show;
  }

  ngOnInit(): void {
    if (this.active_token) {
      // Validar el token
      this.userService.validateTokenResetPassword(this.token).then(
        (response) => {
          console.log(response);
          if (response.success) {
            this.token_valid = true;
            this.usuario_id = response.usuario.id;

          } else {
            this.token_valid = false;
            this.errorMessage = 'Token inválido o expirado.';
          }
        },
        (error) => {
          this.token_valid = false;
          this.errorMessage = 'Error en la validación del token.';
        }
      ).finally(() => {
        this.userService.showLoader = false;
      });
    }
  }

  resetPassword() {
    if (this.forgotPasswordForm.valid) {

      const password = this.forgotPasswordForm.value['password'];

      console.log(password);
      this.userService.showLoader = true;
      this.successMessage = '';
      this.errorMessage = '';

      this.userService.actualizarPassword(password, this.usuario_id).then(
        (response) => {

          if (response.success) {
            this.successMessage = response.message;
          } else {
            this.errorMessage = response.message;
          }
        },
        (error) => {
          this.errorMessage = 'Ha ocurrido un error. Por favor, intenta de nuevo más tarde.';
        }
      ).finally(() => {
        this.userService.showLoader = false;
      });

  
    }
  }
}
