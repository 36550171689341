<app-breadcrumb
  [title]="'Cierre de ventas'"
  [items]="['Ventas']"></app-breadcrumb>
<div class="container-fluid">
  <div class="row">
    <div class="col-12 col-md-6"></div>
    <div class="col-12 col-md-6">
      <app-breadcrumb-body
        [items]="['Ventas', 'Cierre de ventas']"></app-breadcrumb-body>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div class="card">

    <div class="row">
      <h2>Filtros de búsqueda:</h2>
      <div class="col-12 col-md-6">
        <div class="form-group row">
          <label class="col-sm-3 col-form-label"><b>Desde:</b></label>
          <div class="col-sm-9">
            <input
                class="form-control digits"
                [(ngModel)]="filtros.fecha_inicio"
                id="example-datetime-local-input"
                type="date" />
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group row">
          <label class="col-sm-3 col-form-label"><b>Hasta:</b></label>
          <div class="col-sm-9">
              <input
                    class="form-control digits"
                    [(ngModel)]="filtros.fecha_fin"
                    id="example-datetime-local-input"
                    type="date" />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="form-group row">
          <label class="col-sm-3 col-form-label"><b>Productos:</b></label>
          <div class="col-sm-9">
            <ng-select
              bindValue="id"
              bindLabel="name"
              [items]="productos"
              class="col-sm-9 js-example-basic-multiple"
              placeholder="Seleccionar producto"
              [(ngModel)]="filtros.productos"
              [multiple]="true">
            </ng-select>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group row">
          <label class="col-sm-3 col-form-label"><b>Plataformas:</b></label>
          <div class="col-sm-9">
            <ng-select
              bindValue="id"
              bindLabel="nombre"
              [items]="plataformas"
              class="col-sm-9 js-example-basic-multiple"
              placeholder="Seleccionar plataforma"
              [(ngModel)]="filtros.plataformas"
              [multiple]="true">
            </ng-select>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="form-group row">
          <label class="col-sm-3 col-form-label"><b>Tiendas:</b></label>
          <div class="col-sm-9">
            <ng-select
              bindValue="id"
              bindLabel="name"
              [items]="tiendas"
              class="col-sm-9 js-example-basic-multiple"
              placeholder="Seleccionar tienda"
              [(ngModel)]="filtros.tiendas"
              [multiple]="true">
            </ng-select>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <button
        id="make_filter"
        type="button"
        class="btn btn-primary btn-custom"
        (click)="rerender()"
        [attr.aria-expanded]="!isCollapsed"
        aria-controls="collapseExample"
        [attr.disabled]="buttonFiltrarTexto != 'Buscar' ? true : null">
        <span>{{ buttonFiltrarTexto }}</span>
      </button>
      </div>
    </div>
  </div>
</div> 
<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-inner">
          <h2 class="card-title">Ventas</h2>
          <div class="row">
            <div class="col-md-6">
              <div class="card-row ventas-row">
                <ul>
                  <li>Total venta bruta</li>
                </ul>
                <span>{{currency_symbol_selected}} {{ sales_summary.total_ventas_bruta | currency: currency_selected: ''}}</span>
              </div>
              <div class="card-row ventas-row">
                <ul>
                  <li>Total descuentos</li>
                </ul>
                <span>{{currency_symbol_selected}} 0</span>

              </div>
              <div class="card-row ventas-row big-row">
                <ul>
                  <li>Total Venta Bruta (c/IVA):</li>
                </ul>
                <span>{{currency_symbol_selected}} {{ sales_summary.total_ventas_bruta | currency: currency_selected: ''}}</span>

              </div>
            </div>
            <div class="col-md-6">
              <div class="card-row ventas-row">
                <ul>
                  <li>Impuestos (IVA):</li>
                </ul>
                <span>{{currency_symbol_selected}} {{ sales_summary.total_taxes | currency: currency_selected: ''}}</span>
              </div>
              <div class="card-row ventas-row">
                <ul>
                  <li>Venta Neta (s/IVA):</li>
                </ul>
                <span>{{currency_symbol_selected}} {{ sales_summary.total_ventas_neta | currency: currency_selected: ''}}</span>
              </div>
              <div class="card-row ventas-row">
                <ul>
                  <li>Costo Neto Productos (s/IVA):</li>
                </ul>
                <span>$ 0</span>
              </div>
              <div class="card-row ventas-row big-row">
                <ul>
                  <li>Margen de Contribución Neto:</li>
                </ul>
                <span>$ 0</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="card">
        <div class="card-inner">
          <h2 class="card-title">Compras por Apps</h2>
          <div class="row">
            <div class="col-12">
              <div class="card-row compras-row">
                <p></p>
                <span><strong>Venta</strong></span>
                <span><strong>TRX</strong></span>
              </div>
              <div class="card-row compras-row" *ngFor="let pla of plataformas_order.plataformas_orders">
                <ul>
                  <li>{{pla.nombre}}</li>
                </ul>
                <span>{{currency_symbol_selected}} {{ pla.venta | currency: currency_selected: ''}}</span>
                <span>{{ pla.cantidad }}</span>
            </div>
   
            <div class="card-row compras-row total-row">
              <p>TOTAL</p>
              <span>{{currency_symbol_selected}} {{ transacciones.trx_total_venta | currency: currency_selected: ''}}</span>
              <span>{{ transacciones.trx_total_trx }}</span>
            </div>

        
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card">
        <div class="card-inner">
          <h2 class="card-title">Medio de pago</h2>
          <div class="row">
            <div class="col-12">
              <div class="card-row compras-row">
                <p></p>
                <span><strong>Venta</strong></span>
                <span><strong>TRX</strong></span>
              </div>
              <!-- <div class="card-row compras-row border-none"> -->
              <div class="card-row compras-row" *ngFor="let sales of transacciones.transacciones_orders">
                  <ul>
                    <li *ngIf="sales.metodo_pago === 'tarjeta_credito'">Tarjeta de crédito</li>
                    <li *ngIf="sales.metodo_pago === 'efectivo'">Efectivo</li>
                  </ul>
                  <span>{{currency_symbol_selected}} {{ sales.venta | currency: currency_selected: ''}}</span>
                  <span>{{ sales.cantidad }}</span>
              </div>
     
              <div class="card-row compras-row total-row">
                <p>TOTAL</p>
                <span>{{currency_symbol_selected}} {{ transacciones.trx_total_venta | currency: currency_selected: ''}}</span>
                <span>{{ transacciones.trx_total_trx }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="card">
        <div class="card-inner">
          <h2 class="card-title">Clientes</h2>
          <div class="row">
            <div class="col-12">
              <div class="card-row ventas-row">
                <ul>
                  <li>Número de órdenes:</li>
                </ul>
                <span>{{clientes_orders.orders}}</span>
              </div>
              <div class="card-row ventas-row">
                <ul>
                  <li>Número de clientes:</li>
                </ul>
                <span>{{cantidad_clientes}}</span>
              </div>
              <div class="card-row ventas-row">
                <ul>
                  <li>Nuevos clientes:</li>
                </ul>
                <span>{{clientes_nuevos.clientes_nuevos}}</span>
              </div>
              <div class="card-row ventas-row">
                <ul>
                  <li>Ticket promedio:</li>
                </ul>
                <span>{{currency_symbol_selected}} {{ clientes_orders.promedio | currency: currency_selected: ''}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card">
        <div class="card-inner">
          <h2 class="card-title">Ventas por producto</h2>
          <div class="row">
            <div class="col-12">
              <div class="card-row compras-row">
                <p></p>
                <span><strong>Venta</strong></span>
                <span><strong>TRX</strong></span>
              </div>
              
              <div class="card-row compras-row" *ngFor="let pro of productos_order">
                  <ul>
                    <li>{{pro.name}}</li>
                  </ul>
                  <span>{{currency_symbol_selected}} {{ pro.venta | currency: currency_selected: ''}}</span>
                  <span>{{pro.trx}}</span>
           
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
