<app-breadcrumb
  [title]="''"
  [items]="['Clientes']"
  [active_item]="'Listado de clientes'">
</app-breadcrumb>
<div class="container-fluid">
  <div class="row">
      <div class="col-12 col-md-6"></div>
      <div class="col-12 col-md-6">
      <app-breadcrumb-body
          [items]="['Clientes', 'Listado']"></app-breadcrumb-body>
      </div>
  </div>
</div>
<div class="container-fluid">
  <div class="container-card">
    <h2>Lista de clientes</h2>
    <div class="container-card-status-inner">
      <app-card-status-sales
        [text]="'Total clientes registrados'"
        [number]="metricas.total_clientes_registrados"
        [icon]="'assets/images/total-clientes.svg'"
        [bgColor]="'#94BAFE'">
      </app-card-status-sales>
      <app-card-status-sales
        [text]="'Clientes nuevos'"
        [number]="metricas.clientes_nuevos"
        [icon]="'assets/images/clientes-nuevos.svg'"
        [bgColor]="'#007BFF'">
      </app-card-status-sales>
      <app-card-status-sales
        [text]="'Clientes recurrentes'"
        [number]="metricas.clientes_recurrentes"
        [icon]="'assets/images/clientes-recurrentes.svg'"
        [bgColor]="'#404663'">
      </app-card-status-sales>
    </div>
    <div class="container-card-status-inner">
      <app-card-status-sales
        [text]="'Clientes activos'"
        [number]="metricas.clientes_activos"
        [icon]="'assets/images/clientes-activos.svg'"
        [bgColor]="'#94BAFE'">
      </app-card-status-sales>
      <app-card-status-sales
        [text]="'Promedio de visita'"
        [number]="000"
        [icon]="'assets/images/promedio-visita.svg'"
        [bgColor]="'#007BFF'">
      </app-card-status-sales>
      <app-card-status-sales
        [text]="'Ticket promedio'"
        [number]="metricas.ticket_promedio | currency: currency_selected: ''"
        [icon]="'assets/images/ticket-promedio.svg'"
        [bgColor]="'#404663'">
      </app-card-status-sales>
    </div>
  </div>
</div>
 <div class="container-fluid">
  <div class="container-card">
    <div class="row">
      <h2>Buscar un cliente:</h2>
      <div class="col-12">
        <div class="search-container">
          <input
            type="text"
            class="search-input form-control"
            [(ngModel)]="filtros.texto"
            [ngModelOptions]="{ standalone: true }"
            type="text"
            placeholder="Ingresar cliente o RUT" />
          <div class="search-icon">
            <i class="fa fa-search"></i>
          </div>
        </div>

        <button
          id="make_filter"
          type="button"
          class="btn btn-primary btn-custom"
          (click)="rerender()"
          [attr.aria-expanded]="!isCollapsed"
          aria-controls="collapseExample"
          [attr.disabled]="buttonFiltrarTexto != 'Buscar' ? true : null">
          <span>{{ buttonFiltrarTexto }}</span>
        </button>
      </div>
      <div class="col-12 col-md-6"></div>
    </div>
    <div class="row">
      <h2>Filtros de búsqueda:</h2>
      <div class="col-12 col-md-6">
        <div class="form-group row">
          <label class="col-sm-3 col-form-label"><b>Desde:</b></label>
          <div class="col-sm-9">
            <input
                class="form-control digits"
                [(ngModel)]="filtros.fecha_inicio"
                id="example-datetime-local-input"
                type="date" />
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group row">
          <label class="col-sm-3 col-form-label"><b>Hasta:</b></label>
          <div class="col-sm-9">
              <input
                    class="form-control digits"
                    [(ngModel)]="filtros.fecha_fin"
                    id="example-datetime-local-input"
                    type="date" />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      
      <div class="col-12 col-md-6">
        <div class="form-group row">
          <label class="col-sm-3 col-form-label"><b>Tiendas:</b></label>
          <div class="col-sm-9">
            <ng-select
              bindValue="id"
              bindLabel="name"
              [items]="stores"
              class="col-sm-9 js-example-basic-multiple"
              placeholder="Seleccionar tienda"
              [(ngModel)]="filtros.stores"
              [multiple]="true">
            </ng-select>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group row">
          <label class="col-sm-3 col-form-label"><b>Plataformas:</b></label>
          <div class="col-sm-9">
            <ng-select
              bindValue="id"
              bindLabel="nombre"
              [items]="plataformas"
              class="col-sm-9 js-example-basic-multiple"
              placeholder="Seleccionar plataforma"
              [(ngModel)]="filtros.plataformas"
              [multiple]="true">
            </ng-select>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <button
        id="make_filter"
        type="button"
        class="btn btn-primary btn-custom"
        (click)="rerender()"
        [attr.aria-expanded]="!isCollapsed"
        aria-controls="collapseExample"
        [attr.disabled]="buttonFiltrarTexto != 'Buscar' ? true : null">
        <span>{{ buttonFiltrarTexto }}</span>
      </button>
      </div>
    </div>
  </div>
</div> 
<div *ngIf="customer_id > 0; then edit_customer; else pick_customer"></div>
<ng-template #pick_customer>
  <div class="container-fluid" style="margin-top: 12px">
    <div class="container-card">
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <table class="table table-concesionarios">
              <thead>
                <tr>
                  <th scope="col">Nombres</th>
                  <th scope="col">RUT</th>
                  <th scope="col">Tienda</th>
                  <th scope="col">Correo electrónico</th>
                  <th scope="col">TRX</th>
                  <th scope="col">Ticket Prom</th>
                  <th scope="col">Fecha de registro</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let customer of customers" class="tr-mobile">
                  <td>
                    <div class="td-mobile">Nombre</div>
                    <b><a href="#" (click)="openViewModal($event,customer.id)">{{ customer.name }} {{ customer.last_name }}</a></b>
                  </td>
                  <td>
                    <div class="td-mobile">RUT</div>
                    <b>{{ customer.rut }}</b>
                  </td>
                  <td>
                    <div class="td-mobile">Tienda</div>
                    <b>{{ customer.tienda }}</b>
                  </td>
                  <td>
                    <div class="td-mobile">Correo electrónico</div>
                    <b>{{ customer.additional_fields['Correo electrónico'] }}</b>
                  </td>
                  <td>
                    <div class="td-mobile">TRX</div>
                    <b>{{ customer.total_trx }}</b>
                  </td>
                  <td>
                    <div class="td-mobile">Ticket Prom</div>
                    <b>{{currency_symbol_selected}} {{ customer.total_ticket | currency: currency_selected: ''}}</b>
                  </td>
                  <td>
                    <div class="td-mobile">Fecha de registro</div>
                    <b>{{ obtenerFecha(customer.created_time) }}</b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
   
  </div>
</ng-template>
<ng-template #edit_customer> </ng-template>
