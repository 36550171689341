<app-breadcrumb
  [title]="'Cliente'"
  [items]="['Clientes', 'Editar Cliente']"></app-breadcrumb>
  <div class="container-fluid">
    <div class="row">
        <div class="col-12 col-md-6"></div>
        <div class="col-12 col-md-6">
        <app-breadcrumb-body
            [items]="['Clientes', 'Editar']"></app-breadcrumb-body>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="card">
        <div class="card-body p-4">
            <h2>Editar Datos de Cliente</h2>
            <nav class="breadcrumb-nav">
                <a routerLink="/customer"> ← Volver a clientes</a>
            </nav>
            <div class="container-product">
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label for="nombre">Nombre</label>
                          <input
                            type="text"
                            class="form-control"
                            id="nombre"  
                            [(ngModel)]="customer_edit.name" />
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label for="last_name">Apellido</label>
                          <input
                            type="text"
                            class="form-control"
                            id="last_name"
                            [(ngModel)]="customer_edit.last_name" />
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label for="rut">RUT</label>
                          <input
                            type="text"
                            class="form-control"
                            id="rut" 
                            [(ngModel)]="customer_edit.rut"/>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label for="correo">Correo electrónico</label>
                          <input
                            type="text"
                            class="form-control"
                            id="correo"
                            [(ngModel)]="customer_edit.additional_fields['Correo electrónico']" />
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label for="telefono">Teléfono</label>
                          <input
                            type="text"
                            class="form-control"
                            id="telefono"
                            [(ngModel)]="customer_edit.additional_fields['Teléfono']" />
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label for="cumpleanos">Fecha</label>
                          <input
                            type="date"
                            class="form-control"
                            id="cumpleanos"
                            [(ngModel)]="customer_edit.additional_fields['Cumpleaños']" />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group buttons">
                      <button
                        [attr.disabled]="is_loading ? true : null"
                        class="btn btn-primary"
                        (click)="onSubmit()">
                        Guardar
                      </button>
                      <button class="btn btn-secondary" (click)="onCancel()">
                        Cancelar
                      </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>