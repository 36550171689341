<div class="container">
    <div class="row">
        <div class="col-8">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                              <div class="search">
                                <form class="theme-form">
                                  <div class="form-group">
                                    <input class="form-control" [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}"
                                      (keyup)="searchTerm(searchText)" type="text" placeholder="Buscar productos"><i class="fa fa-search"></i>
                                  </div>
                                </form>
                            </div>
                            
                            
                        </div>
                        <div class="col-12">
                          <div class="categorias-container">
                            <button class="categoria-btn active"  [ngClass]="{'active': selectedButton === -1}" (click)="onCategoriaClick('',-1)">Todos</button>
                            <button 
                              *ngFor="let categoria of list_of_categories; let i = index" 
                              [ngClass]="{'active': selectedButton === i}"
                              (click)="onCategoriaClick(categoria,i)" 
                              class="categoria-btn">
                              {{ categoria.name }}
                            </button>
                            <button class="categoria-btn active"  [ngClass]="{'active': selectedButton === -2}" (click)="onCategoriaClick('combos',-2)">Combos</button>

                          </div>
                        </div>
                        <div class="col-12">
                          <div class="product-wrapper-grid" [class.list-view]="listView">
                            <div class="row">
                              <div *ngFor="let product of searchProducts" [class]="' col-xl-'+col+' col-md-'+col1+' col-sm-'+col2" [class.col-xl-12]="listView">
                                <div class="card">
                                  <div class="product-box">
                                    <div class="product-img"><img class="img-fluid" src="{{ product.photo }}" alt="">
                                    </div>
                                    <div class="product-details">
                                      <h4>{{ product.name }}</h4>
                                      <p>{{product.description}}</p>
                                      <div *ngIf="product.product_type == 2">
                                        <div *ngIf="product.price_range.min_price === product.price_range.max_price" class="product-price">{{currency_symbol_selected}} {{ product.price_range.min_price | currency: currency_selected: ''}}</div>
                                        <div *ngIf="product.price_range.min_price !== product.price_range.max_price" class="product-price">{{currency_symbol_selected}} {{ product.price_range.min_price | currency: currency_selected: ''}} - {{currency_symbol_selected}} {{ product.price_range.max_price | currency: currency_selected: ''}}</div>
  
                                      </div>
                                      <div *ngIf="product.product_type != 2">
                                        <div class="product-price">{{currency_symbol_selected}} {{ product.price | currency: currency_selected: ''}}</div>

                                      </div>
                                      
                                 
                                      <button class="btn-custom" (click)="addToCart(product)">Agregar</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-4">
            <div class="card">
              <div class="card-body">
             
                <div class="row">
                  <div *ngFor="let item of cartItemsCustom">
                    
                    <div class="title-cart-product">
                      <div class="name" *ngIf="item[0].name == null; else noItems">{{ item[0].name_father }}</div>
                      <ng-template #noItems>
                        <div class="name">{{ item[0].name_father }} - {{ item[0].name }}</div>
                      </ng-template>
                      
                      <div class="price">{{currency_symbol_selected}} {{ item[0].precio | currency: currency_selected: ''}}</div>
                      <button class="delete" (click)="removeProduct(item[0].product_id)">x</button>
                    </div>

                    <div class="col-6">

                      <app-product-quantity 
                      [quantity]="item[0].cantidad" 
                      [min]="1" 
                      [max]="item[0].stock" 
                      (quantityChange)="onQuantityChange($event, item[0])">
                      </app-product-quantity>

                      <div  *ngIf="item[0].qty_extras != 0">
                        <div *ngFor="let extra of item[0].extras_selected" style="display: flex;">
                                <div class="name">+ {{ extra.name }}</div>
                                <div class="price"  *ngIf="extra.price != 0">{{currency_symbol_selected}} {{ extra.price | currency: currency_selected: ''}}</div>
                        </div>
                      </div>
                    </div>

                    <div class="col-8">
                      <div  *ngIf="item[0].selectedCategoriesProduct && item[0].selectedCategoriesProduct.length > 0">
                        
                        <div *ngFor="let cate of item[0].selectedCategoriesProduct" style="display: flex;">
                                <div class="name">+ {{ cate.producto.name }} - {{ cate.variable.name }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div class="payment-info"><p>Subtotal</p><p>{{currency_symbol_selected}} {{ subtotal | currency: currency_selected: ''}}</p></div>
                  <div class="payment-info"><p>IVA</p><p>{{currency_symbol_selected}} {{ tax | currency: currency_selected: ''}}</p></div>
                  <div class="payment-info"><p>Total</p><p>{{currency_symbol_selected}} {{ total | currency: currency_selected: ''}}</p></div>
                 
                  <button [disabled]="isButtonDisabled" class="btn-pay" (click)="pay()">Pagar</button>
                  <button class="btn-cancel">Cancelar</button>
                </div>
              </div>
            </div>
          </div>
    </div>
</div>
