<app-breadcrumb
  [title]="'Reporte'"
  [items]="['Ventas']"></app-breadcrumb>
<div class="container-fluid">
  <div class="row">
    <div class="col-12 col-md-6"></div>
    <div class="col-12 col-md-6">
      <app-breadcrumb-body
        [items]="['Reporte', 'Reporte']"></app-breadcrumb-body>
    </div>
  </div>
</div>
<div class="container-fluid">
    <div class="card">
  
      <div class="row">
        <h2>Filtros de búsqueda:</h2>
        <div class="col-12 col-md-6">
          <div class="form-group row">
            <label class="col-sm-3 col-form-label"><b>Desde:</b></label>
            <div class="col-sm-9">
              <input
                  class="form-control digits"
                  [(ngModel)]="filtros.fecha_inicio"
                  id="example-datetime-local-input"
                  type="date" />
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group row">
            <label class="col-sm-3 col-form-label"><b>Hasta:</b></label>
            <div class="col-sm-9">
                <input
                      class="form-control digits"
                      [(ngModel)]="filtros.fecha_fin"
                      id="example-datetime-local-input"
                      type="date" />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group row">
            <label class="col-sm-3 col-form-label"><b>Tiendas:</b></label>
            <div class="col-sm-9">
              <ng-select
                bindValue="id"
                bindLabel="name"
                [items]="tiendas"
                class="col-sm-9 js-example-basic-multiple"
                placeholder="Seleccionar tiendas"
                [(ngModel)]="filtros.tiendas"
                [multiple]="true">
              </ng-select>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
            <div class="form-group row">
              <label class="col-sm-3 col-form-label"><b>Productos:</b></label>
              <div class="col-sm-9">
                <ng-select
                  bindValue="id"
                  bindLabel="name"
                  [items]="productos"
                  class="col-sm-9 js-example-basic-multiple"
                  placeholder="Seleccionar producto"
                  [(ngModel)]="filtros.productos"
                  [multiple]="true">
                </ng-select>
              </div>
            </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group row">
            <label class="col-sm-3 col-form-label"><b>Plataformas:</b></label>
            <div class="col-sm-9">
              <ng-select
                bindValue="id"
                bindLabel="nombre"
                [items]="plataformas"
                class="col-sm-9 js-example-basic-multiple"
                placeholder="Seleccionar plataforma"
                [(ngModel)]="filtros.plataformas"
                [multiple]="true">
              </ng-select>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6"></div>
      </div>
  
      <div class="row">
        <div class="col-12">
          <button
          id="make_filter"
          type="button"
          class="btn btn-primary btn-custom"
          (click)="rerender()"
          [attr.aria-expanded]="!isCollapsed"
          aria-controls="collapseExample"
          [attr.disabled]="buttonFiltrarTexto != 'Buscar' ? true : null">
          <span>{{ buttonFiltrarTexto }}</span>
        </button>
        </div>
      </div>

      
    </div>
</div> 
 


<div class="container-fluid">

  <!-- Gráfico 1 -->

    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-inner">


                    <h2 class="card-title">
                      <app-feather-icons [icon]="'calendar'" class="txt-google"></app-feather-icons>
                       Indicadores del período</h2>

                      <div class="row">
                          <div class="col-sm-3">
                            <div class="card h-100">
                                  <div class="box">
                                      <label>Venta Bruta</label>
                                      <p>{{currency_symbol_selected}} {{ orders.total_bruto | currency: currency_selected: ''}}</p>
                                  </div>
                                  <div class="box">
                                      <label>Venta Total</label>
                                      <p>{{currency_symbol_selected}} {{ orders.total_bruto | currency: currency_selected: ''}}</p>
                                  </div>
                              </div>
                          </div>


                          <div class="col-sm-3">
                              <div class="card h-100">
                                  <div class="box">
                                      <label>Impuestos (IVA)</label>
                                      <p>{{currency_symbol_selected}} {{ orders.impuestos | currency: currency_selected: ''}}</p>
                                  </div>
                                  <div class="box">
                                      <label>Venta Neta</label>
                                      <p>{{currency_symbol_selected}} {{ orders.total_neta | currency: currency_selected: ''}}</p>
                                  </div>
                              </div>
                          </div>
                          <div class="col-sm-3">
                              <div class="card  h-100">
                                  <div class="box">
                                      <label>Órdenes atentidas</label>
                                      <p>{{orders.cantidad_ordenes_atendidas}}</p>
                                  </div>
                                  <div class="box">
                                      <label>Ticket promedio</label>
                                      <p>{{currency_symbol_selected}} {{ orders.venta_promedio | currency: currency_selected: ''}}</p>
                                  </div>
                                  <div class="box">
                                      <label>Rotación promedio</label>
                                      <p>{{rotacion_promedio.tiempo_promedio}}</p>
                                  </div>
                              </div>
                          </div>
                          <div class="col-sm-3">
                              <div class="card  h-100">
                                  <div class="box">
                                      <label>Comensales</label>
                                      <p>{{orders.cantidad_clientes}}</p>
                                  </div>
                                  <div class="box">
                                      <label>Ticket por comensal</label>
                                      <p>{{currency_symbol_selected}} {{ orders.venta_promedio_por_cliente | currency: currency_selected: ''}}</p>
                                  </div>
                              </div>
                              
                          </div>
                      </div>


                    <div class="row mt-4">
                      <div class="col-12">
                        <p> Detalle de ventas por local por día</p>
                        <table class="table table-striped table-custom-border">
                          <thead >
                              <tr>
                                <th class="text-center">Fecha</th>
                                <th class="text-center">Tienda</th>
                                <th class="text-center">Venta Bruta</th>
                                <th class="text-center">Impuestos</th>
                                <th class="text-center">Venta Neta</th>
                                <th class="text-center">Órdenes</th>
                                <th class="text-center">Productos por Ticket</th>
                              </tr>
                            </thead>
                            <tbody>
                           
                                <tr *ngFor="let or of order_detail">
                                  <td class="text-center">{{or.fecha }}</td>
                                  <td class="text-center">{{ or.name }}</td>
                                  <td class="text-center">{{currency_symbol_selected}} {{ or.total_bruto | currency: currency_selected: ''}}</td>
                                  <td class="text-center">{{currency_symbol_selected}} {{ or.impuestos | currency: currency_selected: ''}}</td>
                                  <td class="text-center">{{currency_symbol_selected}} {{ or.total_neta | currency: currency_selected: ''}}</td>
                                  <td class="text-center">{{ or.cantidad_ventas }}</td>
                                  <td class="text-center">{{ or.producto_ticket }}</td>
                                </tr>
                            </tbody>
                      </table>
                      </div>
                     </div>


                </div>
            </div>
        </div>
    </div>

<!-- Gráfico 2 -->

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-inner">

            <h2 class="card-title"> <app-store-icon [color]="'#FFFFFFFF'" ></app-store-icon>  Claves por local</h2>
            <div class="row">
                <div class="col-md-6">
                  <p>Ticket promedio</p>
                    <div class="">
                      <div class="chart-container-6">
                        <ngx-charts-bar-horizontal
                        [view]="view6"
                        [scheme]="colorScheme"
                        [results]="ticketPromedio"
                        [gradient]="gradient"
                        [xAxis]="showXAxis"
                        [yAxis]="showYAxis"
                        [legend]="showLegend"
                        [showXAxisLabel]="showXAxisLabel"
                        [showYAxisLabel]="showYAxisLabel"
                        [xAxisLabel]="xAxisLabel"
                        [yAxisLabel]="yAxisLabel">
                      </ngx-charts-bar-horizontal>
                    </div>
                  </div>
                  
              </div>
             
              
              <div class="col-md-6">
                <p>Cantidad de Órdenes</p>
                <div class="chart-container-6">
                  <div class="">
                    <ngx-charts-bar-horizontal
                      [view]="view6"
                      [scheme]="colorScheme"
                      [results]="ticketPromedioCliente"
                      [gradient]="gradient"
                      [xAxis]="showXAxis"
                      [yAxis]="showYAxis"
                      [legend]="showLegend"
                      [showXAxisLabel]="showXAxisLabel"
                      [showYAxisLabel]="showYAxisLabel"
                      [xAxisLabel]="xAxisLabel"
                      [yAxisLabel]="yAxisLabel">
                    </ngx-charts-bar-horizontal>
                  </div>
             
                </div>
              </div>

              
            </div>
            <div class="row">
              <div class="col-md-6">
                <p>Ticket por Comensal</p>
                <div class="">
                  <div class="chart-container-6">
                    <ngx-charts-bar-horizontal
                      [view]="view6"
                      [scheme]="colorScheme"
                      [results]="cantidadOrdenes"
                      [gradient]="gradient"
                      [xAxis]="showXAxis"
                      [yAxis]="showYAxis"
                      [legend]="showLegend"
                      [showXAxisLabel]="showXAxisLabel"
                      [showYAxisLabel]="showYAxisLabel"
                      [xAxisLabel]="xAxisLabel"
                      [yAxisLabel]="yAxisLabel">
                    </ngx-charts-bar-horizontal>
                  </div>
                </div>
                
            </div>
           
            
            <div class="col-md-6">
              <p>Cantidad de Comensales</p>
              <div class="">
                <div class="chart-container-6">
                  <ngx-charts-bar-horizontal
                    [view]="view6"
                    [scheme]="colorScheme"
                    [results]="cantidadClientes"
                    [gradient]="gradient"
                    [xAxis]="showXAxis"
                    [yAxis]="showYAxis"
                    [legend]="showLegend"
                    [showXAxisLabel]="showXAxisLabel"
                    [showYAxisLabel]="showYAxisLabel"
                    [xAxisLabel]="xAxisLabel"
                    [yAxisLabel]="yAxisLabel">
                  </ngx-charts-bar-horizontal>
                </div>
              </div>
              
            </div>

            
          </div>
          </div>
        </div>
          
      </div>
    </div>

<!-- Gráfico 3 -->

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-inner">
            <h2 class="card-title"> <i class="fa fa-bar-chart" aria-hidden="true"></i> Detalle de Ventas</h2>

            <div class="row">
              <div class="col-md-12">
                <p>Venta por día por local</p>
                <div class="chart-container">
                  <ngx-charts-bar-vertical-stacked
                  [view]="view"
                  [scheme]="colorScheme"
                  [results]="ventasPorDia"
                  [gradient]="gradient"
                  [xAxis]="showXAxis"
                  [yAxis]="showYAxis"
                  [legend]="showLegend"
                  [showXAxisLabel]="showXAxisLabel"
                  [xAxisLabel]="'Días'"
                  [showYAxisLabel]="showYAxisLabel"
                  [yAxisLabel]="'Ventas'"
                  [animations]="animations"
                >
                </ngx-charts-bar-vertical-stacked>

            
                </div>
              </div>
          </div>
            <div class="row">
                <div class="col-md-12">
                  <p>Venta por día de la semana por local</p>
                  <div class="chart-container">
                    <ngx-charts-bar-vertical-2d
                    [view]="view"
                    [scheme]="colorScheme"
                    [results]="ventasSemanales"
                    [gradient]="gradient"
                    [xAxis]="showXAxis"
                    [yAxis]="showYAxis"
                    [legend]="showLegend"
                    [showXAxisLabel]="showXAxisLabel"
                    [xAxisLabel]="xAxisLabel"
                    [showYAxisLabel]="showYAxisLabel"
                    [yAxisLabel]="yAxisLabel"
                    [animations]="animations">
                  </ngx-charts-bar-vertical-2d>
  
              
                  </div>
                </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <p>Venta por hora por día</p>
                <div class="chart-container">
                  <ngx-charts-line-chart
                  [view]="view"
                  [scheme]="colorScheme"
                  [results]="ventasPorHora"
                  [xAxis]="showXAxis"
                  [yAxis]="showYAxis"
                  [legend]="showLegend"
                  [gradient]="gradient"
                  [showXAxisLabel]="true"
                  [xAxisLabel]="'Hora'"
                  [showYAxisLabel]="true"
                  [yAxisLabel]="'Venta'"
                  [autoScale]="true"
                  [roundDomains]="true"
                  [animations]="true">
                </ngx-charts-line-chart>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <p>Venta por hora por local</p>
                <div class="chart-container">
                  <ngx-charts-line-chart
                  [view]="view"
                  [scheme]="colorScheme"
                  [results]="ventasPorHoraTienda"
                  [xAxis]="showXAxis"
                  [yAxis]="showYAxis"
                  [legend]="showLegend"
                  [gradient]="gradient"
                  [showXAxisLabel]="true"
                  [xAxisLabel]="'Hora'"
                  [showYAxisLabel]="true"
                  [yAxisLabel]="'Venta'"
                  [autoScale]="true"
                  [roundDomains]="true"
                  [animations]="true">
                </ngx-charts-line-chart>
                </div>
              </div>
            </div>
          
          </div>
        </div>
          
      </div>
    </div>

<!-- Gráfico 4 -->

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-inner">
            <h2 class="card-title"> <i class="fa fa-ticket" aria-hidden="true"></i> Ticket promedio neto por local por día</h2>

            <div class="row">
              <div class="col-md-5">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th class="text-center">Fecha</th>
                      <th class="text-center">Tienda</th>
                      <th class="text-center">Ticket Promedio</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of ventas_promedio_neto_tienda">
                      <td class="text-center">{{ item.fecha }}</td>
                      <td class="text-center">{{ item.tienda }}</td>
                      <td class="text-center">{{currency_symbol_selected}} {{ item.promedio_venta_neta | currency: currency_selected: ''}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-md-7">
                <div class="chart-container">
                  <ngx-charts-line-chart
                  [view]="view6"
                  [scheme]="colorScheme"
                  [results]="ventasPromedioNetoTiendaG"
                  [xAxis]="true"
                  [yAxis]="true"
                  [legend]="true"
                  [showXAxisLabel]="true"
                  [xAxisLabel]="'Fecha'"
                  [showYAxisLabel]="true"
                  [yAxisLabel]="'Ticket Promedio'"
                  [autoScale]="true"
                  [animations]="true"
                  [roundDomains]="true"
                  [timeline]="true">
                </ngx-charts-line-chart>
                

            
                </div>
              </div>
          </div>
  
          
          </div>
        </div>
          
      </div>
    </div>

    <!-- GRÁFICO 5: -->
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-inner">
            <h2 class="card-title"> <i class="fa fa-line-chart" aria-hidden="true"></i> Venta Histórica</h2>

            <div class="row">
              <div class="col-md-12">
                <p>Comparador de ventas por año</p>
                <div class="chart-container">
            
                  <ngx-charts-area-chart
                    [view]="view"
                    [scheme]="colorScheme"
                    [results]="ventasPorMesArea"
                    [xAxis]="true"
                    [yAxis]="true"
                    [legend]="false"
                    [showXAxisLabel]="true"
                    [xAxisLabel]=""
                    [showYAxisLabel]="true"
                    [yAxisLabel]=""
                    [gradient]="false"
                    [autoScale]="true"
                    [timeline]="true">
                  </ngx-charts-area-chart>
                 


                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <p>Comparador de ventas por mes</p>
                <div class="chart-container">
                  <ngx-charts-bar-vertical-2d
                  [view]="view"
                  [scheme]="colorScheme"
                  [results]="ventasPorMes"
                  [xAxis]="true"
                  [yAxis]="true"
                  [legend]="true"
                  [showXAxisLabel]="true"
                  [xAxisLabel]=""
                  [showYAxisLabel]="true"
                  [yAxisLabel]=""
                  [roundDomains]="true"
                  [gradient]="false"
                  [showDataLabel]="true">
                </ngx-charts-bar-vertical-2d>
            
                </div>
              </div>
            </div>



          
          </div>
        </div>


      <!-- GRAFICO  5 -->

      <!-- 
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-inner">
              <h2 class="card-title">
                <app-store-icon [color]="'#FFFFFFFF'"></app-store-icon>
                Indicadores de Venta Histórica
              </h2>
      
              <div class="p-3">
                <div class="row my-3 custom-header bg-tittle">
                  <div class="col text-center">
                    <span class="selected-tab">Período</span>
                  </div>
                  <div class="col text-center">
                    <span>Período Actual</span>
                  </div>
                  <div class="col text-center">
                    <span>Período Anterior</span>
                  </div>
                </div>
      
                <div class="row">
                  <div
                    class="col-12 card-custom border border-secondary"
                    *ngFor="let venta of resultado_perido_ventas | keyvalue"
                  >
                    <div class="row">
                      <div class="col d-flex justify-content-center align-items-center">
                        <h5 class="fw-bold m-auto">{{ venta.key.toUpperCase() }}</h5>
                      </div>
      
                      <div class="col text-center">
                        <p class="text-muted">Venta del {{ venta.key }} en Curso</p>
                        <h3>{{ venta.value.venta_actual }}</h3>
                        <p [ngClass]="venta.value.yoy_color"> 
                          <i class="{{venta.value.yoy_icon}}"></i> YoY: {{ venta.value.yoy }}
                        </p>
                      </div>
      
                      <div class="col text-center">
                        <p class="text-muted">Venta Total del {{ venta.key }} Pasado</p>
                        <h3>{{ venta.value.venta_anterior }}</h3>
      
                        <p *ngIf="venta.key === 'AÑO'" [ngClass]="venta.value.mom_color">
                          <i class="{{venta.value.mom_icon}}"></i> MoM: {{ venta.value.mom }}
                        </p>

                        <p *ngIf="venta.key === 'MES'" [ngClass]="venta.value.mom_color">
                          <i class="{{venta.value.mom_icon}}"></i> MoM: {{ venta.value.mom }}
                        </p>
      
                        <p *ngIf="venta.key === 'TRIMESTRE'" [ngClass]="venta.value.qoq_color">
                          <i class="{{venta.value.qoq_icon}}"></i> QoQ: {{ venta.value.qoq }}
                        </p>
      
                        <p *ngIf="venta.key === 'SEMANA'" [ngClass]="venta.value.wow_color">
                          <i class="{{venta.value.wow_icon}}"></i> WoW: {{ venta.value.wow }}
                        </p>
                      </div>
      
                    </div>
                  </div>
                </div>
              </div>
      
            </div>
          </div>
        </div>
      </div>
    -->
      

        <!-- Gráfico 1 -->

    <div class="row">
      <div class="col-12">
          <div class="card">
              <div class="card-inner">

                  <h2 class="card-title"> <i class="fa fa-ticket" aria-hidden="true"></i>  Ticket Promedio, Ordenes y Comensales</h2>
              

                  <div class="row">
                    <div class="col-md-12">
                      <p>Comparador de ticket promedio mensual</p>
                      <div class="chart-container">
                        <ngx-charts-bar-vertical-2d
                        [view]="view"
                        [scheme]="colorScheme"
                        [results]="ventasPromedioPorMes"
                        [xAxis]="true"
                        [yAxis]="true"
                        [legend]="true"
                        [showXAxisLabel]="true"
                        [xAxisLabel]=""
                        [showYAxisLabel]="true"
                        [yAxisLabel]=""
                        [roundDomains]="true"
                        [gradient]="false"
                        [showDataLabel]="true">
                      </ngx-charts-bar-vertical-2d>
                  
                      </div>
                    </div>
                  </div>
  
                  <div class="row">
                    <div class="col-md-12">
                      <p>Comparador de cantidad de órdenes atendidas</p>
                      <div class="chart-container">
                        <ngx-charts-bar-vertical-2d
                        [view]="view"
                        [scheme]="colorScheme"
                        [results]="ventasCantidadPorMes"
                        [xAxis]="true"
                        [yAxis]="true"
                        [legend]="true"
                        [showXAxisLabel]="true"
                        [xAxisLabel]=""
                        [showYAxisLabel]="true"
                        [yAxisLabel]=""
                        [roundDomains]="true"
                        [gradient]="false"
                        [showDataLabel]="true">
                      </ngx-charts-bar-vertical-2d>
                  
                      </div>
                    </div>
                  </div>
        
              </div>
          </div>
      </div>
  </div>
      

          
      </div>
    </div>
</div>