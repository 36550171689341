import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, lastValueFrom } from 'rxjs';
import { HttpClient } from "@angular/common/http";

const Swal = require('sweetalert2')

@Component({
  selector: 'app-modal-create-turno',
  templateUrl: './modal-create-turno.component.html',
  styleUrls: ['./modal-create-turno.component.scss']
})
export class ModalCreateTurnoComponent implements OnInit {

  jwt
  is_loading = false
  
  turno_to_create = {
    'name': '',
    'comment': '',
    'status':'',
  };


  
  constructor(
    private http: HttpClient,
    private activeModalService: NgbActiveModal
  ) {
    this.jwt = JSON.parse(localStorage.user).jwt
  }

  async ngOnInit() {
    const data = new FormData()
    data.append('jwt', this.jwt)

  }

  validate() {
    if (!this.turno_to_create.name) {
      Swal.fire('Ingrese nombre', '', 'error')
      return false
    }
    return true
  }

  async onSubmit() {
    if (this.validate()) {
      
       const data = new FormData()
       data.append('jwt', this.jwt)
       data.append('turno', JSON.stringify(this.turno_to_create))
      
       this.is_loading = true
       const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}ClientAPI/add_turno`, data))
        this.is_loading = false
        if (response.success) {
          Swal.fire(response.message, '', 'success')
          this.activeModalService.close(response);
        } else {
        
          Swal.fire(response.message, '', 'error')
        }
    }
  }
  
  onCancel() {
    this.activeModalService.close({
      success: false
    });
  }

}
