import { Component, OnInit } from '@angular/core';
import { ProductsService } from '../products.service';
import Swal from 'sweetalert2';  // Importa SweetAlert
import { ActivatedRoute, Router} from '@angular/router';
import { Console } from 'console';


@Component({
  selector: 'app-create-combo',
  templateUrl: './create-combo.component.html',
  styleUrls: ['./create-combo.component.scss']
})
export class CreateComboComponent implements OnInit {

  filtros_product;

  constructor(private ProductsService: ProductsService,
    private route: ActivatedRoute,
    private router: Router
  ) { 

    this.filtros_product = {}

  }

  ngOnInit(): void {

    // this.tienda_selected = JSON.parse(localStorage.tienda_selected);

    // if (!this.tienda_selected) {
    //   this.router.navigate(['/']);
    // }

    // this.filtros_product.tienda_id = this.tienda_selected;
    this.filtros_product.status = 1;


    this.init_service();
  }


 
  

  jwt: string;
  categorias = [];
  size = [];
  tipo = [];
  is_loading = false;

  combo_crear = {
    nombre: '',
    productos: [],
    categorias: [],
    descripcion: '',
    imagen: null,
    estado: 'activo',
    precio: 0
  };


  tienda_selected



  async init_service() {
    this.jwt = JSON.parse(localStorage.user).jwt;
    const res = <any>await this.ProductsService.lead_service_init_general(this.jwt);
    if (res.success) {
      this.categorias = res.payload.categorias;
      this.tipo = res.payload.tipos;
      this.size = res.payload.sizes;
    }
  }

  imagePreview: string | ArrayBuffer | null = null;  // Default preview variable

  clearImage(): void {

    this.imagePreview = null;

    const fileInput = document.getElementById('imagenProducto') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
   }
  }

  onFileSelected(event: any) {
      const file = event.target.files[0];

      if (!file) {
          return;
      }

      const maxSizeInMB = 2;
      const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

      if (file.size > maxSizeInBytes) {
          Swal.fire('Archivo muy grande', `El archivo debe ser menor a ${maxSizeInMB}MB`, 'error');
          return;
      }

      const validTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];
      if (!validTypes.includes(file.type)) {
          Swal.fire('Tipo de archivo no permitido', 'Solo se permiten imágenes (PNG, JPG, GIF)', 'error');
          return;
      }

      const reader = new FileReader();
      reader.onload = () => {
          this.imagePreview = reader.result;
      };
      reader.readAsDataURL(file);

      this.combo_crear.imagen = file;
  }


  validateFields() {
    // Validación del nombre
    if (!this.combo_crear.nombre) {
      Swal.fire('Error', 'El nombre es obligatorio.', 'error');
      return false;
    }


    return true;
  }

  onSubmit() {
    if (this.validateFields()) {
      this.is_loading = true;
      console.log(this.combo_crear);

      this.generateCombo()

      this.is_loading = false;
    }
  }



  async generateCombo() {

    const data = new FormData();
    
    this.combo_crear.categorias = this.categoriasSeleccionadas;

    data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
    data.append('nombre', this.combo_crear.nombre);
    data.append('descripcion', this.combo_crear.descripcion);
    data.append('estado', this.combo_crear.estado);
    data.append('productos', JSON.stringify(this.combo_crear.productos));
    data.append('categorias', JSON.stringify(this.combo_crear.categorias));

    data.append('tienda_id',this.tienda_selected);
    data.append('precio', this.combo_crear.precio.toString());

    if (this.combo_crear.imagen) {
        data.append('imagen', this.combo_crear.imagen);
    }
    
   const res = <any>await this.ProductsService.generateCombo(data)

   if (res.success) {

      Swal.fire({
          title: 'Combo Creado!',
          text: 'El Combo se ha creado exitosamente y ahora está disponible en tu inventario.',
          icon: 'success',
          confirmButtonText: 'Perfecto',
          timer: 3000
      });

      if (res.id_product) {
        const route_link = '/details-combo/'+ res.id_product;
        console.log(route_link);
        this.router.navigate([route_link]); // Redirige a otra ruta
      }

      
  } else {
      Swal.fire({
          title: 'Error al Crear Combo',
          text: 'Hubo un problema al intentar crear el Combo. Por favor, revisa los datos ingresados o intenta nuevamente más tarde.',
          icon: 'error',
          confirmButtonText: 'Entendido',
          timer: 3000
      });
  }



  }

  
  onCancel() {
    // Lógica para cancelar la operación
    console.log('Operación cancelada');
    Swal.fire('Cancelado', 'La creación del combo ha sido cancelada.', 'info');
    this.router.navigate(['/combos'])

  }


  /* CAMBIO VARIABLES CATEGORIAS COMBO */

  // Esta sería la lista de productos disponibles
  selectedProductId: number | null = null; // Producto seleccionado
  cantidadProducto: number = 1; // Inicialización

  variables_product = [];
  variables_product_select = [];
  atributes_product = [];
  selectedAttributes: { [key: string]: string } = {};
  idVariable;
  disabled_buton = false;
  productos = [];
  selecteCategoriaID;
  categoriasSeleccionadas: { 
    id: number; 
    name: string, 
    cantidad: number, 
    productos: [], 
    productos_seleccionados: [],
    variables_product: [],
    atributes_product: [],
    selectedAttributes: [],
    disabled_buton: boolean,
    idVariable: '',
  }[] = [];  

 async validar_producto_variable(i, categoria){

   var id = categoria.selectedProductId;
   if (id) {
     categoria.disabled_buton = true;
     const res = <any>await this.ProductsService.get_product_by_id(id);
     if (res.success) {
       categoria.variables_product = res.product.variables;
       categoria.atributes_product = res.product.atributos;
 
       Object.keys(categoria.atributes_product).forEach(key => {
         categoria.selectedAttributes[key] = ''; 
       });
 
       categoria.disabled_buton = false;
 
       categoria.idVariable = null;
     } 
 
 
   } else {
     categoria.selectedProductId = null;
     categoria.cantidadProducto = 1;
     categoria.idVariable = null; 
     categoria.variables_product = [];

   }

   this.categoriasSeleccionadas[i] = categoria;
 }

  onChangeVariable(categoria, i): void {
     const selectedAttributes = categoria.selectedAttributes;
     const variables_product = categoria.variables_product; 

     const filteredProducts = variables_product.filter(product => {
         return Object.keys(selectedAttributes).every(key => {
             if (selectedAttributes[key] === '' || !selectedAttributes[key]) {
                 return true;
             }
             return product.atributos[key] && product.atributos[key].includes(selectedAttributes[key]);
         });
     });
       
     categoria.idVariable = '';
     categoria.variables_product_select = filteredProducts;
     this.categoriasSeleccionadas[i] = categoria;

 }

  increment() {
    this.cantidadProducto++;
  }
  
  decrement() {
    if (this.cantidadProducto > 1) {
      this.cantidadProducto--;
    }
  }

  quitarProducto(i, categoria, producto_seleccionado) {

    
    const productoId = producto_seleccionado.producto.id;
    const variableId = producto_seleccionado.variable ? producto_seleccionado.variable.id : null;
    const productType = producto_seleccionado.producto.product_type;

    categoria.productos_seleccionados = categoria.productos_seleccionados.filter(p => {
        if (productType === "2") {
            return !(p.producto.id === productoId && p.variable && p.variable.id === variableId);
        } else {
            return p.producto.id !== productoId;
        }
    });

    this.categoriasSeleccionadas[i] = categoria;
  }


  quitarCategoria(i) {
    this.categoriasSeleccionadas.splice(i, 1);
  }

  async agregarCategoria() {

    if (this.selecteCategoriaID && this.cantidadProducto > 0) {

      const categoria_select = this.categorias.find(categoria => categoria.id === this.selecteCategoriaID);

      const categoriaExistente = this.categoriasSeleccionadas.find(categoria => categoria.id === this.selecteCategoriaID);


      if (categoriaExistente) {
        categoriaExistente.cantidad = this.cantidadProducto;
        console.error(`La categoría ${this.selecteCategoriaID} ya ha sido seleccionada.`);
        return;
      }
  
      const res = <any>await this.ProductsService.get_products_filter_by_category(this.selecteCategoriaID, this.filtros_product);
      
      this.categoriasSeleccionadas.push({
        id: categoria_select.id,
        name: categoria_select.name,
        cantidad: this.cantidadProducto,
        productos: res.products,
        productos_seleccionados: [],
        variables_product: [],
        atributes_product: [],
        selectedAttributes: [],
        idVariable: '',
        disabled_buton: true
      });
  
      this.selecteCategoriaID = null;
      this.cantidadProducto = 1;
  
      this.disabled_buton = false;
    } else {
      console.error('Selecciona una categoría válida y una cantidad mayor que cero.');
    }
  
  }

  agregarProducto(categoria, i) {

    var insertar_product = false;

    if (categoria.selectedProductId) {
      insertar_product = true;
      if(categoria.variables_product.length > 0){
        if (categoria.idVariable) {
          insertar_product = true;
        } else {
          insertar_product = false;
        }
      }
    }


    if (insertar_product) {
      
      const producto = categoria.productos.find(p => p.id == categoria.selectedProductId);

      if (producto) {
  
        let productoVariable = null;
        
        if (categoria.idVariable) {
          productoVariable = categoria.variables_product.find(v => v.id == categoria.idVariable);
        }


        const productoExistente = categoria.productos_seleccionados.find(p => 
            p.producto.id === producto.id &&
            (!productoVariable || (p.variable && p.variable.id == productoVariable.id))
        );


        if (!productoExistente) {

          var nombre_producto = producto.name;
          if (productoVariable) {
             nombre_producto = producto.name + " - " + productoVariable.name;
          }
          categoria.productos_seleccionados.push({ 
            name: nombre_producto,
            producto: producto, 
            variable: productoVariable 
          });
        }

        categoria.selectedProductId = null;
        categoria.cantidadProducto = 1;
        categoria.idVariable = null; 
        categoria.variables_product = [];

        this.categoriasSeleccionadas[i] = categoria;

      }
        
    }

  }

  



}
