import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-store-icon',
  templateUrl: './store-icon.component.html',
  styleUrls: ['./store-icon.component.scss']
})
export class StoreIconComponent implements OnInit {

  constructor() { }
  @Input() color: string = '';

  ngOnInit(): void {
  }

}
