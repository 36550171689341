<app-breadcrumb
  [title]="'Pedido'"
  [items]="['Pedido', 'Detalle']"></app-breadcrumb>
<div class="container-fluid">
  <div class="row">
    <div class="col-12 col-md-6"></div>
    <div class="col-12 col-md-6">
      <app-breadcrumb-body [items]="['Pedido', 'Detalle']"></app-breadcrumb-body>
    </div>
  </div>
</div>
<div class="container-fluid">
    <div class="card">
      <div class="card-body p-4">
        <h2>Administrar Pedido #{{ order_detail.code }}</h2>
        <nav class="breadcrumb-nav">
          <a routerLink="/ventas"> ← Volver a ventas</a>
        </nav>
        <div class="container-product">
            <h4>Información de Facturación</h4>
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="orderStatus">Estado del Pedido</label>
                  <select class="form-control" id="orderStatus" [(ngModel)]="order_detail.estado">
                    <option [value]="'pending'">Pendiente de Pago</option>
                    <option [value]="'processing'">Procesando</option>
                    <option [value]="'on-hold'">En Espera</option>
                    <option [value]="'completed'">Completado</option>
                    <option [value]="'cancelled'">Cancelado</option>
                    <option [value]="'refunded'">Reembolsado</option>
                    <option [value]="'failed'">Fallido</option>
                </select>
                </div>
              </div>
    
              <div class="col-12 col-md-6">
                <div class="form-group">
                    <label for="orderNumber">Número de Orden</label>
                    <input type="text" class="form-control" id="orderNumber" [(ngModel)]="order_detail.order_number" disabled>    
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                    <label for="orderDate">Fecha del Pedido</label>
                    <input type="datetime-local" class="form-control" id="orderDate" [(ngModel)]="order_detail.order_date" disabled>    
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                    <label for="subtotal">Subtotal</label>
                    <input type="text" class="form-control" id="subtotal" [(ngModel)]="order_detail.subtotal" disabled>
                </div>
              </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="tax">Impuestos</label>
                    <input type="text" class="form-control" id="tax" [(ngModel)]="order_detail.tax" disabled>                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="total">Total</label>
                    <input type="text" class="form-control" id="total" [(ngModel)]="order_detail.total" disabled>
                  </div>
                </div>
              </div>
        </div>
        <div class="container-product">
            <h4>Información del Cliente</h4>
            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label for="billingZip">Método de pago</label>
                        <input type="text" class="form-control" id="billingZip" [(ngModel)]="order_detail.metodo_pago" disabled style="text-transform: uppercase;">
        
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label for="customerName">Nombre del Cliente</label>
                        <input type="text" class="form-control" id="customerName" [(ngModel)]="order_detail.customer_name" disabled>
        
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label for="customerEmail">Correo Electrónico</label>
                        <input type="email" class="form-control" id="customerEmail" [(ngModel)]="order_detail.customer_email" disabled>
        
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label for="customerPhone">Teléfono</label>
                        <input type="tel" class="form-control" id="customerPhone" [(ngModel)]="order_detail.customer_phone" disabled>
                
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label for="customerPhone">¿Se le envío Boleta?</label>
                        <input type="text" class="form-control" id="customerTicket" [(ngModel)]="order_detail.email_enviado" disabled>
                
                    </div>
                </div>
                <div class="col-12 col-md-6" *ngIf="order_detail.email_enviado === 'Si'">
                    <div class="form-group">
                        <a (click)="ver_template()"> Ver Email <i class="fa fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-product">
            <h4>Detalle de Pedido</h4>
               
            <div class="row">
                <div class="col-12">
                    <div class="list-group">
                        <div *ngFor="let detail of order_detail_product" class="list-group-item d-flex align-items-center">
                            
                            <!-- Imagen del producto -->
                            <div class="product-image me-3">
                                <img [src]="detail.photo|| 'assets/images/default-image.svg'" alt="{{detail.nombre}}" class="img-thumbnail" style="width: 100px; height: 100px; object-fit: cover;">
                         
                                 <!-- <img src="{{detail.photo}}" alt="{{detail.nombre}}" class="img-thumbnail" style="width: 100px; height: 100px; object-fit: cover;"> -->
                            </div>
                            
                            <!-- Detalles del producto -->
                            <div class="product-details">
                                <h5 class="mb-1">{{detail.nombre}}</h5>
                                <p class="mb-1">Precio: {{detail.price | currency:'USD':'symbol'}}</p>
                                <p *ngIf="detail.quantity">Cantidad: {{detail.quantity}}</p>
                                <p>Tipo: {{detail.tipo}}</p>
                                
                                <!-- Productos Combo -->
                                <div *ngIf="detail.productos_combo.length > 0">
                                    <h6>Productos Combo:</h6>
                                    <ul class="list-unstyled mb-1">
                                        <li *ngFor="let item of detail.productos_combo">{{ item.name }}</li>
                                    </ul>
                                </div>
                                
                                <!-- Extras -->
                                <div *ngIf="detail.extras.length > 0">
                                    <h6>Extras:</h6>
                                    <ul class="list-unstyled mb-1">
                                        <li *ngFor="let extra of detail.extras">{{ extra.name }}</li>
                                    </ul>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group buttons mt-3">
                    <button [attr.disabled]="is_loading ? true : null" class="btn btn-primary" (click)="onSubmit()">Guardar Cambios</button>
                    <button class="btn btn-secondary" (click)="onCancel()">Cancelar</button>
                </div>
            </div>
        </div>
      </div>
    </div>
</div>




