import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { lastValueFrom } from 'rxjs'
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private http: HttpClient) { }

  async get_all_customers__disponibles() {
    const data = new FormData()
    data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
    const response = await lastValueFrom(this.http.post(`${environment.url_api}Customer/get_list_of_customers`, data));

    return response
  }

  async get_all_customers__disponibles_autoservicio() {
    const data = new FormData()
    const response = await lastValueFrom(this.http.post(`${environment.url_api}Customer/get_list_of_customers`, data));

    return response
  }

//   async makeAssignUserStore(store,user){
//     const data = new FormData()
//     data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
//     data.append('store', JSON.stringify(store))
//     data.append('user', JSON.stringify(user))
//     const response = await lastValueFrom(this.http.post(`${environment.url_api}Store/makeAssignUserStore`, data));
//     return response


//   }
}
