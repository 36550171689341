import { Component, OnInit} from '@angular/core';
import { ProductsService } from '../products.service';
import { OrdersService } from '../orders.service';

import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, Router} from '@angular/router';
import { Subscription, lastValueFrom, timer } from 'rxjs';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MailOrderComponent } from '../mail-order/mail-order.component';


import Swal from 'sweetalert2';  // Importa SweetAlert

@Component({
  selector: 'app-venta-details',
  templateUrl: './venta-details.component.html',
  styleUrls: ['./venta-details.component.scss']
})
export class VentaDetailsComponent implements OnInit {

  private routeSub: Subscription;
  jwt: string;
  is_loading = false;

  constructor(
    private ProductsService: ProductsService,
    private OrdersService: OrdersService,
    private route: ActivatedRoute,
    private http: HttpClient,
    private router: Router,
    private modalService: NgbModal,

  ) { }

  ver_template() {

    const dialogRef = this.modalService.open(MailOrderComponent, {
      size: 'xl'
    });

    dialogRef.componentInstance.boleta = this.boleta;

    dialogRef.result.then(
      (data) => {
        console.log(data)
      },
      (reason) => {
        console.log('Modal cerrado con motivo:', reason);
      }
    );

  }


  order_detail = {
    order_number: '',                // ID del pedido
    code: '',              // Código del pedido
    estado: '',            // Estado del pedido
    subtotal: '',          // Subtotal del pedido
    tax: '',               // Impuestos aplicados
    total: '',             // Total a pagar
    order_date: '',      // Fecha de creación del pedido
    metodo_pago: '',
    // Información del Cliente
    customer_name: '',     // Nombre del cliente
    customer_email: '',    // Correo electrónico del cliente
    customer_phone: '',    // Teléfono del cliente
    email_enviado: 'No',

    // Información de Facturación
    billing_address: '',   // Dirección de facturación
    billing_city: '',      // Ciudad de facturación
    billing_zip: '',       // Código postal de facturación
    billing_country: '',   // País de facturación

  
};


  order_id;
  tienda_selected;

  ngOnInit(): void {

    this.tienda_selected = JSON.parse(localStorage.tienda_selected);

    if (!this.tienda_selected) {
      this.router.navigate(['/']);
    }


    this.routeSub = this.route.params.subscribe(parametros => {

      if (isNaN(parametros.id) || parametros.id <= 0) { // Verifica si no es un número válido
        this.router.navigate(['/products-list']); // Redirige a otra ruta
      }

      this.order_id = parametros.id

      this.init_producto_by_id(this.order_id);

    })


    this.init_service();

  }

  async openDeleteConfirmationModal() {

    if (this.order_id) {

    Swal.fire({
      title: '¿Estás seguro?',
      text: 'Se eliminará completamente',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {

        const response = await this.ProductsService.eliminar_product(this.order_id);

        if (response.success) {

          Swal.fire(
            'Eliminado!',
            'El Pedido ha sido eliminado',
            'success'
          );

          this.router.navigate(['/products-list']); 

        }



      }
    });
     }
    
  }



  order_detail_product = [];

  boleta;

  async init_producto_by_id(id) {

    const res = <any>await this.OrdersService.get_order_by_id(id);
    const cliente_datos = res.cliente_datos;

 
    if (res.success) {
      this.order_detail_product = res.order_details;

      console.log(res);

      this.boleta = res.boleta.boleta;

      this.order_detail = {
        order_number: res.order.id,
        code: res.order.order_code,
        estado: res.order.status,
        subtotal: res.order.subtotal,
        tax: res.order.tax,
        total: res.order.total,
        order_date: res.order.created_time,
        metodo_pago: res.order.metodo_pago,
         // Información del Cliente
         customer_name: `${cliente_datos?.name ?? ''} ${cliente_datos?.last_name ?? ''}`.trim() || '',
         customer_email: cliente_datos?.additional_fields?.['Correo electrónico'] ?? '',
         customer_phone: cliente_datos?.additional_fields?.['Teléfono'] ?? '',
         email_enviado: res.order.send_mail == 1 ? 'Si' : 'No',

        // Información de Facturación
        billing_address: '',   // Dirección de facturación
        billing_city: '',      // Ciudad de facturación
        billing_zip: '',       // Código postal de facturación
        billing_country: '',   // País de facturación
      };

    

    } else {
      this.router.navigate(['/ventas']); 
    }
  }

  atributosExtra: any[]; 


  async init_service() {
  }


  validateFields() {
    return true;
  }



  onSubmit() {
    if (this.validateFields()) {
      this.is_loading = true;
      this.editOrder()

      this.is_loading = false;
    }
  }

  async editOrder() {

    const data = new FormData();
    data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
    data.append('order', this.order_id);
    data.append('estado', this.order_detail.estado);


   const res = <any>await this.OrdersService.editOrder(data)

   if (res.success) {
    Swal.fire({
        title: '¡Pedido Actualizado!',
        text: 'El producto se ha actualizado exitosamente.',
        icon: 'success',
        confirmButtonText: 'Perfecto',
        timer: 3000
    });
  } else {
    Swal.fire({
        title: 'Error al Actualizar Pedido',
        text: 'Hubo un problema al intentar actualizar el producto',
        icon: 'error',
        confirmButtonText: 'Entendido',
        timer: 3000
    });
  }
  



  }

  


  onCancel() {
    // Lógica para cancelar la operación
    console.log('Operación cancelada');
    Swal.fire('Cancelado', 'La Edición del producto ha sido cancelada.', 'info');
    this.router.navigate(['/ventas'])

  }









}
