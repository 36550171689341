<app-breadcrumb
  [title]="'Productos'"
  [items]="['Productos', 'Detalle Producto']"></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-12 col-md-6"></div>
        <div class="col-12 col-md-6">
        <app-breadcrumb-body
            [items]="['Productos', 'Detalle']"></app-breadcrumb-body>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="card">
        <div class="card-body p-4">
            <h2>Detalle Producto</h2>
            <nav class="breadcrumb-nav">
                <a routerLink="/products-list"> ← Volver a Productos</a>
            </nav>
            <div class="container-product">
                <div class="row">
                  <!-- Nombre del producto -->
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label for="nombreProducto">Nombre del Producto</label>
                      <input
                         disabled
                        type="text"
                        class="form-control"
                        id="nombreProducto"
                        placeholder="Ingrese el nombre del producto"
                        [(ngModel)]="producto_detail.nombre" />
                    </div>
                  </div>
        
                  <!-- Estado del Combo -->
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label for="estadoCombo">Estado</label>
                      <select disabled placeholder="Ingresa el estado" 
                        class="form-control" id="estadoCombo" [(ngModel)]="producto_detail.estado">
                        <option [value]="'activo'">Activo</option>
                        <option [value]="'desactivado'">Desactivado</option>
                     </select>
                    
                    </div>
                  </div>
        
                  <!-- Tipo del producto -->
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label for="categoriaProducto">Tipo de Producto</label>
                      <ng-select disabled
                        placeholder="Ingresa el tipo de producto"
                        bindValue="id"
                        bindLabel="name"
                        [items]="tipo"
                        [(ngModel)]="producto_detail.tipo">
                      </ng-select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <!-- Descripción del producto -->
                  <div class="col-12">
                    <div class="form-group">
                      <label for="descripcionProducto">Descripción</label>
                      <textarea
                        disabled
                        class="form-control"
                        id="descripcionProducto"
                        rows="3"
                        [(ngModel)]="producto_detail.descripcion"></textarea>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <!-- Categoría del producto -->
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label for="categoriaProducto">Categoría</label>
                      <ng-select
                        disabled
                        placeholder="Elige la categoría"
                        bindValue="id"
                        bindLabel="name"
                        [items]="categorias"
                        [(ngModel)]="producto_detail.categoria">
                      </ng-select>
                    </div>
                  </div>
        
                  <!-- Tipo del producto -->
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label for="categoriaProducto">Tamaño Producto</label>
                      <ng-select
                      disabled
                        bindValue="id"
                        bindLabel="name"
                        [items]="size"
                        [(ngModel)]="producto_detail.size">
                      </ng-select>
                    </div>
                  </div>
                </div>
            </div>
            <div class="container-product">
                <div class="row">
                  <!-- Precio del producto -->
        
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label for="stockProducto">Precio</label>
                      <input
                        disabled
                        type="number"
                        class="form-control"
                        id="precio"
                        placeholder="Ingrese Precio"
                        [(ngModel)]="producto_detail.precio" />
                    </div>
                  </div>
        
                  <!-- Stock del producto -->
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label for="stockProducto">Stock</label>
                      <input disabled type="number"
                      class="form-control"
                      id="precio"
                      placeholder="Ingrese stock"
                      [(ngModel)]="producto_detail.stock"
                      min="0"
                      max="2"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                      >
                    </div>
                  </div>
                </div>
            </div>
            <div class="container-product">
                <h3>Azúcar</h3>
                <div class="row">
                  <!-- Selección de productos -->
        
                  <div class="col-12 col-md-12 d-flex align-items-center">
              
                    <div class="content-switch">
                      <p class="content-switch-text">Requerido:</p>
                      <label class="switch">
                        <input
                          type="checkbox"
                          [checked]="producto_detail.azucar_requerido == 1" /><span
                          class="switch-state"></span>
                      </label>
                    </div>
                  </div>
        
                </div>
                <div class="row">
                  <!-- Productos seleccionados -->
                  <div *ngIf="producto_detail.extras_azucar.length != 0">
                    <div class="col-12">
                      <h3 class="mt-15">Seleccionados</h3>
                      <ul class="list-group">
                        <li
                          *ngFor="let item of producto_detail.extras_azucar"
                          class="list-group-item d-flex justify-content-between align-items-center">
                          {{ item.extra.name }} - Precio: {{ item.precio }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
            </div>
            <div class="container-product">
                <h3>Salsas</h3>
                <div class="row">
                  <div class="col-12 col-md-12 d-flex align-items-center">
         
                    <div class="content-switch">
                      <p class="content-switch-text">Requerido:</p>
                      <label class="switch">
                        <input
                          type="checkbox"
                          [checked]="producto_detail.extra_requerido == 1" /><span
                          class="switch-state"></span>
                      </label>
                    </div>
                  </div>
                  <div class="form-group col-4">
                    <label for="stockProducto">Cantidades Salsas</label>
                    <input
                      type="number"
                      class="form-control"
                      disabled=""
                      id="stockProducto"
                      placeholder="Ingrese Cantidades Extras"
                      [(ngModel)]="producto_detail.qty_extras"
                      min="0"
                      max="2"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                  </div>
        
                 
                  <div class="row">
                    <!-- Productos seleccionados -->
                    <div *ngIf="producto_detail.extras.length != 0">
                      <div class="col-12">
                        <h3 class="mt-15">Seleccionados</h3>
                        <ul class="list-group">
                          <li
                            *ngFor="let item of producto_detail.extras"
                            class="list-group-item d-flex justify-content-between align-items-center">
                            {{ item.extra.name }} - Precio: {{ item.precio }}
                            
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            <div class="container-product">
                <div class="row">
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                 
                        <!-- Image preview or default image -->
                        <div class="upload-image">
                          <div class="upload-image-preview">
                            <img
                              *ngIf="imagePreview"
                              [src]="imagePreview"
                              alt="Imagen del Producto"
                              class="img-thumbnail" />
                            <div
                              *ngIf="!imagePreview"
                              style="
                                width: 140px;
                                height: 140px;
                                margin-bottom: 0;
                                background-color: #adb5bd;
                              "></div>
                            <ul>
                              <li>Formatos permitidos: JPG, JPEG, PNG.</li>
                              <li>Tamaño máximo: 5000 x 5000 píxeles.</li>
                              <li>Peso máximo: 16 MB</li>
                            </ul>
                          </div>
                      
                        </div>
             
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            <ng-container *ngIf="producto_detail.tipo == 2">
                <div class="container-product">
                  <h3>Variaciones</h3>
        
                  <div class="row">
                    <h4>Variaciones Seleccionadas</h4>
                                <ul class="list-group">
                                    <li *ngFor="let item of producto_detail.variables; let i = index" class="list-group-item">
                                        <div class="d-flex justify-content-between align-items-center" (click)="toggleAcordeon(i)" >
                                            <!-- Título del disparador -->
                                            <div>
                                                <p>
                                                    <span *ngIf="!item.abierto"> ▼</span>  <!-- Ícono de desplegar -->
                                                    <span *ngIf="item.abierto"> ▲</span>   <!-- Ícono de colapsar --> 

                                                    <span *ngFor="let atributo of item.attributes;" class="badge badge-pill border border-primary text-primary px-3 py-2 mx-1">
                                                        <strong>{{ atributo.atributo_padre_name }}:</strong> {{ atributo.valor }}
                                                      </span>
                                                      
                                                 
                                                 </p>

                                               
                                            </div>
                        
                                        </div>
                        
                                        <!-- Contenido del acordeón que se muestra/oculta -->
                                        <div *ngIf="item.abierto" class="mt-3">

                                            <div class="row">
                                                <!-- Campo de Nombre -->
                                                <div class="col-md-6 mt-2">
                                                    <label for="nombre{{ i }}">Nombre:</label>
                                                    <input 
                                                        disabled
                                                        type="text" 
                                                        class="form-control" 
                                                        id="nombre{{ i }}" 
                                                        [(ngModel)]="item.name" 
                                                        placeholder="Nombre de la variación">
                                                </div>
                                                                  
                        
                                                <!-- Campo de Precio -->
                                                <div class="col-md-6 mt-2">
                                                    <label for="stock{{ i }}">Stock:</label>
                                                    <input 
                                                        disabled
                                                        type="number" 
                                                        class="form-control" 
                                                        id="stock{{ i }}" 
                                                        [(ngModel)]="item.stock" 
                                                        placeholder="Stock">
                                                </div>

                                                <div class="col-md-6 mt-2">
                                                    <label for="precio{{ i }}">Precio:</label>
                                                    <input 
                                                        disabled
                                                        type="number" 
                                                        class="form-control" 
                                                        id="precio{{ i }}" 
                                                        [(ngModel)]="item.price" 
                                                        placeholder="Precio">
                                                </div>

                                                <div class="col-md-12 mt-2">
                                                    <label for="descripcion{{ i }}">Descripción:</label>
                                                    <textarea 
                                                    disabled
                                                    class="form-control" 
                                                    id="descripcion{{ i }}" 
                                                    [(ngModel)]="item.description" 
                                                    placeholder="" 
                                                    rows="3">
                                                </textarea>
                                                
                                                </div>

                                                <div class="col-md-12 mt-2">
                                                   

                            
                                                    <!-- Image preview or default image -->
                                                    <img *ngIf="item.imagePreview" [src]="item.imagePreview" alt="Imagen del Producto" 
                                                        class="img-thumbnail" style="max-width: 200px; margin: 10px;">

                                                </div>
                                                

                                            </div>
                                        </div>
                                    </li>
                                </ul>
                  </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>




<div class="container">
   

   
        
    <!-- Botones -->
    <div class="form-group buttons">
        <button class="btn btn-primary" (click)="regresar()">Regresar a Productos</button>
    </div>
</div>
