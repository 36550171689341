<app-breadcrumb [title]="'Combos'" [items]="['Combos','Detalle Combo']"></app-breadcrumb>

<div class="container">
  <div class="form-group">
    <nav class="breadcrumb-nav">
      <a routerLink="/combos"> ← Volver a Combos</a>
    </nav>
  </div>
</div>

<div class="container">


  <div class="form-group row">
    <div class="col-12 d-flex justify-content-between align-items-center">
      <h2>Detalle Combo</h2>
    </div>
  </div>


    <div class="row">
        <!-- Nombre del Combo -->
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="nombreProducto">Nombre del Combo</label>
                <input disabled type="text" class="form-control" id="nombreProducto" [(ngModel)]="combo_detail.nombre">
            </div>
        </div>


      <!-- Estado del Combo -->
        <div class="col-12 col-md-6">
            <div class="form-group">
            <label for="estadoCombo">Estado</label>
            <select disabled class="form-control" id="estadoCombo" [(ngModel)]="combo_detail.estado">
                <option [value]="'activo'">Activo</option>
                <option [value]="'desactivado'">Desactivado</option>
            </select>
            </div>
        </div>

    </div>
  
    <div class="row">
        <!-- Descripción del Combo -->
        <div class="col-12">
        <div class="form-group">
            <label for="descripcionProducto">Descripción</label>
            <textarea disabled class="form-control" id="descripcionProducto" rows="3" [(ngModel)]="combo_detail.descripcion"></textarea>
        </div>
        </div>
    </div>

    <!-- Precio del producto -->

    <div class="col-12 col-md-6">
        <div class="form-group">
            <label for="stockProducto">Precio</label>
            <input disabled type="number"
                    class="form-control"
                    id="precio"
                    [(ngModel)]="combo_detail.precio"
                    >
        </div>
    </div>
  
      <!-- Imagen del producto -->
      <div class="row">
        <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <label for="imagenProducto">Imagen del Combo</label>
                    <!-- Image preview or default image -->
 
                
                           <img *ngIf="imagePreview" [src]="imagePreview" alt="Imagen del Producto" class="img-thumbnail" style="max-width: 100px; margin: 10px;">

                     

                </div>
            </div>
        </div>

    <div class="form-group mt-2 mb-2">
        <h3>Administrar de Combo</h3>
    </div>

      <!-- Selección de Categoría -->


      <ng-container *ngIf="categoriasSeleccionadas.length > 0">
        <div class="form-group mt-2 mb-2">
          <h3 >Categorías: </h3>
        </div>
        <div *ngFor="let categoria of categoriasSeleccionadas; let i = index" class="card mt-4">
          <div class="card-body">
            <div class="row">
      
      
              <div class="col-12 col-md-12 row">
                <div class="col-12 col-md-6">
                  <h4 class="align-middle"> {{ categoria.name }} </h4>
                </div>
      
                <div class="col-12 col-md-3">
                  <label> Cantidad </label>
                  <input disabled type="number" class="form-control text-center border-left-0 border-right-0" [(ngModel)]="categoria.cantidad" />  
                </div>

            

              </div>
      
              
              <ng-container *ngIf="categoria.variables_product.length != 0" >
                <div  class="col-12 col-md-12 row mt-2">
                  <div class="col-12 col-md-4" *ngFor="let item of categoria.atributes_product | keyvalue">
                    <label>{{ item.key }}</label>
                    
                 
                  </div>
                </div>
        
                <div class="col-12 col-md-12 mt-2">
                  <div class="col-12 col-md-4 form-group">
                    <label for="productosSelect">Seleccionar Producto Variable</label>
                    <ng-select bindValue="id" bindLabel="name"
                        [items]="categoria.variables_product_select" class="js-example-basic-multiple"
                        [(ngModel)]="categoria.idVariable">
                    </ng-select>
                  </div>
                </div>
              </ng-container>
              
    
            </div>
        
            <!-- Lista de productos seleccionados para la categoría -->
            <div *ngIf="categoria.productos_seleccionados.length > 0" class="mt-4">
              <h5>Productos Seleccionados:</h5>
      
              <ul class="list-group">
                <li *ngFor="let producto_seleccionado of categoria.productos_seleccionados" class="list-group-item d-flex justify-content-between align-items-center">
              
                  <p>
                    {{ producto_seleccionado.name }}
                  </p>
      
                </li>
              </ul>
      
            </div>
      
            
          </div>
        </div>
      </ng-container>


  <div class="row">
    <!-- Botones -->
    <div class="form-group buttons">
      <button [attr.disabled]="is_loading ? true : null" class="btn btn-primary" (click)="Regresar()">Regresar a Combos</button>
    </div>
  </div>
</div>
