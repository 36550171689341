import { Component, OnInit } from '@angular/core';
import { BlackListService } from '../../../src/app/black-list.service';
import { ProductsService } from '../products.service';
import { OrdersService } from '../orders.service';

import { ActivatedRoute, Router} from '@angular/router';
import { lastValueFrom } from 'rxjs'
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-cierre-ventas',
  templateUrl: './cierre-ventas.component.html',
  styleUrls: ['./cierre-ventas.component.scss']
})
export class CierreVentasComponent implements OnInit {
  protected orders = []
  protected sales_summary

  // ARRAY CON FILTROS
  filtros
  buttonFiltrarTexto
  isCollapsed
  default_values
  cantidad_dias = 0;
  jwt: string;
  clientes_nuevos;
  clientes_orders;
  tienda_selected
  plataformas : []
  productos :[]
  tiendas :[]
  productosMasVendidos = [];
  formaDePago = []; 
  transacciones
  cantidad_clientes = 0
  productos_order
  plataformas_order

  currency_selected
  currency_symbol_selected
  
  constructor(
    private BlackListService: BlackListService,
    private ProductsService: ProductsService,
    private OrdersService: OrdersService,
    private router: Router,
    private http: HttpClient,
  ) { 

    this.filtros = {}
    this.jwt = JSON.parse(localStorage.user).jwt
    this.tienda_selected = JSON.parse(localStorage.tienda_selected)
    this.default_values = {}


  }

  async ngOnInit() {
    this.currency_selected = localStorage.getItem('currency_selected')
    this.currency_symbol_selected = localStorage.getItem('currency_symbol_selected')
    this.setFechas();
    this.init_service()
    this.load_resumen()
    this.load_values()
  }


  rerender(): void {

    const fechaInicio = new Date(this.filtros.fecha_inicio);
    const fechaFin = new Date(this.filtros.fecha_fin);
  


    this.cantidad_dias = this.calcularDiasEntreFechas(fechaInicio, fechaFin);

    this.buttonFiltrarTexto = `Cargando...`
    this.load_resumen();

    this.buttonFiltrarTexto = "Buscar"

  }

  async init_service() {
    
    const data = new FormData()
    data.append('jwt', this.jwt)
    data.append('tienda_selected', this.tienda_selected)
 
    const res = <any>await lastValueFrom(this.http.post(`${environment.url_api}Ventas/load_ventas_init_general`, data))

    if (res.success) {

      this.plataformas = res.payload.plataformas;
      this.productos = res.payload.productos;
      this.tiendas = res.payload.tiendas;

    }


  }

  async load_resumen() {

    Swal.showLoading()

    console.log(this.filtros)

    const res = <any>await this.OrdersService.get_orders_cierre_ventas(this.filtros,this.tienda_selected);
    console.log(res)
    if (res.success) {
       this.productos_order = res.productos;
       this.plataformas_order = res.plataformas;
       this.sales_summary = res.sales_summary;
       this.transacciones = res.transacciones;
       this.clientes_nuevos = res.clientes_nuevos;
       this.clientes_orders = res.clientes_orders;
       this.cantidad_clientes = res.cantidad_clientes;
  
    }

    Swal.close()
  }

  setFechas() {
    const fechaActual = new Date();
    const primerDiaDelMes = new Date(fechaActual.getFullYear(), fechaActual.getMonth(), 1);
    this.filtros.fecha_inicio = this.formatFecha(primerDiaDelMes);
    this.filtros.fecha_fin = this.formatFecha(fechaActual);

     this.cantidad_dias = this.calcularDiasEntreFechas(primerDiaDelMes, fechaActual);
  }

  calcularDiasEntreFechas(fechaInicio: Date, fechaFin: Date): number {
    const milisegundosPorDia = 1000 * 60 * 60 * 24;
    const diferenciaMilisegundos = fechaFin.getTime() - fechaInicio.getTime();
    return Math.ceil(diferenciaMilisegundos / milisegundosPorDia);
  }

  formatFecha(fecha: Date): string {
    const anio = fecha.getFullYear();
    const mes = ('0' + (fecha.getMonth() + 1)).slice(-2); // Se asegura que el mes sea de dos dígitos
    const dia = ('0' + fecha.getDate()).slice(-2); // Se asegura que el día sea de dos dígitos
    return `${anio}-${mes}-${dia}`;
  }

  async load_values() {
    this.buttonFiltrarTexto = "Buscar"
  }
}
