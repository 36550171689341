import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  @Input() title: string = ''; // El título que puede contener la palabra a reemplazar
  @Input() items: any[] = [];
  @Input() active_item: string = '';

  public userName: string

  constructor(public router: Router) {
    this.userName = JSON.parse(localStorage.getItem("user")).nombres
  }

  ngOnInit() {
    // Reemplazar "#NOMBRE_PAGINA" por el valor de "title"
    if (this.title) {
      // Seleccionar el div por su id
      var titleDiv = document.getElementById("tittle_page");
      titleDiv.innerHTML = this.title; 

    }
  }


  logoutFunc(event: Event) {
    // this.usersService.SignOut();
    event.preventDefault();
    console.log('✅ Logout')
    localStorage.removeItem('user');
    localStorage.removeItem('lead_comercial__filtros')
    localStorage.removeItem('tienda_selected')
    this.userName = null
    this.router.navigate(['auth/login']);
  }


}
