<div class="table-swtch-status" [class.active]="status">
  <span class="status-text">{{ status ? "Habilitado" : "Deshabilitado" }}</span>
  <label class="switch" style="margin-bottom: 0 !important">
    <input
      type="checkbox"
      [checked]="status"
      (change)="updateStatus($event.target.checked)"
      />
    <span class="switch-state"></span>
  </label>
</div>
