import { Component, OnInit } from '@angular/core';
import { BlackListService } from '../../../src/app/black-list.service';
import { ProductsService } from '../products.service';
import { OrdersService } from '../orders.service';

import { ActivatedRoute, Router} from '@angular/router';
import { lastValueFrom } from 'rxjs'
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import Swal from 'sweetalert2';
@Component({
  selector: 'app-resumen-ventas',
  templateUrl: './resumen-ventas.component.html',
  styleUrls: ['./resumen-ventas.component.scss']
})
export class ResumenVentasComponent implements OnInit  {

  protected orders = []
  protected sales_summary = []

  // ARRAY CON FILTROS
  filtros
  buttonFiltrarTexto
  isCollapsed
  default_values

  currency_selected
  currency_symbol_selected

  constructor(
    private BlackListService: BlackListService,
    private ProductsService: ProductsService,
    private OrdersService: OrdersService,
    private router: Router,
    private http: HttpClient,
  ) { 

    this.filtros = {}
  
    this.default_values = {}


  }

  
  async ngOnInit() {
    this.currency_selected = localStorage.getItem('currency_selected')
    this.currency_symbol_selected = localStorage.getItem('currency_symbol_selected')
    this.setFechas();
    this.init_service()
    this.load_resumen()
    // this.load_values()


    // if (localStorage.user) {
    //   if (JSON.parse(localStorage.user).groups[0]['id'] == 5) {

    //     this.router.navigate(['/kitchen-display'])

    //   }

    //   if (JSON.parse(localStorage.user).groups[0]['id'] == 2) {

    //     this.router.navigate(['/create-sale'])

    //   }

    // }

  }

  

  getTodayDate(): string {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = today.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  setTodayIfEmpty(): void {
    if (!this.filtros.dia) {
      this.filtros.dia = this.getTodayDate();
    }
  }

  jwt: string;

  // tienda_selected;
  clientes;
  async init_service() {
    this.jwt = JSON.parse(localStorage.user).jwt;

    // this.tienda_selected = JSON.parse(localStorage.tienda_selected);

    // if (this.tienda_selected) {
    //   this.filtros.tienda_id = this.tienda_selected;
    // }

  }

  rerender(): void {

    const fechaInicio = new Date(this.filtros.fecha_inicio);
    const fechaFin = new Date(this.filtros.fecha_fin);
  


    this.cantidad_dias = this.calcularDiasEntreFechas(fechaInicio, fechaFin);

    this.buttonFiltrarTexto = `Cargando...`
    this.load_resumen();

    this.buttonFiltrarTexto = "Filtrar"

  }




  async load_values() {

    this.buttonFiltrarTexto = "Filtrar"


  }

  selectedOrders = [];





  validarCorreo(correo: string): boolean {
    const cadena = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (correo) {
      return cadena.test(correo);
    } else {
      return false;
    }
  }

  validarCelular(celular: string): boolean {
    if (celular) {
      if (this.isNumber(celular)) {
        if (celular.length === 9) {
          return true;
        }
      }
    }
    return false;
  }

  private isNumber(n: any): boolean {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }



  CrearVenta() {
    this.router.navigate(['/create-sale']); 
  }

  cantidad_dias = 0;

  
  setFechas() {
    const fechaActual = new Date();
    const primerDiaDelMes = new Date(fechaActual.getFullYear(), fechaActual.getMonth(), 1);
    this.filtros.fecha_inicio = this.formatFecha(primerDiaDelMes);
    this.filtros.fecha_fin = this.formatFecha(fechaActual);

     this.cantidad_dias = this.calcularDiasEntreFechas(primerDiaDelMes, fechaActual);
  }

  calcularDiasEntreFechas(fechaInicio: Date, fechaFin: Date): number {
    const milisegundosPorDia = 1000 * 60 * 60 * 24;
    const diferenciaMilisegundos = fechaFin.getTime() - fechaInicio.getTime();
    return Math.ceil(diferenciaMilisegundos / milisegundosPorDia);
  }

  formatFecha(fecha: Date): string {
    const anio = fecha.getFullYear();
    const mes = ('0' + (fecha.getMonth() + 1)).slice(-2); // Se asegura que el mes sea de dos dígitos
    const dia = ('0' + fecha.getDate()).slice(-2); // Se asegura que el día sea de dos dígitos
    return `${anio}-${mes}-${dia}`;
  }


  productosMasVendidos = [];
  productos = [];
  ventas_por_usuario = [];
  metricas
  order_cash = 0
  order_creditcard = 0
  cardColor: string = '#232837';


  async load_resumen() {

    Swal.showLoading()

    const res = <any>await this.OrdersService.get_orders_resumen_dia(this.filtros);

    if (res.success) {
      console.log(res)
       this.metricas = res.metricas;
       this.order_cash = res.order_cash
       this.order_creditcard = res.order_creditcard
       this.productos = res.productos
       this.ventas_por_usuario = res.ventas_por_usuario
      // this.sales_summary = res.sales_summary;
      // this.pieChart = res.estados;
      // this.formaDePago = res.transacciones;
      // this.clientes = res.clientes;
    }

    Swal.close()
  }


  pieChart = [];
  formaDePago = []; 
  pieChartColorScheme = {
    domain: ['#121A90FF', '#F5DA09FF']
  };

  onSelect(event: any): void {
    console.log('Elemento seleccionado:', event);
  }


  view: [number, number] = [600, 400]; // Tamaño del gráfico

  // Opciones del gráfico
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = true;
  xAxisLabel = 'Productos';
  showYAxisLabel = true;
  yAxisLabel = 'Cantidad Vendida';
  animations = true;

  colorScheme = {
    domain: ['#121A90FF', '#F5DA09FF']
  };
  





}
