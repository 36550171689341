import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { lastValueFrom } from 'rxjs'
import { environment } from '../environments/environment';
const Swal = require('sweetalert2')


@Injectable({
  providedIn: 'root'
})


export class OrdersService {

  constructor(private http: HttpClient, public router: Router) { 


    
  }

  async get_orders_resumen_dia(filtros) {
    const data = new FormData()
    data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
    data.append('filtros', JSON.stringify(filtros));
  
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}Ventas/get_resumen_dia_by_filter`, data));
    return response
  }

  async get_orders_cierre_ventas(filtros, tienda_id) {
    const data = new FormData()
    data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
    data.append('tienda_id', JSON.stringify(tienda_id));
    data.append('filtros', JSON.stringify(filtros));
  
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}Ventas/get_orders_cierre_ventas`, data));
    return response
  }

  async get_reporte_ventas(filtros) {
    const data = new FormData()
    data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
    data.append('filtros', JSON.stringify(filtros));
  
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}Ventas/get_reporte_ventas`, data));
    return response
  }

  async get_reporte_ventas_productos(filtros) {
    const data = new FormData()
    data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
    data.append('filtros', JSON.stringify(filtros));
  
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}Ventas/get_reporte_ventas_productos`, data));
    return response
  }

  async get_reporte_ventas_clientes(filtros) {
    const data = new FormData()
    data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
    data.append('filtros', JSON.stringify(filtros));
  
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}Ventas/get_reporte_ventas_clientes`, data));
    return response
  }

  

  
  async get_orders_filter(filtros) {
    const data = new FormData()
    data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
    data.append('filtros', JSON.stringify(filtros));
  
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}Ventas/get_orders_by_filter`, data));
    return response
  }

  async get_orders__display_kitchen_filter(filtros) {
    const data = new FormData()
    data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
    data.append('filtros', JSON.stringify(filtros));
  
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}Ventas/get_orders_diplay_kitchen_by_filter`, data));
    return response
  }



  async get_order_by_id( id) {

    const jwt = JSON.parse(localStorage.user).jwt;
    const tienda_selected = JSON.parse(localStorage.tienda_selected);

    const data = new FormData()
    data.append('jwt', jwt)
    data.append('order_id', id)
    data.append('tienda_selected', tienda_selected)

    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}Ventas/get_order_id_detail`, data))
    return response;
  }

  async eliminar_orders_seleccionados( select: any) {
    const data = new FormData()
    data.append('seleccionados', JSON.stringify(select));
  
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}Ventas/EliminarOrdersSeleccionados`, data))
    return response
  }

  async eliminar_Order( id: any) {
    const data = new FormData()
    data.append('id', id)
    data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
    data.append('group', JSON.parse(localStorage.getItem("user")).groups[0]['id'] )
    
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}Ventas/EliminarOrder`, data))
    return response
  }


  async editOrder(data) {
    
    const response = await lastValueFrom(this.http.post(`${environment.url_api}Ventas/edit_order`, data));

    return response
  }
}
