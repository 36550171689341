import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { DomSanitizer } from '@angular/platform-browser';
import { StoreService } from '../shared/services/store.service'
import { UsersService } from '../shared/services/users.service'
import { Router } from '@angular/router';

import Swal from 'sweetalert2'

@Component({
  selector: 'app-assign-user-store',
  templateUrl: './assign-user-store.component.html',
  styleUrls: ['./assign-user-store.component.scss']
})
export class AssignUserStoreComponent implements OnInit {
  
  store = {
    id: null,
    name: null,
  }

  user = {
    id: null,
    username: null,
  }

  list_of_users = []
  list_of_stores = []

  constructor(
    private storeService: StoreService,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer,
    private userService: UsersService,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.init()
  }

  init() {
    this.loadUsers()
    this.loadStores()
  }

  async get_store(store){
    console.log(store)
  }

  // async get_users(){
  //   console.log('here')
  // }

  async loadUsers() {
    const res = <any>await this.userService.get_all_users__disponibles()
    if (res.success) {
      this.list_of_users = res.users
    }
  }

  async loadStores() {

    const res = <any>await this.storeService.get_all_stores__disponibles()

    if (res.success) {
      this.list_of_stores = res.stores
    }
  }

  async addUsersToStore(){
   
    if (!this.store.id) {
      Swal.fire('Debe seleccionar una tienda', '', 'error')
      return 0
    }

    if (!this.user.id) {
      Swal.fire('Debe seleccionar un usuario como mínimo', '', 'error')
      return 0
    }

    const res = <any>await this.storeService.makeAssignUserStore(this.store,this.user)
    if (res.success) {
      Swal.fire({
        icon: 'success',
        title: res.message,
        text: ''
      });
    }

  }



}
