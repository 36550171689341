import { Component, OnDestroy, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { CampusService } from 'src/app/shared/services/campus.service';
import { LeadsService } from 'src/app/shared/services/leads.service';
import { environment } from '../../../environments/environment';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';


import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { ContentObserver } from '@angular/cdk/observers';

const Swal = require('sweetalert2')


// import { Person } from '';

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
  detallado_leads: any;
}

@Component({
  selector: 'app-select-tienda',
  templateUrl: './select-tienda.component.html',
  styleUrls: ['./select-tienda.component.scss']
})



export class SelectTiendaComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;


  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<void> = new Subject();

  spanish_datatable

  tableCabeceras: any
  dataTablesParameters: any
  persons: []

  private routeSub: Subscription;
  tienda_seleccionada
  nombre_tienda_seleccionada
  detallado_leads
  tiendas: [];
  isCollapsed
  default_values

  user_type_id
  num_of_cols

  // ARRAY CON FILTROS
  filtros


  buttonFiltrarTexto

  campus_name = ''


  leads_selected = []


  public multipleSelectedCity: string[];






  constructor(private campus: CampusService,
    private route: ActivatedRoute,
    private leadService: LeadsService,
    private http: HttpClient,
    private modalService: NgbModal,
  ) {
    this.user_type_id = JSON.parse(localStorage.user).user_type_id


    if (this.user_type_id != 2) {
      this.tableCabeceras = [
        {
          'id': 1,
          'name': 'Estado'
        },
        {
          'id': 2,
          'name': 'Nombres'
        },
        {
          'id': 3,
          'name': 'Celular'
        },
        {
          'id': 4,
          'name': 'Correo electrónico'
        },
        {
          'id': 5,
          'name': 'DNI'
        },
        {
          'id': 6,
          'name': 'Fecha del lead'
        },
        {
          'id': 7,
          'name': 'Plataforma'
        },
        {
          'id': 8,
          'name': 'Modelo'
        },
        {
          'id': 9,
          'name': 'Acciones'
        },
      ]
    } else {
      this.tableCabeceras = [
        {
          'id': 1,
          'name': 'Estado'
        },
        {
          'id': 2,
          'name': 'Nombres'
        },
        {
          'id': 5,
          'name': 'DNI'
        },
        {
          'id': 6,
          'name': 'Fecha del lead'
        },
        {
          'id': 7,
          'name': 'Plataforma'
        },
        {
          'id': 8,
          'name': 'Modelo'
        },
        {
          'id': 9,
          'name': 'Acciones'
        },
      ]
    }


    this.num_of_cols = 8
    if (this.user_type_id == 2) {
      this.num_of_cols = 6
    }

    this.persons = []
    this.filtros = {}
    if (localStorage.getItem("lead_comercial__filtros")) {
      this.filtros = JSON.parse(localStorage.getItem("lead_comercial__filtros"))
    }

    this.filtros.jwt = JSON.parse(localStorage.user).jwt

    this.detallado_leads = {
      frios: 0,
      tibios: 0,
      calientes: 0,
      reprogramados: 0,
      pendientes: 0,
      ventas: 0,
      total: 0
    }

    this.default_values = {}
    this.isCollapsed = true

    this.spanish_datatable = {
      processing: "Procesando...",
      search: "Buscar:",
      lengthMenu: "Mostrar _MENU_ elementos",
      info: "Mostrando desde _START_ al _END_ de _TOTAL_ elementos",
      infoEmpty: "Mostrando ningún elemento.",
      infoFiltered: "(filtrado _MAX_ elementos total)",
      infoPostFix: "",
      loadingRecords: "Cargando registros...",
      zeroRecords: "",
      // zeroRecords: "No se encontraron registros",
      emptyTable: "No hay datos disponibles en la tabla",
      paginate: {
        first: "Primero",
        previous: "Anterior",
        next: "Siguiente",
        last: "Último"
      },
      aria: {
        sortAscending: ": Activar para ordenar la tabla en orden ascendente",
        sortDescending: ": Activar para ordenar la tabla en orden descendente"
      }
    }

    this.buttonFiltrarTexto = `Filtrar`

  }





  showTabla(tienda_seleccionada) {
    const that = this;
    this.filtros.tienda_seleccionada = tienda_seleccionada

    const to_send = new FormData()



    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      processing: true,
      searching: false,
      order: [5, 'DESC'],
      columnDefs: [
        { "orderable": false, "targets": [this.num_of_cols] }
      ],
      language: this.spanish_datatable,
      ajax: (dataTablesParameters, callback) => {
        const info = Object.assign(dataTablesParameters,
          this.filtros
        )

        to_send.append('data', JSON.stringify(info))


        that.http
          .post<DataTablesResponse>(
            `${environment.url_api}LeadAPI/get_leads_test`,
            to_send
          ).subscribe(resp => {
            resp.data.map(el => {
              if (this.leads_selected.find(element => element == el.id)) {
                el.checked = true
              } else {
                el.checked = false
              }
            })
            console.log(resp.data)
            that.persons = <any>resp.data
            const detalle = <any>resp.detallado_leads
            console.log("FILTRO => ", this.filtros)
            console.log("FILTRO => ", JSON.stringify(this.filtros))
            localStorage.setItem("lead_comercial__filtros", JSON.stringify(this.filtros))
            this.buttonFiltrarTexto = "Filtrar"


            this.detallado_leads = {
              frios: detalle.find(detalle => detalle.estado_cliente == "Frio")?.cantidad != undefined ? detalle.find(detalle => detalle.estado_cliente == "Frio")?.cantidad : '0',
              tibios: detalle.find(detalle => detalle.estado_cliente == "Tibio")?.cantidad != undefined ? detalle.find(detalle => detalle.estado_cliente == "Tibio")?.cantidad : '0',
              calientes: detalle.find(detalle => detalle.estado_cliente == "Caliente")?.cantidad != undefined ? detalle.find(detalle => detalle.estado_cliente == "Caliente")?.cantidad : '0',
              reprogramados: detalle.find(detalle => detalle.estado_cliente == "Reprogramado")?.cantidad != undefined ? detalle.find(detalle => detalle.estado_cliente == "Reprogramado")?.cantidad : '0',
              pendientes: detalle.find(detalle => detalle.estado_cliente == "Pendiente")?.cantidad != undefined ? detalle.find(detalle => detalle.estado_cliente == "Pendiente")?.cantidad : '0',
              ventas: detalle.find(detalle => detalle.estado_cliente == "Venta")?.cantidad != undefined ? detalle.find(detalle => detalle.estado_cliente == "Venta")?.cantidad : '0',
              total: 0
            }

            console.log('leads_selected', this.leads_selected)

            this.detallado_leads.total = Number.parseInt(this.detallado_leads.frios) + Number.parseInt(this.detallado_leads.tibios) + Number.parseInt(this.detallado_leads.calientes) + Number.parseInt(this.detallado_leads.reprogramados) + Number.parseInt(this.detallado_leads.pendientes) + Number.parseInt(this.detallado_leads.ventas)

            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: this.detallado_leads.total,
              data: []
            });
          });
      },
      // columns: [{ data: 'id' }, { data: 'firstName' }, { data: 'lastName' }]
    };
  }

  async ngOnInit() {
    window.scrollTo({ top: 0, behavior: 'smooth' })


    this.tienda_seleccionada = -1
    this.routeSub = this.route.params.subscribe(params => {
      this.tienda_seleccionada = params['tienda_id']
    })


    if (this.tienda_seleccionada === undefined) {
      this.tiendas = <any>await this.campus.get_list_of_campus()
    } else {
      this.showTabla(this.tienda_seleccionada)
      const res = <any>await this.leadService.lead_service_init(this.tienda_seleccionada)


      if (res.success) {
        if (res.payload) {
          if (res.payload.campus) {
            this.campus_name = res.payload.campus.name
          }
        }
      }


      this.default_values.respondio_llamada = res.payload.respondio_llamada
      this.default_values.estado_cliente = res.payload.estado_cliente
      this.default_values.tipo_filtro = res.payload.tipo_filtro
      this.default_values.modelo = res.payload.modelo
      this.default_values.plataforma = res.payload.plataforma
      this.default_values.fuente = res.payload.fuente
      this.default_values.region = res.payload.region
      this.default_values.utm_source = res.payload.utm_source
      this.default_values.utm_medium = res.payload.utm_medium
      this.default_values.utm_campaign = res.payload.utm_campaign
      this.default_values.utm_term = res.payload.utm_term
      this.default_values.financiamiento = false

      this.default_values.vendedores = res.payload.sellers_disponibles



      console.log('default_values', this.default_values)

      this.nombre_tienda_seleccionada = ''
      if (res.payload.campus) {
        this.nombre_tienda_seleccionada = `${res.payload.campus.name} - ${res.payload.campus.distrito_name}`
      }

    }




  }
  rerender(): void {

    this.buttonFiltrarTexto = `Cargando...`
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    // this.dtTrigger.unsubscribe();
  }

  exportar() {
    console.log('exportar')
  }

  reasignar() {

    // if (this.leads_selected.length > 1) {
    //   const dialogRef = this.modalService.open(ModalReasignarmasivoLeadComponent,
    //     {
    //       size: 'lg'
    //     })
    //   dialogRef.componentInstance.leads_selected = this.leads_selected
    //   dialogRef.componentInstance.jwt = JSON.parse(localStorage.user).jwt
    //   dialogRef.componentInstance.tienda_seleccionada = this.tienda_seleccionada
    //   dialogRef.result.then((data) => {
    //     if (data.success) {
    //       this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
    //         dtInstance.destroy();
    //         this.dtTrigger.next();
    //       });
    //       this.leads_selected = []
    //     }
    //   })
    // } else {
    //   Swal.fire('Para usar esta funcionalidad, debes seleccionar más de un lead', '', 'warning')
    // }
  }

  select_lead($event) {
    const id = $event.target.value
    if ($event.target.checked) {
      this.leads_selected.push(id)
    } else {
      this.leads_selected = this.leads_selected.filter(function (e) {
        return e !== id
      })

    }


  }


}


